define("safesite/templates/modals/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jd/bJSRM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\"],[\"closeModal\",[28,\"t\",[\"reset-password.title\"],null]]],{\"statements\":[[0,\"    \"],[7,\"form\",false],[12,\"role\",\"form\"],[12,\"class\",\"form-group ss-modal-form-group\"],[3,\"action\",[[23,0,[]],\"reset\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\n        \"],[1,[28,\"input\",null,[[\"id\",\"value\",\"type\",\"placeholder\",\"classNames\",\"autocomplete\",\"autocorrect\",\"autocapitalize\",\"spellcheck\"],[\"email\",[24,[\"email\"]],\"email\",[28,\"t\",[\"reset-password.placeholder.email\"],null],\"form-control input-lg\",\"off\",\"off\",\"off\",\"false\"]]],false],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[1,[28,\"loading-btn\",null,[[\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"btn btn-default btn-lg btn-primary btn-block\",[28,\"t\",[\"reset-password.button.reset\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/reset-password.hbs"
    }
  });

  _exports.default = _default;
});