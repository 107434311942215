define("safesite/components/safesite-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scrollTop: null,
    buttonClassList: ['btn'],
    isIOS: Ember.computed(function () {
      return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }),
    getClassNames: Ember.computed(function () {
      return this.buttonClassList.join(' ');
    }),
    setup: Ember.on('init', function () {
      var self = this;
      self.set('buttonClassList', ['btn']);

      if (self.get('buttonClasses')) {
        self.get('buttonClasses').forEach(function (btnClass) {
          self.get('buttonClassList').pushObject(btnClass);
        });
      } else {
        self.get('buttonClassList').pushObject('btn-lg');
        self.get('buttonClassList').pushObject('btn-default');
        self.get('buttonClassList').pushObject('btn-block');
      }
    }),
    actions: {
      closeModal: function closeModal() {
        (0, _jquery.default)('#safesite-dropdown').modal('hide');
      },
      cancel: function cancel() {
        (0, _jquery.default)('#safesite-dropdown').modal('hide');
      }
    },
    to: Ember.computed.alias('destinationElementId'),
    destinationElementId: 'safesite-modals',
    destinationElement: Ember.computed('destinationElementId', 'renderInPlace', function () {
      return this.renderInPlace ? this.element : document.getElementById(this.destinationElementId);
    }),
    renderInPlace: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      this.set('scrollTop', (0, _jquery.default)(window).scrollTop());
      (0, _jquery.default)('#safesite-dropdown').css({
        position: "absolute",
        marginTop: (0, _jquery.default)(window).scrollTop() + "px",
        bottom: "auto",
        top: "26px"
      });
      setTimeout(function () {
        (0, _jquery.default)(".modal-backdrop").css({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight) + "px"
        });
      }, 0);
      Ember.run.schedule('afterRender', this, function () {
        (0, _jquery.default)('#safesite-dropdown').modal('show').one('hidden.bs.modal', function () {
          self.sendAction();
        });
      });
      this._firstNode = this.element.firstChild;
      this._lastNode = this.element.lastChild;
      this.appendToDestination();
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var firstNode = this._firstNode;
      var lastNode = this._lastNode;
      Ember.run.schedule('render', function () {
        _this.removeRange(firstNode, lastNode);
      });
    },
    destinationDidChange: Ember.observer('destinationElement', function () {
      var destinationElement = this.destinationElement;

      if (destinationElement !== this._firstNode.parentNode) {
        Ember.run.schedule('render', this, 'appendToDestination');
      }
    }),
    appendToDestination: function appendToDestination() {
      var destinationElement = this.destinationElement;
      var currentActiveElement = document.activeElement;

      if (!destinationElement) {
        var destinationElementId = this.destinationElementId;

        if (destinationElementId) {
          throw new Error("ember-wormhole failed to render into '#".concat(this.destinationElementId, "' because the element is not in the DOM"));
        }

        throw new Error('ember-wormhole failed to render content because the destinationElementId was set to an undefined or falsy value.');
      }

      this.appendRange(destinationElement, this._firstNode, this._lastNode);

      if (document.activeElement !== currentActiveElement) {
        currentActiveElement.focus();
      }
    },
    appendRange: function appendRange(destinationElement, firstNode, lastNode) {
      while (firstNode) {
        destinationElement.insertBefore(firstNode, null);
        firstNode = firstNode !== lastNode ? lastNode.parentNode.firstChild : null;
      }
    },
    removeRange: function removeRange(firstNode, lastNode) {
      var node = lastNode;

      do {
        var next = node.previousSibling;

        if (node.parentNode) {
          node.parentNode.removeChild(node);

          if (node === firstNode) {
            break;
          }
        }

        node = next;
      } while (node);
    }
  });

  _exports.default = _default;
});