define("safesite/controllers/modals/logout_confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LogoutConfirmationModalController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    applicationController: Ember.inject.controller('application'),
    i18n: Ember.inject.service(),
    isLoggingOut: false,
    unableToLogout: false,
    showForceLogoutButton: Ember.computed('unableToLogout', 'applicationController.isOnline', function () {
      return this.unableToLogout || !this.applicationController.isOnline;
    }),
    logoutErrorMessage: Ember.computed('unableToLogout', 'applicationController.isOnline', function () {
      if (!this.applicationController.isOnline) {
        return this.i18n.t('logout-confirm.subtitle.message.offline');
      } else if (this.unableToLogout) {
        return this.i18n.t('logout-confirm.subtitle.message.unableToLogout');
      }

      ;
    }),
    actions: {
      logout: function logout() {
        var _this = this;

        this.set('isLoggingOut', true);
        this.authController.send('logout', function () {
          _this.set('isLoggingOut', false);

          _this.set('unableToLogout', true);

          _this.woof.danger(_this.i18n.t('logout-confirm.message.error'));
        });
      },
      forceLogout: function forceLogout() {
        this.set('isLoggingOut', true);
        this.authController.send('destroyLocalDBAndLogout');
      }
    }
  });
  var _default = LogoutConfirmationModalController;
  _exports.default = _default;
});