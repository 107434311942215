define("safesite/adapters/online", ["exports", "ember-data", "safesite/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    namespace: _environment.default.APP.API_NAMESPACE,
    host: _environment.default.APP.API_HOST,
    coalesceFindRequests: true,
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    headers: function () {
      return {
        "Authorization": "Token ".concat(localStorage.getItem('api_token'))
      };
    }.property().volatile()
  });

  _exports.default = _default;
});