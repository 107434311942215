define("safesite/components/time-field", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DateField = Ember.Component.extend({
    classNames: ['timepicker'],
    i18n: Ember.inject.service(),
    hasFocus: false,
    timeValue: null,
    showErrors: false,
    required: Ember.computed('errors.[]', 'showErrors', function () {
      return !Ember.isEmpty(this.errors) && this.showErrors;
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      (0, _jquery.default)('.timepicker').pickatime({
        clear: this.i18n.t('datePicker.clear'),
        onSet: function onSet() {
          self.set('timeValue', (0, _jquery.default)('.timepicker').val());
        }
      });
    }
  });
  var _default = DateField;
  _exports.default = _default;
});