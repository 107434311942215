define("safesite/controllers/notifications/index", ["exports", "safesite/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotificationsIndexController = Ember.Controller.extend(_pagination.default, {
    authController: Ember.inject.controller('auth'),
    queryParams: [{
      tab: 'unread',
      page: 0
    }],
    navMenuTabBar: Ember.inject.service(),
    i18n: Ember.inject.service(),
    tab: 'unread',
    init: function init() {
      this._super();

      this.navMenuTabBar.set('controller', this);
    },
    updateTabs: function updateTabs() {
      this.navMenuTabBar.set('tabs', [{
        isActive: this.tab === 'unread',
        tabName: 'unread',
        label: this.i18n.t('notifications.index.filter.unread')
      }, {
        isActive: this.tab === 'read',
        tabName: 'read',
        label: this.i18n.t('notifications.index.filter.read')
      }]);
    },
    entriesSorting: ['created_date_time:desc'],
    sortedNotifications: Ember.computed('model.[]', 'paginatedModel.length', 'page', 'tab', function () {
      return this.paginatedModel;
    }),
    actions: {
      selectTab: function selectTab(tab) {
        this.set('tab', tab);
        this.set('page', 0);
        this.transitionToRoute({
          queryParams: {
            tab: tab,
            page: this.page
          }
        });
      }
    }
  });
  var _default = NotificationsIndexController;
  _exports.default = _default;
});