define("safesite/services/basic-signature-creator", ["exports", "safesite/config/environment", "uuid"], function (_exports, _environment, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    generateObject: function generateObject(_ref) {
      var referral = _ref.referral,
          company_name = _ref.company_name,
          email = _ref.email,
          firstName = _ref.firstName,
          lastName = _ref.lastName,
          createdBy = _ref.createdBy,
          modifiedBy = _ref.modifiedBy,
          projects = _ref.projects,
          memberType = _ref.memberType;
      email = Ember.isPresent(email) ? email : (0, _uuid.v4)();
      var profile = {
        referral: referral,
        company_name: company_name || 'N/A',
        key_expires: moment.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z',
        has_verified_email: false,
        default_language: 0,
        phone_number: '',
        role: '',
        time_zone: 'UTC'
      };
      var person = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        username: email,
        profile: profile,
        created_by: createdBy,
        modified_by: modifiedBy,
        created_date_time: moment.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z',
        modified_date_time: moment.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z',
        invite_to_project: {
          'email': email,
          'projects': projects,
          'member_type': memberType
        }
      };
      return person;
    },
    generateObjectFromUserRecord: function generateObjectFromUserRecord(_ref2) {
      var user = _ref2.user;
      var userJSON = user.serialize();
      var person = {
        first_name: userJSON.first_name,
        last_name: userJSON.last_name,
        email: userJSON.email,
        username: userJSON.username,
        profile: userJSON.profile,
        created_by: userJSON.created_by,
        modified_by: userJSON.modified_by,
        created_date_time: userJSON.created_date_time,
        modified_date_time: userJSON.modified_date_time
      };
      return person;
    }
  });

  _exports.default = _default;
});