define("safesite/controllers/observation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObservationIndexController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    deleteMode: false,
    positive: Ember.computed('model.nature', function () {
      return this.get('model.nature') === "safe";
    }),
    actions: {
      addComment: function addComment(comment) {
        comment.set('object_pk', this.get('model.id'));
        comment.save();
      },
      edit: function edit() {
        this.transitionToRoute('observation.edit');
      },
      delete: function _delete() {
        this.toggleProperty('deleteMode');
      },
      cancelDelete: function cancelDelete() {
        // set deleteMode back to false
        this.set('deleteMode', false);
      },
      confirmDelete: function confirmDelete() {
        // tell Ember to delete the current user - use self as a proxy as 'this' becomes unknown in callback due to object scope
        var self = this;
        this.set('deleteMode', false);
        this.model.deleteRecord();
        this.model.save().then(function () {
          self.transitionToRoute('observations');
        }).catch(function () {});
      },
      goBack: function goBack() {
        window.history.back();
      }
    }
  });
  var _default = ObservationIndexController;
  _exports.default = _default;
});