define("safesite/routes/meeting/add-attendees", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MeetingAddAttendeesRoute = _restricted.default.extend({
    i18n: Ember.inject.service(),
    model: function model() {
      return this.modelFor('meeting');
    },
    setupController: function setupController(controller, model) {
      model.reload();
      var loadme = model.get('attendees');
      controller.set('model', model);
      this.controllerFor('application').send('enterNewItemMode', this.i18n.t('meetings.addAttendees'));
    },
    actions: {}
  });

  var _default = MeetingAddAttendeesRoute;
  _exports.default = _default;
});