define("safesite/initializers/footer-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:footer-menu', 'session', 'controller:auth');
    application.inject('component:footer-menu', 'siteController', 'controller:site');
    application.inject('component:footer-menu', 'store', 'service:store');
  }

  var _default = {
    name: 'footer-menu',
    initialize: initialize
  };
  _exports.default = _default;
});