define("safesite/controllers/modals/email-export", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ModalEmailExportController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    content: null,
    personnel: [],
    results: [],
    recipients: [],
    email: null,
    report_url: null,
    message: null,
    validEmail: Ember.computed('email', function () {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    }),
    filterResults: Ember.observer('email', 'personnel.[]', function () {
      var self = this;
      var inputVal = self.get('email');
      var content = self.get('personnel');

      if (content && !Ember.isEmpty(inputVal) && content.length !== 0) {
        self.set('results', content.filter(function (item) {
          return inputVal.split(' ').every(function (input) {
            var emailMatch = false;
            var firstNameMatch = false;
            var lastNameMatch = false;
            var fullNameMatch = false;

            if (!Ember.isEmpty(item.get('email'))) {
              emailMatch = item.get('email').toLowerCase().search(input.toLowerCase()) !== -1;
            }

            if (!Ember.isEmpty(item.get('first_name'))) {
              firstNameMatch = item.get('first_name').toLowerCase().search(input.toLowerCase()) !== -1;
            }

            if (!Ember.isEmpty(item.get('last_name'))) {
              lastNameMatch = item.get('last_name').toLowerCase().search(input.toLowerCase()) !== -1;
            }

            if (!Ember.isEmpty(item.get('full_name'))) {
              fullNameMatch = item.get('full_name').toLowerCase().search(input.toLowerCase()) !== -1;
            }

            return emailMatch || firstNameMatch || lastNameMatch || fullNameMatch;
          });
        }).slice(0, 3));
      } else if (Ember.isEmpty(inputVal)) {
        self.setProperties({
          'results': []
        });
      }
    }),
    actions: {
      send: function send() {
        var self = this;
        var data = this.get('content');

        if (Ember.isEmpty(this.get('recipients'))) {
          self.woof.danger('Please add a Recipient.');
        } else {
          data.email = this.get('recipients').map(function (item) {
            return Ember.get(item, 'email');
          });
          data.tz = (0, _moment.default)().zone();
          data.message = this.get('message');
          Ember.$.get(this.get('report_url') + '/', data, 'json').done(function () {
            self.woof.success('Report successfully sent.');
          }).fail(function () {
            self.woof.warning('Report send failed. Please contact support.');
          });
          self.get('recipients').map(function (item) {
            return self.get('personnel').addObject(item);
          });
          self.set('recipients', []);
          self.set('message', "");
          (0, _jquery.default)('.modal').modal('hide');
        }
      },
      sendToMe: function sendToMe() {
        var self = this;
        var data = self.get('content');
        self.get('authController.content').then(function (user) {
          data.email = user.get('email');
          data.tz = (0, _moment.default)().zone();

          _jquery.default.get(self.get('report_url') + '/', data, 'json').done(function () {
            self.woof.success('Report successfully sent.');
          }).fail(function () {
            self.woof.warning('Report send failed. Please contact support.');
          });

          (0, _jquery.default)('.modal').modal('hide');
        });
      },
      cancel: function cancel() {
        (0, _jquery.default)('.modal').modal('hide');
      },
      addRecipient: function addRecipient(recipient) {
        this.recipients.addObject(recipient);
        this.personnel.removeObject(recipient);
        this.results.removeObject(recipient);
        this.set('email', '');
      },
      removeRecipient: function removeRecipient(recipient) {
        this.recipients.removeObject(recipient);

        if (Ember.get(recipient, 'full_name') !== 'Unknown') {
          this.personnel.addObject(recipient);
        }
      },
      addExternalEmailAddress: function addExternalEmailAddress() {
        var extern = {
          email: this.email,
          full_name: 'Unknown'
        };

        if (this.recipients.every(function (item) {
          return Ember.get(item, 'email') !== Ember.get(extern, 'email');
        })) {
          this.recipients.pushObject(extern);
          this.set('email', '');
        }
      }
    }
  });
  var _default = ModalEmailExportController;
  _exports.default = _default;
});