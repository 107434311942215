define("safesite/controllers/modals/sign-up-steps", ["exports", "jquery", "safesite/config/environment", "email-validator"], function (_exports, _jquery, _environment, _emailValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SignUpModalStepsController = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    i18n: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    applicationController: Ember.inject.controller('application'),
    requiredFields: ['email'],
    requiredFieldsStep2: ['email', 'firstName', 'lastName', 'password', 'mobile'],
    email: null,
    password: null,
    password2: null,
    firstName: null,
    lastName: null,
    company: null,
    mobile: null,
    org_name: null,
    org_id: null,
    new_org: true,
    isLoading: false,
    showStep1: true,
    showStep2: false,
    showStep3: false,
    showStep4: false,
    showStep5: false,
    project_names: [],
    passwordVisible: false,
    passwordType: Ember.computed('passwordVisible', function () {
      if (this.passwordVisible) {
        return 'text';
      } else {
        return 'password';
      }
    }),
    passwordMsg: Ember.computed('i18n.locale', 'passwordVisible', function () {
      if (this.passwordVisible) {
        return this.i18n.t('auth.password.hide');
      } else {
        return this.i18n.t('auth.password.show');
      }
    }),
    user: Ember.computed('authController.model', function () {
      return this.get('authController.model');
    }),
    profile: Ember.computed('authController.model.profile', function () {
      return this.get('authController.model.profile');
    }),
    init: function init() {
      this._super();

      if (this.user) {
        this.set('email', this.get('user.email'));
        this.set('firstName', this.get('user.first_name'));
        this.set('lastName', this.get('user.last_name'));
      }

      this.applicationController.send('enterNewItemMode', '');
    },
    actions: {
      togglePasswordType: function togglePasswordType() {
        this.toggleProperty('passwordVisible');
      },
      cancel: function cancel() {
        (0, _jquery.default)('.modal').modal('hide');
        this.authController.send('logout');
        this.applicationController.send('exitNewItemMode');
      },
      step1: function step1() {
        var self = this;
        self.set('isLoading', true);
        var default_language = 0;

        switch (self.get('i18n.locale')) {
          case "en":
            default_language = 0;
            break;

          case "es":
            default_language = 1;
            break;

          case "pt":
            default_language = 2;
            break;

          case "fr":
            default_language = 3;
            break;
        } // Collect missing fields


        var missingFields = self.get('requiredFields').reduce(function (acc, val) {
          if (Ember.isEmpty(self.get(val))) {
            acc.push(val);
          }

          return acc;
        }, []);

        if (!Ember.isEmpty(missingFields)) {
          self.woof.danger('This following fields are mandatory: ' + missingFields.reduce(function (acc, val) {
            return acc + ', ' + val;
          }), "");
          self.set('isLoading', false);
        } else {
          if (self.get('email')) {
            if (!(0, _emailValidator.validate)(self.email)) {
              self.woof.danger(self.i18n.t('default-form-messages.enter.valid.email'));
              self.set('isLoading', false);
            } else {
              self.set('email', self.get('email').toLowerCase());
              var data = {
                'email': self.get('email').toLowerCase(),
                'default_language': default_language
              };

              _jquery.default.ajax({
                data: data,
                async: true,
                type: 'POST',
                url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/step1Signup'
              }).then(function (res_data) {
                self.get('authController').send('setLogin', self.get('email').toLowerCase(), res_data.token);
                self.set('isLoading', false);
                self.set('showStep1', false);
                self.set('showStep2', true);
                self.set('showStep3', false);
                self.set('showStep4', false);
                self.set('showStep5', false);
              }).catch(function (error) {
                self.set('isLoading', false);
                self.woof.danger(error.responseText);
              });
            }
          }
        }

        window.scrollTo(0, 0);
      },
      step2: function step2() {
        var self = this;
        self.set('isLoading', true); // Collect missing fields

        var missingFields = self.get('requiredFieldsStep2').reduce(function (acc, val) {
          if (Ember.isEmpty(self.get(val))) {
            acc.push(val);
          }

          return acc;
        }, []);

        if (!Ember.isEmpty(missingFields)) {
          self.woof.danger('This following fields are mandatory: ' + missingFields.reduce(function (acc, val) {
            return acc + ', ' + val;
          }), "");
          self.set('isLoading', false);
        } else {
          if (!(self.get('password') == self.get('password2'))) {
            self.woof.danger('Passwords do not match');
            self.set('isLoading', false);
          } else {
            if (self.get('email')) {
              if (!(0, _emailValidator.validate)(self.email)) {
                self.woof.danger(self.i18n.t('default-form-messages.enter.valid.email'));
                self.set('isLoading', false);
              } else {
                self.set('email', self.get('email').toLowerCase());
                var data = self.getProperties('email', 'password', 'firstName', 'lastName', 'mobile');

                _jquery.default.ajax({
                  data: data,
                  async: true,
                  type: 'POST',
                  url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/step2Signup',
                  headers: {
                    'Authorization': 'Token ' + self.get('authController.api_token')
                  }
                }).then(function (res_data) {
                  self.set('isLoading', false);
                  self.set('showStep1', false);
                  self.set('showStep2', false);
                  self.set('showStep3', true);
                  self.set('showStep4', false);
                  self.set('showStep5', false);

                  if (res_data.organization_name) {
                    self.set('org_name', res_data.organization_name);
                    self.set('org_id', res_data.organization_id);
                    self.set('new_org', false);
                  }
                }).catch(function (error) {
                  self.set('isLoading', false);
                  self.woof.danger(error.responseText);
                });
              }
            }
          }
        }

        window.scrollTo(0, 0);
      },
      step3: function step3() {
        var self = this;
        self.set('isLoading', true);

        if (Ember.isEmpty(this.org_name)) {
          self.woof.danger('This following fields are mandatory: ' + "Company");
          self.set('isLoading', false);
        } else {
          self.set('email', self.get('email').toLowerCase());
          var data = {
            'company_name': self.get('org_name'),
            'company_id': self.get('org_id'),
            'new_company': self.get('new_org')
          };

          _jquery.default.ajax({
            data: data,
            async: true,
            type: 'POST',
            url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/step3Signup',
            headers: {
              'Authorization': 'Token ' + self.get('authController.api_token')
            }
          }).then(function (res_data) {
            self.set('isLoading', false);
            self.set('showStep1', false);
            self.set('showStep2', false);
            self.set('showStep3', false);
            self.set('showStep4', true);
            self.set('showStep5', false); //Check if projects

            self.set('project_names', res_data.projects);
          }).catch(function (error) {
            self.set('isLoading', false);
            self.woof.danger(error.responseText);
          });
        }

        window.scrollTo(0, 0);
      },
      step4: function step4() {
        var self = this;
        self.set('isLoading', false);
        self.set('showStep1', false);
        self.set('showStep2', false);
        self.set('showStep3', false);
        self.set('showStep4', false);
        self.set('showStep5', true);
        window.scrollTo(0, 0);
      },
      resetActivation: function resetActivation() {
        var self = this;
        self.set('isLoading', true);
        var data = {
          'company_name': self.get('org_name'),
          'email': self.get('email')
        };

        _jquery.default.ajax({
          data: data,
          async: true,
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/resendActivationSignup',
          headers: {
            'Authorization': 'Token ' + self.get('authController.api_token')
          }
        }).then(function () {
          self.set('isLoading', false);
          self.set('showStep1', false);
          self.set('showStep2', false);
          self.set('showStep3', false);
          self.set('showStep4', false);
          self.set('showStep5', true);
          self.set('isLoading', false);
          self.woof.info("Sent Activation Email");
        }).catch(function (error) {
          self.set('isLoading', false);
          self.woof.danger(error.responseText);
        });
      }
    }
  });
  var _default = SignUpModalStepsController;
  _exports.default = _default;
});