define("safesite/models/location", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var Location = _abstract.default.extend({
    name: attr(),
    site: _emberData.default.belongsTo('site', {
      async: false
    }),
    is_deleted: attr()
  });

  var _default = Location;
  _exports.default = _default;
});