define("safesite/components/modal-dialog", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scrollTop: null,
    title: null,
    modalId: 'modal-dialog',
    additionalClass: null,
    dialogClass: Ember.computed('additionalClass', function () {
      return this.additionalClass ? "modal ".concat(this.additionalClass) : "modal ";
    }),
    module: null,
    backdrop: null,
    keyboard: null,
    focus: null,
    hideCancel: false,
    CancelButtonText: null,
    buttonClass: 'btn btn-default btn-lg btn-block',
    overrideCancelAction: false,
    cancelAction: null,
    icon: Ember.computed('module', function () {
      var module = this.module;

      if (module === 'hazard') {
        return '<i class="icon-Hazard"></i>';
      } else if (module === 'inspection') {
        return '<i class="icon-Inspection"></i>';
      } else if (module === 'meeting') {
        return '<i class="icon-Meeting"></i>';
      } else if (module === 'equipment') {
        return '<i class="icon-Equipment"></i>';
      } else if (module === 'observation') {
        return '<i class="icon-Observation"></i>';
      } else if (module === 'incident-report') {
        return '<i class="icon-Incident"></i>';
      }

      return '';
    }),
    isIOS: Ember.computed(function () {
      return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }),
    didInsertElement: function didInsertElement() {
      this.set('scrollTop', (0, _jquery.default)(window).scrollTop());
      (0, _jquery.default)('.modal').css({
        position: "absolute",
        marginTop: (0, _jquery.default)(window).scrollTop() + "px",
        bottom: "auto",
        top: "26px"
      });
      setTimeout(function () {
        (0, _jquery.default)(".modal-backdrop").css({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight) + "px"
        });
      }, 0);
      Ember.run.schedule('afterRender', this, function () {
        var self = this;
        (0, _jquery.default)('.modal').modal('show').one('hidden.bs.modal', function () {
          Ember.run.schedule("afterRender", this, function () {
            (0, _jquery.default)('html, body').scrollTop(self.get('scrollTop'));
            self.sendAction();
          });
        });
      });
    },
    actions: {
      defaultCancel: function defaultCancel() {
        (0, _jquery.default)("#".concat(this.elementId, " .modal")).modal('hide');
      },
      overrideAction: function overrideAction() {
        //  need () to execute the function
        this.cancelAction();
      },
      cancel: function cancel() {
        var _overrideAction = this.overrideCancelAction;

        if (_overrideAction) {
          this.send('overrideAction');
        } else {
          this.send('defaultCancel');
        }
      }
    }
  });

  _exports.default = _default;
});