define("safesite/templates/components/safesite-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/nn5WTfJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"textarea\",null,[[\"placeholder\",\"value\",\"autocorrect\",\"autocomplete\",\"autocapitalize\",\"spellcheck\",\"maxlength\",\"rows\",\"cols\",\"disabled\",\"tabindex\",\"selectionStart\",\"selectionEnd\",\"selectionDirection\",\"wrap\",\"readonly\",\"autofocus\",\"form\",\"spellcheck\",\"required\",\"style\",\"class\"],[[24,[\"placeholder\"]],[24,[\"value\"]],[24,[\"autocorrect\"]],[24,[\"autocomplete\"]],[24,[\"autocapitalize\"]],[24,[\"spellcheck\"]],[24,[\"maxlength\"]],[24,[\"rows\"]],[24,[\"cols\"]],[24,[\"disabled\"]],[24,[\"tabindex\"]],[24,[\"selectionStart\"]],[24,[\"selectionEnd\"]],[24,[\"selectionDirection\"]],[24,[\"wrap\"]],[24,[\"readonly\"]],[24,[\"autofocus\"]],[24,[\"form\"]],[24,[\"spellcheck\"]],[24,[\"required\"]],[24,[\"style\"]],\"safesite-textarea\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showCounter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"safesite-textarea-counter\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"info-block\"],[8],[1,[22,\"counterDisplay\"],false],[9],[0,\"\\n    \"],[9],[7,\"div\",true],[10,\"class\",\"safesite-textarea-counter-padding\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"safesite-textarea-counter-padding\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/safesite-textarea.hbs"
    }
  });

  _exports.default = _default;
});