define("safesite/models/injury", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Injury = _abstract.default.extend({
    type: _emberData.default.belongsTo('injuryType', {
      async: true
    }),
    body_part: _emberData.default.hasMany('bodyPart', {
      async: true
    }),
    person: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    description: _emberData.default.attr(),
    objectsInvolved: _emberData.default.attr(),
    incident: _emberData.default.belongsTo('incidentReport', {
      async: false
    }),
    treatment: _emberData.default.attr('raw'),
    is_deleted: _emberData.default.attr(),
    is_added_to_injury_log: _emberData.default.attr('boolean'),
    site: _emberData.default.belongsTo('site', {
      async: true
    }),
    bodyPartsNames: Ember.computed('body_part.[]', function () {
      return this.body_part.map(function (item) {
        return item.name;
      }).join(', ');
    })
  });

  var _default = Injury;
  _exports.default = _default;
});