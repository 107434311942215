define("safesite/components/incident-item", ["exports", "ember-i18n"], function (_exports, _emberI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IncidentItemComponent = Ember.Component.extend({
    i18n: Ember.inject.service(),
    layoutName: 'components/incident-item',
    record: null,
    showProjectName: false,
    showModuleName: true,
    moduleName: (0, _emberI18n.translationMacro)("models.Incident"),
    showIcon: true,
    showLocation: true,
    showImage: true,
    disableLinkTo: false
  });
  var _default = IncidentItemComponent;
  _exports.default = _default;
});