define("safesite/initializers/inject-woof-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "injectWoofMessages",
    initialize: function initialize(application) {
      application.inject('controller', 'woof', 'woof:main');
      application.inject('component', 'woof', 'woof:main');
      application.inject('route', 'woof', 'woof:main');
      application.inject('adapter', 'woof', 'woof:main');
      application.inject('service', 'woof', 'woof:main');
    }
  };
  _exports.default = _default;
});