define("safesite/templates/modals/project-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qocteHOK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"id\",\"CancelButtonText\",\"action\",\"title\"],[\"project-create\",\"OK\",\"closeModal\",\"To create your own project or team, use our Safety Analytics Dashboard\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"id\",\"project-create-image\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"img-responsive\"],[10,\"src\",\"assets/images/safesite-pro-select@2x.png\"],[8],[9],[0,\"\\n        \"],[7,\"hr\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"project-create-footer\"],[8],[0,\"\\n        Visit \"],[7,\"a\",true],[10,\"href\",\"https://safesiteapp.com\"],[8],[0,\"safesiteapp.com\"],[9],[0,\" on a desktop computer\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/project-create.hbs"
    }
  });

  _exports.default = _default;
});