define("safesite/models/topic", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Topic = _abstract.default.extend({
    text: _emberData.default.attr(),
    link_text: _emberData.default.attr(),
    link_url: _emberData.default.attr(),
    order: _emberData.default.attr(),
    prefixedId: Ember.computed('order', function () {
      return "q" + this.order;
    })
  });

  var _default = Topic;
  _exports.default = _default;
});