define("safesite/mixins/scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({// bindScrolling: function(opts) {
    //     var onScroll, _this = this;
    //     opts = opts || {debounce: 100, target=};
    //     if opts.target {
    //     }
    //     if (opts.debounce) {
    //         onScroll = App.debounce(function() { return _this.scrolled(); }, 100);
    //     } else {
    //         onScroll = function(){ 
    //             return _this.scrolled(); 
    //         };
    //     }
    //     $(document).bind('touchmove', onScroll);
    //     $(window).bind('scroll', onScroll);
    // },
    // unbindScrolling: function() {
    //     $(window).unbind('scroll');
    //     $(document).unbind('touchmove');
    // }
  });

  _exports.default = _default;
});