define("safesite/routes/hazard", ["exports", "safesite/routes/multi-model"], function (_exports, _multiModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _multiModel.default.extend({
    modelName: 'hazard',
    recordId: 'hazard_id'
  });

  _exports.default = _default;
});