define("safesite/templates/modals/template-section-skip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t31TnxEM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"hideCancel\",\"title\"],[\"closeModal\",false,[28,\"t\",[\"inspections.skip-modal.title\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[1,[24,[\"section\",\"text\"]],false],[0,\" (\"],[1,[24,[\"section\",\"questions\",\"length\"]],false],[0,\" \"],[1,[28,\"t\",[\"inspections.skip-modal.label.questions\"],null],false],[0,\")\"],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[1,[28,\"t\",[\"inspections.skip-modal.message\"],null],false],[9],[0,\"\\n\\n\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-lg btn-primary btn-block\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"doSectionSkip\"]],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"inspections.skip-modal.button.confirm\"],null],false],[0,\" \\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/template-section-skip.hbs"
    }
  });

  _exports.default = _default;
});