define("safesite/controllers/modals/dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DropdownModalController = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    isLoading: false,
    isSaving: false,
    name: "",
    inputValue: "",
    results: [],
    scrollerHeight: null,
    creatable: true,
    title: Ember.computed('i18n.locale', 'name', function () {
      return this.i18n.t("modals.dropdown.text.pleaseSelect") + " " + this.name;
    }),
    placeholder: Ember.computed('creatable', function () {
      if (this.creatable) {
        return this.i18n.t('modals.dropdown.text.searchOrAdd');
      } else {
        return this.i18n.t('modals.dropdown.text.search');
      }
    }),
    resultsAreEmpty: Ember.computed('inputValue', 'results', function () {
      var self = this;
      var inputValue = this.inputValue;

      if (Ember.isEmpty(inputValue)) {
        return self.get('content.length') === 0;
      }

      return this.results.length === 0;
    }),
    resultCount: Ember.computed('inputValue', 'results', function () {
      var self = this;
      var inputValue = this.inputValue;

      if (Ember.isEmpty(inputValue)) {
        return self.get('content.length');
      }

      return this.results.length;
    }),
    isExisting: Ember.computed('inputValue', 'results', function () {
      var inputValue = this.inputValue.trim().toLowerCase();
      return Ember.A(this.results).any(function (item) {
        return item.get('name').trim().toLowerCase() === inputValue;
      });
    }),
    filterResults: Ember.observer('inputValue', function () {
      var self = this;
      var inputValue = self.get('inputValue');
      var content = self.get('content');
      var path = self.get('labelPath');

      if (content && !Ember.isEmpty(inputValue)) {
        self.set('results', content.filter(function (item) {
          var inString = true;

          if (item.get(path)) {
            var name = item.get(path).trim().toLowerCase();

            if (name.search(inputValue.trim().toLowerCase()) === -1) {
              inString = false;
            }
          }

          return inString;
        }));
      } else if (Ember.isEmpty(inputValue)) {
        self.setProperties({
          'results': content
        });
      }
    }),
    actions: {
      selectValue: function selectValue(selectedValue) {
        if (this.hasMany) {
          this.get('view.value').pushObject(selectedValue);
        } else {
          this.set('view.value', selectedValue);
        }

        (0, _jquery.default)('#dropdown').modal('hide');
        (0, _jquery.default)(window).scrollTop(localStorage.getItem('scrollTop'));
      },
      addNewValue: function addNewValue() {
        var self = this;

        if (!self.get('isSaving')) {
          self.set('isSaving', true);
          var modelName = self.get('name');
          modelName = modelName.replace(/\s+/g, '');
          modelName = modelName.camelize();

          if (modelName.toLowerCase() === 'project') {
            modelName = 'Site';
          }

          var model = self.store.createRecord(modelName, {});
          var savePromise = self.getPromise(model, self.get('labelPath'), self.get('inputValue'));
          savePromise.then(function (model) {
            self.send('selectValue', model);
            self.set('isSaving', false);
            (0, _jquery.default)('#dropdown').modal('hide');
          }).catch(function (err) {
            if (err && err.message) {
              self.woof.danger(err.message);
            } else {
              self.woof.danger(self.get('i18n').t('errors.network'));
            }

            model.deleteRecord();
            self.set('isSaving', false);
          });
        }
      }
    }
  });
  var _default = DropdownModalController;
  _exports.default = _default;
});