define("safesite/templates/sites/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49KcG2fi",
    "block": "{\"symbols\":[\"site\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"list-group\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"ss-list-group-item btn-group\"],[8],[0,\"\\n            \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"site\",[23,1,[]]]],{\"statements\":[[7,\"div\",true],[8],[7,\"strong\",true],[8],[1,[23,1,[\"name\"]],false],[9],[7,\"br\",true],[8],[9],[7,\"small\",true],[8],[1,[23,1,[\"address\"]],false],[9],[9]],\"parameters\":[]},null],[0,\" \\n            \"],[7,\"div\",true],[10,\"class\",\"drop-down-holder\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"ss-dropdown-menu\"],[10,\"role\",\"menu\"],[11,\"aria-labelledby\",[29,[[23,1,[\"id\"]]]]],[8],[0,\"\\n                    \"],[7,\"a\",false],[12,\"href\",\"javascript:void(0);\"],[3,\"action\",[[23,0,[]],\"inviteSite\"]],[8],[0,\"Invite\"],[9],[0,\"\\n                    \"],[7,\"a\",true],[10,\"href\",\"javascript:void(0);\"],[8],[0,\"Edit\"],[9],[0,\"\\n                    \"],[7,\"a\",true],[10,\"class\",\"delete\"],[10,\"href\",\"javascript:void(0);\"],[8],[0,\"Delete\"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"a\",true],[11,\"id\",[29,[[23,1,[\"id\"]]]]],[10,\"class\",\"btn btn-default dropdown-toggle\"],[10,\"role\",\"button\"],[10,\"data-toggle\",\"dropdown\"],[8],[7,\"strong\",true],[8],[7,\"i\",true],[10,\"class\",\"icon-circle icon\"],[8],[9],[7,\"i\",true],[10,\"class\",\"icon-circle icon\"],[8],[9],[7,\"i\",true],[10,\"class\",\"icon-circle icon\"],[8],[9],[9],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ss-list-group-item btn-group\"],[8],[4,\"link-to\",null,[[\"route\"],[\"sites.create\"]],{\"statements\":[[7,\"strong\",true],[8],[0,\"Add Your Project\"],[9]],\"parameters\":[]},null],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/sites/index.hbs"
    }
  });

  _exports.default = _default;
});