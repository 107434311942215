define("safesite/templates/notifications/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L7ivAr1X",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tabbed-page\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedNotifications\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"notification-item\",null,[[\"notification\",\"user\"],[[23,1,[]],[24,[\"authController\",\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"ss-empty-actions\"],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"ss-empty-actions-subtitle\"],[8],[1,[28,\"t\",[\"notifications.index.message.empty\"],null],false],[9],[0,\"\\n            \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \\n    \"],[15,\"mixins/pagination\",[]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "safesite/templates/notifications/index.hbs"
    }
  });

  _exports.default = _default;
});