define("safesite/store/application", ["exports", "ember-data", "safesite/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Store = _emberData.default.Store.extend({
    adapter: _application.default
  });

  var _default = Store;
  _exports.default = _default;
});