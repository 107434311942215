define("safesite/templates/modals/logout_confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AbT/RwrM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\"],[\"closeModal\",[28,\"t\",[\"logout-confirm.title\"],null]]],{\"statements\":[[4,\"unless\",[[24,[\"showForceLogoutButton\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"logout\",\"btn btn-primary btn-lg btn-block\",[28,\"t\",[\"logout-confirm.button.yes\"],null],[24,[\"isLoggingOut\"]],[24,[\"isLoggingOut\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[1,[22,\"logoutErrorMessage\"],false],[9],[0,\"\\n\\n        \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"forceLogout\",\"btn btn-primary btn-lg btn-block\",[28,\"t\",[\"logout-confirm.button.forceLogout\"],null],[24,[\"isLoggingOut\"]],[24,[\"isLoggingOut\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/logout_confirmation.hbs"
    }
  });

  _exports.default = _default;
});