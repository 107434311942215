define("safesite/templates/layouts/savebtn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HRDUbNRW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",false],[12,\"disabled\",[29,[[24,[\"model\",\"isSaving\"]]]]],[12,\"class\",[29,[[28,\"if\",[[24,[\"model\",\"isSaving\"]],\"is-saving\"],null],\" btn btn-primary btn-lg btn-block\"]]],[3,\"action\",[[23,0,[]],\"submit\"]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading-container\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"spinner-holder\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"spinner tiny\"],[8],[0,\" \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/layouts/savebtn.hbs"
    }
  });

  _exports.default = _default;
});