define("safesite/components/image-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['img-wrapper'],
    classNameBindings: ['loaded', 'confirming', 'errorThrown'],
    loaded: false,
    confirming: false,
    image: null,
    _resolveImage: Ember.on('didRender', function () {
      var component = this;
      var image = component.$('img');
      component.set('image', component.get('image'));
      image.one('load', function () {
        image.off('error');

        if (!component.isDestroyed) {
          Ember.run.schedule('afterRender', component, function () {
            return Ember.set(component, 'loaded', true);
          });
        }
      });
      image.one('error', function () {
        image.off('load');
        Ember.run.schedule('afterRender', component, function () {
          return Ember.set(component, 'errorThrown', true);
        });
      });
    }),
    getImageSrc: Ember.computed('image.src', 'image.full', function () {
      if (this.image.full) {
        return this.image.full;
      } else if (this.image.data) {
        return "data:".concat(this.image.content_type, ";base64,").concat(this.image.data);
      }
    }),
    actions: {
      deleteRequest: function deleteRequest() {
        if (!this.errorThrown) {
          this.set('confirming', true);
        }
      },
      deleteConfirm: function deleteConfirm(image) {
        if (this.parentModel) {
          this.parentModel[this.parentModelDeleteMethod](this.model, this.image);
        } else {
          this.model.deleteImage(this.fieldName, this.image);
        }

        this.set('confirming', false);
      },
      deleteCancel: function deleteCancel() {
        this.set('confirming', false);
      },
      openLightbox: function openLightbox() {
        if (!this.errorThrown) {
          this.sendAction('openLightboxHandler', this.image);
        }
      }
    }
  });

  _exports.default = _default;
});