define("safesite/controllers/modals/reset-password", ["exports", "jquery", "safesite/config/environment", "ember-i18n", "email-validator"], function (_exports, _jquery, _environment, _emberI18n, _emailValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ResetPasswordModalController = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    requiredFields: ['email'],
    email: null,
    isLoading: false,
    actions: {
      reset: function reset() {
        var self = this;
        self.set('isLoading', true); // Collect missing fields

        var missingFields = self.get('requiredFields').reduce(function (acc, val) {
          if (Ember.isEmpty(self.get(val))) {
            acc.push(val);
          }

          return acc;
        }, []);

        if (!Ember.isEmpty(missingFields)) {
          self.woof.danger(self.i18n.t('default-form-messages.enter.valid.email'));
          self.set('isLoading', false);
        } else {
          if (self.get('email')) {
            if (!(0, _emailValidator.validate)(self.email)) {
              self.woof.danger(self.i18n.t('default-form-messages.enter.valid.email'));
              self.set('isLoading', false);
            } else {
              self.get('metrics').trackEvent({
                event: 'w_Login_Forgot_Password_Reset'
              });
              self.set('email', self.get('email').toLowerCase());
              var data = self.getProperties('email');
              data['domain_key'] = _environment.default.APP.DOMAIN_KEY;

              _jquery.default.ajax({
                data: data,
                async: true,
                type: 'POST',
                url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/resetPassword'
              }).then(function () {
                self.get('metrics').trackEvent({
                  event: 'm_Account_ResetPassword',
                  category: 'account',
                  action: 'reset_password'
                });
                self.set('isLoading', false);
                self.woof.success(self.get('i18n').t('reset-password.email.sent'));
                (0, _jquery.default)('.modal').modal('hide');
                self.sendAction();
              }, function (e) {
                self.set('isLoading', false);

                if (e && e.status) {
                  if (parseInt(e.status) === 404) {
                    self.woof.danger(self.get('i18n').t('reset-password.error.no-connection'));
                  } else if (parseInt(e.status) === 403 && e.responseText && e.responseText.startsWith('UserNotFound')) {
                    self.woof.danger(self.get('i18n').t('reset-password.error.no-user'));
                  } else if (e.responseText) {
                    self.woof.danger(e.responseText);
                  } else {
                    self.woof.danger(self.get('i18n').t('reset-password.error.default'));
                  }
                } else {
                  self.woof.danger(self.get('i18n').t('reset-password.error.default'));
                }
              });
            }
          }
        }
      }
    }
  });
  var _default = ResetPasswordModalController;
  _exports.default = _default;
});