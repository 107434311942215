define("safesite/models/incident-report-online", ["exports", "ember-data", "safesite/models/incident-report"], function (_exports, _emberData, _incidentReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _incidentReport.default.extend({
    couchdb_id: _emberData.default.attr()
  });

  _exports.default = _default;
});