define("safesite/models/abstract", ["exports", "ember-data", "ember-pouch", "moment"], function (_exports, _emberData, _emberPouch, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberPouch.Model.extend({
    created_date_time: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return _moment.default.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z';
      }
    }),
    modified_date_time: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return _moment.default.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z';
      }
    }),

    /**
    * Before saving the record, update the modified_date_time and modified_by attributes.
    * If the save failed because of a conflict, reload the record and reapply the changed attributes and
    * attempt to save again.
    */
    save: function save(options) {
      var changedAttributes = this.changedAttributes();

      if (this.hasDirtyAttributes || this.hasDirtyRelations) {
        this.set('modified_date_time', _moment.default.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z');
        this.set('modified_by', this.session.model); // this.session is only available if current session is being inject into the model using initializers/session
      }

      return this._super(options).catch(function (error) {
        if (!Ember.isEmpty(options) && options.retry) {
          throw error;
        } else {
          if (error.name && error.name.indexOf && error.name.indexOf('conflict') > -1) {
            // Conflict encountered, so rollback, reload and then save the record with the changed attributes.
            this.rollbackAttributes();
            return this.reload().then(function (record) {
              Object.keys(changedAttributes).forEach(function (attribute) {
                record.set(attribute, changedAttributes[attribute][1]);
              });

              if (Ember.isEmpty(options)) {
                options = {};
              }

              options.retry = true;
              return record.save(options);
            });
          } else {
            throw error;
          }
        }
      }.bind(this));
    },
    isOnlineModel: function isOnlineModel() {
      return !!this._internalModel.modelName.match(/-online$/);
    },
    getCouchDBId: function getCouchDBId() {
      return this.couchdb_id ? this.couchdb_id : this.id;
    }
  });

  _exports.default = _default;
});