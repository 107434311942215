define("safesite/components/item-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    comments: null,
    actions: {
      addComment: function addComment() {
        this.sendAction('action', this.id, this.comment);
        this.set('comment', null);
      }
    }
  });

  _exports.default = _default;
});