define("safesite/controllers/site/edit", ["exports", "jquery", "safesite/mixins/data-validator"], function (_exports, _jquery, _dataValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SitesEditController = Ember.Controller.extend(_dataValidator.default, {
    validations: {
      'model.name': function modelName() {
        if (Ember.isBlank(this.model.name)) {
          return "Must not be blank.";
        }
      }
    },
    content: null,
    editing: true,
    actions: {
      save: function save() {
        var self = this;

        if (self.isValid()) {
          if (self.get('model.is_demo')) {
            this.woof.warning('Please create a new site to change site details.');
          } else {
            self.set('showFieldValidation', false);
            Ember.RSVP.Promise.all([self.get('model.personnel'), self.get('model.teams'), self.get('model.locations'), self.get('model.supervisors'), self.get('model.inspectiontemplates')]).then(function () {
              self.get('model').save();
              (0, _jquery.default)('.modal').modal('hide');
            });
          }
        } else {
          self.woof.danger('Please fill in mandatory fields');
        }
      }
    }
  });
  var _default = SitesEditController;
  _exports.default = _default;
});