define("safesite/models/meeting-template", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _AbstractModel$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var MeetingTemplate = _abstract.default.extend((_AbstractModel$extend = {
    name: _emberData.default.attr(),
    uuid: _emberData.default.attr(),
    version: _emberData.default.attr(),
    default: _emberData.default.attr(),
    archived: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization', {
      inverse: 'current_meeting_templates',
      async: true
    }),
    created_date_time: _emberData.default.attr(),
    author: _emberData.default.attr(),
    topics: _emberData.default.hasMany('topic', {
      async: true
    }),
    sorted_topics: Ember.computed('topics', 'topics.@each.order', function () {
      return this.topics.sortBy('order');
    }),
    tags: _emberData.default.attr(),
    language_slug: _emberData.default.attr(),
    description: _emberData.default.attr(),
    jurisdiction: _emberData.default.attr(),
    industries: Ember.computed('tags', function () {
      return this.get('tags').map(function (industry) {
        return industry.capitalize();
      }).join(', ');
    })
  }, _defineProperty(_AbstractModel$extend, "archived", _emberData.default.attr()), _defineProperty(_AbstractModel$extend, "is_safesite_template", _emberData.default.attr()), _AbstractModel$extend));

  var _default = MeetingTemplate;
  _exports.default = _default;
});