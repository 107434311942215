define("safesite/components/inspection-item", ["exports", "ember-i18n"], function (_exports, _emberI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InspectionItemComponent = Ember.Component.extend({
    i18n: Ember.inject.service(),
    layoutName: 'components/inspection-item',
    record: null,
    showCreatedBy: true,
    showModuleName: true,
    showProjectName: false,
    moduleName: (0, _emberI18n.translationMacro)("models.Inspection"),
    showIcon: true,
    addMargin: false,
    actions: {}
  });
  var _default = InspectionItemComponent;
  _exports.default = _default;
});