define("safesite/components/custom-dropdown-nested-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CustomDropdownNestedModalComponent = Ember.Component.extend({
    layoutName: 'components/custom-dropdown-modal-nested',
    title: null,
    module: null,
    icon: Ember.computed('module', function () {
      var module = this.module;

      if (module === 'hazard') {
        return '<i class="icon-Hazard"></i>';
      } else if (module === 'inspection') {
        return '<i class="icon-Inspection"></i>';
      } else if (module === 'meeting') {
        return '<i class="icon-Meeting"></i>';
      } else if (module === 'equipment') {
        return '<i class="icon-Equipment"></i>';
      } else if (module === 'observation') {
        return '<i class="icon-Observation"></i>';
      } else if (module === 'incident-report') {
        return '<i class="icon-Incident"></i>';
      }

      return '';
    }),
    isIOS: Ember.computed(function () {
      return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      (0, _jquery.default)("#dropdown").css({
        position: "absolute",
        bottom: "auto",
        top: "26px" // fix for Iphone X notch.

      });
      localStorage.setItem('scrollTop', (0, _jquery.default)(window).scrollTop());
      Ember.run.schedule('afterRender', this, function () {
        (0, _jquery.default)('#dropdown').modal('show').one('hidden.bs.modal', function () {
          self.sendAction();
        });
      });
    },
    actions: {
      cancel: function cancel() {
        (0, _jquery.default)('#dropdown').modal('hide');
        (0, _jquery.default)(window).scrollTop(localStorage.getItem('scrollTop'));
      }
    }
  });
  var _default = CustomDropdownNestedModalComponent;
  _exports.default = _default;
});