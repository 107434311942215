define("safesite/models/incident-report", ["exports", "ember-data", "safesite/models/abstract", "safesite/mixins/image-attachment", "moment"], function (_exports, _emberData, _abstract, _imageAttachment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var IncidentReport = _abstract.default.extend(_imageAttachment.default, {
    created_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    created_at: _emberData.default.attr('number', {
      defaultValue: function defaultValue() {
        return _moment.default.utc().unix();
      }
    }),
    cocreators: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    signatures: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    modified_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    complete_date_time: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    incident_date_time: _emberData.default.attr(),
    site: _emberData.default.belongsTo('site', {
      async: true
    }),
    location: _emberData.default.belongsTo('location', {
      async: true
    }),
    task: _emberData.default.belongsTo('incidentTask', {
      async: true
    }),
    root_cause: _emberData.default.belongsTo('rootCause', {
      async: true
    }),
    images: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    comments: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    completed: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    description: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    corrective_action: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    persons_involved: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    injuries: _emberData.default.hasMany('injury', {
      async: true,
      readOnly: true
    }),
    risk_type: _emberData.default.belongsTo('risk-type', {
      async: true
    }),
    active_injuries: Ember.computed('injuries', 'injuries.@each.is_deleted', function () {
      return this.injuries.filter(function (f) {
        return f.get('is_deleted') === false;
      }).sortBy('person.full_name');
    }),
    types: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    witnesses: _emberData.default.hasMany('witness', {
      async: true
    }),
    active_witnesses: Ember.computed('witnesses', 'witnesses.@each.is_deleted', function () {
      return this.witnesses.filter(function (f) {
        return f.get('is_deleted') === false;
      }).sortBy('person.full_name');
    }),
    hazards: _emberData.default.hasMany('hazard', {
      async: true
    }),
    unresolved_hazards: Ember.computed('hazards', 'hazards.@each.status', function () {
      return this.hazards.filter(function (f) {
        return f.get('status') === true;
      });
    }),
    //Computed
    incident_type: Ember.computed('type', function () {
      var types = {
        'INJ': Ember.Object.create({
          id: 'INJ',
          value: 'INJ',
          name: 'Injury'
        }),
        'NMS': Ember.Object.create({
          id: 'NMS',
          value: 'NMS',
          name: 'Near Miss'
        }),
        'DMG': Ember.Object.create({
          id: 'DMG',
          value: 'DMG',
          name: 'Property Damage'
        }),
        'TFT': Ember.Object.create({
          id: 'TFT',
          value: 'TFT',
          name: 'Theft'
        }),
        'EQF': Ember.Object.create({
          id: 'EQF',
          value: 'EQF',
          name: 'Equipment Failure'
        })
      };
      return types[this.type];
    }),
    draft: _emberData.default.attr(),
    is_deleted: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    injury_description: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    near_miss_description: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    equipment_failures: _emberData.default.hasMany('equipment', {
      async: true
    }),
    equipment_failure_description: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    equipment_theft: _emberData.default.hasMany('equipment', {
      async: true
    }),
    equipment_theft_description: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    damage_owner: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    damage_description: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    damage_cause: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    TYPE_INJURY_CODE: 'INJ',
    TYPE_NEAR_MISS_CODE: 'NMS',
    TYPE_DAMAGE_CODE: 'DMG',
    TYPE_THEFT_CODE: 'TFT',
    TYPE_EQUIP_FAIL_CODE: 'EQF',
    types_translatable: Ember.computed('types.[]', function () {
      return this.types.map(function (value) {
        return 'incidents.types.' + value;
      });
    }),
    hasInjury: Ember.computed('types.[]', function () {
      return this.types && this.types.includes(this.TYPE_INJURY_CODE);
    }),
    hasNearMiss: Ember.computed('types.[]', function () {
      return this.types && this.types.includes(this.TYPE_NEAR_MISS_CODE);
    }),
    hasPropertyDamage: Ember.computed('types.[]', function () {
      return this.types && this.types.includes(this.TYPE_DAMAGE_CODE);
    }),
    hasEquipmentFailure: Ember.computed('types.[]', function () {
      return this.types && this.types.includes(this.TYPE_EQUIP_FAIL_CODE);
    }),
    hasTheft: Ember.computed('types.[]', function () {
      return this.types && this.types.includes(this.TYPE_THEFT_CODE);
    })
  });

  var _default = IncidentReport;
  _exports.default = _default;
});