define("safesite/mixins/reset-scroll-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super();

      Ember.run.debounce(this, this._scrollFunction, 500);
      Ember.run.next(this, this._scrollFunction);
    },
    _scrollFunction: function _scrollFunction() {
      // This function will run after 100ms after scrolling stops
      window.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});