define("safesite/serializers/site", ["exports", "ember-data", "ember-pouch"], function (_exports, _emberData, _emberPouch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberPouch.Serializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      teams: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      equipment: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      hazards: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      incidents: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      inspections: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      locations: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      meetings: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      observations: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      supervisors: {
        deserialize: 'ids',
        serialize: 'ids'
      },
      site_user_profiles: {
        serialize: 'id',
        deserialize: 'id'
      }
    }
  });

  _exports.default = _default;
});