define("safesite/templates/components/time-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+jk4DsVH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"timeValue\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info ss-custom-dropdown-alert\"],[8],[0,\"\\n        \"],[7,\"table\",true],[10,\"style\",\"width:100%\"],[8],[0,\"\\n            \"],[7,\"tr\",true],[8],[0,\"\\n                \"],[7,\"td\",true],[10,\"style\",\"width:80%\"],[8],[0,\"\\n                    \"],[1,[22,\"timeValue\"],false],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"td\",true],[10,\"style\",\"width:20%\"],[8],[0,\"\\n                    \"],[7,\"i\",true],[10,\"class\",\"icon btn-icon icon-delete pull-right\"],[8],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"required\"]],\"ss-btn-required\"],null],\" btn btn-lg btn-warning btn-block ss-custom-dropdown-btn\"]]],[8],[0,\"\\n        \"],[7,\"table\",true],[10,\"style\",\"width:100%\"],[8],[0,\"\\n            \"],[7,\"tr\",true],[8],[0,\"\\n                \"],[7,\"td\",true],[10,\"style\",\"width:80%\"],[8],[0,\"\\n                    \"],[1,[28,\"t\",[\"components.time-field.text.set\"],null],false],[0,\" \"],[1,[22,\"label\"],false],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"td\",true],[10,\"style\",\"width:20%\"],[8],[0,\"\\n                    \"],[7,\"i\",true],[10,\"class\",\"icon icon-ss-right-arrow\"],[8],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/time-field.hbs"
    }
  });

  _exports.default = _default;
});