define("safesite/controllers/inspection/edit", ["exports", "safesite/controllers/inspections/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var InspectionEditController = _create.default.extend({
    i18n: Ember.inject.service(),
    cocreatorReadOnly: Ember.computed('model.draft', function () {
      if (this.get('model.draft')) {
        return false;
      }

      return true;
    }),
    // In edit mode - the created_by may have been another device user
    createdBy: Ember.computed('model.created_by', function () {
      return this.model.created_by;
    }),
    actions: {}
  });

  var _default = InspectionEditController;
  _exports.default = _default;
});