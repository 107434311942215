define("safesite/components/email-render", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SignatureRender = Ember.Component.extend({
    classNames: ['signature'],
    email: null,
    emailRegex: /.@.+..+/,
    noEmail: 'No email',
    isEmailValid: Ember.computed('email', 'emailRegex', function () {
      return typeof this.email !== 'undefined' && this.emailRegex.test(this.email);
    })
  });
  var _default = SignatureRender;
  _exports.default = _default;
});