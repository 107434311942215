define("safesite/utilities/sortUsers", ["exports", "email-validator"], function (_exports, _emailValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortUsers;

  function sortUsers(userA, userB) {
    var userAString = '';

    if (Ember.isPresent(userA.first_name)) {
      userAString = "".concat(userA.first_name.toLowerCase(), " ").concat(userA.last_name.toLowerCase());
    } else if ((0, _emailValidator.validate)(userA.username)) {
      userAString = userA.username.toLowerCase();
    } else if ((0, _emailValidator.validate)(userA.email)) {
      userAString = userA.email.toLowerCase();
    }

    var userBString = '';

    if (Ember.isPresent(userB.first_name)) {
      userBString = "".concat(userB.first_name.toLowerCase(), " ").concat(userB.last_name.toLowerCase());
    } else if ((0, _emailValidator.validate)(userB.username)) {
      userBString = userB.username.toLowerCase();
    } else if ((0, _emailValidator.validate)(userB.email)) {
      userBString = userB.email.toLowerCase();
    }

    return userAString === userBString ? 0 : userAString > userBString ? 1 : -1;
  }
});