define("safesite/templates/components/x-woof-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3R3C5npd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"alert alert-dismissable \",[24,[\"message\",\"typeClass\"]],\" text-center\"]]],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[1,[24,[\"message\",\"message\"]],true],[9],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"alert\"],[10,\"aria-hidden\",\"true\"],[10,\"type\",\"button\"],[8],[0,\"×\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/x-woof-message.hbs"
    }
  });

  _exports.default = _default;
});