define("safesite/locales/fr/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "pagination": {
      "previous": "Précédent",
      "next": "Suivant"
    },
    "datePicker": {
      "clear": "Dégager",
      "close": "Fermé",
      "today": "Aujourd’hui",
      "month.jan": "Janv",
      "month.feb": "Févr",
      "month.mar": "Mars",
      "month.apr": "Avr",
      "month.may": "Mai",
      "month.jun": "Juin",
      "month.jul": "Juil",
      "month.aug": "Août",
      "month.sep": "Sept",
      "month.oct": "Oct",
      "month.nov": "Nov",
      "month.dec": "Déc",
      "week.sun": "Di",
      "week.mon": "Lu",
      "week.tue": "Ma",
      "week.wed": "Me",
      "week.thu": "Je",
      "week.fri": "Ve",
      "week.sat": "Sa"
    },
    "models": {
      "Hazard": "Risque",
      "Observation": "Observation",
      "Meeting": "Réunion",
      "Inspection": "Inspection",
      "IncidentReport": "Rapport d’Incident",
      "Incident Report": "Rapport d’Incident",
      "Incident": "Incident",
      "Equipment": "Équipement"
    },
    "splash": {
      "button.get-started": "Commencer",
      "hero.pre-text": "Enchérir",
      "hero.alt-text": " Sur",
      "hero.post-text": " Conformité à la sécurité",
      "label.terms.text": "En continuant vous acceptez notre ",
      "label.terms.link": "Terms & Conditions",
      "message.language": "Langue Préférée"
    },
    "welcome": {
      "title": "Bienvenue sur Safesite!",
      "subtitle": "Entrez votre email pour commencer",
      "label.email": "E-mail",
      "placeholder.email": "nom@compagnie.com",
      "button.continue": "Continuer",
      "link.reset": "Mot de passe oublié?",
      "reset.message": "Nous avons trouvé votre compte, mais il semble que vous ayez besoin de créer un mot de passe. Nous vous avons envoyé un lien de réinitialisation du mot de passe par e-mail, veuillez l'ouvrir sur votre appareil mobile.",
      "message.offline": "Vous devez être en ligne pour vous inscrire ou vous connecter.",
      "messages.reset.email": "Merci de consulter vos emails."
    },
    "resetted": {
      "title": "Application Redémarrée",
      "message": "Oups! L'application Safesite a été redémarrée en raison d'une erreur. Si ce problème persiste, veuillez contacter notre équipe d'assistance client à success@safesitehq.com ou via un message d'assistance intégré à l'application. Désolé pour tout inconvénient.",
      "button.ok": "Bien"
    },
    "auth": {
      "button.signup": "S’inscrire",
      "button.go": "Identifiant",
      "button.reset": "Mot de passe oublié?",
      "label.email": "E-mail",
      "label.password": "Mot de Passe",
      "placeholder.email": "Entrez votre e-mail",
      "placeholder.password": "Entrer le mot de passe",
      "password.hide": "Cacher",
      "password.show": "Afficher",
      "password.error": "Veuillez entrer le mot de passe",
      "email.error": "S'il vous plaît entrer l'adresse e-mail",
      "message.new": "Nouveau sur Safesite?",
      "message.terms_1": "En vous inscrivant, vous acceptez les",
      "message.terms_2": "Conditions de Service",
      "title": "Se connecter",
      "subtitle": "Bienvenue à nouveau ! Entrez votre adresse e-mail et mot de passe ci-dessous",
      "message.error": "Une erreur s’est produite, veuillez réessayer ou contacter l’assistance."
    },
    "reset": {
      "button.login": "Mettre",
      "button.reset": "Renvoyer le mot de passe oublié ?",
      "label.email": "E-mail",
      "label.password": "Créer un mot de passe (min. 8 caractères)",
      "placeholder.email": "",
      "placeholder.password": "Nouveau Mot de Passe",
      "password.hide": "Cacher",
      "password.show": "Afficher",
      "password.error": "Veuillez entrer le mot de passe",
      "label.terms.pretext": "En vous inscrivant, vous acceptez les ",
      "label.terms.link": "Conditions de Service",
      "title": "Définir le Mot de Passe",
      "subtitle": "Entrez votre nouveau mot de passe",
      "message.error": "Une erreur s'est produite, veuillez réessayer ou contacter l'assistance."
    },
    "sync": {
      "button.start": "Commencer",
      "button.continue": "Continuer",
      "label.logout": "Se déconnecter",
      "db_creation.message": "Activation hors ligne...",
      "h4.signup_creation": "La création du Compte",
      "h4.sync_progress": "Synchronisation du compte pour une utilisation hors connexion...",
      "h4.sync_progress_larger_accounts": "...cela peut prendre jusqu'à 2 minutes pour les grands comptes",
      "h4.sync_progress_signup": "Configuration de votre compte...",
      "h4.sync_progress_signup_larger_accounts": "...il s'agit d'une synchronisation unique pour que l'application fonctionne hors ligne",
      "h4.sync_completed": "Configuration terminée!",
      "video.message": "Pendant que vous attendez, familiarisons-nous avec Safesite",
      "finishing.message": "Fin de la synchronisation, veuillez ne pas vous déconnecter",
      "user.has.unsynced.items": "Vous êtes hors ligne avec des données non synchronisées.",
      "is.complete": "Synchronisation terminée!"
    },
    "reset-password": {
      "title": "Mot de Passe Oublié?",
      "placeholder.email": "Email",
      "button.reset": "Réinitialiser le Mot de Passe",
      "error": {
        "no-connection": "Impossible de se connecter au serveur, veuillez réessayer plus tard",
        "no-user": "L'e-mail entré est introuvable dans le système",
        "default": "Impossible de se connecter au serveur, veuillez réessayer plus tard"
      },
      "email.sent": "Vérifiez votre courrier électronique pour les informations de réinitialisation"
    },
    "change-password": {
      "title": "Modifier le Mot de Passe",
      "placeholder.newPassword": "Nouveau Mot de Passe",
      "placeholder.confirmPassword": "Confirmer le Mot de Passe",
      "button.update": "Modifier le Mot de Passe",
      "password.hide": "Cacher",
      "password.show": "Afficher"
    },
    "signup": {
      "title": "Commençons",
      "subtitle": "Entrez une adresse e-mail et un mot de passe pour créer votre profil Safesite",
      "label.email": "E-mail",
      "label.password": "Créer un mot de passe (min. 8 caractères)",
      "placeholder.email": "Entrez Email",
      "placeholder.password": "Créer Mot de Passe",
      "label.terms.pretext": "En vous inscrivant, vous acceptez nos",
      "label.terms.link": "Conditions Générales",
      "button.signup": "S’inscrire"
    },
    "signup-organization-set-up": {
      "industry.type": "Industrie",
      "select.industry.type": "Sélectionner l'industrie",
      "label.organization": "Organisation",
      "subtitle": "Afin de personnaliser votre expérience, veuillez entrer plus d'informations (facultatif) ci-dessous",
      "title": "Créer une organisation",
      "validation.orgNotBlank": "Le nom de l'organisation ne doit pas être vide.;"
    },
    "nav": {
      "side": {
        "label.certificates": "Certificats",
        "label.help_center": "Centre d’Assistance",
        "label.join_webinar": "Webinars",
        "label.contact": "Assistance",
        "label.refresh": "Rafraîchir",
        "label.tour": "Tour",
        "label.logout": "Déconnexion"
      },
      "footer": {
        "main": {
          "Actions": "Actions",
          "Projects": "Projets",
          "Inbox": "Boite de Réception",
          "SafetyHQ": "SécuritéHQ"
        },
        "add": {
          "title": "Nouvelle Action de Sécurité",
          "inspection": "Conduite<br>Inspection",
          "hazard": "Ajouter <br>Risque",
          "meeting": "Organiser <br>Réunion",
          "equipment": "Inspecter <br>Équipement",
          "observation": "Enregistrer <br>Observation",
          "incident": "Enregistrer <br>Incident"
        },
        "manage": {
          "title": "Gérer la Sécurité de l’Équipe",
          "message.create_project": "Créer votre propre projet pour accéder à SécuritéHQ.",
          "message.non_org_admin": "Malheureusement, vous ne pouvez accéder à SécuritéHQ pour aucun de vos projets, veuillez contacter le responsable de votre organisation.",
          "Message.not_your_org": "Vous ne pouvez accéder à SécuritéHQ pour ce projet.",
          "button.create_project": "Créez Votre Projet",
          "inspections": "Inspections",
          "hazards": "Risques",
          "meetings": "Réunions",
          "equipment": "Équipement",
          "observations": "Observations",
          "incidents": "Incident <br> Rapports"
        }
      }
    },
    "actions": {
      "index": {
        "title.hazards": "Non Résolu",
        "title.team.hazards": "Dangers",
        "title.my.hazards": "Les dangers de mon équipe",
        "title.in_progress": "Eléments en cours",
        "title.incomplete": "Incomplet",
        "title.actions_this_week": "Actions",
        "message.no_more_actions": "Aucune Autre Action.",
        "message.new_item_1": "Cliquer sur le bouton + ci-dessous",
        "message.new_item_2": "pour démarrer une nouvelle activité de sécurité.",
        "cards": {
          "title.inspection": "Conduite Inspection",
          "title.hazard": "Enregistrer le Risque",
          "title.observation": "Enregistrer Observation",
          "title.meeting": "Organiser Réunion",
          "title.resolve": "Résoudre Risque",
          "title.announcement": "Annonce",
          "title.profile": "Compléter votre profil",
          "title.verify": "Vérifier votre boite e-mail",
          "title.safety-item": "Compléter votre première action de sécurité",
          "text.project": "Projet",
          "text.raised": "Signalé par"
        },
        "empty-state": {
          "project": "Projet",
          "actions.title": "Excellent travail pour compléter vos actions de sécurité! Appuyez sur le bouton + pour en créer plus.",
          "incomplete.title": "Excellent travail pour terminer vos actions incomplètes!",
          "unresolved-hazards.title": "Excellent travail pour résoudre tous vos dangers!",
          "message.part1": "Améliorer la sécurité est un travail important et permanent. Restez actif(s) et complétez les nouvelles actions de sécurité au fur et à mesure en utilisant le",
          "message.part2": "bouton ci-dessous"
        }
      },
      "text.overdue": "En retard",
      "text.today": "Aujourd’hui",
      "text.left": "restant"
    },
    "sites": {
      "title.main": "Vos Projets",
      "message.empty": "Il est temps de créer votre premier projet",
      "message.non_org_admin": "Vous ne voyez pas votre projet ? Contactez votre administrateur pour y accéder.",
      "button.join": "Se joindre au Projet",
      "button.create": "Créer un Projet",
      "button.invite": "Inviter",
      "button.remove": "Quitter",
      "create": {
        "label.name": "* Nom du Projet",
        "label.address": "Adresse",
        "placeholder.name": "Entrer le Nom du Projet",
        "placeholder.address": "Entrer l’Adresse du Projet",
        "placeholder.search": "Rechercher un nom de projet",
        "label.organization": "Sélectionner une Organisation",
        "button.join": "Se joindre au Projet",
        "button.save": "Créer",
        "message.project": "Si le projet n'appartient pas à votre organisation, vous devrez contacter l'administrateur Safesite de cette organisation.",
        "title.create": "Nouveau Projet",
        "title.join": "Se Joindre à un Projet Existant",
        "site.name.already.exists": "Ce nom de projet existe déjà",
        "site.successfully.created": "Projet créé avec succès"
      },
      "invite-modal": {
        "title": "Inviter au Projet",
        "subtitle": "Inviter des participants à votre projet en entrant leur adresse e-mail ci-dessous:",
        "button": "Inviter",
        "invitation.sent.online.alert": "Votre invitation a été envoyée",
        "invitation.sent.offline.alert": "Votre invitation sera envoyée une fois que vous serez de retour en ligne"
      },
      "leave-modal": {
        "title": "Es-tu sûr? Cela vous supprimera complètement du projet.",
        "button.yes": "Oui"
      }
    },
    "notifications": {
      "index": {
        "title": "Notifications",
        "filter.read": "Lu",
        "filter.unread": "Non lu",
        "message.empty": "Aucun messages non lu, on dirait que vous êtes à jour!",
        "button.read": "Marquer comme lu"
      }
    },
    "inspection": {
      "conduct": "Conduite une Inspection",
      "index": {
        "title": "Inspection",
        "message.unresolved": "Risques Non Résolus Restants",
        "message.completed": "Inspection Terminée",
        "button.back": "Précédent",
        "button.export": "Exporter",
        "button.archive": "Archiver l’Inspection",
        "heading.unresolved": "Risques Non Résolus",
        "heading.timeframe": "Période",
        "heading.details": "Détails",
        "heading.resolvedHazards": "Risques Résolus",
        "heading.dateResolved": "Date de Résolution",
        "heading.observations": "Observations de Sécurité",
        "heading.answers": "Réponses",
        "label.inspection_template": "Modèle d'inspection",
        "label.location": "Lieu",
        "label.conductedOn": "Conduit Le",
        "label.conductedBy": "Conduit Par By",
        "label.hazardLogged": "Risque Enregistré",
        "label.observationLogged": "Observation Enregistrée",
        "label.commentAdded": "Commentaire Ajouté",
        "text.answered": "Répondu",
        "text.question": "Question",
        "message.noComments": "Aucun Commentaire Enregistré"
      }
    },
    // Default - may be overridden by safety item specific strings
    "add-cocreator": {
      "title": "Cocréateurs",
      "button.add": "Ajouter un Cocréateur",
      "no-cocreators": "Aucun utilisateur ajouté",
      "modal-title": "Sélectionnez Cocréateur"
    },
    "inspections": {
      "add-cocreator": {
        "title": "Conduit Par",
        "button.add": "Ajouter Personne",
        "modal-title": "Sélectionnez Personne"
      },
      "create": {
        "error.missing-template": "Impossible de trouver le modèle, veuillez réessayer ou contacter l'assistance.",
        "heading": "Détails de l’Inspection",
        "label.location": "Choisir un Lieu",
        "label.template": "Sélectionner un modèle d’Inspection",
        "label.location-modal": "Lieu",
        "label.template-modal": "Modèle",
        "button.complete": "Terminer l’Inspection",
        "button.save": "Enregistrer l’Inspection",
        "button.archive": "Archiver l’Inspection",
        "question-item": {
          "counter": "Question {{nombre}} de {{total}}",
          "placeholder.comment": "Entrer commentaire",
          "button.add-observation": "Ajouter Observation",
          "message.observation-added": "Observation Enregistrée",
          "message.hazard-added": "Risque Signalé",
          "label.optional": "Facultatif",
          "label.comments": "Commentaires",
          "label.actions": "Actions",
          "placeholder.response": "Entrez une réponse *",
          "label.required": "* = Obligatoire",
          "question_type.undefined.positive": " ",
          "question_type.undefined.negative": " ",
          "question_type.undefined.na": " ",
          "question_type.1.positive": "&#x2714;&#xfe0e;",
          "question_type.1.negative": "&#x2716;&#xfe0e;",
          "question_type.1.na": "NA",
          "question_type.2.positive": "Sûr",
          "question_type.2.negative": "Peu sûr",
          "question_type.2.na": "NA",
          "question_type.3.positive": "Passer",
          "question_type.3.negative": "Échouer",
          "question_type.3.na": "NA",
          "question_type.4.positive": "Oui",
          "question_type.4.negative": "Non",
          "question_type.4.na": "NA"
        },
        "section-item": {
          "footer.answers": " question sur ",
          "footer.questions": " remplie"
        },
        "message.saved": "Enregistré!",
        "message.draftSaved": "Brouillon enregistré!",
        "message.answerAllQuestions": "Veuillez répondre aux questions",
        "message.sectionLabel": "Section ",
        "message.missLocation": "Veuillez sélectionner un emplacement",
        "message.success": "Inspection Enregistrée avec Succès.",
        "message.completed": "Inspection terminée avec Succès.",
        "message.observationAdded": "Observation Enregistrée avec Succès.",
        "message.hazardAdded": "Risque Enregistré avec Succès.",
        "message.recent": "Récent",
        "message.myOrg": "Mon Org",
        "templates-library": {
          "message.noRecentlyUsedTemplate": "Vous n'avez pas encore de modèles récemment utilisés, effectuez votre première inspection.",
          "message.noTemplatesInYourOrg": "Vous n'avez pas encore de modèles dans la bibliothèque de votre organisation. Ajoutez-en en créant un journal d'administration dans le tableau de bord de l'administrateur via app.safesitehq.com",
          "message.noResults": "Aucun résultat à afficher."
        }
      },
      "index": {
        "title": "Inspections",
        "button.export": "Exporter",
        "button.open": "Ouvrir",
        "button.closed": "Fermé",
        "button.all": "Tous",
        "button.person": "Personne",
        "button.type": "Type",
        "button.filter": "Trier par",
        "heading.inspectionDetails": "Détails Inspection",
        "heading.dateStarted": "Date Ouverture",
        "heading.dateClosed": "Date Clôture",
        "message.noOpenInspections": "Pas d’inspection ouverte",
        "message.noClosedInspections": "Pas d’inspection clôturée",
        "text.conductedBy": "Conduit Par",
        "text.type": "Type",
        "text.count": "Nombre"
      },
      "skip-modal": {
        "title": "Voulez-vous vraiment ignorer cette section?",
        "button.confirm": "Oui, sauter la section",
        "label.questions": " questions",
        "message": "Omitir una sección marcará todas las respuestas en la sección como NA",
        "skip.btn": "Passer"
      }
    },
    "meeting.conduct": "Organiser Réunion",
    "meeting.index": {
      "title": "Réunion",
      "heading.unresolvedHazardsCount": "Risques Non résolus restants",
      "heading.unresolvedHazards": "Risques Non résolus",
      "heading.timeframe": "Période",
      "heading.details": "Détails",
      "heading.resolvedHazards": "Risques Résolus",
      "heading.dateResolved": "Date de Résolution",
      "heading.safetyObservations": "Observations de Sécurité",
      "heading.answers": "Réponses",
      "heading.attendees": "Participants",
      "heading.photos": "Photos",
      "label.location": "Lieu",
      "label.conductedBy": "Conduit Par By",
      "label.conductedOn": "Conduit Le On",
      "label.topic": "Thème",
      "label.status": "État",
      "label.hazardLogged": "Risque Enregistré",
      "label.observationsLogged": "Observation Enregistrée",
      "label.comment": "Commentaire",
      "button.back": "Précédent",
      "button.export": "Exporter",
      "button.archive": "Archiver Réunion"
    },
    "meetings": {
      "add-cocreator": {
        "title": "Conduit Par",
        "button.add": "Ajouter Personne",
        "modal-title": "Sélectionnez Personne"
      },
      "conduct": "Organiser Réunion",
      "addAttendees": "Ajouter Participants à la Réunion",
      "index": {
        "title": "Réunions",
        "button.export": "Exporter",
        "button.open": "Ouvrir",
        "button.closed": "Fermé",
        "button.all": "Tous",
        "button.person": "Personne",
        "button.topic": "Thème",
        "button.filter": "Trier par",
        "heading.meetingDetails": "Détails Réunion",
        "heading.dateStarted": "Date Ouverture",
        "heading.dateClosed": "Date Fermeture",
        "message.noOpenMeetings": "Aucune réunion ouverte",
        "message.noClosedMeetings": "Aucune réunion fermée",
        "text.conductedBy": "Conduit Par",
        "text.topic": "Thème",
        "text.count": "Nombre"
      },
      "create": {
        "error.missing-template": "Impossible de trouver le modèle, veuillez réessayer ou contacter l'assistance.",
        "title": "Détails Réunion",
        "label.location": "Choisir un Lieu",
        "label.topic": "Choisir un Thème de Réunion",
        "button.finish": "Terminer & Ajouter Participants",
        "button.archive": "Archiver Réunion",
        "topic": {
          "heading": "Tema {{number}} de {{total}}",
          "message.optional": "Facultatif",
          "message.actions": "Actions",
          "placeholder.comment": "Entrer un Commentaire",
          "placeholder.response": "Entrez une réponse",
          "button.save": "ENREGISTRER",
          "message.obs_logged": "Observation Enregistrée",
          "message.haz_raised": "Risque Signalé",
          "message.comment_added": "Commentaire Ajouté"
        },
        "message.saved": "Enregistré!",
        "message.draftSaved": "Brouillon enregistré!",
        "message.answerAllTopics": "Veuillez répondre à tous les thèmes",
        "message.missLocation": "Veuillez sélectionner un emplacement",
        "message.success": "Réunion Enregistrée avec Succès.",
        "message.pleaseStart": "Veuillez démarrer une nouvelle réunion pour changer le thème de la réunion",
        "add-attendees": {
          "button.addAttendee": "Ajouter Participants",
          "button.finish": "Terminer",
          "heading.photos": "Ajouter Photos",
          "heading.attendees": "Participants Ajoutés",
          "heading.select": "Select Attendees",
          "message.uploadSignatures": "Téléchargez une photo de la feuille de signature ou des participants si vous ne parvenez pas à obtenir des signatures numériques.",
          "message.missingSignatures": "Ajoutez toutes les signatures ou une photo de la feuille de signatures avant de terminer la réunion.",
          "message.missingPhotoAndAttendees": "Ajouter des participants et des photos avant de terminer la réunion.",
          "message.missingAttendees": "Ajouter des participants avant de terminer la réunion.",
          "message.missingPhotos": "Ajouter des photos avant de terminer la réunion."
        },
        "templates-library": {
          "message.noRecentlyUsedTemplate": "Vous n'avez pas encore de modèles récemment utilisés, terminez votre première réunion."
        }
      }
    },
    "hazards": {
      "add-cocreator": {
        "title": "Enregistré par",
        "button.add": "Ajouter Personne",
        "modal-title": "Sélectionnez Personne"
      },
      "index": {
        "title": "Risques",
        "button.export": "Exporter",
        "button.unresolved": "Non Résolus",
        "button.resolved": "Résolus",
        "button.all": "Tous",
        "button.team": "Équipe",
        "button.location": "Lieu",
        "button.filter": "Trier par",
        "heading.hazardDetails": "Détails du Risque",
        "heading.timeframe": "Période",
        "heading.dateResolved": "Date de Résolution",
        "message.noResolvedHazards": "Aucun risque résolu pour cette période",
        "message.noUnresolvedHazards": "Aucun risque non résolu pour cette période",
        "text.location": "Lieu",
        "text.team": "Équipe",
        "text.count": "Nombre"
      }
    },
    "hazard": {
      "add": "Ajouter un Risque",
      "index": {
        "heading.details": "Détails du Risque",
        "heading.equipment": "Équipement Lié",
        "heading.comments": "Commentaires",
        "heading.location": "Lieu",
        "heading.risk": "Niveau de Risque",
        "button.back": "Précédent",
        "button.edit": "Modifier",
        "button.export": "Exporter",
        "button.resolve": "Résoudre le Risque",
        "button.archive": "Archiver le Risque",
        "button.markResolved": "Marquer comme résolu",
        "text.hazard": "Risque",
        "text.unresolved": "Non résolu",
        "text.resolved": "Résolu",
        "label.task": "Activité en cours d’exécution",
        "label.root": "Cause Principale",
        "label.riskType": "Type de Risque",
        "label.riskProbability": "Probabilité du Risque",
        "label.riskConsequence": "Conséquence du Risque",
        "label.riskLevel": "Niveau de Risque Calculé",
        "label.recommendedAction": "Action Recommandée",
        "label.description": "Description",
        "label.logged": "Enregistré par",
        "label.responsibleParty": "Équipe Responsable",
        "label.location": "Lieu",
        "label.sublocation": "Lieu secondaire",
        "label.additionParties": "Équipe Notifiée Supplémentaire",
        "label.name": "Nom",
        "label.number": "Numéro",
        "label.team": "Équipe",
        "label.Email": "E-mail",
        "message.resolved": "Risque Résolu avec Succès",
        "message.not_resolvable": "Le risque ne peut pas être résolu tant qu'un commentaire, une image ou une action corrective ne sont pas joints",
        "message.mark_resolvable": "Le Risque est ‘Marqué pour Résolution’",
        "message.not_archivable": "Le risque ne peut pas être archivé tant qu'un commentaire n'a pas été ajouté pour expliquer pourquoi vous l’avez archivé."
      },
      "edit": {
        "title.details": "Détails",
        "title.images": "Ajouter des Images",
        "title.team": "Équipe Responsable",
        "title.location": "Lieu - Faites glisser l'icône du marqueur à l'endroit souhaité",
        "title.riskAssessment": "Évaluation Danger & Risque",
        "title.rootCause": "Analyse de la Cause Principale (facultatif)",
        "title.description": "Description",
        "title.correctiveAction": "Action Corrective",
        "title.correctiveActions": "Description et Action Corrective",
        "title.riskLevel": "Niveau de Risque Calculé",
        "label.recommendedActions": "Action Recommandée",
        "label.team": "Choisir Équipe",
        "label.location": "Choisir Lieu",
        "label.riskType": "Choisir Type de Risque",
        "label.riskProbability": "Choisir Probabilité de Risque",
        "label.riskConsequence": "Choisir Conséquence du Risque",
        "label.task": "Choisir Activité en cours d’Exécution",
        "label.rootCause": "Choisir Cause Principale",
        "button.update": "Mettre à Jour le Risque",
        "button.record": "Enregistrer le Risque",
        "button.resolve": "Résoudre le Risque",
        "button.cancel": "Annuler",
        "validation.notBlank": "Ne doit pas être vide.",
        "message.success": "Risque Enregistré avec Succès.",
        "message.resolved": "Risque résolu avec succès.",
        "message.mandatory": "Veuillez remplir les champs obligatoires"
      }
    },
    "observation": {
      "add": "Enregistrer Observation",
      "index": {
        "heading.details": "Détails Observation",
        "button.back": "Précédent",
        "button.edit": "Modifier",
        "button.export": "Exporter",
        "button.archive": "Archiver Observation",
        "label.description": "Description",
        "label.loggedBy": "Enregistré par",
        "label.location": "Choisir Lieu",
        "label.team": "Équipe",
        "text.safe": "Positif",
        "text.risk": "Négatif",
        "text.observation": "Observation",
        "text.on": "sur"
      },
      "nature": "Taper"
    },
    "observations": {
      "add-cocreator": {
        "title": "Enregistré par",
        "button.add": "Ajouter Personne",
        "modal-title": "Sélectionnez Personne"
      },
      "create": {
        "title": "Détails Observation",
        "label.safe": "Positif",
        "label.risk": "Négatif",
        "label.location": "Choisir Lieu",
        "label.team": "Choisir Équipe (optionnel)",
        "label.resolved": "Résolu",
        "placeholder.description": "Description",
        "button.record": "Enregistrer Observation",
        "button.update": "Mettre à Jour Observation",
        "button.cancel": "Annuler",
        "message.success": "Observation Enregistrée avec Succès.",
        "message.validation": "Veuillez remplir les champs obligatoires",
        "validation.locationNotBlank": "Veuillez sélectionner un emplacement.",
        "validation.descriptionBlank": "L'observation ne peut être complétée sans une description.",
        "validation.descriptionShort": "La description doit comporter au moins deux mots."
      },
      "index": {
        "title": "Observations",
        "button.safe": "Positif",
        "button.risk": "Négatif",
        "button.all": "Tous",
        "button.team": "Équipe",
        "button.location": "Lieu",
        "button.export": "Exporter",
        "button.filter": "Trier Par",
        "heading.observationDetails": "Détails Observation",
        "heading.dateCreated": "Date de Création",
        "heading.dateResolved": "Date de Résolution",
        "heading.backToWork": "Date approximative de retour au travail",
        "message.noSafeObservations": "Aucune Observation de Sureté",
        "message.noRiskObservations": "Aucune Observation de Risque",
        "text.count": "Nombre",
        "text.location": "Lieu",
        "text.team": "Équipe"
      }
    },
    "incident.index": {
      "title": "Rapport d’Incident",
      "button.back": "Précédent",
      "button.edit": "Modifier",
      "button.export": "Exporter",
      "button.archive": "Archiver Rapport d’Incident",
      "heading.incident": "Détails Incident",
      "heading.rootAnalysis": "Analyse Cause Principale",
      "heading.hazards": "Risques Liés à la Cause Principale",
      "heading.comments": "Commentaires",
      "label.type": "Type",
      "label.location": "Lieu",
      "label.dateTime": "Date/Heure Incident",
      "label.task": "Tâche en cours de finalisation",
      "label.root": "Cause Principale",
      "label.correctiveAction": "Description Action Corrective",
      "label.resolved": "Résolu",
      "label.logged": "Conduit Par"
    },
    "incidents": {
      "add-cocreator": {
        "title": "Conduit Par",
        "button.add": "Ajouter Personne",
        "modal-title": "Sélectionnez Personne"
      },
      "types.INJ": "Blessure ou maladie",
      "types.NMS": "Quasi-Collision",
      "types.DMG": "Dommages Matériels",
      "types.TFT": "Vol",
      "types.EQF": "Défaillance Équipement",
      "add": "Enregistrer Incident",
      "index": {
        "title": "Rapports d’Incident",
        "button.open": "Ouvrir",
        "button.closed": "Fermé",
        "button.all": "Tous",
        "button.type": "Type",
        "button.location": "Lieu",
        "button.filter": "Trier Par",
        "heading.incidentReport": "Détails Rapport d’Incident",
        "heading.dateCreated": "Date de Création",
        "heading.dateResolved": "Date de Résolution",
        "text.location": "Lieu",
        "text.type": "Type",
        "text.count": "Nombre",
        "message.noOpenIncidents": "Pas de Rapports d’Incident Ouverts",
        "message.noClosedIncidents": "Pas de Rapports d’Incident Clôturés"
      },
      "create": {
        "heading.incidentReport": "Détails Rapport d’Incident",
        "title.description": "Description",
        "title.rootCauseAnalysis": "Analyse Cause Principale",
        "title.rootCauseHazard": "Risques Liés à la Cause Principale",
        "title.comments": "Commentaires",
        "title.injuries": "Blessure",
        "title.nearMiss": "Quasi-Collision",
        "title.propertyDamage": "Dommages matériels",
        "label.incidentType": "Type d’Incident",
        "label.location": "Choisir un Lieu",
        "label.incidentDate": "Date Incident",
        "label.incidentTime": "Heure Incident",
        "label.task": "Choisir Activité en cours d’exécution",
        "label.resolved": "Résolu",
        "label.rootCause": "Choisir Cause Principale",
        "label.potentialRisk": "Choisir Type de Risque",
        "label.incidentType.options": "Types d’Incident (Sélectionnez tout ce qui s'y rapporte)",
        "label.equipmentFailure": "Défaillance Équipement",
        "label.equipmentFailure.description": "Description Défaillance",
        "label.equipmentTheft": "Vol",
        "label.equipmentTheft.description": "Description Vol",
        "label.injuries": "Description de la blessure ou de la maladie",
        "label.nearMiss": "Description Quasi-Collision",
        "label.propertyDamage.owner": "Propriétaire du Bien",
        "label.propertyDamage.description": "Description Dommages",
        "label.propertyDamage.cause": "Cause Dommages",
        "placeholder.description": "Description Incident",
        "placeholder.correctiveAction": "Description Action Corrective",
        "placeholder.equipmentFailure.description": "Que s’est-il passé?",
        "placeholder.equipmentTheft.description": "Que s’est-il passé?",
        "placeholder.injuries.description": "Que s’est-il passé?",
        "placeholder.propertyDamage.description": "Quels sont les dommages?",
        "placeholder.propertyDamage.cause": "Quelle était la Cause?",
        "button.addHazard": "Ajouter Risque Lié à la Cause Principale",
        "button.save": "ENREGISTRER",
        "button.log": "Enregistrer Incident",
        "button.savedraft": "Enregistrer en tant que Brouillon",
        "button.update": "Mettre à Jour Incident",
        "button.archive": "Archiver Incident",
        "button.cancel": "Annuler",
        "message.saved": "Enregistré!",
        "message.draftSaved": "Brouillon enregistré!",
        "message.success": "Rapport d’Incident Enregistré avec Succès.",
        "message.descriptionAdded": "Description ajoutée.",
        "message.correctiveDescription": "Description Action Corrective ajoutée.",
        "message.error": "Une erreur s’est produite",
        "injury": {
          "title": "Joindre Blessure ou Maladie",
          "label.type": "Choisir Type de Blessure ou de Maladie",
          "label.bodyPart": "Choisir Partie du Corps",
          "label.treatment": "Choisir Type de Traitement",
          "placeholder.treatment": "Description Traitement",
          "message.treatment": "Note : le Superviseur doit compléter les détails du traitement dans le portail du bureau.",
          "button.done": "Terminé"
        },
        "witness": {
          "title": "Témoins",
          "button.add": "Ajouter Témoin",
          "modal": {
            "title": "Joindre Témoin",
            "placeholder.statement": "Entrer Déclaration...",
            "button.done": "Terminé"
          }
        },
        "add-equipment": {
          "title": "Équipement Concerné",
          "title_singular": "Équipement Concerné",
          "button.add": "Ajouter Équipement",
          "label.equipment": "Équipement",
          "modal": {
            "label.or": "OU",
            "label.results": "Équipement du Projet",
            "button.add": "Ajouter Nouvel Équipement"
          }
        }
      }
    },
    "equipment": {
      "index": {
        "button.back": "Précédent",
        "button.edit": "Modifier",
        "button.export": "Exporter",
        "button.cancel": "Annuler",
        "button.delete": "Supprimer",
        "button.yes": "Oui",
        "button.no": "Non",
        "button.archive": "Archiver Équipement",
        "button.createInspection": "Créer une nouvelle inspection",
        "button.createHazard": "Créer un nouveau danger",
        "heading.owner": "Propriétaire",
        "heading.inspections": "Inspections",
        "heading.inspection": "Détails Inspection",
        "heading.dateStarted": "Date Ouverture",
        "heading.dateClosed": "Date Clôture",
        "heading.maintenance": "Maintenance",
        "heading.comments": "Commentaires",
        "label.team": "Équipe",
        "label.location": "Lieu",
        "label.type": "Type",
        "label.make": "Marque",
        "label.model": "Modèle",
        "text.hazards": "Risques",
        "message.noInspections": "Aucune Inspection",
        "message.noHazards": "Aucun Risque",
        "text.hazard.unresolvedDetails": "Détails Risques Non résolus",
        "text.hazard.resolvedDetails": "Détails Risques Résolus",
        "text.details": "Détails",
        "label.serial": "Numéro de Série",
        "label.lastTestDate": "Date de Dernière Inspection",
        "label.testFreq": "Fréquence d’Inspection",
        "label.nextTestDate": "Date de Prochaine Inspection"
      }
    },
    "equipments": {
      "manage": "Enregistrer Équipement",
      "create": {
        "title.project": "Projet",
        "title.owner": "Propriétaire Équipement",
        "title.location": "Lieu",
        "title.description": "Description",
        "title.maintenance": "Maintenance",
        "label.project": "Choisir un Projet",
        "label.team": "Choisir Équipement",
        "label.location": "Choisir Lieu",
        "label.serial": "Numéro de Série",
        "label.eq_type": "Choisir Type Équipement",
        "label.make": "Choisir Marque",
        "label.model": "Modèle",
        "label.last_date_date": "Définir Date de Dernière Inspection",
        "label.test_frequency": "Définir Fréquence d’Inspection",
        "label.test_date": "Date de Prochaine Inspection",
        "message.location": "Faites glisser l'icône du marqueur à l'endroit souhaité",
        "button.update": "Mettre à Jour Équipement",
        "button.register": "Enregistrer Équipement",
        "button.cancel": "Annuler",
        "message.success": "Équipement Enregistré avec Succès.",
        "message.mandatory": "Veuillez remplir les champs obligatoires"
      },
      "index": {
        "title": "Enregistrement Équipement",
        "button.all": "Tous",
        "button.team": "Équipe",
        "button.type": "Type",
        "button.export": "Exporter",
        "button.filter": "Trier Par",
        "heading.itemDetails": "Détails article",
        "heading.nextService": "Prochain Service",
        "message.noEquipment": "Pas d’équipement enregistré",
        "placeholder.search": "Rechercher..."
      }
    },
    "certificate": {
      "index": {
        "button.back": "Précédent",
        "button.edit": "Modifier",
        "button.export": "Exporter",
        "button.cancel": "Annuler",
        "button.delete": "Supprimer",
        "button.yes": "Oui",
        "button.no": "Non",
        "button.archive": "Archiver Équipement",
        "title": "Certificat",
        "title.details": "Certificat Détails",
        "title.upload": "Télécharger Photo(s)",
        "text.details": "Certificat Détails",
        "label.name": "Nom ou type de certificat",
        "label.issuing_body": "Émetteur (facultatif)",
        "label.additional_notes": "Notes complémentaires",
        "label.date_issued": "Date de publication",
        "label.date_expires": "Date d'expiration"
      }
    },
    "certificates": {
      "manage_create": "Enregistrer Certificat",
      "manage_edit": "Modifier le certificat",
      "create": {
        "title.project": "Projet",
        "title.description": "Description",
        "title.details": "Certificat Détails",
        "title.upload": "Télécharger Photo(s)",
        "button.save": "Enregistrer le certificat",
        "button.update": "Mettre à Jour Équipement",
        "button.addnew": "Enregistrer Équipement",
        "button.cancel": "Annuler",
        "message.success": "Certificat Enregistré avec Succès.",
        "message.mandatory": "Veuillez remplir les champs obligatoires",
        "label.name": "Nom ou type de certificat*",
        "label.issuing_body": "Émetteur",
        "label.additional_notes": "Notes complémentaires",
        "label.date_issued": "Date de publication",
        "label.date_expires": "Date d'expiration",
        "placeholder": {
          "label.name": "Insérer Nom ou type de certificat",
          "label.issuing_body": "OSHA, CDC, etc.",
          "label.additional_notes": "Insérer notes complémentaires"
        }
      },
      "index": {
        "title": "Certificat",
        "button.all": "Tous",
        "button.issued": "Publié",
        "button.expiry": "Expiration",
        "button.filter": "Trier Par",
        "label.issued": "Publié",
        "label.expiry": "Expiration",
        "message.noCertificate": "Pas d’certificat enregistré",
        "placeholder.search": "Rechercher..."
      }
    },
    "modals": {
      "dropdown": {
        "text.pleaseSelect": " ",
        "text.searchOrAdd": "Rechercher ou ajouter un nouveau...",
        "text.search": "Rechercher...",
        "label.addNew": "Ajouter Nouveau",
        "errors.fail": "Le Contrôleur Déroulant n’a pas pu enregistrer de copie",
        "errors.team": "Échec dans l’enregistrement de l’Équipe",
        "errors.location": "Échec dans l’enregistrement du Lieu",
        "errors.equipmentType": "Échec dans l’enregistrement du Type d’Équipement",
        "errors.make": "Échec dans l’enregistrement de Marque",
        "errors.model": "Échec de l'enregistrement du modèle"
      },
      "dropdown-multiple": {
        "text.pleaseSelect": " ",
        "text.search": "Rechercher...",
        "text.removed_item": "Elément supprimé",
        "text.added_item": "Élément ajouté",
        "title.add-new-item": "ou Ajouter nouveau",
        "text.no-results": "Aucun résultat"
      },
      "archive-item": {
        "button.yes": "Oui, Archiver",
        "button.no": "Non, Annuler",
        "message.archiveConfirm": "Êtes-vous sûr de vouloir archiver cel?",
        "text.archived": "Archivé",
        "text.failedArchive": "N’a pas pu être archivé"
      },
      "exit-item": {
        "title": "Voulez-vous enregistrer et revenir à cela plus tard?",
        "button.yes_save": "Oui, Enregistrer",
        "button.cancel": "Annuler",
        "button.save_later": "Oui, Sauvegarder pour plus tard",
        "button.no": "Non, Annuler l’article",
        "text.saved": "Enregistré",
        "text.archived": "Archivé",
        "text.failedArchive": "N’a pas pu Archiver",
        "text.failedSave": "N’a pas pu Enregistrer"
      },
      "dropdown-site": {
        "title": "Tous les Projets"
      },
      "exit-new-item-mode": {
        "title": "Êtes-vous sûr de vouloir quitter cet Article de Sécurité?",
        "button.yes": "Oui"
      },
      "select-site": {
        "title": "Tous les Projets",
        "subtitle": "Veuillez Choisir un Projet",
        "button.select": "Choisir",
        "placeholder.search": "Rechercher..."
      },
      "select-project": {
        "title": "Choisir un Projet"
      },
      "hazard-create": {
        "title": "Signaler un Nouveau Risque",
        "message.location": "Lieu – Faites glisser l’icône du marqueur à l'endroit souhaité",
        "message.description": "Description et Action Corrective",
        "label.team": "Choisir Équipe",
        "label.location": "Choisir Lieu",
        "label.riskType": "Choisir Type de Risque",
        "label.riskProbability": "Choisir Probabilité du Risque",
        "label.riskConsequence": "Choisir Conséquence du Risque",
        "button.save": "Enregistrer Risque",
        "button.resolve": "Résoudre Risque"
      },
      "observation-create": {
        "title": "Enregistrer Observation",
        "label.safe": "Positif",
        "label.risk": "Négatif",
        "label.location": "Choisir Lieu",
        "label.team": "Choisir Équipe (optionnel)",
        "placeholder.description": "Description",
        "button.save": "Enregistrer"
      },
      "announcement-modal": {
        "title": "Annonce",
        "button.ack": "Reconnaître"
      },
      "location-permission": {
        "title": "Services de localisation désactivés",
        "subtitle": "Veuillez activer les services de localisation afin de pouvoir localiser où vos actions de sécurité ont lieu.",
        "footer": "Nous ne suivons pas les personnes et ne partageons pas vos données. Nous utilisons uniquement les données de localisation pour les éléments de sécurité.",
        "contact_support": "Si le problème persiste, lisez cet <a href='http://help.safesitehq.com/en/articles/4531342-location-services' target='_blank'>article HelpCenter</a> ou <a href='mailto:success@safesitehq.com'>Contactez le support</a>.",
        "error.title": "Activez le service de localisation",
        "error.message": "Safesite n'a pas pu accéder à votre emplacement"
      },
      "feature-disabled": {
        "title": "Fonctionnalité désactivée hors ligne"
      }
    },
    "userprofile": {
      "title": "Profil",
      "label.photo.add": "Ajouter Photo",
      "label.photo.edit": "modifier",
      "label.button.add": "ajouter",
      "label.button.edit": "modifier",
      "label.button.editName": "modifier nom",
      "label.button.update": " mettre à jour",
      "label.button.cancel": "annuler",
      "label.firstName": "Prénom",
      "placeholder.firstName": "Entrer Prénom (requis)",
      "label.lastName": "Nom de Famille",
      "placeholder.lastName": "Entrer Nom de Famille (requis)",
      "label.email": "E-mail",
      "placeholder.phone": "Entrer numéro de téléphone",
      "label.phone": "Numéro de téléphone",
      "label.organization": "Organisation",
      "label.organizations": "Organisations",
      "label.appVersion": "Version de l’Application",
      "label.defaultLanguage": "Langue Préférée",
      "label.updateApp": "Mettre à jour l'application"
    },
    "contact-us": {
      "title": "Entrez en contact",
      "message.feedback": "Votre avis compte beaucoup pour nous et nous reviendrons vers vous dès que possible.",
      "message.successfully.sent": "Message envoyé avec succès.",
      "message.error": "Une erreur s’est produite, veuillez réessayer",
      "placeholder.message": "Message",
      "button.send": "Envoyer"
    },
    "errors": {
      "network": "Une erreur réseau s'est produite! Veuillez réessayer ou contacter l’assistance."
    },
    "components": {
      "image-upload": {
        "take-picture": "Prendre une photo",
        "from-gallery": "Choisir depuis la galerie"
      },
      "item-comments": {
        "message.noComment": "Aucun commentaire(s) enregistré",
        "button.add": "Ajouter Commentaire",
        "placeholder.comment": "Entrer un commentaire..."
      },
      "modal-dialog": {
        "button.cancel": "Annuler"
      },
      "upload": {
        "text.uploading": "En cours de téléchargement..."
      },
      "custom-dropdown-button": {
        "text.select": "Choisir"
      },
      "date-field": {
        "text.set": "Définir"
      },
      "time-field": {
        "text.set": "Définir"
      },
      "loading-btn": {
        "disabled.offline.message": "désactivée hors ligne"
      },
      "add-injury": {
        "label": "Ajouter une blessure ou une maladie",
        "body-parts": {
          "heading": "Parties du corps ajoutées",
          "select-modal": {
            "title.select": "sélectionner les parties du corps"
          }
        }
      },
      "add-person": {
        "title": "Personnes Impliquées",
        "title_singular": "Personne Impliquée",
        "button.add": "Ajouter Personne",
        "placeholder.search": "Rechercher...",
        "label.person": "Personne",
        "label.add_existing": "Ajouter une personne existante",
        "label.create_new": "Créer une nouvelle personne",
        "modal": {
          "label.or": "OU",
          "label.results": "Résultats",
          "placeholder.jobTitle": "Profession",
          "placeholder.firstName": "Prénom",
          "placeholder.lastName": "Nom de Famille",
          "placeholder.email": "Email (facultatif)",
          "placeholder.phone": "Numéro de Contact",
          "placeholder.company": "Nom de l’Entreprise",
          "button.add": "Ajouter Nouvelle Personne"
        }
      }
    },
    "meeting-item": {
      "text.incomplete": "Incomplet",
      "text.pendingHazards": "Risques en Suspens",
      "text.closedHazards": "Risques Clôturés"
    },
    "incident-item": {
      "text.incomplete": "Incomplet",
      "text.pendingHazards": "Risques en Suspens",
      "text.closedHazards": "Risques Clôturés"
    },
    "inspection-item": {
      "text.incomplete": "Incomplet",
      "text.pendingHazards": "Risques en Suspens",
      "text.closedHazards": "Risques Clôturés"
    },
    "open-hazard-item": {
      "title": "Résoudre Risque"
    },
    "logout-confirm": {
      "title": "Êtes-vous sûr de vouloir vous déconnecter?",
      "button.yes": "Oui",
      "button.forceLogout": "Oui, forcer la déconnexion",
      "subtitle.message.offline": "Vous êtes actuellement hors ligne, sachez que si vous vous déconnectez hors ligne, vous risquez de perdre des données qui n'ont pas encore été synchronisées.",
      "subtitle.message.unableToLogout": "Nous ne sommes pas en mesure de nous connecter à nos serveurs pour le moment. Sachez que si vous vous déconnectez hors ligne, vous risquez de perdre des données qui n'ont pas encore été synchronisées.",
      "message.error": "Une erreur s’est produite, veuillez réessayer ou contacter l’assistance."
    },
    "custom-dropdown": {
      "button.cancel": "Annuler",
      "button.done": "Terminé"
    },
    "signup-steps": {
      "title": "S’inscrire",
      "step1": {
        "title": "Commençons",
        "info": "Veuillez entrer votre adresse e-mail ci-dessous",
        "placeholder.email": "Email",
        "button.continue": "Continuer",
        "button.cancel": "Annuler"
      },
      "step2": {
        "title": "Profil",
        "info": "Nous devons vous envoyer un court e-mail pour confirmer votre adresse e-mail. Veuillez renseigner ci-dessous",
        "placeholder.password": "Mot de Passe",
        "placeholder.password.confirm": "Confirmer le mot de passe",
        "placeholder.firstName": "Prénom",
        "placeholder.lastName": "Nom de Famille",
        "placeholder.company": "Nom de l’Entreprise",
        "placeholder.phoneNumber": "Numéro de Téléphone",
        "button.continue": "Continuer",
        "button.cancel": "Annuler"
      },
      "step3": {
        "title": "Entreprise",
        "not-found-subtext1": "Nous n’avons pu trouver aucune organisation correspondant à cette adresse e-mail.",
        "not-found-subtext2": "Créez votre propre organisation, entrer le nom ci-dessous",
        "placeholder.company": "Nom de votre entreprise",
        "found-subtext1": "Bonne nouvelle, votre entreprise est déjà répertoriée dans notre système",
        "found-subtext2": "Continuons",
        "button.continue": "Continuer",
        "button.cancel": "Annuler"
      },
      "step4": {
        "title": "Vos Projets",
        "subtext1": "Vous avez manqué un projet? Demandez à votre manager ou contactez le service client après avoir finalisé votre inscription.",
        "projects-subtext1": "Vos projets en cours",
        "button.continue": "Continue",
        "button.cancel": "Fermer"
      },
      "step5": {
        "title": "Veuillez consulter votre boite E-mail pour vérifier.",
        "subtext1": "Pour confirmer votre adresse e-mail, ouvrez votre boite e-mail depuis votre appareil mobile et appuyez sur le bouton dans votre e-mail.",
        "button.resend": "Renvoyer Email",
        "button.cancel": "Fermer"
      }
    },
    "project-invite": {
      "title": "Maintenant, commençons",
      "org-projects-text-part1": "Voir les",
      "org-projects-text-part2": "projets ci-dessous dont vous faites partie",
      "button.continue": "Continuer",
      "button.complete": "Compléter",
      "button.cancel": "Fermer",
      "placeholder.email": "Email",
      "placeholder.password": "Créer un mot de passe",
      "placeholder.password.confirm": "Confirmer le Mot de passe",
      "placeholder.firstName": "Prénom",
      "placeholder.lastName": "Nom de Famille",
      "placeholder.company": "Nom de l’Entreprise",
      "placeholder.phoneNumber": "Numéro de Téléphone (facultatif)"
    },
    "validate-email": {
      "title": "Vérifiez votre e-mail",
      "text-part1": "Nous avons envoyé un e-mail de vérification à",
      "text-part2": "Vous n'avez pas reçu votre e-mail? Essayez à nouveau d'envoyer",
      "button.resend": "Renvoyer Email",
      "button.cancel": "Fermer"
    },
    "verify-email": {
      "title": "Vérifier Email",
      "text-part1": "Vérifiez votre adresse e-mail en cliquant sur le lien d’activation que nous vous avons envoyé",
      "text-part2": "En cours de Validation......",
      "button.resend": "Renvoyer Email",
      "button.cancel": "Fermer"
    },
    "tour": {
      "title": "Bienvenue sur Safesite!",
      "body": "Regardez la vidéo ci-dessous pour découvrir l'offre complète de Safesite.",
      "button.done": "C’est parti",
      "video.src": "https://player.vimeo.com/video/385146550?autoplay=0&title=0",
      "message.offline": "La vidéo de la tournée n'est pas disponible hors ligne"
    },
    "offline-mode": {
      "message.offline": "Vous êtes hors ligne, mais vous pouvez continuer à utiliser Safesite. Nous synchroniserons vos données une fois que vous serez de retour en ligne.",
      "message.syncing": "Synchronisation..."
    },
    "default-form-messages": {
      "enter.valid.email": "S'il vous plaît, mettez une adresse email valide",
      "enter.valid.mobile.number": "Veuillez entrer un numero de mobile valide",
      "enter.valid.email.or.mobile.number": "Veuillez saisir une adresse e-mail ou un numéro de portable valide",
      "enter.valid.first_last_names": "Veuillez entrer les noms et prénoms",
      "enter.valid.email_to_invite_project": "Entrez l'e-mail pour inviter au projet",
      "enter.valid.fill_in_all_fields": "Merci de remplir tous les champs",
      "results": "Résultats"
    },
    "release-notes": {
      "title": "Safesite fonctionne désormais hors ligne",
      "message": "Safesite hors ligne est ici! Si vous vous déconnectez, nous enregistrerons les modifications sur votre appareil et les synchroniserons une fois de nouveau en ligne. En savoir plus sur notre <a href='https://help.safesitehq.com/en/articles/4340586-using-the-app-while-offline-coming-soon' target='_blank'>HelpCenter</a>."
    },
    "add-signature-btn": {
      "text": "Ajouter une signature"
    },
    "add-signature-modal": {
      "title": "Ajouter une signature",
      "instruction": "Signe au-dessus de la ligne pointillée",
      "clear": "nette",
      "save": "Terminé"
    }
  };
  _exports.default = _default;
});