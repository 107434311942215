define("safesite/controllers/modals/tour-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TourModalStepsController = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    i18n: Ember.inject.service(),
    connectionStatus: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    applicationController: Ember.inject.controller('application'),
    isLoading: false,
    isOffline: Ember.computed('connectionStatus.offline', function () {
      return this.connectionStatus.offline;
    }),
    actions: {
      skip: function skip() {
        this.metrics.trackEvent({
          event: 'w_SignUp_Get_Started'
        });
        this.send('closeTourModal');
      }
    }
  });
  var _default = TourModalStepsController;
  _exports.default = _default;
});