define("safesite/controllers/sites", ["exports", "jquery", "safesite/config/environment", "safesite/mixins/pagination"], function (_exports, _jquery, _environment, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pagination.default, {
    authController: Ember.inject.controller('auth'),
    createController: Ember.inject.controller('sites/create'),
    sortBy: ['name:asc'],
    joinSiteLoading: false,
    createSiteLoading: false,
    queryParams: ['page'],
    actions: {
      createSite: function createSite() {
        this.toggleProperty('createSiteLoading');
        this.createController.set('joinSite', false);
        this.createController.set('linkTo', null);
        this.send('openCreateSite');
        this.toggleProperty('createSiteLoading');
      },
      joinSite: function joinSite() {
        var self = this;
        self.get('createController').set('joinSite', true);
        self.get('createController').set('linkTo', null);
        self.toggleProperty('joinSiteLoading');
        var host = _environment.default.APP.API_HOST;
        var namespace = _environment.default.APP.API_NAMESPACE;
        var siteListURL = [host, namespace, 'siteList'].join('/');

        _jquery.default.ajax({
          async: false,
          type: 'GET',
          url: siteListURL,
          headers: {
            'Authorization': 'Token ' + self.session.get('api_token')
          },
          success: function success(sites) {
            self.get('createController').set('sites', sites["siteListView"]);
            self.get('createController').set('joinSite', true);
            self.get('createController').set('linkTo', null);
            self.send('openJoinSite');
            self.toggleProperty('joinSiteLoading');
          }
        });
      }
    }
  });

  _exports.default = _default;
});