define("safesite/controllers/modals/feature-disabled-while-offline", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FeatureDisabledWhileOfflineController = Ember.Controller.extend({
    i18n: Ember.inject.service()
  });
  var _default = FeatureDisabledWhileOfflineController;
  _exports.default = _default;
});