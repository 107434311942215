define("safesite/components/language-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    classNames: ['language-select'],
    localeChanged: Ember.observer('locale', function () {
      if (Ember.isPresent(this.locale)) {
        this.set('i18n.locale', this.locale);
        localStorage.setItem('locale', this.locale);
      }
    }),
    locales: Ember.computed('i18n.{locale,locales}', function () {
      return this.get('i18n.locales').map(function (loc) {
        var language;

        switch (loc) {
          case 'en':
            language = 'English';
            break;

          case 'es':
            language = 'Español';
            break;

          case 'pt':
            language = 'Português';
            break;

          case 'fr':
            language = 'Français';
            break;
        }

        return {
          id: loc,
          text: language
        };
      });
    }),
    actions: {
      setLocale: function setLocale() {
        var locale = this.$('select').val();

        if (!Ember.isEmpty(this.languageChanged)) {
          this.languageChanged(locale);
        } else {
          this.set('locale', locale);
        }
      }
    }
  });

  _exports.default = _default;
});