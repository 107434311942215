define("safesite/initializers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:nav-menu', 'application', 'controller:application');
    application.inject('component:footer-menu', 'application', 'controller:application');
    application.inject('component:leaflet-map', 'application', 'controller:application');
    application.inject('route', 'router', 'router:main');
    application.inject('component', 'router', 'router:main');
    application.inject('controller', 'router', 'router:main');
  }

  var _default = {
    name: 'application',
    initialize: initialize
  };
  _exports.default = _default;
});