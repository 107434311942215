define("safesite/services/connection-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    online: false,
    offline: Ember.computed.not('online'),
    startWatching: function () {
      var _this = this;

      this.set('online', navigator.onLine);

      var updateStatus = function updateStatus() {
        _this.set('online', navigator.onLine);
      };

      window.addEventListener('online', updateStatus);
      window.addEventListener('offline', updateStatus);
    }.on('init')
  });

  _exports.default = _default;
});