define("safesite/templates/components/input-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/7u+GFn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[0,\"\\n    \"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\"* \"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\",\"disabled\"],[[24,[\"type\"]],\"form-control  input-lg\",[24,[\"placeholder\"]],[24,[\"value\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/input-number.hbs"
    }
  });

  _exports.default = _default;
});