define("safesite/templates/meeting/add-attendees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iXT729z8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"add-attendees\",null,[[\"openMultipleDropdownHandler\",\"showErrors\",\"errors\",\"model\",\"meetingIsCompleted\"],[\"openMultipleDropdown\",[24,[\"showErrors\"]],[24,[\"errors\",\"model\",\"person\"]],[24,[\"model\"]],\"meetingIsCompleted\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/meeting/add-attendees.hbs"
    }
  });

  _exports.default = _default;
});