define("safesite/templates/components/language-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JIkAyfA/",
    "block": "{\"symbols\":[\"loc\"],\"statements\":[[7,\"select\",false],[3,\"action\",[[23,0,[]],\"setLocale\"],[[\"on\"],[\"change\"]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"locales\"]]],null,{\"statements\":[[0,\"        \"],[7,\"option\",true],[11,\"value\",[29,[[23,1,[\"id\"]]]]],[11,\"selected\",[28,\"is-equal\",[[23,1,[\"id\"]],[24,[\"locale\"]]],null]],[8],[0,\"\\n            \"],[1,[23,1,[\"text\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/language-select.hbs"
    }
  });

  _exports.default = _default;
});