define("safesite/templates/components/image-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vhKu1uVA",
    "block": "{\"symbols\":[\"closeModal\"],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[[22,\"iconClasses\"]]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\"    \"],[7,\"b\",true],[8],[0,\"*\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isModalOpened\"]]],null,{\"statements\":[[4,\"safesite-modal\",null,[[\"action\",\"title\"],[\"closeModalHandler\",[24,[\"title\"]]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"ss-custom-dropdown\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n                 \"],[1,[28,\"loading-btn\",null,[[\"classNames\",\"action\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"btn btn-lg btn-primary btn-block\",[28,\"action\",[[23,0,[]],\"takePicture\",[23,1,[]]],null],[28,\"t\",[\"components.image-upload.take-picture\"],null],[24,[\"isUploading\"]],[24,[\"isUploading\"]]]]],false],[0,\"\\n\\n                 \"],[1,[28,\"loading-btn\",null,[[\"classNames\",\"action\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"btn btn-lg btn-primary btn-block\",[28,\"action\",[[23,0,[]],\"pickImages\",[23,1,[]]],null],[28,\"t\",[\"components.image-upload.from-gallery\"],null],[24,[\"isUploading\"]],[24,[\"isUploading\"]]]]],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/image-upload.hbs"
    }
  });

  _exports.default = _default;
});