define("safesite/components/add-cocreator-yielder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isFlagEnabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.cocreators = this.cocreators || [];
    },
    // This logic exists to handle the scenario where an organization may have
    // had the is_cocreator_enabled flag enabled. A user in the org then began
    // a safety item which was not resolved (e.g. a Hazard). The org then 
    // decided to disable the is_cocreator_enabled flag. In that scenario it
    // would be very confusing to the user if the data in the safety item they
    // were already working on dissapeared from the UI. It would be even
    // doubly confusing when they completed the item and saw the cocreators
    // that they initially configured were still stored on the safety item
    // and they were now unable to see/remove them in mobile edit/create pages.
    canYieldAddCoCreator: Ember.computed('isFlagEnabled', 'cocreators', function () {
      return this.isFlagEnabled || this.cocreators.length > 0;
    })
  });

  _exports.default = _default;
});