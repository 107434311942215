define("safesite/controllers/dashboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    siteController: Ember.inject.controller('site'),
    actions: {}
  });

  _exports.default = _default;
});