define("safesite/locales/es/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _index;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = {
    "pagination": {
      "previous": "Anterior",
      "next": "Siguiente"
    },
    "datePicker": {
      "clear": "Limpiar",
      "close": "Cerrar",
      "today": "Hoy",
      "month.jan": "Enero",
      "month.feb": "Feb",
      "month.mar": "Mar",
      "month.apr": "Abr",
      "month.may": "Mayo",
      "month.jun": "Jun",
      "month.jul": "Jul",
      "month.aug": "Agosto",
      "month.sep": "Set",
      "month.oct": "Oct",
      "month.nov": "Nov",
      "month.dec": "Dic",
      "week.sun": "Do",
      "week.mon": "Lu",
      "week.tue": "Ma",
      "week.wed": "Mi",
      "week.thu": "Ju",
      "week.fri": "Vi",
      "week.sat": "Sá"
    },
    "models": {
      "Hazard": "Riesgo",
      "Observation": "Observación",
      "Meeting": "Reunión",
      "Inspection": "Inspección",
      "IncidentReport": "Informe de incidente",
      "Incident Report": "Informe de incidente",
      "Incident": "Incidente",
      "Equipment": "Equipo"
    },
    "splash": {
      "button.get-started": "Empezar",
      "hero.pre-text": "Ir",
      "hero.alt-text": "  Tras",
      "hero.post-text": " Cumplimiento de seguridad",
      "label.terms.text": "Al continuar, acepta nuestro ",
      "label.terms.link": "Terms & Conditions",
      "message.language": "Idioma predeterminado"
    },
    "welcome": {
      "title": "¡Bienvenido a Safesite!",
      "subtitle": "Ingrese su correo electrónico para comenzar",
      "label.email": "Correo electrónico",
      "placeholder.email": "nombre@compañía.com",
      "button.continue": "Continuar",
      "link.reset": "¿Has olvidado tu contraseña?",
      "reset.message": "Encontramos su cuenta, pero parece que necesita configurar una contraseña. Le enviamos un enlace para restablecer la contraseña por correo electrónico; ábralo en su dispositivo móvil.",
      "message.offline": "Debe estar en línea para registrarse o iniciar sesión.",
      "messages.reset.email": "Por favor revise su correo electrónico."
    },
    "resetted": {
      "title": "Aplicación Reiniciada",
      "message": "¡Vaya! La aplicación Safesite se reinició debido a un error. Si este problema persiste, comuníquese con nuestro útil equipo de Éxito del cliente en success@safesitehq.com o mediante un mensaje de asistencia en la aplicación. Disculpe las molestias",
      "button.ok": "Aprobado"
    },
    "auth": {
      "button.signup": "Registrarse",
      "button.go": "Ir",
      "button.reset": "¿Has olvidado tu contraseña?",
      "label.email": "Correo electrónico",
      "label.password": "Contraseña",
      "placeholder.email": "Ingrese correo electrónico",
      "placeholder.password": "Introducir la contraseña",
      "password.hide": "Esconder",
      "password.show": "Mostrar",
      "password.error": "Por favor, ingrese contraseña",
      "email.error": "Por favor, introduzca la dirección de correo electrónico",
      "message.new": "¿Eres nuevo en Safesite?",
      "message.terms_1": "Al registrarse, usted acepta ",
      "message.terms_2": "los Términos del Servicio",
      "title": "Iniciar sesión",
      "subtitle": "¡Dar una buena acogida! Ingrese su correo electrónico y contraseña a continuación",
      "message.error": "Se ha producido un error, por favor intente de nuevo o contacte a soporte técnico."
    },
    "reset": {
      "button.login": "Actualizar",
      "button.reset": "¿Reenviar contraseña olvidada?",
      "label.email": "Correo electrónico",
      "label.password": "Crear contraseña (menos 8 caracteres)",
      "placeholder.email": "",
      "placeholder.password": "Nueva Contraseña",
      "password.hide": "Esconder",
      "password.show": "Mostrar",
      "password.error": "Por favor, ingrese contraseña",
      "label.terms.pretext": "Al registrarse, usted acepta ",
      "label.terms.link": "los Términos del Servicio",
      "title": "Configurar la Clave",
      "subtitle": "Introduzca su nueva contraseña",
      "message.error": "Se ha producido un error, inténtelo de nuevo o póngase en contacto con el servicio de asistencia."
    },
    "sync": {
      "button.start": "Empezar",
      "button.continue": "Seguir",
      "label.logout": "finalizar la sesión",
      "db_creation.message": "Habilitación sin conexión...",
      "h4.signup_creation": "Creando Cuenta",
      "h4.sync_progress": "Sincronizando cuenta para uso sin conexión...",
      "h4.sync_progress_larger_accounts": "...esto puede demorar hasta 2 minutos para cuentas más grandes",
      "h4.sync_progress_signup": "Configurando su cuenta...",
      "h4.sync_progress_signup_larger_accounts": "...esta es una sincronización única, por lo que la aplicación funciona sin conexión",
      "h4.sync_completed": "¡Instalación completa!",
      "video.message": "Mientras espera, familiarícese con Safesite",
      "finishing.message": "Finalizando la sincronización, no desconecte",
      "user.has.unsynced.items": "Estás sin conexión con datos no sincronizados.",
      "is.complete": "Sincronización completada!"
    },
    "reset-password": {
      "title": "¿Has olvidado tu contraseña?",
      "placeholder.email": "Correo Electrónico",
      "button.reset": "Restablecer la contraseña",
      "error": {
        "no-connection": "No se pudo conectar con el servidor, intente nuevamente más tarde",
        "no-user": "El correo electrónico ingresado no se encuentra en el sistema",
        "default": "No se pudo conectar con el servidor, intente nuevamente más tarde"
      },
      "email.sent": "Consulte su cuenta de correo electrónico para instrucciones de como restablecer su contraseña"
    },
    "change-password": {
      "title": "Actualiza Contraseña",
      "placeholder.newPassword": "Nueva Contraseña",
      "placeholder.confirmPassword": "Confirmar Contraseña",
      "button.update": "Actualiza Contraseña",
      "password.hide": "Esconder",
      "password.show": "Mostrar"
    },
    "signup": {
      "title": "Empecemos",
      "subtitle": "Ingrese un correo electrónico y contraseña para crear su perfil de Safesite",
      "label.email": "Correo electrónico",
      "label.password": "Crear contraseña (menos 8 caracteres)",
      "placeholder.email": "Ingrese correo electrónico",
      "placeholder.password": "Crear Contraseña",
      "label.terms.pretext": "Al registrarte estás de acuerdo con nuestros ",
      "label.terms.link": "Términos y Condiciones",
      "button.signup": "Regístrate"
    },
    "signup-organization-set-up": {
      "industry.type": "Industria",
      "select.industry.type": "Seleccionar industria",
      "label.organization": "Organización",
      "subtitle": "Para personalizar su experiencia, ingrese más información (opcional) a continuación",
      "title": "Crear organización",
      "validation.orgNotBlank": "El nombre de la organización no debe dejarse en blanco."
    },
    "nav": {
      "side": {
        "label.certificates": "Certificados",
        "label.help_center": "Centro de Ayuda",
        "label.join_webinar": "Webinars",
        "label.contact": "Apoyo",
        "label.refresh": "Refrescar",
        "label.tour": "Gira",
        "label.logout": "Cerrar cesión"
      },
      "footer": {
        "main": {
          "Actions": "Acciones",
          "Projects": "Proyectos",
          "Inbox": "Notificaciones",
          "SafetyHQ": "Seguridad<br>HQ"
        },
        "add": {
          "title": "Nueva acción de seguridad",
          "inspection": "Iniciar sesión<br>Inspección",
          "hazard": "Agregar <br>Peligro",
          "meeting": "Dirigir <br> Reunión",
          "equipment": "Inspeccionar <br>Equipo",
          "observation": "Iniciar sesión<br>Observación",
          "incident": "Iniciar sesión <br>Incidente"
        },
        "manage": {
          "title": "Administrar la seguridad del equipo",
          "message.create_project": "Crea tu propio proyecto para acceder a SafetyHQ.",
          "message.non_org_admin": "Desafortunadamente no puede acceder a SafetyHQ para ninguno de tus proyectos, comunícate con el gerente de tu organización.",
          "message.not_your_org": "No puedes acceder a SafetyHQ para este proyecto.",
          "button.create_project": "Crea tu proyecto",
          "inspections": "Inspecciones",
          "hazards": "Peligro",
          "meetings": "Reuniones",
          "equipment": "Equipo",
          "observations": "Observaciones",
          "incidents": "Incidentes <br> Informes"
        }
      }
    },
    "actions": {
      "index": {
        "title.hazards": "Peligros",
        "title.team.hazards": "Mi equipo Hazards",
        "title.my.hazards": "Mis peligros creados",
        "title.in_progress": "Elementos en progreso",
        "title.incomplete": "Incompletas",
        "title.actions_this_week": "Actividades",
        "message.no_more_actions": "No más acciones.",
        "message.new_item_1": "Haga clic en el botón + a continuación",
        "message.new_item_2": "para iniciar una nueva actividad de seguridad.",
        "cards": {
          "title.inspection": "Inspección de Conducta",
          "title.hazard": "Grabar riesgo",
          "title.observation": "Iniciar Sesión Observación",
          "title.meeting": "Dirigir Reunión",
          "title.resolve": "Resolver el Peligro",
          "title.announcement": "Anuncio",
          "title.profile": "Perfil Completo",
          "title.verify": "Verificar correo electrónico",
          "title.safety-item": "Completa tu primera acción de seguridad.",
          "text.project": "Proyecto",
          "text.raised": "Criado Por"
        },
        "empty-state": {
          "project": "Proyecto ",
          "actions.title": "¡Buen trabajo completando sus acciones de seguridad! Toque el botón + para crear más.",
          "incomplete.title": "¡Buen trabajo completando sus acciones incompletas!",
          "unresolved-hazards.title": "¡Buen trabajo resolviendo todos sus peligros!",
          "message.part1": "Mejorar la seguridad es un trabajo importante que nunca termina realmente. Manténgase activo y complete nuevas acciones de seguridad a medida que surjan con el ",
          "message.part2": "botón de abajo"
        }
      },
      "text.overdue": "Atrasado",
      "text.today": "Hoy",
      "text.left": "para ir"
    },
    "sites": {
      "title.main": "Tus Proyectos",
      "message.empty": "Es hora de crear tu primer proyecto",
      "message.non_org_admin": "¿No puedes ver tu proyecto? Contacta a tu administrador para obtener acceso.",
      "button.join": "Unirse al Proyecto",
      "button.create": "Crear Proyecto",
      "button.invite": "Invitar",
      "button.remove": "Salir",
      "create": {
        "label.name": "* Nombre del Proyecto",
        "label.address": "Dirección",
        "placeholder.name": "Ingrese el nombre del proyecto",
        "placeholder.address": "Ingrese la dirección del proyecto",
        "placeholder.search": "Buscar nombre del proyecto",
        "label.organization": "Seleccione Organizacion",
        "button.join": "Unir Proyecto",
        "button.save": "Crear",
        "message.project": "Si el proyecto no pertenece a su organización, deberá ponerse en contacto con el administrador de Safesite de esa organización.",
        "title.create": "Nuevo Proyecto",
        "title.join": "Unirse al proyecto existente",
        "site.name.already.exists": "Ya existe un proyecto con ese nombre",
        "site.successfully.created": "Proyecto creado exitosamente"
      },
      "invite-modal": {
        "title": "Invita al Proyecto",
        "subtitle": "Invite al personal a unirse a su proyecto ingresando su correo electrónico a continuación:",
        "button": "Invitación",
        "invitation.sent.online.alert": "Tu invitación ha sido enviada",
        "invitation.sent.offline.alert": "Su invitación se enviará una vez que vuelva a estar en línea"
      },
      "leave-modal": {
        "title": "¿Está seguro? Esto lo eliminará completamente del proyecto.",
        "button.yes": "Sí"
      }
    },
    "notifications": {
      "index": {
        "title": "Notificaciones",
        "filter.read": "Leer",
        "filter.unread": "No leído",
        "message.empty": "No hay mensajes sin leer, ¡ya estás al dia!",
        "button.read": "Marcar como leído"
      }
    },
    "inspection": {
      "conduct": "Dirigir inspección",
      "index": {
        "title": "Inspección",
        "message.unresolved": "Riesgos no resueltos restantes",
        "message.completed": "Inspección completa",
        "button.back": "Atrás",
        "button.export": "Exportar",
        "button.archive": "Inspección de archivo",
        "heading.unresolved": "Riesgos no resueltos",
        "heading.timeframe": "Tiempo estimado",
        "heading.details": "Detalles",
        "heading.resolvedHazards": "Riesgos resueltos",
        "heading.dateResolved": "Fecha resuelta",
        "heading.observations": "Observaciones de seguridad",
        "heading.answers": "Respuestas",
        "label.inspection_template": "Plantilla de Inspección",
        "label.location": "Ubicación",
        "label.conductedOn": "Dirigido en",
        "label.conductedBy": "Dirigido por",
        "label.hazardLogged": "Riesgos registrados",
        "label.observationLogged": "Observaciones registradas",
        "label.commentAdded": "Comentario agregado",
        "text.answered": "Respondido",
        "text.question": "Pregunta",
        "message.noComments": "Sin comentarios registrados"
      }
    },
    // Default - may be overridden by safety item specific strings
    "add-cocreator": {
      "title": "Cocreadors",
      "button.add": "Agregar Cocreador",
      "no-cocreators": "No hay usuarios añadidos",
      "modal-title": "Seleccionar cocreador"
    },
    "inspections": {
      "add-cocreator": {
        "title": "Dirigido por",
        "button.add": "Agregar persona",
        "modal-title": "Seleccionar persona"
      },
      "create": {
        "error.missing-template": "No se pudo encontrar la plantilla, intente nuevamente o comuníquese con soporte.",
        "heading": "Detalles de inspección",
        "label.location": "Seleccionar Ubicación",
        "label.template": "Seleccionar plantilla de inspección",
        "label.location-modal": "Ubicación",
        "label.template-modal": "Plantilla",
        "button.complete": "Completar inspección",
        "button.save": "Guardar Inspección",
        "button.archive": "Archivar Inspección",
        "question-item": {
          "counter": "Pregunta {{number}} de {{total}}",
          "placeholder.comment": "Introducir comentario",
          "button.add-observation": "Agregar observación",
          "message.observation-added": "Observación registrada",
          "message.hazard-added": "Riesgo detectado",
          "label.optional": "Opcional",
          "label.comments": "Comentarios",
          "label.actions": "Comportamiento",
          "placeholder.response": "Ingrese respuesta *",
          "label.required": "* = Necesario",
          "question_type.undefined.positive": " ",
          "question_type.undefined.negative": " ",
          "question_type.undefined.na": " ",
          "question_type.1.positive": "&#x2714;&#xfe0e;",
          "question_type.1.negative": "&#x2716;&#xfe0e;",
          "question_type.1.na": "NA",
          "question_type.2.positive": "Seguro",
          "question_type.2.negative": "Inseguro",
          "question_type.2.na": "NA",
          "question_type.3.positive": "Aprobado",
          "question_type.3.negative": "No aprobado",
          "question_type.3.na": "NA",
          "question_type.4.positive": "Si",
          "question_type.4.negative": "No",
          "question_type.4.na": "NA"
        },
        "section-item": {
          "footer.answers": " de ",
          "footer.questions": " preguntas completadas"
        },
        "message.saved": "Guardado!",
        "message.draftSaved": "¡Borrador guardado!",
        "message.answerAllQuestions": "Por favor complete las preguntas",
        "message.sectionLabel": "Sección ",
        "message.missLocation": "Por favor seleccione una ubicación",
        "message.success": "Inspección guardada con éxito .",
        "message.completed": "Inspección completada con éxito.",
        "message.observationAdded": "Observación guardada con éxito.",
        "message.hazardAdded": "Riesgo guardado con éxito.",
        "message.recent": "Reciente",
        "message.myOrg": "Mi Org",
        "templates-library": {
          "message.noRecentlyUsedTemplate": "Aún no tiene plantillas utilizadas recientemente, complete su primera inspección.",
          "message.noTemplatesInYourOrg": "Aún no tienes plantillas en la biblioteca de tu organización. Agregue algunos haciendo que un administrador inicie sesión en el panel de administración a través de app.safesitehq.com",
          "message.noResults": "No hay resultados para mostrar."
        }
      },
      "index": {
        "title": "Inspecciones",
        "button.export": "Exportar",
        "button.open": "Abrir",
        "button.closed": "Cerrar",
        "button.all": "Todo",
        "button.person": "Persona",
        "button.type": "Tipo",
        "button.filter": "Filtrar por",
        "heading.inspectionDetails": "Detalles de la inspección",
        "heading.dateStarted": "Fecha de inicio",
        "heading.dateClosed": "Fecha de cierre",
        "message.noOpenInspections": "Sin inspecciones abiertas",
        "message.noClosedInspections": "Sin inspecciones cerradas",
        "text.conductedBy": "Dirigido por",
        "text.type": "Tipo",
        "text.count": "Contar"
      },
      "skip-modal": {
        "title": "¿Estás seguro de que quieres saltar esta sección?",
        "button.confirm": "Sí, saltar sección",
        "label.questions": " Preguntas",
        "message": "Omitir una sección marcará todas las respuestas en la sección como NA",
        "skip.btn": "Saltarse"
      }
    },
    "meeting.conduct": "Dirigir reunión",
    "meeting.index": {
      "title": "Reuniones",
      "heading.unresolvedHazardsCount": "Riesgos no resueltos restantes",
      "heading.unresolvedHazards": "Riesgos no resueltos",
      "heading.timeframe": "Tiempo estimado",
      "heading.details": "Detalles",
      "heading.resolvedHazards": "Riesgos resueltos",
      "heading.dateResolved": "Fecha de solución",
      "heading.safetyObservations": "Observaciones de seguridad",
      "heading.answers": "Respuestas",
      "heading.attendees": "Asistentes",
      "heading.photos": "Fotos",
      "label.location": "Ubicación",
      "label.conductedBy": "Dirigido por",
      "label.conductedOn": "Dirigido en",
      "label.topic": "Tema",
      "label.status": "Estado",
      "label.hazardLogged": "Riesgos registrados",
      "label.observationLogged": "Observaciones registradas",
      "label.comment": "Comentario",
      "button.back": "Atrás",
      "button.export": "Exportar",
      "button.archive": "Archivar Reunión"
    },
    "meetings": {
      "add-cocreator": {
        "title": "Dirigido por",
        "button.add": "Agregar persona",
        "modal-title": "Seleccionar persona"
      },
      "conduct": "Dirigir reunión",
      "addAttendees": "Agregar asistentes a la reunión",
      "index": {
        "title": "Reuniones",
        "button.export": "Exportar",
        "button.open": "Abrir",
        "button.closed": "Cerrado",
        "button.all": "Todo",
        "button.person": "Persona",
        "button.topic": "Tema",
        "button.filter": "Filtrar por",
        "heading.meetingDetails": "Detalles de la reunión",
        "heading.dateStarted": "Fecha de inicio",
        "heading.dateClosed": "Fecha de cierre",
        "message.noOpenMeetings": "No hay reuniones abiertas",
        "message.noClosedMeetings": "No hay reuniones cerradas",
        "text.conductedBy": "Dirigido por",
        "text.topic": "Tema",
        "text.count": "Contar"
      },
      "create": {
        "error.missing-template": "No se pudo encontrar la plantilla, intente nuevamente o comuníquese con soporte.",
        "title": "Detalles de las reuniones",
        "label.location": "Seleccione Ubicación",
        "label.topic": "Seleccione el tema de la reunión",
        "button.finish": "Finalizar y agregar asistentes",
        "button.archive": "Archivar Reunión",
        "topic": {
          "heading": "Tema {{number}} de {{total}}",
          "message.optional": "Opcional",
          "message.actions": "Acciones",
          "placeholder.comment": "Introducir comentario",
          "placeholder.response": "Ingrese respuesta",
          "button.save": "GUARDAR",
          "message.obs_logged": "Observaciones registradas",
          "message.haz_raised": "Riesgo detectado",
          "message.comment_added": "Comentario agregado"
        },
        "message.saved": "Guardado!",
        "message.draftSaved": "¡Borrador guardado!",
        "message.answerAllTopics": "Por favor responda todos los temas.",
        "message.missLocation": "Por favor seleccione una ubicación",
        "message.success": "Reunión guardada con éxito.",
        "message.pleaseStart": "Por favor inicie una nueva reunión para cambiar de tema.",
        "add-attendees": {
          "button.addAttendee": "Añadir Asistente",
          "button.finish": "Finalizar",
          "heading.photos": "Añadir fotos",
          "heading.attendees": "Asistentes agregados",
          "heading.select": "Seleccionar asistentes",
          "message.uploadSignatures": "Agregue una foto de la hoja de firmas o de los asistentes si no pueden obtener firmas digitales.",
          "message.missingSignatures": "Agregue todas las firmas o fotos de la hoja de firmas antes de completar la reunión.",
          "message.missingPhotoAndAttendees": "Agregue asistentes y fotos antes de completar la reunión.",
          "message.missingAttendees": "Agregue asistentes antes de completar la reunión.",
          "message.missingPhotos": "Agrega fotos antes de completar la reunión."
        }
      },
      "templates-library": {
        "message.noRecentlyUsedTemplate": "Aún no tiene plantillas utilizadas recientemente, complete su primera reunión."
      }
    },
    "hazards": {
      "add-cocreator": {
        "title": "Grabado por",
        "button.add": "Agregar persona",
        "modal-title": "Seleccionar persona"
      },
      "index": {
        "title": "Peligros",
        "button.export": "Exportar",
        "button.unresolved": "Sin resolver",
        "button.resolved": "Resuelto",
        "button.all": "Todo",
        "button.team": "Equipo",
        "button.location": "Ubicación",
        "button.filter": "Filtrar por",
        "heading.hazardDetails": "Detalles del riesgo",
        "heading.timeframe": "Tiempo estimado",
        "heading.dateResolved": "Fecha de solución",
        "message.noResolvedHazards": "No hay riesgos resueltos para este período de tiempo",
        "message.noUnresolvedHazards": "No hay riesgos no resueltos para este período de tiempo",
        "text.location": "Ubicación",
        "text.team": "Equipo",
        "text.count": "Contar"
      }
    },
    "hazard": {
      "add": "Añadir riesgo",
      "index": {
        "heading.details": "Detalles del riesgo",
        "heading.equipment": "Equipo relacionado",
        "heading.comments": "Comentarios",
        "heading.location": "Ubicación",
        "heading.risk": "Nivel de riesgo",
        "button.back": "Atrás",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.resolve": "Resolver riesgo",
        "button.archive": "Archivar riesgo",
        "button.markResolved": "Marcar como resuelto",
        "text.hazard": "Peligro",
        "text.unresolved": "Sin resolver",
        "text.resolved": "Resuelto",
        "label.task": "Actividad que se realiza",
        "label.root": "Causa Raíz",
        "label.riskType": "Tipo de riesgo",
        "label.riskProbability": "Probabilidad de riesgo",
        "label.riskConsequence": "Consecuencia de riesgo",
        "label.riskLevel": "Nivel de riesgo calculado",
        "label.recommendedAction": "Acción recomendada",
        "label.description": "Descripción",
        "label.logged": "Grabado por",
        "label.responsibleParty": "Equipo responsable",
        "label.location": "Ubicación",
        "label.sublocation": "Sub-ubicación",
        "label.additionParties": "Equipo adicional notificada",
        "label.name": "Nombre",
        "label.number": "Número",
        "label.team": "Equipo",
        "label.Email": "Correo electrónico",
        "message.resolved": "Peligro resuelto con éxito",
        "message.not_resolvable": "El peligro no se puede resolver hasta que se adjunte un comentario, una imagen o una acción correctiva",
        "message.mark_resolvable": "El peligro está 'marcado para resolver'",
        "message.not_archivable": "El peligro no puede ser archivado, necesita un comentario"
      },
      "edit": {
        "title.details": "Detalles",
        "title.images": "Agregar imágenes",
        "title.team": "Equipo responsable",
        "title.location": "Ubicación - Arrastrar el icono del marcador a la ubicación deseada",
        "title.riskAssessment": "Evaluación de riesgos y peligro",
        "title.rootCause": "Análisis de causa raíz (opcional)",
        "title.description": "Descripción",
        "title.correctiveAction": "acción correctiva",
        "title.correctiveActions": "Descripción y acción correctiva",
        "title.riskLevel": "Nivel de riesgo calculado",
        "label.recommendedActions": "Acción recomendada",
        "label.team": "Seleccionar Equipo",
        "label.location": "Seleccionar Ubicación",
        "label.riskType": "Seleccionar Tipo de riesgo",
        "label.riskProbability": "Seleccionar Probabilidad de riesgo",
        "label.riskConsequence": "Seleccionar Consecuencia de riesgo",
        "label.task": "Seleccionar Actividad que se realiza",
        "label.rootCause": "Seleccionar Causa raíz",
        "button.update": "Actualizar riesgo",
        "button.record": "Grabar riesgo",
        "button.resolve": "Resolver riesgo",
        "button.cancel": "Cancelar",
        "validation.notBlank": "No debe quedar en blanco.",
        "message.success": "Riesgo guardado con éxito.",
        "message.resolved": "Riesgo resuelto con éxito.",
        "message.mandatory": "Por favor complete los campos obligatorios"
      }
    },
    "observation": {
      "add": "Observación de registro",
      "index": {
        "heading.details": "Detalles de la observación",
        "button.back": "Atrás",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.archive": "Archivar Observación",
        "label.description": "Descrición",
        "label.loggedBy": "Grabado por",
        "label.location": "Seleccionar Ubicación",
        "label.team": "Equipo",
        "text.safe": "Positivo",
        "text.risk": "Negativo",
        "text.observation": "Observación",
        "text.on": "on"
      },
      "nature": "Tipo"
    },
    "observations": {
      "add-cocreator": {
        "title": "Grabado por",
        "button.add": "Agregar persona",
        "modal-title": "Seleccionar persona"
      },
      "create": {
        "title": "Detalles de la observación",
        "label.safe": "Positivo",
        "label.risk": "Negativo",
        "label.location": "Seleccionar Ubicación",
        "label.team": "Seleccionar Equipo (opcional)",
        "label.resolved": "Resuelto",
        "placeholder.description": "Descripción",
        "button.record": "Grabar observación",
        "button.update": "Actualizar observación",
        "button.cancel": "Cancelar",
        "message.success": "Observación guardada con éxito.",
        "message.validation": "Por favor complete los campos obligatorios",
        "validation.locationNotBlank": "Seleccione una ubicación.",
        "validation.descriptionBlank": "No puede quedar descripctión en blanco.",
        "validation.descriptionShort": "La descripción debe tener al menos dos palabras."
      },
      "index": {
        "title": "Observaciones",
        "button.safe": "Positivo",
        "button.risk": "Negativo",
        "button.all": "Todo",
        "button.team": "Equipo",
        "button.location": "Ubicación",
        "button.export": "Exportar",
        "button.filter": "Filtrar por",
        "heading.observationDetails": "Detalles de la observación",
        "heading.dateCreated": "Fecha de creación",
        "heading.dateResolved": "Fecha de solución",
        "heading.backToWork": "Regreso al trabajo ETA",
        "message.noSafeObservations": "No hay observaciones seguras",
        "message.noRiskObservations": "No hay observaciones de riesgo",
        "text.count": "Contar",
        "text.location": "Ubicación",
        "text.team": "Equipo"
      }
    },
    "incident.index": {
      "title": "Informe de incidente",
      "button.back": "Atrás",
      "button.edit": "Editar",
      "button.export": "Exportar",
      "button.archive": "Archivar informe de incidente",
      "heading.incident": "Detalles del incidente",
      "heading.rootAnalysis": "Análisis de la causa raíz",
      "heading.hazards": "Riesgos de raíz",
      "heading.comments": "Comentarios",
      "label.type": "Tipo",
      "label.location": "Ubicación",
      "label.dateTime": "Fecha/Tiempo de incidente",
      "label.task": "Tarea completada",
      "label.root": "Causa Raíz",
      "label.correctiveAction": "Descripción de la acción correctiva",
      "label.resolved": "Resuelto",
      "label.logged": "Dirigido por"
    },
    "incidents": {
      "add-cocreator": {
        "title": "Dirigido por",
        "button.add": "Agregar persona",
        "modal-title": "Seleccionar persona"
      },
      "types.INJ": "Lesión o enfermedad",
      "types.NMS": "Casi Accidente",
      "types.DMG": "Daño a la propiedad",
      "types.TFT": "Robo",
      "types.EQF": "Falla en el equipo",
      "add": "Registrar incidente",
      "index": {
        "title": "Informe de incidente",
        "button.open": "Abrir",
        "button.closed": "Cerrado",
        "button.all": "Todo",
        "button.type": "Tipo",
        "button.location": "Ubicación",
        "button.filter": "Filtrar por",
        "heading.incidentReport": "Detalles del informe de incidente",
        "heading.dateCreated": "Fecha de creación",
        "heading.dateResolved": "Fecha de solución",
        "text.location": "Ubicación",
        "text.type": "Tipo",
        "text.count": "Contar",
        "message.noOpenIncidents": "No hay informes de incidentes abiertos",
        "message.noClosedIncidents": "No hay informes de incidentes cerrados"
      },
      "create": {
        "heading.incidentReport": "Detalles del informe de incidente",
        "title.description": "Descripción",
        "title.rootCauseAnalysis": "Análisis de causa raíz",
        "title.rootCauseHazard": "Riesgos de raíz",
        "title.comments": "Comentarios",
        "title.injuries": "Lesiones",
        "title.nearMiss": "Near Miss",
        "title.propertyDamage": "Daño a la propiedad",
        "label.incidentType": "Tipo de incidente",
        "label.location": "Seleccionar Ubicación",
        "label.incidentDate": "Fecha de incidente",
        "label.incidentTime": "Tiempo de incidente",
        "label.task": "Seleccione la actividad que se realiza",
        "label.resolved": "Resuelto",
        "label.rootCause": "Seleccionar causa raíz",
        "label.potentialRisk": "Seleccionar tipo de riesgo",
        "label.incidentType.options": "Tipos de incidentes (seleccione todas las que correspondan)",
        "label.equipmentFailure": "Falla en el equipo",
        "label.equipmentFailure.description": "Descripcion de falla",
        "label.equipmentTheft": "Robo de equipo",
        "label.equipmentTheft.description": " Descripción de robo",
        "label.injuries": "Descripción de la lesión o enfermedad",
        "label.nearMiss": "Near Miss Descripción",
        "label.propertyDamage.owner": "Propietario",
        "label.propertyDamage.description": "Descripción del Daño",
        "label.propertyDamage.cause": "Causa de Daño",
        "placeholder.description": "Descripción del incidente",
        "placeholder.correctiveAction": "Descripción de la acción correctiva",
        "placeholder.equipmentFailure.description": "¿Que pasó?",
        "placeholder.equipmentTheft.description": "¿Que pasó?",
        "placeholder.injuries.description": "¿Que pasó?",
        "placeholder.propertyDamage.description": "¿Qué fue el daño?",
        "placeholder.propertyDamage.cause": "¿Cuál fue la causa?",
        "button.addHazard": "Añadir riesgo de raíz",
        "button.save": "GUARDAR",
        "button.log": "Registrar incidente",
        "button.update": "Actualizar incidente",
        "button.savedraft": "Guardar como borrador",
        "button.archive": "Archivar incidente",
        "button.cancel": "Cancelar",
        "message.saved": "Guardado!",
        "message.draftSaved": "¡Borrador guardado!",
        "message.success": "Informe de Incidente Guardado con Exito.",
        "message.descriptionAdded": "Descripción Agregada",
        "message.correctiveDescription": "Acción correctiva Descripción agregada",
        "message.error": "Se ha producido un error",
        "injury": {
          "title": "Adjuntar lesión o enfermedad",
          "label.type": "Tipo de lesión o enfermedad",
          "label.bodyPart": "Parte del cuerpo",
          "label.treatment": "Tipo de tratamiento",
          "placeholder.treatment": "Descripción del tratamiento",
          "message.treatment": "Nota: Supervisor para completar los detalles del tratamiento en el portal del escritorio",
          "button.done": "Hecho"
        },
        "witness": {
          "title": "Testigos",
          "button.add": "Agregar testigos",
          "modal": {
            "title": "Adjuntar testigo",
            "placeholder.statement": "Introducir declaración...",
            "button.done": "Hecho"
          }
        },
        "add-equipment": {
          "title": "Equipment involucrada",
          "title_singular": "Equipment involucrada",
          "button.add": "Agregar Equipment",
          "label.equipment": "Equipment",
          "modal": {
            "label.or": "OR",
            "label.results": "Project's Equipment",
            "button.add": "Agregar nueva Equipment"
          }
        }
      }
    },
    "equipment": {
      "index": {
        "button.back": "Atrás",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.cancel": "Cancelar",
        "button.delete": "Eliminar",
        "button.yes": "Sí",
        "button.no": "No",
        "button.archive": "Archivar Equipo",
        "button.createInspection": "Crear Nueva Inspección",
        "button.createHazard": "Crear Nuevo Peligro",
        "heading.owner": "Propietario",
        "heading.inspections": "Inspecciones",
        "heading.inspection": "Detalles de inspección",
        "heading.dateStarted": "Fecha de inicio",
        "heading.dateClosed": "Fecha de cierre",
        "heading.maintenance": "Mantenimiento",
        "heading.comments": "Comentarios",
        "title": "Equipo",
        "label.team": "Equipo",
        "label.location": "Ubicación",
        "label.type": "Tipo",
        "label.make": "Marca",
        "label.model": "Modelo",
        "text.hazards": "Riesgos",
        "message.noInspections": "No hay inspecciones",
        "message.noHazards": "No hay riesgos",
        "text.hazard.unresolvedDetails": "Detalles de riesgos no resueltos",
        "text.hazard.resolvedDetails": "Detalles de riesgos resueltos",
        "text.details": "Detalles",
        "label.serial": "Número de serie",
        "label.lastTestDate": "Última fecha de inspección",
        "label.testFreq": "Frecuencia de inspección",
        "label.nextTestDate": "Proxima fecha de inspección"
      }
    },
    "equipments": {
      "manage": "Registrar Equipo",
      "create": {
        "title.project": "Proyecto",
        "title.owner": "Propietario del equipo",
        "title.location": "Ubicación",
        "title.description": "Descripción",
        "title.maintenance": "Mantenimiento",
        "label.project": "Proyecto",
        "label.team": "Equipo",
        "label.location": "Ubicación",
        "label.serial": "Número de serie",
        "label.eq_type": "Tipo de equipo",
        "label.make": "Marca",
        "label.model": "Modelo",
        "label.last_date_date": "Última fecha de inspección",
        "label.test_frequency": "Frecuencia de inspección",
        "label.test_date": "Próxima fecha de inspección",
        "message.location": "Arrastrar el icono de marcador a la ubicación deseada",
        "button.update": "Actualizar equipo",
        "button.register": "Registrar equipo",
        "button.cancel": "Cancelar",
        "message.success": "Equipo guardado con éxito.",
        "message.mandatory": "Por favor complete los campos obligatorios"
      },
      "index": {
        "title": "Registro de equipo",
        "button.all": "Todo",
        "button.team": "Equipo",
        "button.type": "Tipo",
        "button.export": "Exportar",
        "button.filter": "Filtrar por",
        "heading.itemDetails": "Detalles del artículo",
        "heading.nextService": "Siguiente servicio",
        "message.noEquipment": "No hay equipo en el registro",
        "placeholder.search": "Buscar..."
      }
    },
    "certificate": {
      "index": (_index = {
        "button.back": "Atrás",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.cancel": "Cancelar",
        "button.delete": "Eliminar",
        "button.yes": "Sí",
        "button.no": "No",
        "button.archive": "Archivar Certificado",
        "title": "Certificado",
        "text.details": "Certificado Detalles",
        "title.upload": "Cargar foto(s)"
      }, _defineProperty(_index, "text.details", "Detalles"), _defineProperty(_index, "label.name", "Nombre o Tipo de Certificado"), _defineProperty(_index, "label.issuing_body", "Organismo emisor (opcional)"), _defineProperty(_index, "label.additional_notes", "Notas adicionales"), _defineProperty(_index, "label.date_issued", "Fecha de emisión"), _defineProperty(_index, "label.date_expires", "Fecha de expiración"), _index)
    },
    "certificates": {
      "manage_create": "Registrar Certificado",
      "manage_edit": "Editar Certificado",
      "create": {
        "title.project": "Proyecto",
        "title.description": "Descripción",
        "title.details": "Certificado Detalles",
        "title.upload": "Cargar foto(s)",
        "button.save": "Guardar Certificado",
        "button.update": "Actualizar Certificado",
        "button.addnew": "Registrar",
        "button.cancel": "Cancelar",
        "message.success": "Certificado guardado con éxito.",
        "message.mandatory": "Por favor complete los campos obligatorios",
        "label.name": "Nombre o Tipo de Certificado*",
        "label.issuing_body": "Organismo emisor",
        "label.additional_notes": "Notas adicionales",
        "label.date_issued": "Fecha de emisión",
        "label.date_expires": "Fecha de expiración",
        "placeholder": {
          "label.name": "Ingrese el Nombre o Tipo de Certificado",
          "label.issuing_body": "OSHA, CDC, etc.",
          "label.additional_notes": "Ingrese notas adicionales"
        }
      },
      "index": {
        "title": "Certificados",
        "button.all": "Todo",
        "button.issued": "Emitido",
        "button.expiry": "Expiración",
        "button.filter": "Filtrar por",
        "label.issued": "Emisión",
        "label.expiry": "Expiración",
        "message.noCertificate": "No hay certificado en el registro",
        "placeholder.search": "Buscar..."
      }
    },
    "modals": {
      "dropdown": {
        "text.pleaseSelect": "",
        "text.searchOrAdd": "Buscar o agregar nuevo...",
        "text.search": "Buscar...",
        "label.addNew": "Agregar Nuevo",
        "errors.fail": "El controlador desplegable no pudo guardar el registro",
        "errors.team": "Error al guardar el equipo",
        "errors.location": "Error al guardar la ubicación",
        "errors.equipmentType": "Error al guardar el tipo de equipo",
        "errors.make": "Error al guardar Marca",
        "errors.model": "No se pudo guardar el modelo"
      },
      "dropdown-multiple": {
        "text.pleaseSelect": " ",
        "text.search": "Buscar...",
        "text.removed_item": "Elemento eliminado",
        "text.added_item": "Elemento agregado",
        "title.add-new-item": "O agregar nueva",
        "text.no-results": "No hay resultados"
      },
      "archive-item": {
        "button.yes": "Sí, archivar",
        "button.no": "No, Cancelar",
        "message.archiveConfirm": "¿Está seguro de que desea archivar esto?",
        "text.archived": "Archivado",
        "text.failedArchive": "Error al archivar"
      },
      "exit-item": {
        "title": "¿Desea guardar y volver más tarde?",
        "button.yes_save": "Sí, guardar",
        "button.cancel": "Cancelar",
        "button.save_later": "Sí, guardar para más tarde",
        "button.no": "No, descartar elemento",
        "text.saved": "Guardado",
        "text.archived": "Archivado",
        "text.failedArchive": "Error al archivar",
        "text.failedSave": "Error al guardar"
      },
      "dropdown-site": {
        "title": "Todos los proyectos"
      },
      "exit-new-item-mode": {
        "title": "¿Está seguro de que desea abandonar este elemento de seguridad?",
        "button.yes": "Sí"
      },
      "select-site": {
        "title": "Todos los proyectos",
        "subtitle": "Por favor Seleccione el Proyecto",
        "button.select": "Seleccionar",
        "placeholder.search": "Buscar..."
      },
      "select-project": {
        "title": "Proyecto"
      },
      "hazard-create": {
        "title": "Nuevo riesgo detectado",
        "message.location": "Ubicación - Arrastrar el icono del marcador a la ubicación deseada",
        "message.description": "Descripción y acción correctiva",
        "label.team": "Seleccionar Equipo",
        "label.location": "Seleccionar Ubicación",
        "label.riskType": "seleccionar Tipo de riesgo",
        "label.riskProbability": "seleccionar Probabilidad de riesgo",
        "label.riskConsequence": "seleccionar Consecuencia de riesgo",
        "button.save": "Grabar el Peligro",
        "button.resolve": "Resolver el Peligro"
      },
      "observation-create": {
        "title": "Observación de registro",
        "label.safe": "Positivo",
        "label.risk": "Negativo",
        "label.location": "Seleccionar Ubicación",
        "label.team": "Seleccionar Equipo (opcional)",
        "placeholder.description": "Descripción",
        "button.save": "Guardar"
      },
      "announcement-modal": {
        "title": "Anuncio",
        "button.ack": "Reconocer"
      },
      "location-permission": {
        "title": "Servicios de ubicación deshabilitados",
        "subtitle": "Habilite los servicios de ubicación para poder identificar dónde se llevan a cabo sus acciones de seguridad.",
        "footer": "No rastreamos personas ni compartimos sus datos. Solo utilizamos datos de ubicación para artículos de seguridad.",
        "contact_support": "Si el problema continúa, lea este <a href='http://help.safesitehq.com/en/articles/4531342-location-services' target='_blank'>artículo del Centro de ayuda</a> o <a href='mailto:success@safesitehq.com'>póngase en contacto con el servicio de asistencia</a>.",
        "error.title": "Habilite el servicio de ubicación",
        "error.message": "Safesite no pudo acceder a su ubicación"
      },
      "feature-disabled": {
        "title": "Función desactivada sin conexión"
      }
    },
    "userprofile": {
      "title": "Perfil",
      "label.photo.add": "Añadir Foto",
      "label.photo.edit": "editar",
      "label.button.add": "añadir",
      "label.button.edit": "editar",
      "label.button.editName": "editar nombre",
      "label.button.update": " actualizar",
      "label.button.cancel": "cancelar ",
      "label.firstName": "Nombre",
      "placeholder.firstName": "Ingrese el nombre (requerido)",
      "label.lastName": "Apellido",
      "placeholder.lastName": "Ingrese el apellido (requerido)",
      "label.email": "Correo electrónico",
      "placeholder.phone": "Ingresa número telefónico",
      "label.phone": "número telefónico",
      "label.organization": "Organización",
      "label.organizations": "Organizaciónes",
      "label.appVersion": "Versión de la aplicación",
      "label.defaultLanguage": "Idioma predeterminado",
      "label.updateApp": "Actualización de la aplicación"
    },
    "contact-us": {
      "title": "Entrar en contacto",
      "message.feedback": "Sus comentarios son importantes para nosotros y nos comunicaremos con usted tan pronto como nos sea posible.",
      "message.successfully.sent": "Mensaje enviado con exito.",
      "message.error": "Se ha producido un error, por favor intente de nuevo",
      "placeholder.message": "Mensaje",
      "button.send": "Enviar"
    },
    "errors": {
      "network": "¡Ha ocurrido un error de red! Por favor intente de nuevo o contacte a soporte técnico."
    },
    "components": {
      "image-upload": {
        "take-picture": "Tomar la foto",
        "from-gallery": "Elegir de la galería"
      },
      "item-comments": {
        "message.noComment": "No hay comentarios grabados",
        "button.add": "Agregar comentario",
        "placeholder.comment": "Introducir un comentario..."
      },
      "modal-dialog": {
        "button.cancel": "Cancelar"
      },
      "upload": {
        "text.uploading": "Cargando..."
      },
      "custom-dropdown-button": {
        "text.select": "Seleccionar"
      },
      "date-field": {
        "text.set": "Ajustar"
      },
      "time-field": {
        "text.set": "Ajustar"
      },
      "loading-btn": {
        "disabled.offline.message": "desactivada sin conexión"
      },
      "add-injury": {
        "label": "Añadir lesión o enfermedad",
        "body-parts": {
          "heading": "Partes del cuerpo guardadas",
          "select-modal": {
            "title.select": "Seleccionar partes del cuerpo"
          }
        }
      },
      "add-person": {
        "title": "Personas involucradas",
        "title_singular": "Persona involucrada",
        "button.add": "Agregar persona",
        "placeholder.search": "Buscar...",
        "label.person": "Persona",
        "label.add_existing": "Agregar persona existente",
        "label.create_new": "Crear nueva persona",
        "modal": {
          "label.or": "O",
          "label.results": "Resultados",
          "placeholder.jobTitle": "Título del trabajo",
          "placeholder.firstName": "Nombre",
          "placeholder.lastName": "Apellido",
          "placeholder.email": "Correo electrónico (opcional)",
          "placeholder.phone": "Número de contacto",
          "placeholder.company": "Nombre de la compañía",
          "button.add": "Agregar nueva persona"
        }
      }
    },
    "meeting-item": {
      "text.incomplete": "Incompleto",
      "text.pendingHazards": "Riesgos pendientes",
      "text.closedHazards": "Riesgos cerrados"
    },
    "incident-item": {
      "text.incomplete": "Incompleto",
      "text.pendingHazards": "Riesgos pendientes",
      "text.closedHazards": "Riesgos cerrados"
    },
    "inspection-item": {
      "text.incomplete": "Incompleto",
      "text.pendingHazards": "Riesgos pendientes",
      "text.closedHazards": "Riesgos cerrados"
    },
    "open-hazard-item": {
      "title": "Resolver riesgo"
    },
    "logout-confirm": {
      "title": "¿Está seguro de que desea cerrar sesión?",
      "button.yes": "Sí",
      "button.forceLogout": "Sí, forzar el cierre de sesión",
      "subtitle.message.offline": "Actualmente está desconectado, tenga en cuenta que si cierra la sesión mientras está desconectado, podría perder datos que aún no se han sincronizado.",
      "subtitle.message.unableToLogout": "No podemos conectarnos a nuestros servidores en este momento. Tenga en cuenta que si cierra sesión sin conexión, podría perder datos que aún no se han sincronizado.",
      "message.error": "Se ha producido un error, por favor intente de nuevo o contacte a soporte técnico."
    },
    "custom-dropdown": {
      "button.cancel": "Cancelar",
      "button.done": "Hecho"
    },
    "signup-steps": {
      "title": "Regístrate",
      "step1": {
        "title": "Empecemos",
        "info": "Por favor ingrese su dirección de correo electrónico a continuación",
        "placeholder.email": "Correo electrónico",
        "button.continue": "Continuar",
        "button.cancel": "Cancelar"
      },
      "step2": {
        "title": "Perfil",
        // step2.info is not being used.
        "info": "Necesitamos enviarle un correo electrónico para confirmar su identidad. Por favor, ingréselo a continuación",
        "placeholder.email": "Correo electrónico",
        "placeholder.password": "Contraseña",
        "placeholder.password.confirm": "Confirmar contraseña",
        "placeholder.firstName": "Nombre",
        "placeholder.lastName": "Apellido",
        "placeholder.phoneNumber": "Número de contacto",
        "button.continue": "Continuar",
        "button.cancel": "Cancelar"
      },
      "step3": {
        "title": "Empresa",
        "not-found-subtext1": "No pudimos encontrar ninguna organización asignada a este correo electrónico.",
        "not-found-subtext2": "Creemos tu propia organización, ingresa el nombre a continuación",
        "placeholder.company": "Nombre de la compañía",
        "found-subtext1": "Buenas noticias, ya tenemos su empresa en nuestro sistema",
        "found-subtext2": "Avancemos",
        "button.continue": "Continuar",
        "button.cancel": "Cancelar"
      },
      "step4": {
        "title": "Tus proyectos",
        "subtext1": "¿Falta un proyecto? Pregúntale a tu gerente o ponte en contacto con el servicio de atención al cliente una",
        "projects-subtext1": "Sus proyectos actuales",
        "button.continue": "Continuar",
        "button.cancel": "Cerrar"
      },
      "step5": {
        "title": "Consultar su correo electrónico para verificar",
        "subtext1": "Para confirmar su dirección de correo electrónico, abra su correo electrónico a través de su dispositivo móvil y toque el botón en su correo electrónico",
        "button.resend": "reenviar el mensaje",
        "button.cancel": "Cerrar"
      }
    },
    "project-invite": {
      "title": "Ahora comencemos",
      "org-projects-text-part1": "ver el",
      "org-projects-text-part2": "proyectos debajo de usted son parte de",
      "button.continue": "Continuar",
      "button.complete": "Completar",
      "button.cancel": "Cerrar",
      "placeholder.email": "Correo electrónico",
      "placeholder.password": "Crear contraseña",
      "placeholder.password.confirm": "Confirmar contraseña",
      "placeholder.firstName": "Nombre",
      "placeholder.lastName": "Apellido",
      "placeholder.company": "Nombre de la compañía",
      "placeholder.phoneNumber": "Número de contacto (opcional)"
    },
    "validate-email": {
      "title": "Verifica tu correo electrónico",
      "text-part1": "Enviamos un correo electrónico de verificación a",
      "text-part2": "¿No ha recibido su correo electrónico? Intente enviar de nuevo",
      "button.resend": "Reenviar el mensaje",
      "button.cancel": "Cerrar"
    },
    "verify-email": {
      "title": "Verificar correo electrónico",
      "text-part1": "Verifique su dirección de correo electrónico haciendo clic en el enlace de activación que le enviamos",
      "text-part2": "Validando......",
      "button.resend": "Reenviar el mensaje",
      "button.cancel": "Cerrar"
    },
    "tour": {
      "title": "¡Bienvenido a Safesite!",
      "body": "Mira el video a continuación que te guía por la oferta completa de Safesite",
      "button.done": "Empezar",
      "video.src": "https://player.vimeo.com/video/355896403?autoplay=0&title=0",
      "message.offline": "El video del recorrido no está disponible sin conexión"
    },
    "default-form-messages": {
      "enter.valid.email": "Por favor, introduce una dirección de correo electrónico válida",
      "enter.valid.mobile.number": "Por favor, introduzca un número de teléfono móvil válido",
      "enter.valid.email.or.mobile.number": "Ingrese una dirección de correo electrónico o un número de teléfono móvil válidos",
      "enter.valid.first_last_names": "Por favor ingrese nombres y apellidos",
      "enter.valid.email_to_invite_project": "Ingrese el email para invitar al proyecto",
      "enter.valid.fill_in_all_fields": "Por favor rellena todos los campos",
      "results": "Resultados"
    },
    "offline-mode": {
      "message.offline": "No tienes conexión, pero puedes seguir usando Safesite. Sincronizaremos sus datos una vez que vuelva a estar en línea.",
      "message.syncing": "Sincronizando..."
    },
    "release-notes": {
      "title": "Safesite ahora funciona sin conexión",
      "message": "Safesite offline está aquí! Si se desconecta, almacenaremos los cambios en su dispositivo y lo sincronizaremos una vez que vuelva a estar en línea. Lea más en nuestro <a href='https://help.safesitehq.com/en/articles/4340586-using-the-app-while-offline-coming-soon' target='_blank'>HelpCenter</a>."
    },
    "add-signature-btn": {
      "text": "Agregar firma"
    },
    "add-signature-modal": {
      "title": "Agregar firma",
      "instruction": "Firmar por encima de la línea de puntos",
      "clear": "borrar",
      "save": "Hecho"
    }
  };
  _exports.default = _default;
});