define("safesite/models/equipment", ["exports", "ember-data", "safesite/models/abstract", "safesite/mixins/image-attachment", "moment"], function (_exports, _emberData, _abstract, _imageAttachment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var Equipment = _abstract.default.extend(_imageAttachment.default, {
    responsible_person: _emberData.default.belongsTo('user', {
      async: true
    }),
    created_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    created_at: _emberData.default.attr('number', {
      defaultValue: function defaultValue() {
        return _moment.default.utc().unix();
      }
    }),
    asset_number: attr({
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    modified_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    type: _emberData.default.belongsTo('equipment-type', {
      async: true
    }),
    sub_type: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    eq_location: _emberData.default.belongsTo('location', {
      async: true
    }),
    make: _emberData.default.belongsTo('make', {
      async: true
    }),
    eq_model: _emberData.default.belongsTo('model', {
      async: true
    }),
    last_test_date: attr(),
    next_test_date: attr(),
    test_frequency: _emberData.default.belongsTo('test-frequency', {
      async: true
    }),
    site: _emberData.default.belongsTo('site', {
      async: true
    }),
    team: _emberData.default.belongsTo('team', {
      async: true
    }),
    supervisor: _emberData.default.belongsTo('user', {
      async: false
    }),
    images: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    active_inspections: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    hazards: _emberData.default.hasMany('hazards', {
      async: true
    }),
    unresolved_hazards: Ember.computed('hazards', 'hazards.@each.status', function () {
      return this.hazards.filter(function (f) {
        return f.get('status') === true;
      });
    }),
    resolved_hazards: Ember.computed('hazards', 'hazards.@each.status', function () {
      return this.hazards.filter(function (f) {
        return f.get('status') === false;
      });
    }),
    comments: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    longitude: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    latitude: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    is_deleted: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    })
  });

  var _default = Equipment;
  _exports.default = _default;
});