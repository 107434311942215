define("safesite/serializers/organization", ["exports", "ember-data", "ember-pouch"], function (_exports, _emberData, _emberPouch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberPouch.Serializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      organization_user_profiles: {
        serialize: 'id',
        deserialize: 'id'
      },
      model: {
        embedded: 'always'
      },
      injury_type: {
        embedded: 'always'
      },
      incident_task: {
        embedded: 'always'
      },
      risk_consequence: {
        embedded: 'always'
      },
      risk_priority: {
        embedded: 'always'
      },
      risk_probability: {
        embedded: 'always'
      },
      risk_type: {
        embedded: 'always'
      },
      root_cause: {
        embedded: 'always'
      },
      body_part: {
        embedded: 'always'
      },
      test_frequency: {
        embedded: 'always'
      },
      make: {
        embedded: 'always'
      },
      equipment_type: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});