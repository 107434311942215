define("safesite/initializers/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('controller', 'device', 'service:device');
    application.inject('component:safesite-textarea', 'device', 'service:device');
    application.inject('component:input-text', 'device', 'service:device');
    application.inject('component:input-text-with-debounce', 'device', 'service:device');
    application.inject('component:nav-menu', 'device', 'service:device');
    application.inject('component:permissions-location', 'device', 'service:device');
    application.inject('component:question-item', 'device', 'service:device');
  }

  var _default = {
    name: 'device',
    initialize: initialize
  };
  _exports.default = _default;
});