define("safesite/templates/certificates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7/TTPKZz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"default-page\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"default-header\"],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\"pull-left default-back\"],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"goBack\"]],[8],[7,\"i\",true],[10,\"class\",\"icon-chevron-left\"],[8],[9],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"pull-right default-header-spacer\"],[8],[9],[0,\"\\n        \"],[7,\"h3\",true],[10,\"class\",\"default-header-title\"],[8],[1,[28,\"t\",[\"certificates.index.title\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"default-details\"],[8],[0,\"\\n        \"],[1,[22,\"loading\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/certificates/loading.hbs"
    }
  });

  _exports.default = _default;
});