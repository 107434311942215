define("safesite/routes/index", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var IndexRoute = _restricted.default.extend({
    eventBusService: Ember.inject.service('event-bus'),
    routed: false,
    listen: Ember.on('init', function () {
      this.eventBusService.on('deeplink', this, 'routedFunct');
    }),
    // remember to remove what you bind upon component destruction
    cleanup: Ember.on('willDestroyElement', function () {
      this.eventBusService.off('deeplink', this, 'routedFunct');
    }),
    routedFunct: function routedFunct() {
      this.set('routed', true);
    },
    redirect: function redirect() {
      if (this.routed === true) {
        return false;
      } else {
        if (this.eventBusService.get('routed') == true) {
          return false;
        } else {
          this.transitionTo('sites/actions');
          return true;
        }
      }
    },
    sites: null,
    beforeModel: function beforeModel() {
      var path = document.location.pathname;

      if (path === '/' || path.match(/www\/index.html$/)) {
        this.controllerFor('auth').send('redirectToActions');
      }
    },
    model: function model() {
      return this.store.find('user', 'me');
    },
    actions: {
      addHazard: function addHazard() {
        this.controllerFor('modals/select-site').set('sites', this.sites);
        this.controllerFor('modals/select-site').set('linkTo', "hazards.create");
        this.send('openSelectSiteModal');
      },
      addObservation: function addObservation() {
        this.controllerFor('modals/select-site').set('sites', this.sites);
        this.controllerFor('modals/select-site').set('linkTo', "observations.create");
        this.send('openSelectSiteModal');
      },
      addIncident: function addIncident() {
        this.controllerFor('modals/select-site').set('sites', this.sites);
        this.controllerFor('modals/select-site').set('linkTo', "incidentreports.create");
        this.send('openSelectSiteModal');
      },
      completeInspection: function completeInspection() {
        this.controllerFor('modals/select-site').set('sites', this.sites);
        this.controllerFor('modals/select-site').set('linkTo', "inspections.create");
        this.send('openSelectSiteModal');
      },
      manageEquipment: function manageEquipment() {
        this.controllerFor('modals/select-site').set('sites', this.sites);
        this.controllerFor('modals/select-site').set('linkTo', "equipments");
        this.send('openSelectSiteModal');
      },
      conductMeeting: function conductMeeting() {
        this.controllerFor('modals/select-site').set('sites', this.sites);
        this.controllerFor('modals/select-site').set('linkTo', "meetings.create");
        this.send('openSelectSiteModal');
      },
      openSelectSiteModal: function openSelectSiteModal() {
        this.send('renderModal', {
          controllerPath: 'modals/select-site'
        });
      }
    }
  });

  var _default = IndexRoute;
  _exports.default = _default;
});