define("safesite/models/risk-consequence", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Riskconsequence = _abstract.default.extend({
    grade: _emberData.default.attr(),
    description: _emberData.default.attr()
  });

  var _default = Riskconsequence;
  _exports.default = _default;
});