define("safesite/serializers/inspection-template-online", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeQueryResponse: function normalizeQueryResponse(store, ModelClass, payload, id, requestName) {
      payload['inspection-template-online'] = payload.inspectionTemplateViewSet;
      return this._super(store, ModelClass, payload, id, requestName);
    },
    normalizeResponse: function normalizeResponse(store, ModelClass, payload, id, requestName) {
      payload['inspection-template-online'] = payload.inspectionTemplateViewSet;
      return this._super(store, ModelClass, payload, id, requestName);
    }
  });

  _exports.default = _default;
});