define("safesite/templates/components/safesite-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SwbFNF+c",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"safesite-dropdown\"],[10,\"class\",\"modal\"],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[10,\"aria-labelledby\",\"myModalLabel\"],[10,\"aria-hidden\",\"false\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n                    \"],[7,\"h4\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n                \"],[14,1,[[28,\"action\",[[23,0,[]],\"closeModal\"],null]]],[0,\"\\n            \"],[9],[0,\"\\n\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n                \"],[7,\"button\",false],[12,\"class\",[22,\"getClassNames\"]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"closeModalButtonText\"]]],null,{\"statements\":[[0,\"                        \"],[1,[22,\"closeModalButtonText\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                        \"],[1,[28,\"t\",[\"components.modal-dialog.button.cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[2,\" /.modal-content \"],[0,\"\\n    \"],[9],[2,\" /.modal-dialog \"],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/safesite-modal.hbs"
    }
  });

  _exports.default = _default;
});