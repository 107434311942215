define("safesite/templates/modals/feature-disabled-while-offline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o3yZkUvW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\",\"CancelButtonText\"],[\"closeModal\",[28,\"t\",[\"modals.feature-disabled.title\"],null],\"OK\"]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/feature-disabled-while-offline.hbs"
    }
  });

  _exports.default = _default;
});