define("safesite/components/input-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputNumber = Ember.Component.extend({
    classNames: ['form-group'],
    classNameBindings: ['hasError:has-error'],
    type: 'text',
    placeholder: '',
    pattern: '[0-9]*',
    disabled: false,
    errors: null,
    hasError: Ember.computed('errors', 'showErrors', function () {
      return this.showErrors && !Ember.isEmpty(this.errors);
    })
  });
  var _default = InputNumber;
  _exports.default = _default;
});