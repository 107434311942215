define("safesite/utilities/woof", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var woof = Ember.ArrayProxy.extend({
    content: Ember.A(),
    timeout: 2000,
    pushObject: function pushObject(object) {
      object.typeClass = 'alert-' + object.type;

      this._super(object);
    },
    danger: function danger(message) {
      this.pushObject({
        type: 'danger',
        message: message
      });
    },
    warning: function warning(message) {
      this.pushObject({
        type: 'warning',
        message: message
      });
    },
    info: function info(message) {
      this.pushObject({
        type: 'info',
        message: message
      });
    },
    success: function success(message) {
      this.pushObject({
        type: 'success',
        message: message
      });
    }
  });
  var _default = woof;
  _exports.default = _default;
});