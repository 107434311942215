define("safesite/models/make", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Make = _abstract.default.extend({
    name: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization', {
      async: true
    })
  });

  var _default = Make;
  _exports.default = _default;
});