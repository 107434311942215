define("safesite/models/test-frequency", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Testfrequency = _abstract.default.extend({
    days: _emberData.default.attr(),
    label: _emberData.default.attr()
  });

  var _default = Testfrequency;
  _exports.default = _default;
});