define("safesite/templates/components/comment-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HNOrDv0y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"alert\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[1,[24,[\"comment\",\"comment\"]],false],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"small\",true],[8],[0,\"\\n        \"],[1,[28,\"format-yyyymmddthhmm-date-time\",[[24,[\"comment\",\"created_date_time\"]]],null],false],[0,\" \"],[1,[24,[\"created_by_full_name\",\"content\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/comment-item.hbs"
    }
  });

  _exports.default = _default;
});