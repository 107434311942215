define("safesite/components/input-mobile", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputMobile = Ember.Component.extend({
    mobile: null,
    isNotValid: true,
    required: false,
    didInsertElement: function didInsertElement() {
      var self = this;

      _jquery.default.get('http://ipinfo.io', function (response) {
        (0, _jquery.default)('.input-mobile').intlTelInput({
          defaultCountry: response.country.toLowerCase(),
          responsiveDropdown: true
        });
        self.send('validateNumber');
      }, 'jsonp');
    },
    input: function input() {
      this.send('validateNumber');
    },
    click: function click() {
      this.send('validateNumber');
    },
    focusOut: function focusOut() {
      if (!this.isNotValid) {
        this.setProperties({
          'mobile': null,
          'isNotValid': true
        });
        (0, _jquery.default)('.input-mobile').removeClass('error');
        (0, _jquery.default)('.check-holder').addClass('hide');
      }
    },
    actions: {
      validateNumber: function validateNumber() {
        var self = this;

        if (_jquery.default.trim((0, _jquery.default)('.input-mobile').val())) {
          if ((0, _jquery.default)('.input-mobile').intlTelInput('isValidNumber')) {
            (0, _jquery.default)('.input-mobile').removeClass('error');
            (0, _jquery.default)('.check-holder').removeClass('hide');
            self.set('isNotValid', true);
          } else {
            (0, _jquery.default)('.input-mobile').addClass('error');
            (0, _jquery.default)('.check-holder').addClass('hide');
            self.set('isNotValid', false);
          }
        } else {
          (0, _jquery.default)('.input-mobile').removeClass('error');
          (0, _jquery.default)('.check-holder').addClass('hide');
          self.set('isNotValid', true);
        }
      }
    }
  });
  var _default = InputMobile;
  _exports.default = _default;
});