define("safesite/components/input-text-with-debounce", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputTextWithDebounce = Ember.Component.extend({
    classNames: ['form-group'],
    type: 'text',
    keyword: '',
    isSearching: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.footer = (0, _jquery.default)('.footer-menu');

      this.autoScroll = function () {
        (0, _jquery.default)('.navbar, .footer-menu').css('top', document.body.scrollTop + 'px');
      };
    },
    fixFixedPosition: function fixFixedPosition() {
      this.footer.css({
        visibility: 'hidden',
        opacity: '0',
        transition: 'visibility 0.3s, opacity 0.3s linear',
        position: 'absolute'
      });
    },
    resetFixedPosition: function resetFixedPosition() {
      this.footer.css({
        visibility: 'visible',
        opacity: '1',
        transition: 'visibility 0.3s, opacity 0.3s linear',
        position: 'fixed'
      });
    },
    focusIn: function focusIn() {
      // NOTE: The delay is required.
      if (this.device.get('isCordova')) {
        setTimeout(this.fixFixedPosition.bind(this), 100);
      }
    },
    focusOut: function focusOut() {
      if (this.device.get('isCordova')) {
        this.resetFixedPosition.bind(this)();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.device.get('isCordova')) {
        this.resetFixedPosition.bind(this)();
      }
    },
    keyUp: function keyUp(event) {
      this.updateKeywordDebounce();
    },
    updateKeyword: function updateKeyword(keyword) {
      this.set('value', keyword);
    },
    updateKeywordDebounce: function updateKeywordDebounce() {
      if (this.keyword === '') {
        this.updateKeyword(this.keyword);
      } else if (this.value !== this.keyword) {
        this.set('isSearching', Ember.isPresent(this.keyword) && this.keyword.length > 0);
        Ember.run.debounce(this, this.updateKeyword, this.keyword, 800);
      }
    }
  });
  var _default = InputTextWithDebounce;
  _exports.default = _default;
});