define("safesite/components/add-injury", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    readOnly: false,
    addPersonModalOpened: false,
    displayAddButton: Ember.computed('readOnly', function () {
      return !this.readOnly;
    }),
    selectedPeople: Ember.computed('injuries', function () {
      return this.injuries.map(function (injury) {
        return injury.person;
      });
    }),
    actions: {
      openAddInjuryModal: function openAddInjuryModal() {
        this.sendAction('openAddInjuryModal', this.myController, this.selectedPeople);
      },
      openEditInjuryModal: function openEditInjuryModal(injury) {
        this.sendAction('openAddInjuryModal', this.myController, this.selectedPeople, injury);
      },
      removeInjury: function removeInjury(injury) {
        this.injuries.removeObject(injury);
        this.sendAction('removeInjuryHandler', injury);
      }
    }
  });

  _exports.default = _default;
});