define("safesite/templates/components/add-signature-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZRs9NYmZ",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[4,\"safesite-modal\",null,[[\"title\",\"action\"],[[28,\"t\",[\"add-signature-modal.title\"],null],\"cancel\"]],{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"add-signature-modal-body-content panel-body\"],[13,1],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"label\",false],[12,\"class\",\"label label-primary pull-right\"],[3,\"action\",[[23,0,[]],\"clear\"]],[8],[0,\"\\n                \"],[1,[28,\"t\",[\"add-signature-modal.clear\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"add-signature-modal-canvas-container\"],[8],[0,\"\\n            \"],[7,\"canvas\",true],[10,\"class\",\"add-signature-modal-canvas\"],[8],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"add-signature-modal-instruction\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"add-signature-modal-instruction-text\"],[8],[0,\"\\n                    \"],[1,[28,\"t\",[\"add-signature-modal.instruction\"],null],false],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"hr\",true],[10,\"class\",\"add-signature-modal-dottedline\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-primary btn-block\"],[12,\"disabled\",[22,\"isSaveDisabled\"]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n                \"],[1,[28,\"t\",[\"add-signature-modal.save\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/add-signature-modal.hbs"
    }
  });

  _exports.default = _default;
});