define("safesite/models/meeting-template-online", ["exports", "ember-data", "safesite/models/meeting-template"], function (_exports, _emberData, _meetingTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _meetingTemplate.default.extend({
    couchdb_id: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization', {
      inverse: null,
      async: true
    })
  });

  _exports.default = _default;
});