define("safesite/routes/restricted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RestrictedRoute = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      if (!this.controllerFor('auth').isUserLoggedIn) {
        if (transition) {
          this.controllerFor('auth').set('intendedURL', transition.intent.url);
        }

        this.transitionTo('splash');
      }
    }
  });
  var _default = RestrictedRoute;
  _exports.default = _default;
});