define("safesite/routes/terms", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TermsRoute = Ember.Route.extend({
    // renderTemplate() {
    //     var self = this;
    //         var termsController = self.controllerFor('modals/terms');
    //         var scrollerHeight = "height:" + (Ember.$(window).height() * 0.5) + "px;";
    //         termsController.setProperties({'scrollerHeight':scrollerHeight});
    //         self.render('modals/terms', {
    //             into: 'application',
    //             outlet: 'modal',
    //             controller: termsController
    //         });
    // },
    actions: {
      closeModal: function closeModal() {
        (0, _jquery.default)('.modal').modal('hide');
        this.transitionTo('welcome');
      }
    }
  });
  var _default = TermsRoute;
  _exports.default = _default;
});