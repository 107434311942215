define("safesite/components/add-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addEquipmentModalOpened: false,
    readOnly: false,
    showErrors: false,
    multiple: true,
    displayData: null,
    equipments: Ember.A(),
    equipment: null,
    required: Ember.computed('errors.[]', 'showErrors', function () {
      return !Ember.isEmpty(this.errors) && this.showErrors;
    }),
    displayAddButton: Ember.computed('multiple', 'readOnly', 'equipment', 'equipments', function () {
      if (this.multiple) {
        return true && !this.readOnly;
      } else {
        return this.get('equipments.length') === 0 && Ember.isEmpty(this.get('equipments.content')) && !this.readOnly;
      }
    }),
    actions: {
      removeEquipment: function removeEquipment(equipment) {
        if (this.multiple) {
          this.equipments.removeObject(equipment);
        } else {
          this.set('equipment', null);
        }

        this.sendAction('removeEquipmentHandler', equipment);
      },
      toggleAddEquipmentModal: function toggleAddEquipmentModal() {
        this.toggleProperty('addEquipmentModalOpened');
      },
      addEquipment: function addEquipment(equipment) {
        if (!this.multiple) {
          this.set('equipment', equipment);
        } else {
          this.equipments.pushObject(equipment);
        }

        this.sendAction('addEquipmentHandler', equipment);
      },
      goToEquipmentCreation: function goToEquipmentCreation() {
        this.sendAction('goToEquipmentCreationHandler');
      }
    }
  });

  _exports.default = _default;
});