define("safesite/controllers/modals/abort_sync_confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AbortSyncConfirmationModalController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    applicationController: Ember.inject.controller('application'),
    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),
    synchronization: Ember.inject.service(),
    isLoggingOut: false,
    unableToLogout: false,
    showForceLogoutButton: Ember.computed('unableToLogout', 'applicationController.isOnline', function () {
      return this.unableToLogout || !this.applicationController.isOnline;
    }),
    logoutErrorMessage: Ember.computed('unableToLogout', 'applicationController.isOnline', function () {
      if (!this.applicationController.isOnline) {
        return this.i18n.t('logout-confirm.subtitle.message.offline');
      } else if (this.unableToLogout) {
        return this.i18n.t('logout-confirm.subtitle.message.unableToLogout');
      }

      ;
    }),
    actions: {
      logout: function logout() {
        this.metrics.trackEvent({
          event: 'm_Synchronization_Abort_logout'
        });
        this.set('isLoggingOut', true);
        this.authController.send('destroyLocalDBAndLogout');
        this.synchronization.abortSync();
      },
      forceLogout: function forceLogout() {
        this.metrics.trackEvent({
          event: 'm_Synchronization_Abort_forcelogout'
        });
        this.set('isLoggingOut', true);
        this.authController.send('destroyLocalDBAndLogout');
        this.synchronization.abortSync();
      }
    }
  });
  var _default = AbortSyncConfirmationModalController;
  _exports.default = _default;
});