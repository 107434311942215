define("safesite/controllers/modals/dropdown-location", ["exports", "safesite/controllers/modals/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dropdown.default.extend({
    siteController: Ember.inject.controller('site'),
    getPromise: function getPromise(model, propertyName, value) {
      var self = this;
      model.set(propertyName, value);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        model.set('site', self.get('siteController.model'));
        model.save().then(function (location) {
          self.get('siteController.model.locations').then(function (siteLocations) {
            siteLocations.pushObject(model);
          });
          resolve(location);
        }).catch(function (err) {
          reject(new Error("SAFESITE:DROPDOWN:Failed to save Location:", err));
        });
      });
    },
    actions: {}
  });

  _exports.default = _default;
});