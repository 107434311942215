define("safesite/serializers/user", ["exports", "ember-data", "ember-pouch"], function (_exports, _emberData, _emberPouch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberPouch.Serializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      profile: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});