define("safesite/templates/components/input-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DOAXyO5Z",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"required\",\"type\",\"class\",\"autocomplete\",\"autocorrect\",\"autocapitalize\",\"spellcheck\"],[[24,[\"mobile\"]],[24,[\"placeholder\"]],false,\"tel\",\"input-mobile form-control input-lg\",\"off\",\"off\",\"off\",\"false\"]]],false],[0,\"\\n \"],[7,\"div\",true],[10,\"class\",\"check-holder hide\"],[8],[7,\"i\",true],[10,\"class\",\"icon icon-ss-check\"],[8],[0,\" \"],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/input-mobile.hbs"
    }
  });

  _exports.default = _default;
});