define("safesite/models/witness", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Witness = _abstract.default.extend({
    person: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    statement: _emberData.default.attr(),
    incident: _emberData.default.belongsTo('incidentReport', {
      async: false
    }),
    is_deleted: _emberData.default.attr(),
    site: _emberData.default.belongsTo('site', {
      async: true
    })
  });

  var _default = Witness;
  _exports.default = _default;
});