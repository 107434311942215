define("safesite/templates/components/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/sN7f9N",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"big-spinner-holder\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\" \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"spinner-text\"],[8],[0,\"Loading...\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/loading.hbs"
    }
  });

  _exports.default = _default;
});