define("safesite/templates/modals/resetted-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lLIFvzSP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\",\"CancelButtonText\"],[\"closeModal\",[24,[\"title\"]],[24,[\"cancelButtonText\"]]]],{\"statements\":[[0,\"    \"],[7,\"form\",false],[12,\"role\",\"form\"],[12,\"class\",\"form-group \"],[3,\"action\",[[23,0,[]],\"confirm\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group ss-modal-form-group\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[1,[22,\"message\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/resetted-app.hbs"
    }
  });

  _exports.default = _default;
});