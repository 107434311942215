define("safesite/components/add-equipment-modal", ["exports", "jquery", "safesite/mixins/data-validator"], function (_exports, _jquery, _dataValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataValidator.default, {
    validations: {
      'equipmentName': function (_equipmentName) {
        function equipmentName() {
          return _equipmentName.apply(this, arguments);
        }

        equipmentName.toString = function () {
          return _equipmentName.toString();
        };

        return equipmentName;
      }(function () {
        if (Ember.isBlank(equipmentName)) {
          return "Must not be blank.";
        }
      })
    },
    isLoading: false,
    isSaving: false,
    addNew: false,
    inputValue: "",
    equipmentName: "",
    displayData: null,
    results: Ember.computed.alias('siteController.model.equipment'),
    title: Ember.computed('addNew', function () {
      var addNew = this.addNew;

      if (!addNew) {
        return 'Add Existing Equipment';
      } else {
        return 'Create New Equipment';
      }
    }),
    scrollerHeight: Ember.computed('results.[]', function () {
      return Ember.String.htmlSafe("max-height:" + (0, _jquery.default)(window).height() * 0.45 + "px;");
    }),
    project: Ember.computed.alias('siteController.model'),
    equipments: Ember.computed.alias('siteController.model.equipment'),
    equipment: null,
    actions: {
      selectValue: function selectValue(selectedValue, closeModal) {
        this.sendAction('addEquipmentHandler', selectedValue);
        closeModal();
      },
      closeModal: function closeModal() {
        this.sendAction('closeModalHandler');
      },
      addNewButtonPressed: function addNewButtonPressed() {
        this.toggleProperty('addNew');
      },
      goToEquipmentCreation: function goToEquipmentCreation() {
        this.sendAction('goToEquipmentCreationHandler');
      },
      resetForm: function resetForm() {
        this.set('equipmentName', "");
        this.set('inputValue', "");
      }
    }
  });

  _exports.default = _default;
});