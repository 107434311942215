define("safesite/routes/dashboard/index", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DashboardRoute = _restricted.default.extend({
    actions: {}
  });

  var _default = DashboardRoute;
  _exports.default = _default;
});