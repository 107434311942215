define("safesite/templates/modals/invite-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tOzHnTLx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\"],[\"closeModal\",[28,\"t\",[\"sites.invite-modal.title\"],null]]],{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"sites.invite-modal.subtitle\"],null],false],[9],[0,\"\\n    \"],[7,\"form\",false],[12,\"role\",\"form\"],[12,\"class\",\"form-group ss-modal-form-group\"],[3,\"action\",[[23,0,[]],\"sendInvite\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"id\",\"value\",\"type\",\"required\",\"placeholder\",\"classNames\",\"autocomplete\",\"autocorrect\",\"autocapitalize\",\"spellcheck\"],[\"email\",[24,[\"email\"]],\"email\",false,\"Email\",\"form-control input-lg\",\"off\",\"off\",\"off\",\"false\"]]],false],[0,\"\\n        \"],[1,[28,\"loading-btn\",null,[[\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"btn btn-default btn-lg btn-primary btn-block\",[28,\"t\",[\"sites.invite-modal.button\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/invite-site.hbs"
    }
  });

  _exports.default = _default;
});