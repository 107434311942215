define("safesite/models/question", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Question = _abstract.default.extend({
    text: _emberData.default.attr(),
    order: _emberData.default.attr(),
    question_type: _emberData.default.attr(),
    link_text: _emberData.default.attr(),
    link_url: _emberData.default.attr(),
    question_action_type: _emberData.default.attr(),
    section: _emberData.default.belongsTo('section', {
      async: true
    }),
    // inspection_template: DS.belongsTo('inspection-template', {async:true}),
    comment_only: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    prefixedId: Ember.computed('order', function () {
      return "q" + this.get('order');
    })
  });

  var _default = Question;
  _exports.default = _default;
});