define("safesite/templates/components/x-woof", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WTLBy+H+",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"x-woof-message\",null,[[\"message\",\"action\"],[[23,1,[]],\"removeObject\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/x-woof.hbs"
    }
  });

  _exports.default = _default;
});