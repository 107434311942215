define("safesite/templates/components/loading-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/bcZT0gS",
    "block": "{\"symbols\":[\"@buttonLabel\",\"@classNames\",\"@isLoading\"],\"statements\":[[7,\"a\",true],[11,\"class\",[29,[[23,2,[]],\" ss-flex ss-flex-justify-center ss-flex-align-center\"]]],[11,\"onclick\",[23,0,[\"sendAction\"]]],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"spinner spinner-white\"],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/loading-link.hbs"
    }
  });

  _exports.default = _default;
});