define("safesite/adapters/hazard-online", ["exports", "safesite/config/environment", "ember-data", "safesite/adapters/online"], function (_exports, _environment, _emberData, _online) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _online.default.extend({
    pathForType: function pathForType(type) {
      return 'hazards';
    }
  });

  _exports.default = _default;
});