define("safesite/templates/mixins/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y1OmHeDR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"multiplePage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"pagination-controls ss-btn-group btn-group btn-group-justified\"],[8],[0,\"\\n        \"],[5,\"loading-link\",[],[[\"@isLoading\",\"@disabled\",\"@buttonLabel\",\"@classNames\",\"@action\"],[[22,\"isLoadingPreviousPage\"],[22,\"disablePreviousPageButton\"],\"Previous\",[22,\"previousButtonClasses\"],[28,\"action\",[[23,0,[]],\"setPage\",[24,[\"previousPage\"]]],null]]]],[0,\"\\n\\n        \"],[7,\"a\",true],[10,\"class\",\"btn-group-ss-middle btn-group-ss pagination pagination-third\"],[10,\"click\",\"false;\"],[8],[0,\"\\n            \"],[7,\"strong\",true],[8],[1,[22,\"currentPage\"],false],[0,\"/\"],[1,[22,\"totalPages\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[5,\"loading-link\",[],[[\"@isLoading\",\"@disabled\",\"@buttonLabel\",\"@classNames\",\"@action\"],[[22,\"isLoadingNextPage\"],[22,\"disableNextPageButton\"],\"Next\",[22,\"nextButtonClasses\"],[28,\"action\",[[23,0,[]],\"setPage\",[24,[\"nextPage\"]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/mixins/pagination.hbs"
    }
  });

  _exports.default = _default;
});