define("safesite/components/signature-render", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SignatureRender = Ember.Component.extend({
    classNames: ['signature'],
    signature: null,
    dataurl: Ember.computed('signature', function () {
      if (this.signature.type === 'data-url') {
        return this.signature.data;
      } else if (this.signature.type === 'svg') {
        return "data:image/svg+xml;utf8,".concat(this.signature.data);
      } else if (this.signature.type === 'base64-svg') {
        return "data:image/svg+xml;base64,".concat(this.signature.data);
      } else if (this.signature.type === 'base64-png') {
        return "data:image/png;base64,".concat(this.signature.data);
      } else if (this.signature.type === 'base64-jpg') {
        return "data:image/jpeg;base64,".concat(this.signature.data);
      }

      return null;
    })
  });
  var _default = SignatureRender;
  _exports.default = _default;
});