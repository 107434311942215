define("safesite/models/section", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _abstract.default.extend({
    text: _emberData.default.attr(),
    order: _emberData.default.attr(),
    inspection_template: _emberData.default.belongsTo('inspection-template', {
      async: true
    }),
    prefixedId: Ember.computed('order', function () {
      return "q" + this.get('order');
    }),
    questions: _emberData.default.hasMany('question', {
      async: true
    }),
    sorted_questions: Ember.computed('questions', 'questions.@each.order', function () {
      return this.get('questions').sortBy('order');
    }),
    // Used only in ember to trigger the skipping of the questions
    skip: _emberData.default.attr()
  });

  _exports.default = _default;
});