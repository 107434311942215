define("safesite/templates/modals/permissions-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDXjNuZc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"hideCancel\"],[\"closeModal\",false]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ss-flex ss-flex-column ss-flex-align-center\"],[8],[0,\"\\n        \"],[1,[28,\"image\",null,[[\"image\"],[\"assets/images/Location.svg\"]]],false],[0,\"\\n\\n        \"],[7,\"h3\",true],[10,\"style\",\"text-align: center;\"],[8],[1,[28,\"t\",[\"modals.location-permission.title\"],null],false],[9],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[1,[28,\"t\",[\"modals.location-permission.subtitle\"],null],false],[9],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[1,[28,\"t\",[\"modals.location-permission.contact_support\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/permissions-location.hbs"
    }
  });

  _exports.default = _default;
});