define("safesite/components/observation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObservationItemComponent = Ember.Component.extend({
    layoutName: 'components/observation-item',
    record: null,
    showProjectName: false
  });
  var _default = ObservationItemComponent;
  _exports.default = _default;
});