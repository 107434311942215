define("safesite/templates/components/signature-render", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zbPmrCy1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"signature\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[22,\"dataurl\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/signature-render.hbs"
    }
  });

  _exports.default = _default;
});