define("safesite/models/certificate", ["exports", "ember-data", "safesite/models/abstract", "safesite/mixins/image-attachment"], function (_exports, _emberData, _abstract, _imageAttachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var Certificate = _abstract.default.extend(_imageAttachment.default, {
    name: attr(),
    reference: attr(),
    notes: attr(),
    is_deleted: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    date_issued: attr(),
    date_expires: attr(),
    modified_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    created_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    person: _emberData.default.belongsTo('user', {
      async: true
    }),
    deleted_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    deleted_at: attr(),
    files: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });

  var _default = Certificate;
  _exports.default = _default;
});