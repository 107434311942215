define("safesite/locales/pt/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "pagination": {
      "previous": "Anterior",
      "next": "Seguinte"
    },
    "datePicker": {
      "clear": "Limpar",
      "close": "Fechado",
      "today": "Hoje",
      "month.jan": "Jan",
      "month.feb": "Fev",
      "month.mar": "Março",
      "month.apr": "Abril",
      "month.may": "Maio",
      "month.jun": "Junho",
      "month.jul": "Julho",
      "month.aug": "Agosto",
      "month.sep": "Set",
      "month.oct": "Out",
      "month.nov": "Nov",
      "month.dec": "Dez",
      "week.sun": "Dom",
      "week.mon": "Seg",
      "week.tue": "Ter",
      "week.wed": "Qua",
      "week.thu": "Qui",
      "week.fri": "Sex",
      "week.sat": "Sáb"
    },
    "models": {
      "Hazard": "Perigo",
      "Observation": "Observação",
      "Meeting": "Reunião",
      "Inspection": "Inspeção",
      "IncidentReport": "Relatório de incidentes",
      "Incident Report": "Relatório de incidentes",
      "Incident": "Incidentes",
      "Equipment": "Equipamento"
    },
    "splash": {
      "button.get-started": "Iniciar",
      "hero.pre-text": "Ir",
      "hero.alt-text": " Além",
      "hero.post-text": " Conformidade de Segurança",
      "label.terms.text": "Ao continuar, você aceita nosso ",
      "label.terms.link": "Termos e Condições",
      "message.language": "Idioma de preferência"
    },
    "welcome": {
      "title": "Bem-vindo ao Safesite!",
      "subtitle": "Digite seu e-mail para começar",
      "label.email": "E-mail",
      "placeholder.email": "nome@corporação.com",
      "button.continue": "Continuar",
      "link.reset": "Esqueceu sua senha?",
      "reset.message": "Encontramos sua conta, mas parece que você precisa configurar uma senha. Enviamos a você um link de redefinição de senha por e-mail, abra no seu dispositivo móvel.",
      "message.offline": "Você precisa estar online para se inscrever ou fazer login.",
      "messages.reset.email": "Por favor verifique seu email."
    },
    "resetted": {
      "title": "Aplicativo Reiniciado",
      "message": "Ops! O aplicativo Safesite foi reiniciado devido a um erro. Se o problema persistir, entre em contato com nossa equipe de sucesso do cliente em success@safesitehq.com ou por meio de uma mensagem de suporte no aplicativo. Lamentamos qualquer inconveniente.",
      "button.ok": "Certo"
    },
    "auth": {
      "button.signup": "Inscreva-se",
      "button.go": "Iniciar sessão",
      "button.reset": "Esqueceu sua senha?",
      "label.email": "E-mail",
      "label.password": "Senha",
      "placeholder.email": "Digite E-mail",
      "placeholder.password": "Digite a senha",
      "password.hide": "Esconder",
      "password.show": "Mostrar",
      "password.error": "Digite a senha",
      "email.error": "Digite o endereço de e-mail",
      "message.new": "Novo no Safesite?",
      "message.terms_1": "Ao se registrar, você aceita",
      "message.terms_2": "Termos de serviço",
      "title": "Iniciar sessão",
      "subtitle": "Bem-vindo, digite seu email e senha abaixo",
      "message.error": "Ocorreu um erro, tente novamente ou entre em contato com o suporte."
    },
    "reset": {
      "button.login": "Atualizar",
      "button.reset": "Reenviar senha esquecida?",
      "label.email": "E-mail",
      "label.password": "Criar senha (min. 8 caracteres)",
      "placeholder.email": "",
      "placeholder.password": "Nova senha",
      "password.hide": "Esconder",
      "password.show": "Mostrar",
      "password.error": "Digite a senha",
      "label.terms.pretext": "Ao se registrar, você aceita ",
      "label.terms.link": "Termos de serviço",
      "title": "Configurar Senha",
      "subtitle": "Digite sua nova senha",
      "message.error": "Ocorreu um erro, tente novamente ou entre em contato com o suporte."
    },
    "sync": {
      "button.start": "Iniciar",
      "button.continue": "Continuar",
      "label.logout": "Sair",
      "db_creation.message": "Ativando offline...",
      "h4.signup_creation": "Criando Conta",
      "h4.sync_progress": "Sincronizando conta para uso offline...",
      "h4.sync_progress_larger_accounts": "...isso pode levar até 2 minutos para contas maiores",
      "h4.sync_progress_signup": "Configurando sua conta...",
      "h4.sync_progress_signup_larger_accounts": "...esta é uma sincronização única para que o aplicativo funcione offline",
      "h4.sync_completed": "Configuração concluída!",
      "video.message": "Enquanto você espera, vamos nos familiarizar com o Safesite",
      "finishing.message": "Não foi possível conectar ao servidor, tente novamente mais tarde",
      "user.has.unsynced.items": "Você está offline com dados não sincronizados.",
      "is.complete": "Sincronização concluída!"
    },
    "reset-password": {
      "title": "Esqueceu sua senha?",
      "placeholder.email": "Email",
      "button.reset": "Redefinir senha",
      "error": {
        "no-connection": "Não foi possível conectar ao servidor, tente novamente mais tarde",
        "no-user": "E-mail digitado não foi encontrado no sistema",
        "default": "Não foi possível conectar ao servidor, tente novamente mais tarde"
      },
      "email.sent": "Verifique seu e-mail para obter informações de como redefinir sua senha"
    },
    "change-password": {
      "title": "Atualizar senha",
      "placeholder.newPassword": "Nova senha",
      "placeholder.confirmPassword": "Confirmar Senha",
      "button.update": "Atualizar Senha",
      "password.hide": "Esconder",
      "password.show": "Mostrar"
    },
    "signup": {
      "title": "Vamos começar",
      "subtitle": "Digite um email e senha para criar um perfil no Safesite",
      "label.email": "E-mail",
      "label.password": "Criar senha (min. 8 caracteres)",
      "placeholder.email": "Digite Email",
      "placeholder.password": "Nova senha",
      "label.terms.pretext": "Ao se registrar, você aceita nossos ",
      "label.terms.link": "Termos e Condições",
      "button.signup": "Inscreva-se"
    },
    "signup-organization-set-up": {
      "industry.type": "Industria",
      "select.industry.type": "Selecione a indústria",
      "label.organization": "Organização",
      "subtitle": "Para personalizar sua experiência, insira mais informações (opcional) abaixo",
      "title": "Criar Organização",
      "validation.orgNotBlank": "O nome da organização não deve ficar em branco."
    },
    "nav": {
      "side": {
        "label.certificates": "Certificados",
        "label.help_center": "Centro de Ajuda",
        "label.join_webinar": "Webinars",
        "label.contact": "Suporte",
        "label.refresh": "Atualizar",
        "label.tour": "Tour",
        "label.logout": "Sair"
      },
      "footer": {
        "main": {
          "Actions": "Atividades",
          "Projects": "Projetos",
          "Inbox": "Notificações",
          "SafetyHQ": "Gerenciar"
        },
        "add": {
          "title": "Nova Ação de Segurança",
          "inspection": "Realizar<br>Inspeção",
          "hazard": "Adicionar<br>Perigo",
          "meeting": "Liderar<br>Reunião",
          "equipment": "Inspecionar<br>Equipamento",
          "observation": "Registrar<br>Observação",
          "incident": "Registrar<br>Incidente"
        },
        "manage": {
          "title": "Gerenciar Safesite",
          "message.create_project": "Crie seu próprio projeto para poder gerenciá-lo.",
          "message.non_org_admin": "Infelizmente você não pode gerenciar nenhum dos seus projetos, entre em contato com o gerente da sua organização.",
          "Message.not_your_org": "Você não pode gerenciar este projeto.",
          "button.create_project": "Crie seu projeto",
          "inspections": "Inspeções",
          "hazards": "Perigos",
          "meetings": "Reuniões",
          "equipment": "Equipamento",
          "observations": "Observações",
          "incidents": "Relatórios de Incidentes"
        }
      }
    },
    "actions": {
      "index": {
        "title.hazards": "Perigos",
        "title.team.hazards": "Riscos da minha equipe",
        "title.my.hazards": "Meus perigos criados",
        "title.in_progress": "Ítens em andamento",
        "title.incomplete": "Incompletos",
        "title.actions_this_week": "Atividades",
        "message.no_more_actions": "Não há mais Atividades.",
        "message.new_item_1": "Clique no botão + abaixo",
        "message.new_item_2": "para iniciar uma nova atividade de segurança.",
        "cards": {
          "title.inspection": "Realizar Inspeção",
          "title.hazard": "Gravar Perigo",
          "title.observation": "Registrar Observação",
          "title.meeting": "Liderar Reunião",
          "title.resolve": "Resolver Perigo",
          "title.announcement": "Anúncio",
          "title.profile": "Preencher seu perfil",
          "title.verify": "Verifique seu email",
          "title.safety-item": "Conclua sua primeira ação de segurança",
          "text.project": "Projeto",
          "text.raised": "Carregado por"
        },
        "empty-state": {
          "project": "Projeto ",
          "actions.title": "Excelente trabalho ao concluir suas ações de segurança! Toque no botão + para criar mais.",
          "incomplete.title": "Excelente trabalho ao concluir suas ações incompletas!",
          "unresolved-hazards.title": "Excelente trabalho resolvendo todos os seus perigos!",
          "message.part1": "Mantenha-se ativo e conclua as novas Atividades de Segurança conforme elas surgirem, usando o ",
          "message.part2": "botão abaixo"
        }
      },
      "text.overdue": "Vencido",
      "text.today": "Hoje",
      "text.left": "restante"
    },
    "sites": {
      "title.main": "Seus Projetos",
      "message.empty": "É hora de criar seu primeiro projeto",
      "message.non_org_admin": "Não consegue ver seu projeto? Entre em contato com seu administrador para obter acesso.",
      "button.join": "Participe do Projeto",
      "button.create": "Criar Projeto",
      "button.invite": "Convidar",
      "button.remove": "Sair",
      "create": {
        "label.name": "* Nome do Projeto",
        "label.address": "Endereço",
        "placeholder.name": "Digite o nome do Projeto",
        "placeholder.address": "Digite o endereço do Projeto",
        "placeholder.search": "Pesquise nome do Projeto",
        "label.organization": "Select Organization",
        "button.join": "Participe do Projeto",
        "button.save": "Criar",
        "message.project": "Se o projeto não pertencer à sua organização, entre em contato com o administrador do Safesite dessa organização",
        "title.create": "Novo Projeto",
        "title.join": "Ingressar em um Projeto existente",
        "site.name.already.exists": "O nome deste projeto já existe",
        "site.successfully.created": "Projeto criado com sucesso"
      },
      "invite-modal": {
        "title": "Convidar para o Projeto",
        "subtitle": "Convide a equipe para participar do seu projeto digitando seu email abaixo:",
        "button": "Convidar",
        "invitation.sent.online.alert": "O seu convite foi enviado",
        "invitation.sent.offline.alert": "Seu convite será enviado assim que você voltar a ficar on-line"
      },
      "leave-modal": {
        "title": "Tem certeza? Isso irá removê-lo totalmente do projeto.",
        "button.yes": "Sim"
      }
    },
    "notifications": {
      "index": {
        "title": "Notificações",
        "filter.read": "Lido",
        "filter.unread": "Não lido",
        "message.empty": "Não há mensagens não lidas, aparentemente você está atualizado.",
        "button.read": "Marcar como lida"
      }
    },
    "inspection": {
      "conduct": "Realizar Inspeção",
      "index": {
        "title": "Inspeção",
        "message.unresolved": "Perigos restantes não resolvidos",
        "message.completed": "Inspeção concluída",
        "button.back": "Anterior",
        "button.export": "Exportar",
        "button.archive": "Arquivar a Inspeção",
        "heading.unresolved": "Perigos não resolvidos",
        "heading.timeframe": "Período de tempo",
        "heading.details": "Detalhes",
        "heading.resolvedHazards": "Perigos resolvidos",
        "heading.dateResolved": "Data de resolução",
        "heading.observations": "Observações de segurança",
        "heading.answers": "Respostas",
        "label.inspection_template": "Modelo de Inspeção",
        "label.location": "Localização",
        "label.conductedOn": "Conduzido em",
        "label.conductedBy": "Conduzido por",
        "label.hazardLogged": "Perigo registrado",
        "label.observationLogged": "Observação registrada",
        "label.commentAdded": "Comentário adicionado",
        "text.answered": "Respondida",
        "text.question": "Pergunta",
        "message.noComments": "Nenhum comentário gravado"
      }
    },
    // Default - may be overridden by safety item specific strings
    "add-cocreator": {
      "title": "co-criadors",
      "button.add": "Adicionar co-criador",
      "no-cocreators": "Nenhum usuário adicionado",
      "modal-title": "Adicionar co-criador"
    },
    "inspections": {
      "add-cocreator": {
        "title": "Conduzido por",
        "button.add": "Adicionar Pessoa",
        "modal-title": "Selecionar Pessoa"
      },
      "create": {
        "error.missing-template": "Não foi possível encontrar o modelo, tente novamente ou entre em contato com o suporte.",
        "heading": "Detalhes da Inspeção",
        "label.location": "Selecione Localização",
        "label.template": "Selecione modelo de Inspeção",
        "label.location-modal": "Localização",
        "label.template-modal": "Modelo",
        "button.complete": "Conclua a Inspeção",
        "button.save": "Salvar Inspeção",
        "button.archive": "Arquivar a Inspeção",
        "question-item": {
          "counter": "Pergunta {{number}} de {{total}}",
          "placeholder.comment": "Escreva um comentário",
          "button.add-observation": "Adicionar Observação",
          "message.observation-added": "Observação registrada",
          "message.hazard-added": "Perigo Adicionado",
          "label.optional": "Opcional",
          "label.comments": "Comentários",
          "label.actions": "Ações",
          "placeholder.response": "Inserir resposta *",
          "label.required": "* = Requeridos",
          "question_type.undefined.positive": " ",
          "question_type.undefined.negative": " ",
          "question_type.undefined.na": " ",
          "question_type.1.positive": "&#x2714;&#xfe0e;",
          "question_type.1.negative": "&#x2716;&#xfe0e;",
          "question_type.1.na": "NA",
          "question_type.2.positive": "Seguro",
          "question_type.2.negative": "Inseguro",
          "question_type.2.na": "NA",
          "question_type.3.positive": "Aprovado",
          "question_type.3.negative": "Não Aprovado",
          "question_type.3.na": "NA",
          "question_type.4.positive": "Sim",
          "question_type.4.negative": "Não",
          "question_type.4.na": "NA"
        },
        "section-item": {
          "footer.answers": " de ",
          "footer.questions": " perguntas completadas"
        },
        "message.saved": "Salvo!",
        "message.draftSaved": "Rascunho salvo",
        "message.answerAllQuestions": "Por favor, responda as perguntas",
        "message.sectionLabel": "Seção ",
        "message.missLocation": "Por favor selecione um Local",
        "message.success": "Inspeção salva com sucesso.",
        "message.completed": "Inspeção concluída com êxito.",
        "message.observationAdded": "Observação salva com sucesso",
        "message.hazardAdded": "Perigo salvo com sucesso",
        "message.recent": "Recente",
        "message.myOrg": "Minha Org",
        "templates-library": {
          "message.noRecentlyUsedTemplate": "Você não usou modelos recentemente, conclua sua primeira inspeção.",
          "message.noTemplatesInYourOrg": "Você ainda não tem modelos na biblioteca da sua organização. Adicione alguns fazendo com que um administrador faça login no painel de administração via app.safesitehq.com",
          "message.noResults": "Nenhum resultado para mostrar."
        }
      },
      "index": {
        "title": "Inspeções",
        "button.export": "Exportar",
        "button.open": "Abrir",
        "button.closed": "Fechado",
        "button.all": "Tudo",
        "button.person": "Pessoa",
        "button.type": "Tipo",
        "button.filter": "Filtrar por ",
        "heading.inspectionDetails": "Detalhes da Inspeção",
        "heading.dateStarted": "Data de início",
        "heading.dateClosed": "Data de fechamento",
        "message.noOpenInspections": "Não há inspeções abertas.",
        "message.noClosedInspections": "Não há inspeções fechadas.",
        "text.conductedBy": "Conduzido por",
        "text.type": "Tipo",
        "text.count": "Contar"
      },
      "skip-modal": {
        "title": "Tem certeza de que deseja pular esta seção?",
        "button.confirm": "Sim, pular seção",
        "label.questions": " Perguntas",
        "message": "Pular uma seção marcará todas as respostas na seção como NA",
        "skip.btn": "pular"
      }
    },
    "meeting.conduct": "Liderar Reunião",
    "meeting.index": {
      "title": "Reunião",
      "heading.unresolvedHazardsCount": "Perigos restantes não resolvidos",
      "heading.unresolvedHazards": "Perigos não resolvidos",
      "heading.timeframe": "Período de tempo",
      "heading.details": "Detalhes",
      "heading.resolvedHazards": "Perigos resolvidos",
      "heading.dateResolved": "Data de resolução",
      "heading.safetyObservations": "Observações de segurança",
      "heading.answers": "Respostas",
      "heading.attendees": "Participantes ",
      "heading.photos": "Fotos",
      "label.location": "Localização",
      "label.conductedBy": "Conduzido por",
      "label.conductedOn": "Conduzido em ",
      "label.topic": "Tema",
      "label.status": "Estado",
      "label.hazardLogged": "Perigo registrado",
      "label.observationsLogged": "Observação registrada",
      "label.comment": "Comentário",
      "button.back": "Anterior",
      "button.export": "Exportar",
      "button.archive": "Arquivar a Reunião"
    },
    "meetings": {
      "add-cocreator": {
        "title": "Conduzido por",
        "button.add": "Adicionar Pessoa",
        "modal-title": "Selecionar Pessoa"
      },
      "conduct": "Liderar Reunião",
      "addAttendees": "Adicionar participantes à reunião",
      "index": {
        "title": "Reuniões",
        "button.export": "Exportar",
        "button.open": "Abrir",
        "button.closed": "Fechar",
        "button.all": "Tudo",
        "button.person": "Pessoa",
        "button.topic": "Tema",
        "button.filter": "Filtrar por",
        "heading.meetingDetails": "Detalhes da Reunião",
        "heading.dateStarted": "Data de início",
        "heading.dateClosed": "Data de fechamento",
        "message.noOpenMeetings": "Não há Reuniões abertas.",
        "message.noClosedMeetings": "Não há Reuniões fechadas",
        "text.conductedBy": "Conduzido por",
        "text.topic": "Tema",
        "text.count": "Contar"
      },
      "create": {
        "error.missing-template": "Não foi possível encontrar o modelo, tente novamente ou entre em contato com o suporte.",
        "title": "Detalhes da Reunião",
        "label.location": "Selecione Localização",
        "label.topic": "Selecione o tema da reunião",
        "button.finish": "Concluir e adicionar participantes",
        "button.archive": "Arquivar a Reunião",
        "topic": {
          "heading": "Tema {{number}} de {{total}}",
          "message.optional": "Opcional",
          "message.actions": "Ações",
          "placeholder.comment": "Escreva um comentário",
          "placeholder.repsonse": "Inserir resposta",
          "button.save": "SALVAR",
          "message.obs_logged": "Observação registrada",
          "message.haz_raised": "Perigo registrado",
          "message.comment_added": "Comentário adicionado"
        },
        "message.saved": "Salvo!",
        "message.draftSaved": "Rascunho salvo!",
        "message.answerAllTopics": "Por favor, responda todos os temas.",
        "message.missLocation": "Por favor selecione um Local",
        "message.success": "Reunião salva com sucesso",
        "message.pleaseStart": "Por favor, inicie uma nova reunião para modificar o tema da reunião.",
        "add-attendees": {
          "button.addAttendee": "Adicionar participantes",
          "button.finish": "Concluir",
          "heading.photos": "Adicionar fotos",
          "heading.attendees": "Adicionar participantes",
          "heading.select": "Selecionar participantes",
          "message.uploadSignatures": "Carregue uma foto da folha de assinaturas ou dos participantes se não conseguir obter assinaturas digitais.",
          "message.missingSignatures": "Adicione todas as assinaturas ou foto da folha de assinaturas antes de concluir a reunião.",
          "message.missingPhotoAndAttendees": "Adicione participantes e fotos antes de concluir a reunião",
          "message.missingAttendees": "Adicione participantes antes de concluir a reunião",
          "message.missingPhotos": "Adicione fotos antes de concluir a reunião"
        },
        "templates-library": {
          "message.noRecentlyUsedTemplate": "Você não usou modelos recentemente, conclua sua primeira reunião."
        }
      }
    },
    "hazards": {
      "add-cocreator": {
        "title": "Gravado por",
        "button.add": "Adicionar Pessoa",
        "modal-title": "Selecionar Pessoa"
      },
      "index": {
        "title": "Perigos",
        "button.export": "Exportar",
        "button.unresolved": "Não resolvido",
        "button.resolved": "Resolvido",
        "button.all": "Tudo",
        "button.team": "Time",
        "button.location": "Localização",
        "button.filter": "Filtrar por",
        "heading.hazardDetails": "Detalhes do Perigo",
        "heading.timeframe": "Período de tempo",
        "heading.dateResolved": "Data de resolução",
        "message.noResolvedHazards": "Não há perigos resolvidos para esse período de tempo",
        "message.noUnresolvedHazards": "Não há perigos não resolvidos para esse período de tempo.",
        "text.location": "Localização",
        "text.team": "Time",
        "text.count": "Contar"
      }
    },
    "hazard": {
      "add": "Reportar Perigo",
      "index": {
        "heading.details": "Detalhes do Perigo",
        "heading.equipment": "Equipamento relacionado",
        "heading.comments": "Comentários",
        "heading.location": "Localização",
        "heading.risk": "Nível de Perigo",
        "button.back": "Anterior",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.resolve": "Resolver Perigo",
        "button.archive": "Arquivar o Perigo",
        "button.markResolved": "Marcar como resolvido",
        "text.hazard": "Perigo",
        "text.unresolved": "Não resolvido",
        "text.resolved": "Resolvido",
        "label.task": "Atividade em andamento",
        "label.root": "Causa principal",
        "label.riskType": "Tipo de perigo",
        "label.riskProbability": "Probabilidade",
        "label.riskConsequence": "Consequência do Perigo",
        "label.riskLevel": "Nível de perigo",
        "label.recommendedAction": "Ação recomendada",
        "label.description": "Descrição",
        "label.logged": "Gravado por",
        "label.responsibleParty": "Equipe Responsável",
        "label.location": "Localização",
        "label.sublocation": "sublocação",
        "label.additionParties": "Equipe adicional notificada",
        "label.name": "Nome",
        "label.number": "Número",
        "label.team": "Time",
        "label.Email": "E-mail",
        "message.resolved": "Perigo resolvido com sucesso",
        "message.not_resolvable": "O perigo não pode ser resolvido até que um comentário, imagem ou ação corretiva seja anexada",
        "message.mark_resolvable": "O Perigo está marcado para ser resolvido",
        "message.not_archivable": "O Perigo não pode ser arquivado até que um comentário seja adicionado, explicando por que ele foi arquivado."
      },
      "edit": {
        "title.details": "Detalhes",
        "title.images": "Adicionar imagens",
        "title.team": "Time responsável",
        "title.location": "Localização: arraste o ícone do marcador para a localização desejada",
        "title.riskAssessment": "Avaliação de perigos e riscos",
        "title.rootCause": "Análise de Causa raiz (opcional)",
        "title.description": "Descrição",
        "title.correctiveAction": "Ação corretiva",
        "title.correctiveActions": "Descrição e ação corretiva",
        "title.riskLevel": "Nível de perigo",
        "label.recommendedActions": "Ação recomendada",
        "label.team": "Selecione Time",
        "label.location": "Selecione Localização",
        "label.riskType": "Selecione o tipo de Perigo",
        "label.riskProbability": "Selecione a Probabilidade",
        "label.riskConsequence": "Selecione a Consequência do Perigo.",
        "label.task": "Selecione Atividade em andamento",
        "label.rootCause": "Selecione Causa raiz",
        "button.update": "Atualizar Perigo",
        "button.record": "Gravar Perigo",
        "button.resolve": "Resolver Perigo",
        "button.cancel": "Cancelar",
        "validation.notBlank": "Não pode estar vazio.",
        "message.success": "Perigo salvo com sucesso",
        "message.resolved": "Perigo resolvido com successo.",
        "message.mandatory": "Por favor, preencha os campos obrigatórios"
      }
    },
    "observation": {
      "add": "Registrar Observação",
      "index": {
        "heading.details": "Detalhes da Observação",
        "button.back": "Anterior",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.archive": "Arquivar Observação",
        "label.description": "Descrição",
        "label.loggedBy": "Gravado por",
        "label.location": "Selecione Localização",
        "label.team": "Time",
        "text.safe": "Positivo",
        "text.risk": "Negativo",
        "text.observation": "Observação",
        "text.on": "ligado"
      },
      "nature": "Tipo"
    },
    "observations": {
      "add-cocreator": {
        "title": "Gravado por",
        "button.add": "Adicionar Pessoa",
        "modal-title": "Selecionar Pessoa"
      },
      "create": {
        "title": "Detalhes da observação",
        "label.safe": "Positivo",
        "label.risk": "Negativo",
        "label.location": "Selecione Localização",
        "label.team": "Selecione Time (opcional)",
        "label.resolved": "Resolvido",
        "placeholder.description": "Descrição",
        "button.record": "Registrar Observação",
        "button.update": "Atualizar Observação",
        "button.cancel": "Cancelar",
        "message.success": "Observação salva com sucesso.",
        "message.validation": "Por favor, preencha os campos obrigatórios",
        "validation.locationNotBlank": "Por favor, selecione um local.",
        "validation.descriptionBlank": "Não pode ser concluída sem uma descrição.",
        "validation.descriptionShort": "A descrição precisa ter pelo menos 2 palavras."
      },
      "index": {
        "title": "Observações",
        "button.safe": "Positivo",
        "button.risk": "Negativo",
        "button.all": "Tudo",
        "button.team": "Time",
        "button.location": "Localização",
        "button.export": "Exportar",
        "button.filter": "Filtrar por ",
        "heading.observationDetails": "Detalhes da Observação",
        "heading.dateCreated": "Data de criação",
        "heading.dateResolved": "Data de resolução",
        "heading.backToWork": "Voltar ao trabalho ETA",
        "message.noSafeObservations": "Não há Observações seguras",
        "message.noRiskObservations": "Não há observações de perigo",
        "text.count": "Contar",
        "text.location": "Localização",
        "text.team": "Time"
      }
    },
    "incident.index": {
      "title": "Relatório de incidente",
      "button.back": "Anterior",
      "button.edit": "Editar",
      "button.export": "Exportar",
      "button.archive": "Arquivar o Relatório de incidente",
      "heading.incident": "Detalhes do Incidente",
      "heading.rootAnalysis": "Análise de Causa raiz",
      "heading.hazards": "Causa raiz dos perigos",
      "heading.comments": "Comentários",
      "label.type": "Tipo",
      "label.location": "LocatiLocalizaçãoon",
      "label.dateTime": "Data/hora do incidente",
      "label.task": "Trabalho sendo concluído",
      "label.root": "Causa raiz",
      "label.correctiveAction": "Descrição da ação corretiva",
      "label.resolved": "Resolvido",
      "label.logged": "Conduzido por"
    },
    "incidents": {
      "add-cocreator": {
        "title": "Conduzido por",
        "button.add": "Adicionar Pessoa",
        "modal-title": "Selecionar Pessoa"
      },
      "types.INJ": "Lesão ou doença",
      "types.NMS": "Quase-incidente",
      "types.DMG": "Danos materiais",
      "types.TFT": "Roubo",
      "types.EQF": "Falha no equipamento",
      "add": "Registrar Incidente",
      "index": {
        "title": "Relatório do Incidente",
        "button.open": "Abrir",
        "button.closed": "Fechado",
        "button.all": "Tudo",
        "button.type": "Tipo",
        "button.location": "Localização",
        "button.filter": "Filtrar por",
        "heading.incidentReport": "Detalhes do Relatório do incidente",
        "heading.dateCreated": "Data de criação",
        "heading.dateResolved": "Data de resolução",
        "text.location": "Localização",
        "text.type": "Tipo",
        "text.count": "Contar",
        "message.noOpenIncidents": "Não há Relatório de incidentes abertos.",
        "message.noClosedIncidents": "Não há Relatório de incidentes fechados."
      },
      "create": {
        "heading.incidentReport": "Detalhes do Relatório do incidente",
        "title.description": "Descrição",
        "title.rootCauseAnalysis": "Análise de causa raiz",
        "title.rootCauseHazard": "Causa raiz do perigo",
        "title.comments": "Comentários",
        "title.injuries": "Danos",
        "title.nearMiss": "Quase-incidente",
        "title.propertyDamage": "Danos materiais",
        "label.incidentType": "Tipo de incidente",
        "label.location": "Selecione a Localização.",
        "label.incidentDate": "Data do Incidente",
        "label.incidentTime": "Hora do Incidente",
        "label.task": "Selecione Atividade em andamento",
        "label.resolved": "Resolvido",
        "label.rootCause": "Selecione Causa raiz",
        "label.potentialRisk": "Selecione Tipo de risco",
        "label.incidentType.options": "Tipos de incidentes (selecione tudo que se aplica)",
        "label.equipmentFailure": "Falha no equipamento",
        "label.equipmentFailure.description": "Descrição da Falha.",
        "label.equipmentTheft": "Roubo",
        "label.equipmentTheft.description": "Descrição do Roubo",
        "label.injuries": "Descrição da lesão ou doença",
        "label.nearMiss": "Descrição do Quase-incidente",
        "label.propertyDamage.owner": "Proprietário",
        "label.propertyDamage.description": "Descrição do Dano",
        "label.propertyDamage.cause": "Causa do Dano",
        "placeholder.description": "Descrição do Incidente",
        "placeholder.correctiveAction": "Descrição da Ação corretiva.",
        "placeholder.equipmentFailure.description": "O que aconteceu?",
        "placeholder.equipmentTheft.description": "O que aconteceu?",
        "placeholder.injuries.description": "O que aconteceu?",
        "placeholder.propertyDamage.description": "Qual foi o Dano?",
        "placeholder.propertyDamage.cause": "Qual foi a Causa?",
        "button.addHazard": "Adicionar causa principal do Perigo",
        "button.save": "SALVAR",
        "button.log": "Registrar Incidente",
        "button.savedraft": "Salvar como Rascunho",
        "button.update": "Atualizar Incidente",
        "button.archive": "Arquivar o Incidente",
        "button.cancel": "Cancelar",
        "message.saved": "Salvo!",
        "message.draftSaved": "Rascunho salvo!",
        "message.success": "Relatório de Incidente salvo com sucesso!.",
        "message.descriptionAdded": "Descrição adicionada.",
        "message.correctiveDescription": "Descrição da Ação corretiva adicionada.",
        "message.error": "Ocorreu um erro",
        "injury": {
          "title": "Anexar o Ferimento ou Doença",
          "label.type": "Selecione tipo de Lesão ou Doença",
          "label.bodyPart": "Selecione parte do Corpo",
          "label.treatment": "Selecione o tipo de Tratamento",
          "placeholder.treatment": "Descrição do Tratamento",
          "message.treatment": "Nota: Supervisor para concluir os detalhes do tratamento no portal da área de trabalho.",
          "button.done": "Feito"
        },
        "witness": {
          "title": "Testemunhas",
          "button.add": "Adicionar Testemunha",
          "modal": {
            "title": "Anexar Testemunha",
            "placeholder.statement": "Digite o Testemunho...",
            "button.done": "Feito"
          }
        },
        "add-equipment": {
          "title": "Equipamento envolvido",
          "title_singular": "Equipamento envolvido",
          "button.add": "Adicionar Equipamento",
          "label.equipment": "Equipamento",
          "modal": {
            "label.or": "OU",
            "label.results": "Equipamento do Projeto",
            "button.add": "Adicionar novo Equipamento"
          }
        }
      }
    },
    "equipment": {
      "index": {
        "button.back": "Anterior",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.cancel": "Cancelar",
        "button.delete": "Remover",
        "button.yes": "Sim",
        "button.no": "Não",
        "button.archive": "Arquivar o Equipamento",
        "button.createInspection": "Criar Nova Inspeção",
        "button.createHazard": "Informar Novo Perigo",
        "heading.owner": "Proprietário",
        "heading.inspections": "Inspeções",
        "heading.inspection": "Detalhes da Inspeção",
        "heading.dateStarted": "Data de início",
        "heading.dateClosed": "Data de fechamento",
        "heading.maintenance": "Manutenção",
        "heading.comments": "Comentários",
        "titile": "Equipamento",
        "label.team": "Time",
        "label.location": "Localização",
        "label.type": "Tipo",
        "label.make": "Fazer",
        "label.model": "Modelo",
        "text.hazards": "Perigos",
        "message.noInspections": "Não há Inspeções",
        "message.noHazards": "Não há Perigos",
        "text.hazard.unresolvedDetails": "Detalhes de Perigos não resolvidos ",
        "text.hazard.resolvedDetails": "Detalhes de Perigos resolvidos",
        "text.details": "Detalhes",
        "label.serial": "Número de série",
        "label.lastTestDate": "Data da última Inspeção",
        "label.testFreq": "Frequência de Inspeção",
        "label.nextTestDate": "Data da próxima Inspeção"
      }
    },
    "equipments": {
      "manage": "Registrar Equipamento",
      "create": {
        "title.project": "Projeto",
        "title.owner": "Proprietário do Equipamento",
        "title.location": "Localização",
        "title.description": "Descrição",
        "title.maintenance": "Manutenção",
        "label.project": "Selecionar Projeto",
        "label.team": "Selecione Time",
        "label.location": "Selecione Localização",
        "label.serial": "Número de série",
        "label.eq_type": "Selecione Tipo de equipamento",
        "label.make": "Selecione Fazer",
        "label.model": "Modelo",
        "label.last_date_date": "Defina a Data da última Inspeção",
        "label.test_frequency": "Defina a Frequência das Inspeções",
        "label.test_date": "Data da próxima Inspeção",
        "message.location": "Localização: arraste o ícone do marcador para a localização desejada",
        "button.update": "Atualizar Equipamento",
        "button.register": "Gravar Equipamento",
        "button.cancel": "Cancelar",
        "message.success": "Equipamento salvo com sucesso",
        "message.mandatory": "Por favor, preencha os campos obrigatórios"
      },
      "index": {
        "title": "Registro de Equipamento",
        "button.all": "Tudo",
        "button.team": "Time",
        "button.type": "Tipo",
        "button.export": "Exportar",
        "button.filter": "Filtrar por",
        "heading.itemDetails": "Detalhes do item",
        "heading.nextService": "Próximo serviço",
        "message.noEquipment": "Não há nenhum equipamento no registro",
        "placeholder.search": "Pesquisar..."
      }
    },
    "certificate": {
      "index": {
        "button.back": "Anterior",
        "button.edit": "Editar",
        "button.export": "Exportar",
        "button.cancel": "Cancelar",
        "button.delete": "Remover",
        "button.yes": "Sim",
        "button.no": "Não",
        "button.archive": "Arquivar Certificado",
        "title": "Certificado",
        "title.details": "Detalhes do Certificado",
        "title.upload": "Carregar Foto(s)",
        "text.details": "Detalhes",
        "label.name": "Nome do Certificado ou Tipo",
        "label.issuing_body": "Emissor (opcional)",
        "label.additional_notes": "Notas adicionais",
        "label.date_issued": "Data de emissão",
        "label.date_expires": "Data de expiração"
      }
    },
    "certificates": {
      "manage_create": "Registrar Certificado",
      "manage_edit": "Editar Certificado",
      "create": {
        "title.project": "Projeto",
        "title.description": "Descrição",
        "title.details": "Detalhes do Certificado",
        "title.upload": "Carregar Foto(s)",
        "button.save": "Salvar Certificado",
        "button.update": "Atualizar Certificado",
        "button.addnew": "Adicionar Novo",
        "button.cancel": "Cancelar",
        "message.success": "Certificado salvo com sucesso",
        "message.mandatory": "Por favor, preencha os campos obrigatórios",
        "label.name": "Nome do Certificado ou Tipo*",
        "label.issuing_body": "Emissor",
        "label.additional_notes": "Notas adicionais",
        "label.date_issued": "Data de emissão",
        "label.date_expires": "Data de expiração",
        "placeholder": {
          "label.name": "Insira Nome do Certificado ou Tipo",
          "label.issuing_body": "OSHA, CDC, etc.",
          "label.additional_notes": "Insira notas adicionais"
        }
      },
      "index": {
        "title": "Certificado",
        "button.all": "Todos",
        "button.issued": "Emitido",
        "button.expiry": "Expirado",
        "button.filter": "Filtrar por",
        "label.issued": "Emitido",
        "label.expiry": "Expirado",
        "message.noCertificate": "Não há nenhum certificado no registro",
        "placeholder.search": "Pesquisar..."
      }
    },
    "modals": {
      "dropdown": {
        "text.pleaseSelect": " ",
        "text.searchOrAdd": "Pesquise ou adicione novo...",
        "text.search": "Pesquisar...",
        "label.addNew": "Adicionar novo",
        "errors.fail": "O driver suspenso não pôde salvar o registro",
        "errors.team": "Erro ao salvar o Time",
        "errors.location": "Erro ao salvar o Localização",
        "errors.equipmentType": "FErro ao salvar o Tipo de equipamento.",
        "errors.make": "Erro ao salvar Fazer",
        "errors.model": "Falha ao salvar o Modelo"
      },
      "dropdown-multiple": {
        "text.pleaseSelect": " ",
        "text.search": "Pesquisar...",
        "text.removed_item": "Item removido",
        "text.added_item": "Item adicionado",
        "title.add-new-item": "ou Adicionar novo",
        "text.no-results": "Sem resultados"
      },
      "archive-item": {
        "button.yes": "Sim, arquivar",
        "button.no": "Não, cancelar",
        "message.archiveConfirm": "Tem certeza de que deseja arquivar isso?",
        "text.archived": "Arquivado",
        "text.failedArchive": "Erro ao arquivar"
      },
      "exit-item": {
        "title": "Deseja salvar e retornar a isso mais tarde?",
        "button.yes_save": "Sim, salvar",
        "button.cancel": "Cancelar",
        "button.save_later": "Yes, Save for later",
        "button.no": "Não, descartar o item",
        "text.saved": "Salvo",
        "text.archived": "Arquivado",
        "text.failedArchive": "Erro ao arquivar",
        "text.failedSave": "Erro ao salvar"
      },
      "dropdown-site": {
        "title": "Todos os Projetos"
      },
      "exit-new-item-mode": {
        "title": "Tem certeza de que deseja deixar este item de Segurança?",
        "button.yes": "Sim"
      },
      "select-site": {
        "title": "Todos os Projetos",
        "subtitle": "Por favor, selecione um Projeto",
        "button.select": "Selecione",
        "placeholder.search": "Pesquisar..."
      },
      "select-project": {
        "title": "Selecionar Projeto"
      },
      "hazard-create": {
        "title": "Carregar novo Perigo",
        "message.location": "Localização: arraste o ícone do marcador para a localização desejada",
        "message.description": "Descrição e ação corretiva",
        "label.team": "Selecione Time",
        "label.location": "Selecione Localização",
        "label.riskType": "Selecione Tipo de perigo",
        "label.riskProbability": "Selecione Probabilidade",
        "label.riskConsequence": "Selecione Conseqüência do perigo",
        "button.save": "Salver Perigo",
        "button.resolve": "Resolver Perigo"
      },
      "observation-create": {
        "title": "Registrar Observação",
        "label.safe": "Positivo",
        "label.risk": "Negativo",
        "label.location": "Selecione Localização",
        "label.team": "Selecione Time (opcional)",
        "placeholder.description": "Descrição",
        "button.save": "Salvar"
      },
      "announcement-modal": {
        "title": "Anúncio",
        "button.ack": "Reconhecer"
      },
      "location-permission": {
        "title": "Serviços de localização desativados",
        "subtitle": "Ative os serviços de localização para que você possa identificar onde suas ações de segurança ocorrem.",
        "footer": "Não rastreamos pessoas nem compartilhamos seus dados. Utilizamos apenas dados de localização para fins de segurança.",
        "contact_support": "Se o problema persistir, leia este <a href='http://help.safesitehq.com/en/articles/4531342-location-services' target='_blank'>artigo do HelpCenter</a> ou <a href='mailto:success@safesitehq.com'>Contate o suporte</a>.",
        "error.title": "Ative o serviço de localização",
        "error.message": "Nós não conseguimos acessar a sua localização."
      },
      "feature-disabled": {
        "title": "Recurso desativado enquanto offline"
      }
    },
    "userprofile": {
      "title": "Perfil",
      "label.photo.add": "Adicionar Foto",
      "label.photo.edit": "editar",
      "label.button.add": "adicionar",
      "label.button.edit": "editar",
      "label.button.editName": "editar nome",
      "label.button.update": " atualizar",
      "label.button.cancel": "cancelar ",
      "label.firstName": "Primeiro Nome",
      "placeholder.firstName": "Digite o Primeiro Nome (obrigatório)",
      "label.lastName": "Sobrenome",
      "placeholder.lastName": "Digite o Sobrenome nome (obrigatório)",
      "label.email": "E-mail",
      "placeholder.phone": "Digite o número de telefone",
      "label.phone": "Número de telefone",
      "label.organization": "Organização",
      "label.organizations": "Organizações",
      "label.appVersion": "Versão da aplicação",
      "label.defaultLanguage": "Idioma de preferência",
      "label.updateApp": "Atualizar aplicativo"
    },
    "contact-us": {
      "title": "Contate-nos",
      "message.feedback": "Seus comentários são importantes para nós e entraremos em contato o mais breve possível.",
      "message.successfully.sent": "Mensagem enviada com sucesso.",
      "message.error": "Ocorreu um erro, por favor tente novamente",
      "placeholder.message": "Mensagem",
      "button.send": "Enviar"
    },
    "errors": {
      "network": "Ocorreu um erro de rede! Tente novamente ou entre em contato com o suporte."
    },
    "components": {
      "image-upload": {
        "take-picture": "Tirar Foto",
        "from-gallery": "Escolher da galeria"
      },
      "item-comments": {
        "message.noComment": "Não há comentários gravados.",
        "button.add": "Adicionar Comentário",
        "placeholder.comment": "Digite um comentário..."
      },
      "modal-dialog": {
        "button.cancel": "Cancelar"
      },
      "upload": {
        "text.uploading": "Carregando..."
      },
      "custom-dropdown-button": {
        "text.select": "Selecione"
      },
      "date-field": {
        "text.set": "Definir"
      },
      "time-field": {
        "text.set": "Definir"
      },
      "loading-btn": {
        "disabled.offline.message": "desativado enquanto offline"
      },
      "add-injury": {
        "label": "Adicionar Lesão ou Doença",
        "body-parts": {
          "heading": "Partes do corpo adicionadas",
          "select-modal": {
            "title.select": "Selecionar partes do corpo"
          }
        }
      },
      "add-person": {
        "title": "Pessoas envolvidas",
        "title_singular": "Pessoa envolvida",
        "button.add": "Adicionar Pessoa",
        "placeholder.search": "Pesquisar...",
        "label.person": "Pessoa",
        "label.add_existing": "Adicionar pessoa existente",
        "label.create_new": "Criar nova pessoa",
        "modal": {
          "label.or": "OU",
          "label.results": "Resultados",
          "placeholder.jobTitle": "Titulo do trabalho",
          "placeholder.firstName": "Primeiro Nome ",
          "placeholder.lastName": "Sobrenome",
          "placeholder.email": "Email (opcional)",
          "placeholder.phone": "Número de Contato",
          "placeholder.company": "Nome da Empresa",
          "button.add": "Adicionar nova Pessoa"
        }
      }
    },
    "meeting-item": {
      "text.incomplete": "Incompleto",
      "text.pendingHazards": "Perigos pendentes",
      "text.closedHazards": "Perigos encerrados"
    },
    "incident-item": {
      "text.incomplete": "Incompleto",
      "text.pendingHazards": "Perigos pendentes",
      "text.closedHazards": "Perigos encerrados"
    },
    "inspection-item": {
      "text.incomplete": "Incompleto",
      "text.pendingHazards": "Perigos pendentes",
      "text.closedHazards": "Perigos encerrados"
    },
    "open-hazard-item": {
      "title": "Resolver Perigo"
    },
    "logout-confirm": {
      "title": "Tem certeza de que deseja sair?",
      "button.yes": "Sim",
      "button.forceLogout": "Sim, forçar saída",
      "subtitle.message.offline": "No momento você está offline, saiba que se você sair do sistema, poderá perder dados que ainda não foram sincronizados.",
      "subtitle.message.unableToLogout": "Nos não conseguimos conectar aos nossos servidores neste momento. Esteja ciente que se você forçar a saída você poderá perder dados que ainda não foram sincronizados.",
      "message.error": "Ocorreu um erro, tente novamente ou entre em contato com o suporte."
    },
    "custom-dropdown": {
      "button.cancel": "Cancelar",
      "button.done": "Feito"
    },
    "signup-steps": {
      "title": "Inscreva-se",
      "step1": {
        "title": "Vamos começar",
        "info": "Por favor, digite seu e-mail abaixo.",
        "placeholder.email": "Email",
        "button.continue": "Continuar",
        "button.cancel": "Cancelar"
      },
      "step2": {
        "title": "Perfil",
        "info": "Precisamos enviar um e-mail rápido para confirmar seu endereço de e-mail. Digite abaixo.",
        "placeholder.password": "Senha",
        "placeholder.password.confirm": "Confirmar senha",
        "placeholder.firstName": "Primeiro Nome",
        "placeholder.lastName": "Sobrenome",
        "placeholder.company": "Nome da Empresa",
        "placeholder.phoneNumber": "Número de telefone",
        "button.continue": "Continuar",
        "button.cancel": "Cancelar"
      },
      "step3": {
        "title": "Empresa",
        "not-found-subtext1": "Não foi possível encontrar nenhuma organização atribuída a este email.",
        "not-found-subtext2": "Vamos criar sua própria organização, digite o nome abaixo",
        "placeholder.company": "Nome da sua empresa",
        "found-subtext1": "Boas notícias, já temos sua empresa em nosso sistema.",
        "found-subtext2": "Vamos continuar",
        "button.continue": "Continuar",
        "button.cancel": "Cancelar"
      },
      "step4": {
        "title": "Seus projetos",
        "subtext1": "Está faltando um projeto? Pergunte ao seu gerente ou entre em contato com o atendimento ao cliente ao concluir o registro.",
        "projects-subtext1": "Seus Projetos atuais",
        "button.continue": "Continuar",
        "button.cancel": "Fechar"
      },
      "step5": {
        "title": "Verifique seu e-mail para autenticar.",
        "subtext1": "Para confirmar seu endereço de e-mail, abra-o através do seu dispositivo móvel e toque no botão no seu e-mail",
        "button.resend": "Reenviar email",
        "button.cancel": "Fechar"
      }
    },
    "project-invite": {
      "title": "Agora vamos começar",
      "org-projects-text-part1": "Ver os",
      "org-projects-text-part2": "projetos dos quais você faz parte",
      "button.continue": "Continuar",
      "button.complete": "Preencher",
      "button.cancel": "Fechar",
      "placeholder.email": "Email",
      "placeholder.password": "Criar senha",
      "placeholder.password.confirm": "Confirmar senha",
      "placeholder.firstName": "Primeiro Nome",
      "placeholder.lastName": "Sobrenome",
      "placeholder.company": "Nome da Empresa",
      "placeholder.phoneNumber": "Número de telefone (opcional)"
    },
    "validate-email": {
      "title": "Verifique seu e-mail",
      "text-part1": "Enviamos um email de verificação para",
      "text-part2": "Você não recebeu seu email? Tente enviá-lo novamente",
      "button.resend": "Reenviar Email",
      "button.cancel": "Fechar"
    },
    "verify-email": {
      "title": "Verificar Email",
      "text-part1": "Verifique seu endereço de e-mail clicando no link de ativação que lhe enviamos",
      "text-part2": "Validando......",
      "button.resend": "Reenviar Email",
      "button.cancel": "Fechar"
    },
    "tour": {
      "title": "Bem-vindo ao Safesite!",
      "body": "Assista ao vídeo abaixo que o guia através da oferta completa do Safesite.",
      "button.done": "Comece hoje",
      "video.src": "https://player.vimeo.com/video/385146720?autoplay=0&title=0",
      "message.offline": "O vídeo do tour não está disponível off-line"
    },
    "offline-mode": {
      "message.offline": "Você está offline, mas pode continuar usando o Safesite. Vamos sincronizar seus dados assim que você estiver online.",
      "message.syncing": "Sincronizando..."
    },
    "default-form-messages": {
      "enter.valid.email": "Por favor insira um endereço de e-mail válido",
      "enter.valid.mobile.number": "Por favor insira um número de celular válido",
      "enter.valid.email.or.mobile.number": "Digite um endereço de e-mail ou número de celular válido",
      "enter.valid.first_last_names": "Por favor, digite o nome e sobrenome",
      "enter.valid.email_to_invite_project": "Digite o e-mail para convidar para o projeto",
      "enter.valid.fill_in_all_fields": "Por favor preencha todos os campos",
      "results": "Resultados"
    },
    "release-notes": {
      "title": "Safesite agora funciona offline",
      "message": "Safesite offline está aqui! Se acontecer de você ficar off-line, armazenaremos todas as alterações no seu dispositivo e sincronizaremos assim que ficar on-line. Leia mais em nosso <a href='https://help.safesitehq.com/en/articles/4340586-using-the-app-while-offline-coming-soon' target='_blank'>HelpCenter</a>."
    },
    "add-signature-btn": {
      "text": "Adicionar assinatura"
    },
    "add-signature-modal": {
      "title": "Adicionar assinatura",
      "instruction": "Sinal acima da linha pontilhada",
      "clear": "apagar",
      "save": "Salve"
    }
  };
  _exports.default = _default;
});