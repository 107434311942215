define("safesite/controllers/splash", ["exports", "safesite/utilities/firebaseLogEvent"], function (_exports, _firebaseLogEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    device: Ember.inject.service(),
    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),
    connectionStatus: Ember.inject.service(),
    isOffline: Ember.computed('connectionStatus.offline', function () {
      return this.connectionStatus.offline;
    }),
    actions: {
      getStarted: function getStarted() {
        this.metrics.trackEvent({
          event: 'w_splash_screen'
        });
        (0, _firebaseLogEvent.default)("splash_screen");
        this.transitionToRoute('welcome');
      }
    }
  });

  _exports.default = _default;
});