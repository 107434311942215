define("safesite/controllers/comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CommentController = Ember.Controller.extend({
    sortBy: ['sortProperties:asc'],
    model: Ember.computed.sort('model', 'sortBy')
  });
  var _default = CommentController;
  _exports.default = _default;
});