define("safesite/routes/site/index", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var SiteIndexRoute = _restricted.default.extend({
    redirect: function redirect() {
      this.transitionTo('actions.index');
    }
  });

  var _default = SiteIndexRoute;
  _exports.default = _default;
});