define("safesite/templates/modals/add-witness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dSr9PJYb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\"],[\"closeModal\",[28,\"t\",[\"incidents.create.witness.modal.title\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[1,[28,\"add-person\",null,[[\"multiple\",\"showErrors\",\"errors\",\"person\",\"referral\",\"selectedPeople\"],[false,[24,[\"showErrors\"]],[24,[\"errors\",\"model\",\"person\"]],[24,[\"model\",\"person\"]],\"incident_witness\",[24,[\"selectedPeople\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[1,[28,\"safesite-textarea\",null,[[\"value\",\"classNames\",\"placeholder\",\"maxlength\",\"showCounter\"],[[24,[\"model\",\"statement\"]],\"form-control\",[28,\"t\",[\"incidents.create.witness.modal.placeholder.statement\"],null],2000,true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[1,[28,\"loading-btn\",null,[[\"classNames\",\"action\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"btn btn-default  btn-lg btn-primary btn-block\",\"save\",[28,\"t\",[\"incidents.create.witness.modal.button.done\"],null],[24,[\"isSaving\"]],[24,[\"isSaving\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/add-witness.hbs"
    }
  });

  _exports.default = _default;
});