define("safesite/initializers/dropdown-btn-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:dropdown-btn-location', 'siteController', 'controller:site');
  }

  var _default = {
    name: 'dropdown-btn-location',
    initialize: initialize
  };
  _exports.default = _default;
});