define("safesite/mixins/unload-unused-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UnloadUnusedModelMixin = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition() {
        if (this.controller.get('model.isNew')) {
          this.controller.get('model').unloadRecord();
        }

        return true;
      }
    }
  });
  var _default = UnloadUnusedModelMixin;
  _exports.default = _default;
});