define("safesite/controllers/modals/resetted-app", ["exports", "ember-i18n"], function (_exports, _emberI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ResettedAppModalController = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    title: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('resetted.title');
    }),
    cancelButtonText: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('resetted.button.ok');
    }),
    message: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('resetted.message');
    }),
    actions: {
      confirm: function confirm() {
        var self = this;
        self.send('cancel');
      }
    }
  });
  var _default = ResettedAppModalController;
  _exports.default = _default;
});