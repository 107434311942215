define("safesite/components/add-witness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    readOnly: false,
    displayAddButton: Ember.computed('readOnly', function () {
      return !this.readOnly;
    }),
    selectedPeople: Ember.computed('witnesses', function () {
      return this.witnesses.map(function (witness) {
        return witness.person;
      });
    }),
    actions: {
      openAddWitnessModal: function openAddWitnessModal() {
        this.sendAction('openAddWitnessModal', this.myController, this.selectedPeople);
      },
      removeWitness: function removeWitness(witness) {
        this.witnesses.removeObject(witness);
        this.sendAction('removeWitnessHandler', witness);
      }
    }
  });

  _exports.default = _default;
});