define("safesite/templates/modals/geo-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q1KYnSn8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\"],[\"closeModal\",\"Add Geo-Location\"]],{\"statements\":[[0,\"    \"],[7,\"form\",false],[12,\"role\",\"form\"],[3,\"action\",[[23,0,[]],\"send\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n            \"],[1,[28,\"leaflet-map\",null,[[\"latitude\",\"longitude\"],[[24,[\"latitude\"]],[24,[\"longitude\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n            \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary btn-lg btn-block\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"Done\"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/geo-location.hbs"
    }
  });

  _exports.default = _default;
});