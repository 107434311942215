define("safesite/initializers/register-woof-messages", ["exports", "safesite/utilities/woof"], function (_exports, _woof) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "registerWoofMessages",
    initialize: function initialize(application) {
      application.register('woof:main', _woof.default);
    }
  };
  _exports.default = _default;
});