define("safesite/controllers/modals/terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TermsAgreementModalController = Ember.Controller.extend({
    isLoading: false,
    scrollerHeight: null
  });
  var _default = TermsAgreementModalController;
  _exports.default = _default;
});