define("safesite/models/observation", ["exports", "ember-data", "safesite/models/abstract", "safesite/mixins/image-attachment", "moment"], function (_exports, _emberData, _abstract, _imageAttachment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Observation = _abstract.default.extend(_imageAttachment.default, {
    responsible_person: _emberData.default.belongsTo('user', {
      async: true
    }),
    created_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    created_at: _emberData.default.attr('number', {
      defaultValue: function defaultValue() {
        return _moment.default.utc().unix();
      }
    }),
    cocreators: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    signatures: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    modified_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    nature: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return 'safe';
      }
    }),
    displayNature: Ember.computed('nature', function () {
      if (this.nature === 'safe') {
        return this.i18n.t('observation.index.text.safe');
      } else if (this.nature === 'risk') {
        return this.i18n.t('observation.index.text.risk');
      }
    }),
    description: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    team: _emberData.default.belongsTo('team', {
      async: true
    }),
    site: _emberData.default.belongsTo('site', {
      async: true
    }),
    location: _emberData.default.belongsTo('location', {
      async: true
    }),
    images: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    comments: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    inspection: _emberData.default.belongsTo('inspection', {
      async: false
    }),
    is_deleted: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    })
  });

  var _default = Observation;
  _exports.default = _default;
});