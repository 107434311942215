define("safesite/templates/components/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DFrc7KgY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[11,\"id\",[22,\"id\"]],[11,\"class\",[22,\"class\"]],[11,\"src\",[29,[[28,\"await\",[[24,[\"getImageSrc\"]]],null]]]],[11,\"onerror\",[22,\"onerror\"]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/image.hbs"
    }
  });

  _exports.default = _default;
});