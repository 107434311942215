define("safesite/models/user", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var User = _abstract.default.extend({
    username: attr(),
    first_name: attr(),
    last_name: attr(),
    email: attr({
      defaultValue: function defaultValue() {
        return '';
      }
    }),
    profile: _emberData.default.attr('raw'),
    created_by: _emberData.default.belongsTo('user', {
      async: true,
      inverse: null
    }),
    modified_by: _emberData.default.belongsTo('user', {
      async: true,
      inverse: null
    }),
    full_name: Ember.computed('first_name', 'last_name', function () {
      if (this.first_name && this.last_name) {
        return this.first_name.capitalize() + ' ' + this.last_name.capitalize();
      }
    }),
    name_and_email: Ember.computed('first_name', 'last_name', 'email', function () {
      if (this.first_name && this.last_name) {
        return this.first_name.capitalize() + ' ' + this.last_name.capitalize() + ' - (' + this.email + ')';
      } else {
        return this.email;
      }
    }),
    name_or_email: Ember.computed('first_name', 'last_name', 'email', function () {
      if (this.first_name && this.last_name) {
        return this.first_name.capitalize() + ' ' + this.last_name.capitalize();
      } else {
        return this.email;
      }
    }),
    initialize_name: Ember.computed('first_name', 'last_name', function () {
      if (this.first_name && this.last_name) {
        return this.first_name.charAt(0) + '.' + this.last_name;
      }
    }),
    // used to know on adding users via sfaety items (meetings and incidents)
    invite_to_project: attr(),
    profile_image: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return null;
      }
    })
  });

  var _default = User;
  _exports.default = _default;
});