define("safesite/templates/components/image-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b6pvHbBK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"image-loader-spinner-holder\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"openLightbox\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[\"icon icon-arrow-maximise \",[28,\"if\",[[23,0,[\"errorThrown\"]],\"hide\"],null]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"image\",null,[[\"image\",\"class\"],[[24,[\"image\"]],\"responsive\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"img-overlay\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"delete-msg\"],[8],[0,\"Are you sure you \"],[7,\"br\",true],[8],[9],[0,\"want to delete?\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"btn-group \"],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\"btn-cancel bg-white btn btn-warning \"],[3,\"action\",[[23,0,[]],\"deleteCancel\"]],[8],[0,\"Cancel\"],[9],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\"btn-yes btn btn-danger \"],[3,\"action\",[[23,0,[]],\"deleteConfirm\",[24,[\"image\"]]]],[8],[0,\"Yes\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"delete\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"delete-btn\"],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\"btn-delete btn btn-primary btn-block\"],[12,\"disabled\",[23,0,[\"errorThrown\"]]],[3,\"action\",[[23,0,[]],\"deleteRequest\"]],[8],[0,\"DELETE\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/image-loader.hbs"
    }
  });

  _exports.default = _default;
});