define("safesite/controllers/modals/permissions-404-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Permissions404SiteController = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    actions: {}
  });
  var _default = Permissions404SiteController;
  _exports.default = _default;
});