define("safesite/templates/components/generic-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ocgmEG7N",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ss-flex ss-flex-space-between ss-flex-align-center ss-generic-item-row space-between \",[28,\"if\",[[24,[\"addMargin\"]],\"ss-generic-item-margin\"],null]]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showIcon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"ss-generic-item-icon ss-flex ss-flex-align-center ss-flex-justify-center\"],[10,\"style\",\"position: relative;\"],[8],[0,\"\\n            \"],[1,[22,\"icon\"],true],[0,\"\\n\"],[4,\"if\",[[24,[\"underlineIcon\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"style\",\"width: 16px;height: 4px;position: absolute;border: 2px solid #58595B;border-radius: 5px;top: 70%;left: 50%;transform: translateX(-50%);\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ss-generic-item-content ss-flex ss-flex-align-center\"],[8],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/generic-item.hbs"
    }
  });

  _exports.default = _default;
});