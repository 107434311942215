define("safesite/routes/equipment/edit", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var EquipmentEditRoute = _restricted.default.extend({
    i18n: Ember.inject.service(),
    model: function model() {
      return this.modelFor('equipment');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('validationFields', Ember.A());
      this.controllerFor('application').send('enterNewItemMode', this.i18n.t('equipments.manage'));
    }
  });

  var _default = EquipmentEditRoute;
  _exports.default = _default;
});