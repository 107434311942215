define("safesite/controllers/meeting/add-attendees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MeetingAddAttendeesController = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    siteController: Ember.inject.controller('site'),
    applicationController: Ember.inject.controller('application'),
    customdropdownController: Ember.inject.controller('modals/custom-dropdown'),
    skipValidationsIfSavingDraft: true,
    actions: {
      meetingIsCompleted: function meetingIsCompleted() {
        this.set('model.completed', true);
        this.model.save();
        this.woof.success('Meeting Successfully Saved.');
        this.applicationController.send('redirectTo', 'actions.index');
        this.applicationController.send('exitNewItemMode');
      }
    }
  });
  var _default = MeetingAddAttendeesController;
  _exports.default = _default;
});