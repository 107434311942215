define("safesite/controllers/modals/exit-new-item-mode-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExitNewItemModeConfirmationModalController = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    actions: {
      exitNewItemMode: function exitNewItemMode() {
        this.applicationController.send('exitNewItemMode');
        this.applicationController.send('redirectTo', 'actions.index');
        this.send('closeModal');
        this.applicationController.send('closeModal');
      }
    }
  });
  var _default = ExitNewItemModeConfirmationModalController;
  _exports.default = _default;
});