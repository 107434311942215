define("safesite/templates/components/custom-dropdown-multiple-user-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QnEreZXK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"list-group-item ss-flex-list-group \",[28,\"if\",[[24,[\"isSelected\"]],\"ss-flex-group-selected\"],null]]]],[3,\"action\",[[23,0,[]],[24,[\"selectValue\"]],[24,[\"result\"]],[24,[\"index\"]]]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"ss-flex-group-item \"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"id\",\"add-item-email-txt-container\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"id\",\"add-item-email-txt\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"displayValue\"]]],null,{\"statements\":[[0,\"                \"],[7,\"b\",true],[8],[1,[22,\"displayValue\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"b\",true],[8],[1,[24,[\"result\",\"username\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isSelected\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"i\",true],[10,\"class\",\"icon btn-icon icon-delete \"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[7,\"i\",true],[10,\"class\",\"icon btn-icon icon-user-add \"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"id\",\"add-item-email-txt\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"result\",\"first_name\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"result\",\"username\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"small\",true],[8],[1,[28,\"email-or-nothing\",[[24,[\"result\",\"username\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/custom-dropdown-multiple-user-item.hbs"
    }
  });

  _exports.default = _default;
});