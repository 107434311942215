define("safesite/initializers/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:nav-menu', 'session', 'controller:auth');
    application.inject('component:footer-menu', 'session', 'controller:auth');
    application.inject('component:notification-item', 'session', 'controller:auth');
    application.inject('component:action-item', 'session', 'controller:auth');
    application.inject('component:add-attendees', 'session', 'controller:auth');
    application.inject('component:dropdown-link-site', 'session', 'controller:auth');
    application.inject('controller:sites', 'session', 'controller:auth');
    application.inject('controller:modals/select-site', 'session', 'controller:auth');
    application.inject('controller:modals/invite-site', 'session', 'controller:auth');
    application.inject('controller:modals/project-invite', 'session', 'controller:auth');
    application.inject('adapter:application', 'session', 'controller:auth');
    application.inject('service:synchronization', 'session', 'controller:auth');
    application.inject('service:synchronization-bar', 'session', 'controller:auth');
    application.inject('service:site', 'session', 'controller:auth');
    application.inject('component:image-upload', 'session', 'controller:auth');
    application.inject("model", 'session', 'controller:auth');
  }

  var _default = {
    name: 'session',
    initialize: initialize
  };
  _exports.default = _default;
});