define("safesite/initializers/i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'i18n',
    after: 'ember-i18n',
    initialize: function initialize(app) {
      app.inject('model', 'i18n', 'service:i18n');
    }
  };
  _exports.default = _default;
});