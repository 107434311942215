define("safesite/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "pagination": {
      "previous": "Previous",
      "next": "Next"
    },
    "datePicker": {
      "clear": "Clear",
      "close": "Close",
      "today": "Today",
      "month.jan": "Jan",
      "month.feb": "Feb",
      "month.mar": "Mar",
      "month.apr": "Apr",
      "month.may": "May",
      "month.jun": "Jun",
      "month.jul": "Jul",
      "month.aug": "Aug",
      "month.sep": "Sep",
      "month.oct": "Oct",
      "month.nov": "Nov",
      "month.dec": "Dec",
      "week.sun": "Su",
      "week.mon": "Mo",
      "week.tue": "Tu",
      "week.wed": "We",
      "week.thu": "Th",
      "week.fri": "Fr",
      "week.sat": "Sa"
    },
    "models": {
      "Hazard": "Hazard",
      "Observation": "Observation",
      "Meeting": "Meeting",
      "Inspection": "Inspection",
      "IncidentReport": "Incident Report",
      "Incident Report": "Incident Report",
      "Incident": "Incident",
      "Equipment": "Equipment",
      "Certificate": "Certificate"
    },
    "splash": {
      "button.get-started": "Get Started",
      "hero.pre-text": "Go",
      "hero.alt-text": " Beyond",
      "hero.post-text": " Safety Compliance",
      "label.terms.text": "By continuing you accept our",
      "label.terms.link": "Terms & Conditions",
      "message.language": "Preferred Language"
    },
    "welcome": {
      "title": "Welcome to Safesite!",
      "subtitle": "Enter your email to begin",
      "label.email": "Email",
      "placeholder.email": "name@company.com",
      "button.continue": "Continue",
      "link.reset": "Forgot password?",
      "reset.message": "We found your account, but it looks like you need to set up a password. We've emailed you a reset password link, please open on your mobile device.",
      "message.offline": "You need to be online to sign up or log in.",
      "messages.reset.email": "Please check your email."
    },
    "resetted": {
      "title": "Application Restarted",
      "message": "Oops! The Safesite Application was restarted due to an error.  If this issue persists, please contact our helpful Customer Success team at success@safesitehq.com or through an in-app support message. Sorry for any inconvenience.",
      "button.ok": "OK"
    },
    "auth": {
      "button.signup": "Sign Up",
      "button.go": "Login",
      "button.reset": "Forgot password?",
      "label.email": "Email",
      "label.password": "Password",
      "placeholder.email": "Enter Email",
      "placeholder.password": "Enter Password",
      "password.hide": "Hide",
      "password.show": "Show",
      "password.error": "Please Enter Password",
      "email.error": "Please Enter Email Address",
      "message.new": "New to Safesite?",
      "message.terms_1": "By signing up, you agree to the",
      "message.terms_2": "Terms of Service",
      "title": "Log in",
      "subtitle": "Welcome back! Enter your email and password below",
      "message.error": "An error has occurred, please try again or contact support."
    },
    "reset": {
      "button.login": "Update",
      "button.reset": "Resend forgot password?",
      "label.email": "Email",
      "label.password": "Create Password (min 8 characters)",
      "placeholder.email": "",
      "placeholder.password": "New Password",
      "password.hide": "Hide",
      "password.show": "Show",
      "password.error": "Please Enter Password",
      "label.terms.pretext": "By continuing you agree to our ",
      "label.terms.link": "Terms & Conditions",
      "title": "Set Password",
      "subtitle": "Enter your new password",
      "message.error": "An error has occurred, please try again or contact support."
    },
    "sync": {
      "button.start": "Get Started",
      "button.continue": "Continue",
      "label.logout": "Log out",
      "db_creation.message": "Enabling Offline...",
      "h4.signup_creation": "Creating Account",
      "h4.sync_progress": "Syncing account for offline usage...",
      "h4.sync_progress_larger_accounts": "...this may take up to 2 minutes for larger accounts",
      "h4.sync_progress_signup": "Setting up your account...",
      "h4.sync_progress_signup_larger_accounts": "...this is a one-time sync so the app works offline",
      "h4.sync_completed": "Setup complete!",
      "video.message": "While you wait, lets get familiar with Safesite",
      "finishing.message": "Finishing synchronization, please do not disconnect",
      "user.has.unsynced.items": "You are offline with unsynced data.",
      "is.complete": "Syncing complete!"
    },
    "reset-password": {
      "title": "Forgot password?",
      "placeholder.email": "Email",
      "button.reset": "Reset Password",
      "error": {
        "no-connection": "Could not connect to the Server, please try again later",
        "no-user": "Email entered is not found in the system",
        "default": "Could not connect to the Server, please try again later"
      },
      "email.sent": "Check your email for reset information"
    },
    "change-password": {
      "title": "Update Password",
      "placeholder.newPassword": "New Password",
      "placeholder.confirmPassword": "Confirm Password",
      "button.update": "Update Password",
      "password.hide": "Hide",
      "password.show": "Show"
    },
    "signup": {
      "title": "Let's get started",
      "subtitle": "Enter an email and password to create your Safesite profile",
      "label.email": "Email",
      "label.password": "Create Password (min 8 characters)",
      "placeholder.email": "Enter Email",
      "placeholder.password": "New Password",
      "label.terms.pretext": "By signing up you agree to our ",
      "label.terms.link": "Terms & Conditions",
      "button.signup": "Sign Up"
    },
    "signup-organization-set-up": {
      "industry.type": "Industry (NAICS Code)",
      "select.industry.type": "Select Industry",
      "label.organization": "Organization",
      "subtitle": "In order to personalize your experience, please enter more info (optional) below",
      "title": "Create Organization",
      "validation.orgNotBlank": "Organization name must not be blank."
    },
    "nav": {
      "side": {
        "label.certificates": "Certificates",
        "label.help_center": "Help Center",
        "label.join_webinar": "Webinars",
        "label.contact": "Support",
        "label.refresh": "Refresh",
        "label.tour": "Tour",
        "label.logout": "Log out"
      },
      "footer": {
        "main": {
          "Actions": "Actions",
          "Projects": "Projects",
          "Inbox": "Inbox",
          "SafetyHQ": "SafetyHQ"
        },
        "add": {
          "title": "New Safety Action",
          "inspection": "Conduct<br>Inspection",
          "hazard": "Record <br>Hazard",
          "meeting": "Conduct <br>Meeting",
          "equipment": "Inspect <br>Equipment",
          "observation": "Log <br>Observation",
          "incident": "Log <br>Incident"
        },
        "manage": {
          "title": "Manage Team Safety",
          "message.create_project": "Create your own project for access to SafetyHQ.",
          "message.non_org_admin": "Unfortunately you are not able to access SafetyHQ for any of your projects, please contact the manager of your Organization.",
          "message.not_your_org": "You are not able to access SafetyHQ for this project.",
          "button.create_project": "Create Your Project",
          "inspections": "Inspections",
          "hazards": "Hazards",
          "meetings": "Meetings",
          "equipment": "Equipment",
          "observations": "Observations",
          "incidents": "Incident <br> Reports"
        }
      }
    },
    "actions": {
      "index": {
        "title.hazards": "Hazards",
        "title.team.hazards": "My Team Hazards",
        "title.my.hazards": "My Created Hazards",
        "title.in_progress": "In Progress Items",
        "title.incomplete": "Incomplete",
        "title.actions_this_week": "Actions",
        "message.no_more_actions": "No More Actions.",
        "message.new_item_1": "Click the + button below",
        "message.new_item_2": "to start a new safety activity.",
        "cards": {
          "title.inspection": "Conduct Inspection",
          "title.hazard": "Record Hazard",
          "title.observation": "Log Observation",
          "title.meeting": "Conduct Meeting",
          "title.resolve": "Resolve Hazard",
          "title.announcement": "Announcement",
          "title.profile": "Complete your profile",
          "title.verify": "Verify your email",
          "title.safety-item": "Complete your first safety action",
          "text.project": "Project",
          "text.raised": "Raised By"
        },
        "empty-state": {
          "project": "Project ",
          "actions.title": "Great job completing your safety actions! Tap the + button to create more.",
          "incomplete.title": "Great job completing your incomplete actions!",
          "unresolved-hazards.title": "Great job resolving all your hazards!",
          "message.part1": "Improving safety is an important job that never really ends. Stay active and complete new safety actions as they arise by using the ",
          "message.part2": "button below"
        }
      },
      "text.overdue": "Overdue",
      "text.today": "Today",
      "text.left": "left"
    },
    "sites": {
      "title.main": "Your Projects",
      "message.empty": "It's time to create your first project",
      "message.non_org_admin": "Can't see your project? Contact your admin for access.",
      "button.join": "Join Project",
      "button.create": "Create Project",
      "button.invite": "Invite",
      "button.remove": "Leave",
      "create": {
        "label.name": "* Project Name",
        "label.address": "Address",
        "placeholder.name": "Enter Project Name",
        "placeholder.address": "Enter Project Address",
        "placeholder.search": "Search project name",
        "label.organization": "Select Organization",
        "button.join": "Join Project",
        "button.save": "Create",
        "message.project": "If the project does not belong to your organization you will need to contact the Safesite administrator of that organization",
        "title.create": "New Project",
        "title.join": "Join Existing Project",
        "site.name.already.exists": "This project name already exists",
        "site.successfully.created": "Project successfully created"
      },
      "invite-modal": {
        "title": "Invite to Project",
        "subtitle": "Invite personnel to join your project by entering their email below:",
        "button": "Invite",
        "invitation.sent.online.alert": "Your invitation has been sent",
        "invitation.sent.offline.alert": "Your invitation will be sent once you’re back online"
      },
      "leave-modal": {
        "title": "Are you sure? This will fully remove you from the project.",
        "button.yes": "Yes"
      }
    },
    "notifications": {
      "index": {
        "title": "Notifications",
        "filter.read": "Read",
        "filter.unread": "Unread",
        "message.empty": "No unread messages, looks like you're all caught up!",
        "button.read": "Mark as Read"
      }
    },
    "inspection": {
      "conduct": "Conduct Inspection",
      "index": {
        "title": "Inspection",
        "message.unresolved": "Unresolved Hazards Remaining",
        "message.completed": "Inspection Complete",
        "button.back": "Back",
        "button.export": "Export",
        "button.archive": "Archive Inspection",
        "heading.unresolved": "Unresolved Hazards",
        "heading.timeframe": "Timeframe",
        "heading.details": "Details",
        "heading.resolvedHazards": "Resolved Hazards",
        "heading.dateResolved": "Date Resolved",
        "heading.observations": "Safety Observations",
        "heading.answers": "Answers",
        "label.inspection_template": "Inspection Template",
        "label.location": "Location",
        "label.conductedOn": "Conducted On",
        "label.conductedBy": "Conducted By",
        "label.hazardLogged": "Hazard Logged",
        "label.observationLogged": "Observation Logged",
        "label.commentAdded": "Comment Added",
        "text.answered": "Answered",
        "text.question": "Question",
        "message.noComments": "No Comment Recorded"
      }
    },
    // Default - may be overridden by safety item specific strings
    "add-cocreator": {
      "title": "Cocreators",
      "button.add": "Add Cocreator",
      "no-cocreators": "No users added",
      "modal-title": "Select Cocreator"
    },
    "inspections": {
      "add-cocreator": {
        "title": "Conducted by",
        "button.add": "Add Person",
        "modal-title": "Select Personnel"
      },
      "create": {
        "error.missing-template": "Could not find template, please try again or contact support.",
        "heading": "Inspection Details",
        "label.location": "Select Location",
        "label.template": "Select Inspection Template",
        "label.location-modal": "Location",
        "label.template-modal": "Template",
        "button.complete": "Complete Inspection",
        "button.save": "Save Inspection",
        "button.archive": "Archive Inspection",
        "question-item": {
          "counter": "Question {{number}} of {{total}}",
          "placeholder.comment": "Enter comment",
          "button.add-observation": "Add Observation",
          "message.observation-added": "Observation Logged",
          "message.hazard-added": "Hazard Raised",
          "label.optional": "Optional",
          "label.comments": "Comments",
          "label.actions": "Actions",
          "placeholder.response": "Enter Response *",
          "label.required": "* = Required",
          "question_type.undefined.positive": " ",
          "question_type.undefined.negative": " ",
          "question_type.undefined.na": " ",
          "question_type.1.positive": "&#x2714;&#xfe0e;",
          "question_type.1.negative": "&#x2716;&#xfe0e;",
          "question_type.1.na": "NA",
          "question_type.2.positive": "Safe",
          "question_type.2.negative": "Unsafe",
          "question_type.2.na": "NA",
          "question_type.3.positive": "Pass",
          "question_type.3.negative": "Fail",
          "question_type.3.na": "NA",
          "question_type.4.positive": "Yes",
          "question_type.4.negative": "No",
          "question_type.4.na": "NA"
        },
        "section-item": {
          "footer.answers": " of ",
          "footer.questions": " questions completed"
        },
        "message.saved": "Saved!",
        "message.draftSaved": "Draft saved!",
        "message.answerAllQuestions": "Please complete questions",
        "message.sectionLabel": "Section ",
        "message.missLocation": "Please select a Location",
        "message.success": "Inspection Successfully Saved.",
        "message.completed": "Inspection Successfully Completed.",
        "message.observationAdded": "Observation Successfully Saved.",
        "message.hazardAdded": "Hazard Successfully Saved.",
        "message.recent": "Recent",
        "message.myOrg": "My Org",
        "templates-library": {
          "message.noRecentlyUsedTemplate": "You have no recently used templates yet, complete your first inspection.",
          "message.noTemplatesInYourOrg": "You have no templates in your Org’s library yet. Add some by having an admin log into the admin dashboard via app.safesitehq.com",
          "message.noResults": "No results to show."
        }
      },
      "index": {
        "title": "Inspections",
        "button.export": "Export",
        "button.open": "Open",
        "button.closed": "Closed",
        "button.all": "All",
        "button.person": "Person",
        "button.type": "Type",
        "button.filter": "Filter by",
        "heading.inspectionDetails": "Inspection Details",
        "heading.dateStarted": "Date Started",
        "heading.dateClosed": "Date Closed",
        "message.noOpenInspections": "No open inspections",
        "message.noClosedInspections": "No closed inspections",
        "text.conductedBy": "Conducted By",
        "text.type": "Type",
        "text.count": "Count"
      },
      "skip-modal": {
        "title": "Are you sure you want to skip this section?",
        "button.confirm": "Yes, Skip section",
        "label.questions": " Questions",
        "message": "Skipping a section will mark all answers in the section as NA",
        "skip.btn": "Skip"
      }
    },
    "meeting.conduct": "Conduct Meeting",
    "meeting.index": {
      "title": "Meeting",
      "heading.unresolvedHazardsCount": "Unresolved Hazards Remaining",
      "heading.unresolvedHazards": "Unresolved Hazards",
      "heading.timeframe": "Timeframe",
      "heading.details": "Details",
      "heading.resolvedHazards": "Resolved Hazards",
      "heading.dateResolved": "Date Resolved",
      "heading.safetyObservations": "Safety Observations",
      "heading.answers": "Answers",
      "heading.attendees": "Attendees",
      "heading.photos": "Photos",
      "label.location": "Location",
      "label.conductedBy": "Conducted By",
      "label.conductedOn": "Conducted On",
      "label.topic": "Topic",
      "label.status": "Status",
      "label.hazardLogged": "Hazard Logged",
      "label.observationsLogged": "Observation Logged",
      "label.comment": "Comment",
      "button.back": "Back",
      "button.export": "Export",
      "button.archive": "Archive Meeting"
    },
    "meetings": {
      "add-cocreator": {
        "title": "Conducted by",
        "button.add": "Add Person",
        "modal-title": "Select Personnel"
      },
      "conduct": "Conduct Meeting",
      "addAttendees": "Add Meeting Attendees",
      "index": {
        "title": "Meetings",
        "button.export": "Export",
        "button.open": "Open",
        "button.closed": "Closed",
        "button.all": "All",
        "button.person": "Person",
        "button.topic": "Topic",
        "button.filter": "Filter by",
        "heading.meetingDetails": "Meeting Details",
        "heading.dateStarted": "Date Started",
        "heading.dateClosed": "Date Closed",
        "message.noOpenMeetings": "No open meetings",
        "message.noClosedMeetings": "No closed meetings",
        "text.conductedBy": "Conducted By",
        "text.topic": "Topic",
        "text.count": "Count"
      },
      "create": {
        "error.missing-template": "Could not find template, please try again or contact support.",
        "title": "Meeting Details",
        "label.location": "Select Location",
        "label.topic": "Select Meeting Topic",
        "button.finish": "Finish & Add Attendees",
        "button.archive": "Archive Meeting",
        "topic": {
          "heading": "Topic {{number}} of     {{total}}",
          "message.optional": "Optional",
          "message.actions": "Actions",
          "placeholder.comment": "Enter comment",
          "placeholder.response": "Enter Response",
          "button.save": "SAVE",
          "message.obs_logged": "Observation Logged",
          "message.haz_raised": "Hazard Raised",
          "message.comment_added": "Comment Added"
        },
        "message.saved": "Saved!",
        "message.draftSaved": "Draft saved!",
        "message.answerAllTopics": "Please answer all topics",
        "message.missLocation": "Please select a Location",
        "message.success": "Meeting Successfully Saved.",
        "message.pleaseStart": "Please start a new meeting to change meeting topic",
        "add-attendees": {
          "button.addAttendee": "Add Attendee",
          "button.finish": "Finish",
          "heading.photos": "Add Photos",
          "heading.attendees": "Attendees Added",
          "heading.select": "Select Attendees",
          "message.uploadSignatures": "Upload a photo of signature sheet or attendees if you are unable to obtain digital signatures.",
          "message.missingSignatures": "Add all signatures or photo of signature sheet before completing meeting.",
          "message.missingPhotoAndAttendees": "Add attendees and photos before completing meeting.",
          "message.missingAttendees": "Add attendees before completing meeting.",
          "message.missingPhotos": "Add photos before completing meeting."
        },
        "templates-library": {
          "message.noRecentlyUsedTemplate": "You have no recently used templates yet, complete your first meeting."
        }
      }
    },
    "hazards": {
      "add-cocreator": {
        "title": "Recorded by",
        "button.add": "Add Person",
        "modal-title": "Select Personnel"
      },
      "index": {
        "title": "Hazards",
        "button.export": "Export",
        "button.unresolved": "Unresolved",
        "button.resolved": "Resolved",
        "button.all": "All",
        "button.team": "Team",
        "button.location": "Location",
        "button.filter": "Filter by",
        "heading.hazardDetails": "Hazards Details",
        "heading.timeframe": "Timeframe",
        "heading.dateResolved": "Date Resolved",
        "message.noResolvedHazards": "No resolved hazards for this timeframe",
        "message.noUnresolvedHazards": "No unresolved hazards for this timeframe",
        "text.location": "Location",
        "text.team": "Team",
        "text.count": "Count"
      }
    },
    "hazard": {
      "add": "Record Hazard",
      "index": {
        "heading.details": "Hazard Details",
        "heading.equipment": "Related Equipment",
        "heading.comments": "Comments",
        "heading.location": "Location",
        "heading.risk": "Risk Level",
        "button.back": "Back",
        "button.edit": "Edit",
        "button.export": "Export",
        "button.resolve": "Resolve Hazard",
        "button.archive": "Archive Hazard",
        "button.markResolved": "Mark as resolved",
        "text.hazard": "Hazard",
        "text.unresolved": "Unresolved",
        "text.resolved": "Resolved",
        "label.task": "Activity being performed",
        "label.root": "Root Cause",
        "label.riskType": "Risk Type",
        "label.riskProbability": "Risk Probability",
        "label.riskConsequence": "Risk Consequence",
        "label.riskLevel": "Computed Risk Level",
        "label.recommendedAction": "Recommended Action",
        "label.description": "Description",
        "label.logged": "Recorded by",
        "label.responsibleParty": "Responsible Team",
        "label.location": "Location",
        "label.sublocation": "Sub-Location",
        "label.additionParties": "Additional Notified Team",
        "label.name": "Name",
        "label.number": "Number",
        "label.team": "Team",
        "label.Email": "Email",
        "message.resolved": "Hazard successfully resolved",
        "message.not_resolvable": "Hazard can't be resolved until a comment, image or corrective action is attached",
        "message.mark_resolvable": "Hazard is 'Marked for Resolving'",
        "message.not_archivable": "Hazard can't be archived until a comment is added explaining why you archived."
      },
      "edit": {
        "title.details": "Details",
        "title.images": "Add Images",
        "title.team": "Responsible Team",
        "title.location": "Location - Drag marker icon to desired location",
        "title.riskAssessment": "Hazard & Risk Assessment",
        "title.rootCause": "Root Cause Analysis (optional)",
        "title.description": "Description",
        "title.correctiveAction": "Corrective Action",
        "title.correctiveActions": "Description and Corrective Action",
        "title.riskLevel": "Computed Risk Level",
        "label.recommendedActions": "Recommended Action",
        "label.team": "Select Team",
        "label.location": "Select Location",
        "label.riskType": "Select Risk Type",
        "label.riskProbability": "Select Risk Probability",
        "label.riskConsequence": "Select Risk Consequence",
        "label.task": "Select Activity being Performed",
        "label.rootCause": "Select Root Cause",
        "button.update": "Update Hazard",
        "button.record": "Record Hazard",
        "button.resolve": "Resolve Hazard",
        "button.cancel": "Cancel",
        "validation.notBlank": "Must not be blank.",
        "message.success": "Hazard Successfully Saved.",
        "message.resolved": "Hazard Successfully Resolved.",
        "message.mandatory": "Please fill in mandatory fields"
      }
    },
    "observation": {
      "add": "Log Observation",
      "index": {
        "heading.details": "Observation Details",
        "button.back": "Back",
        "button.edit": "Edit",
        "button.export": "Export",
        "button.archive": "Archive Observation",
        "label.description": "Description",
        "label.loggedBy": "Recorded by",
        "label.location": "Select Location",
        "label.team": "Team",
        "text.safe": "Positive",
        "text.risk": "Negative",
        "text.observation": "Observation",
        "text.on": "on"
      },
      "nature": "Type"
    },
    "observations": {
      "add-cocreator": {
        "title": "Recorded by",
        "button.add": "Add Person",
        "modal-title": "Select Personnel"
      },
      "create": {
        "title": "Observation Details",
        "label.safe": "Positive",
        "label.risk": "Negative",
        "label.location": "Select Location",
        "label.team": "Select Team (optional)",
        "label.resolved": "Resolved",
        "placeholder.description": "Description",
        "button.record": "Record Observation",
        "button.update": "Update Observation",
        "button.cancel": "Cancel",
        "message.success": "Observation Successfully Saved.",
        "message.validation": "Please fill in mandatory fields",
        "validation.locationNotBlank": "Please select a location.",
        "validation.descriptionBlank": "Observation can’t be completed without a description.",
        "validation.descriptionShort": "Description needs to be at least two words."
      },
      "index": {
        "title": "Observations",
        "button.safe": "Positive",
        "button.risk": "Negative",
        "button.all": "All",
        "button.team": "Team",
        "button.location": "Location",
        "button.export": "Export",
        "button.filter": "Filter By",
        "heading.observationDetails": "Observation Details",
        "heading.dateCreated": "Date Created",
        "heading.dateResolved": "Date Resolved",
        "heading.backToWork": "Back to work ETA",
        "message.noSafeObservations": "No Safe Observations",
        "message.noRiskObservations": "No Risk Observations",
        "text.count": "Count",
        "text.location": "Location",
        "text.team": "Team"
      }
    },
    "incident.index": {
      "title": "Incident Report",
      "button.back": "Back",
      "button.edit": "Edit",
      "button.export": "Export",
      "button.archive": "Archive Incident Report",
      "heading.incident": "Incident Details",
      "heading.rootAnalysis": "Root Cause Analysis",
      "heading.hazards": "Root Cause Hazards",
      "heading.comments": "Comments",
      "label.type": "Type",
      "label.location": "Location",
      "label.dateTime": "Incident Date/Time",
      "label.task": "Task Being Completed",
      "label.root": "Root Cause",
      "label.correctiveAction": "Corrective Action Description",
      "label.resolved": "Resolved",
      "label.logged": "Conducted by"
    },
    "incidents": {
      "add-cocreator": {
        "title": "Conducted by",
        "button.add": "Add Person",
        "modal-title": "Select Personnel"
      },
      "types.INJ": "Injury or Illness",
      "types.NMS": "Near Miss",
      "types.DMG": "Property Damage",
      "types.TFT": "Theft",
      "types.EQF": "Equipment Failure",
      "add": "Log Incident",
      "index": {
        "title": "Incident Reports",
        "button.open": "Open",
        "button.closed": "Closed",
        "button.all": "All",
        "button.type": "Type",
        "button.location": "Location",
        "button.filter": "Filter By",
        "heading.incidentReport": "Incident Report Details",
        "heading.dateCreated": "Date Created",
        "heading.dateResolved": "Date Resolved",
        "text.location": "Location",
        "text.type": "Type",
        "text.count": "Count",
        "message.noOpenIncidents": "No Open Incident Reports",
        "message.noClosedIncidents": "No Closed Incident Reports"
      },
      "create": {
        "heading.incidentReport": "Incident Report Details",
        "title.description": "Description",
        "title.rootCauseAnalysis": "Root Cause Analysis",
        "title.rootCauseHazard": "Root Cause Hazards",
        "title.comments": "Comments",
        "title.injuries": "Injuries",
        "title.nearMiss": "Near Miss",
        "title.propertyDamage": "Property Damage",
        "label.incidentType": "Incident Type",
        "label.location": "Select Location",
        "label.incidentDate": "Set Incident Date",
        "label.incidentTime": "Incident Time",
        "label.task": "Select Activity being performed",
        "label.resolved": "Resolved",
        "label.rootCause": "Select Root Cause",
        "label.potentialRisk": "Select Risk Type",
        "label.incidentType.options": "Incident Types (select all that apply)",
        "label.equipmentFailure": "Equipment Failure",
        "label.equipmentFailure.description": "Failure Description",
        "label.equipmentTheft": "Theft",
        "label.equipmentTheft.description": " Theft Description",
        "label.injuries": "Injury or Illness Description",
        "label.nearMiss": "Near Miss Description",
        "label.propertyDamage.owner": "Property Owner",
        "label.propertyDamage.description": "Damage Description",
        "label.propertyDamage.cause": "Cause of Damage",
        "placeholder.description": "Incident Description",
        "placeholder.correctiveAction": "Corrective Action Description",
        "placeholder.equipmentFailure.description": "What happened?",
        "placeholder.equipmentTheft.description": "What happened?",
        "placeholder.injuries.description": "What happened?",
        "placeholder.propertyDamage.description": "What was the Damage?",
        "placeholder.propertyDamage.cause": "What was the Cause?",
        "button.addHazard": "Add Root Cause Hazard",
        "button.save": "SAVE",
        "button.log": "Log Incident",
        "button.savedraft": "Save as Draft",
        "button.update": "Update Incident",
        "button.archive": "Archive Incident",
        "button.cancel": "Cancel",
        "message.saved": "Saved!",
        "message.draftSaved": "Draft saved!",
        "message.success": "Incident Report Successfully Saved.",
        "message.descriptionAdded": "Description added.",
        "message.correctiveDescription": "Corrective Action Description added.",
        "message.error": "An error has occurred",
        "injury": {
          "title": "Attach Injury or Illness",
          "label.type": "Select Injury or Illness Type",
          "label.bodyPart": "Select Body Part",
          "label.treatment": "Select Treatment Type",
          "placeholder.treatment": "Treatment Description",
          "message.treatment": "Note: Supervisor to complete treatment details in desktop portal.",
          "button.done": "Done"
        },
        "witness": {
          "title": "Witnesses",
          "button.add": "Add Witness",
          "modal": {
            "title": "Attach Witness",
            "placeholder.statement": "Enter Statement...",
            "button.done": "Done"
          }
        },
        "add-equipment": {
          "title": "Equipment Involved",
          "title_singular": "Equipment Involved",
          "button.add": "Add Equipment",
          "label.equipment": "Equipment",
          "modal": {
            "label.or": "OR",
            "label.results": "Project's Equipment",
            "button.add": "Add New Equipment"
          }
        }
      }
    },
    "equipment": {
      "index": {
        "button.back": "Back",
        "button.edit": "Edit",
        "button.export": "Export",
        "button.cancel": "Cancel",
        "button.delete": "Delete",
        "button.yes": "Yes",
        "button.no": "No",
        "button.archive": "Archive Equipment",
        "button.createInspection": "Create New Inspection",
        "button.createHazard": "Create New Hazard",
        "heading.owner": "Owner",
        "heading.inspections": "Inspections",
        "heading.inspection": "Inspection Details",
        "heading.dateStarted": "Date Started",
        "heading.dateClosed": "Date Closed",
        "heading.maintenance": "Maintenance",
        "heading.comments": "Comments",
        "title": "Equipment",
        "label.team": "Team",
        "label.location": "Location",
        "label.type": "Type",
        "label.make": "Make",
        "label.model": "Model",
        "text.hazards": "Hazards",
        "message.noInspections": "No Inspections",
        "message.noHazards": "No Hazards",
        "text.hazard.unresolvedDetails": "Unresolved Hazards Details",
        "text.hazard.resolvedDetails": "Resolved Hazards Details",
        "text.details": "Details",
        "label.serial": "Serial Number",
        "label.lastTestDate": "Last Inspection Date",
        "label.testFreq": "Inspection Frequency",
        "label.nextTestDate": "Next Inspection Date"
      }
    },
    "equipments": {
      "manage": "Register Equipment",
      "create": {
        "title.project": "Project",
        "title.owner": "Equipment Owner",
        "title.location": "Location",
        "title.description": "Description",
        "title.maintenance": "Maintenance",
        "label.project": "Select Project",
        "label.team": "Select Team",
        "label.location": "Select Location",
        "label.serial": "Serial Number",
        "label.eq_type": "Select Equipment Type",
        "label.make": "Select Make",
        "label.model": "Select Model",
        "label.last_date_date": "Set Last Inspection Date",
        "label.test_frequency": "Set Inspection Frequency",
        "label.test_date": "Next Inspection Date",
        "message.location": "Drag the icon marker to desired location",
        "button.update": "Update Equipment",
        "button.register": "Register Equipment",
        "button.cancel": "Cancel",
        "message.success": "Equipment Successfully Saved.",
        "message.mandatory": "Please fill in mandatory fields"
      },
      "index": {
        "title": "Equipment Registry",
        "button.all": "All",
        "button.team": "Team",
        "button.type": "Type",
        "button.export": "Export",
        "button.filter": "Filter By",
        "heading.itemDetails": "Item Details",
        "heading.nextService": "Next Service",
        "message.noEquipment": "No equipment in registry",
        "placeholder.search": "Search..."
      }
    },
    "certificate": {
      "index": {
        "button.back": "Back",
        "button.edit": "Edit",
        "button.export": "Export",
        "button.cancel": "Cancel",
        "button.delete": "Delete",
        "button.yes": "Yes",
        "button.no": "No",
        "button.archive": "Archive Certificate",
        "title": "Certificate",
        "title.details": "Certificate Details",
        "title.upload": "Upload Photo(s)",
        "text.details": "Details",
        "label.name": "Certificate Name or Type",
        "label.issuing_body": "Issuing body (optional)",
        "label.additional_notes": "Additional Notes",
        "label.date_issued": "Issued Date",
        "label.date_expires": "Expiry Date"
      }
    },
    "certificates": {
      "manage_create": "Add New Certificate",
      "manage_edit": "Edit Certificate",
      "create": {
        "title.project": "Project",
        "title.description": "Description",
        "title.details": "Certificate Details",
        "title.upload": "Upload Photo(s)",
        "button.save": "Save Certificate",
        "button.update": "Update Certificate",
        "button.addnew": "Add New",
        "button.cancel": "Cancel",
        "message.success": "Certificate Successfully Saved.",
        "message.mandatory": "Please fill in mandatory fields",
        "label.name": "Certificate Name or Type*",
        "label.issuing_body": "Issuing body",
        "label.additional_notes": "Additional Notes",
        "label.date_issued": "Set Issue Date",
        "label.date_expires": "Set Expiry Date",
        "placeholder": {
          "label.name": "Enter Certificate or Vaccine card",
          "label.issuing_body": "OSHA, CDC, etc.",
          "label.additional_notes": "Enter further notes or description"
        }
      },
      "index": {
        "title": "Certificates",
        "button.all": "All",
        "button.issued": "Issued",
        "button.expiry": "Expiry",
        "button.filter": "Filter By",
        "label.issued": "Issued",
        "label.expiry": "Expiry",
        "message.noCertificate": "No certificate in registry",
        "placeholder.search": "Search..."
      }
    },
    "modals": {
      "dropdown": {
        "text.pleaseSelect": " ",
        "text.searchOrAdd": "Search or add new...",
        "text.search": "Search...",
        "label.addNew": "Add New",
        "errors.fail": "Dropdown Controller failed to save record",
        "errors.team": "Failed to save Team",
        "errors.location": "Failed to save Location",
        "errors.equipmentType": "Failed to save Equipment Type",
        "errors.make": "Failed to save Make",
        "errors.model": "Failed to save Model"
      },
      "dropdown-multiple": {
        "text.pleaseSelect": " ",
        "text.search": "Search...",
        "text.removed_item": "Removed Item",
        "text.added_item": "Added Item",
        "title.add-new-item": "or Add New",
        "text.no-results": "No results"
      },
      "archive-item": {
        "button.yes": "Yes, Archive",
        "button.no": "No, Cancel",
        "message.archiveConfirm": "Are you sure you want to archive this",
        "text.archived": "Archived",
        "text.failedArchive": "Failed to Archived"
      },
      "exit-item": {
        "title": "Do you want to save and come back to this later?",
        "button.yes_save": "Yes, Save",
        "button.cancel": "Cancel",
        "button.save_later": "Yes, Save for later",
        "button.no": "No, Discard item",
        "text.saved": "Saved",
        "text.archived": "Archived",
        "text.failedArchive": "Failed to Archive",
        "text.failedSave": "Failed to Save"
      },
      "dropdown-site": {
        "title": "All Projects"
      },
      "exit-new-item-mode": {
        "title": "Are you sure you want to leave this Safety Item?",
        "button.yes": "Yes"
      },
      "select-site": {
        "title": "All Projects",
        "subtitle": "Please Select Project",
        "button.select": "Select",
        "placeholder.search": "Search..."
      },
      "select-project": {
        "title": "Select Project"
      },
      "hazard-create": {
        "title": "Raise New Hazard",
        "message.location": "Location - Drag marker icon to desired location",
        "message.description": "Description and corrective action",
        "label.team": "Select Team",
        "label.location": "Select Location",
        "label.riskType": "Select Risk Type",
        "label.riskProbability": "Select Risk Probability",
        "label.riskConsequence": "Select Risk Consequence",
        "button.save": "Record Hazard",
        "button.resolve": "Resolve Hazard"
      },
      "observation-create": {
        "title": "Log Observation",
        "label.safe": "Positive",
        "label.risk": "Negative",
        "label.location": "Select Location",
        "label.team": "Select Team (optional)",
        "placeholder.description": "Description",
        "button.save": "Save"
      },
      "announcement-modal": {
        "title": "Announcement",
        "button.ack": "Acknowledge"
      },
      "location-permission": {
        "title": "Location Services Disabled",
        "subtitle": "Please enable location services so you can pinpoint where your safety actions take place.",
        "footer": "We do not track people or share your data. We only use location data for safety items.",
        "contact_support": "If the problem continues, read this <a href='http://help.safesitehq.com/en/articles/4531342-location-services' target='_blank'>HelpCenter article</a> or <a href='mailto:success@safesitehq.com'>Contact support</a>.",
        "error.title": "Please enable Location service",
        "error.message": "Safesite was not able to get access to your location"
      },
      "feature-disabled": {
        "title": "Feature disabled while offline"
      }
    },
    "userprofile": {
      "title": "Profile",
      "label.photo.add": "Add Photo",
      "label.photo.edit": "edit",
      "label.button.add": "add",
      "label.button.edit": "edit",
      "label.button.editName": "edit name",
      "label.button.update": " update",
      "label.button.cancel": "cancel ",
      "label.firstName": "First Name",
      "placeholder.firstName": "Enter First name (required)",
      "label.lastName": "Last Name",
      "placeholder.lastName": "Enter Last name (required)",
      "label.email": "Email",
      "placeholder.phone": "Enter phone number",
      "label.phone": "Phone number",
      "label.organization": "Organization",
      "label.organizations": "Organizations",
      "label.appVersion": "App Version",
      "label.defaultLanguage": "Preferred Language",
      "label.updateApp": "Update App"
    },
    "contact-us": {
      "title": "Get in touch",
      "message.feedback": "Your feedback is important to us and we will get back to you as soon as we possibly can.",
      "message.successfully.sent": "Message successfully sent.",
      "message.error": "An error has occurred, please try again",
      "placeholder.message": "Message",
      "button.send": "Send"
    },
    "errors": {
      "network": "A network error occurred! Please try again or contact support."
    },
    "components": {
      "image-upload": {
        "take-picture": "Take Picture",
        "from-gallery": "Choose from Gallery"
      },
      "item-comments": {
        "message.noComment": "No comments recorded",
        "button.add": "Add Comment",
        "placeholder.comment": "Enter a comment..."
      },
      "modal-dialog": {
        "button.cancel": "Cancel"
      },
      "upload": {
        "text.uploading": "Uploading..."
      },
      "custom-dropdown-button": {
        "text.select": "Select"
      },
      "date-field": {
        "text.set": "Set"
      },
      "time-field": {
        "text.set": "Set"
      },
      "loading-btn": {
        "disabled.offline.message": "disabled while offline"
      },
      "add-injury": {
        "label": "Add Injury or Illness",
        "body-parts": {
          "heading": "Body Parts Added",
          "select-modal": {
            "title.select": "Select Body Parts"
          }
        }
      },
      "add-person": {
        "title": "Persons Involved",
        "title_singular": "Person Involved",
        "button.add": "Add Person",
        "placeholder.search": "Search...",
        "label.person": "Person",
        "label.add_existing": "Add Existing Person",
        "label.create_new": "Create New Person",
        "modal": {
          "label.or": "OR",
          "label.addNew": "Add New",
          "label.results": "Results",
          "placeholder.jobTitle": "Job Title",
          "placeholder.firstName": "First Name",
          "placeholder.lastName": "Last Name",
          "placeholder.email": "Email (optional)",
          "placeholder.phone": "Contact Number",
          "placeholder.company": "Company Name",
          "label.invite_to_project": "Invite to Project",
          "button.add": "Add New Person"
        }
      }
    },
    "meeting-item": {
      "text.incomplete": "Incomplete",
      "text.pendingHazards": "Pending Hazards",
      "text.closedHazards": "Closed Hazards"
    },
    "incident-item": {
      "text.incomplete": "Incomplete",
      "text.pendingHazards": "Pending Hazards",
      "text.closedHazards": "Closed Hazards"
    },
    "inspection-item": {
      "text.incomplete": "Incomplete",
      "text.pendingHazards": "Pending Hazards",
      "text.closedHazards": "Closed Hazards"
    },
    "open-hazard-item": {
      "title": "Resolve Hazard"
    },
    "logout-confirm": {
      "title": "Are you sure you want to log out?",
      "button.yes": "Yes",
      "button.forceLogout": "Yes, force log out",
      "subtitle.message.offline": "You are currently offline, be aware that if you logout while offline you might lose data that hasn't been synced yet.",
      "subtitle.message.unableToLogout": "We’re unable to connect to our servers at this moment. Be aware, if you logout while offline you might lose data that hasn’t been synced yet.",
      "message.error": "An error has occurred, please try again or contact support."
    },
    "custom-dropdown": {
      "button.cancel": "Cancel",
      "button.done": "Done"
    },
    "signup-steps": {
      "title": "Sign up",
      "step1": {
        "title": "Let's get started",
        "info": "Please enter your email address below",
        "placeholder.email": "Email",
        "button.continue": "Continue",
        "button.cancel": "Cancel"
      },
      "step2": {
        "title": "Profile",
        // step2.info is not being used.
        "info": "We need to send you a quick email to confirm your email address. Please enter below",
        "placeholder.password": "Password",
        "placeholder.password.confirm": "Confirm password",
        "placeholder.firstName": "First Name",
        "placeholder.lastName": "Last Name",
        "placeholder.company": "Company Name",
        "placeholder.phoneNumber": "Phone Number",
        "button.continue": "Continue",
        "button.cancel": "Cancel"
      },
      "step3": {
        "title": "Company",
        "not-found-subtext1": "We couldn't find any organizations assigned to this email.",
        "not-found-subtext2": "Let's create your own organization, enter the name below",
        "placeholder.company": "Name of your company",
        "found-subtext1": "Great news, we already have your company in our system",
        "found-subtext2": "Let's keep going",
        "button.continue": "Continue",
        "button.cancel": "Cancel"
      },
      "step4": {
        "title": "Your Projects",
        "subtext1": "Missing a project? Ask your manager or contact customer support upon completing sign up",
        "projects-subtext1": "Your current projects",
        "button.continue": "Continue",
        "button.cancel": "Close"
      },
      "step5": {
        "title": "Check your Email to verify",
        "subtext1": "To confirm your email address, open your email via your mobile device and tap the button in your email",
        "button.resend": "Resend Email",
        "button.cancel": "Close"
      }
    },
    "project-invite": {
      "title": "Now let's get started",
      "org-projects-text-part1": "See the",
      "org-projects-text-part2": "projects below you are part of",
      "button.continue": "Continue",
      "button.complete": "Complete",
      "button.cancel": "Close",
      "placeholder.email": "Email",
      "placeholder.password": "Create Password",
      "placeholder.password.confirm": "Confirm Password",
      "placeholder.firstName": "First Name",
      "placeholder.lastName": "Last Name",
      "placeholder.company": "Company Name",
      "placeholder.phoneNumber": "Phone Number (optional)"
    },
    "validate-email": {
      "title": "Verify your email",
      "text-part1": "We sent a verification email to",
      "text-part2": "Haven't received your email? Try sending again",
      "button.resend": "Resend Email",
      "button.cancel": "Close"
    },
    "verify-email": {
      "title": "Verify Email",
      "text-part1": "Verify your email address by clicking the activation link we sent to you",
      "text-part2": "Validating......",
      "button.resend": "Resend Email",
      "button.cancel": "Close"
    },
    "tour": {
      "title": "Welcome to Safesite!",
      "body": "Check out the video below that walks you through the full Safesite offering.",
      "button.done": "Get Started",
      "video.src": "https://player.vimeo.com/video/351499752?autoplay=0&title=0",
      "message.offline": "Tour video is not available offline"
    },
    "offline-mode": {
      "message.offline": "You're offline but you can keep using Safesite. We'll sync your data once you're back online.",
      "message.syncing": "Syncing..."
    },
    "default-form-messages": {
      "enter.valid.email": "Please enter a valid email address",
      "enter.valid.mobile.number": "Please enter a valid mobile phone number",
      "enter.valid.email.or.mobile.number": "Please enter a valid email address or mobile number",
      "enter.valid.first_last_names": "Please enter first and last names",
      "enter.valid.email_to_invite_project": "Enter email to Invite to Project",
      "enter.valid.fill_in_all_fields": "Please fill in all fields",
      "results": "Results"
    },
    "release-notes": {
      "title": "Safesite now works offline",
      "message": "Safesite offline is here! If you happen to go offline we'll store any changes on your device and sync once back online.<br /> Read more on our <a href='https://help.safesitehq.com/en/articles/4340586-using-the-app-while-offline-coming-soon' target='_blank'>HelpCenter</a>."
    },
    "add-signature-btn": {
      "text": "Add Signature"
    },
    "add-signature-modal": {
      "title": "Add Signature",
      "instruction": "Sign above dotted line",
      "clear": "clear",
      "save": "Done"
    }
  };
  _exports.default = _default;
});