define("safesite/routes/inspection/index", ["exports", "jquery", "safesite/routes/restricted", "safesite/mixins/export"], function (_exports, _jquery, _restricted, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var InspectionIndexRoute = _restricted.default.extend(_export.default, {
    controllerModel: null,
    report_url: null,
    afterModel: function afterModel(model) {
      return model.reload();
    },
    setupController: function setupController(controller, model) {
      this.set('report_url', 'inspection/' + model.get('id'));
      controller.set('model', model);
      this.set('controllerModel', model);
    },
    actions: {
      showHazard: function showHazard(hazard) {
        this.send('renderModal', {
          controllerPath: 'modals/hazard-index',
          controllerProperties: {
            model: hazard
          }
        });
      },
      openObservation: function openObservation(observation) {
        this.send('renderModal', {
          controllerPath: 'modals/observation-create',
          controllerProperties: {
            model: observation
          }
        });
      },
      saveObservation: function saveObservation() {
        var self = this;
        this.controllerFor('modals/observation-create').send('save', function () {
          Ember.run.schedule('afterRender', function () {
            self.woof.success('Observation Successfully Saved.');
          });
        });
      },
      closeModal: function closeModal() {
        (0, _jquery.default)('.modal').modal('hide');
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      saveHazard: function saveHazard() {
        this.controllerFor('modals/hazard-index').send('save');
      },
      archiveModal: function archiveModal() {
        this.send('renderModal', {
          controllerPath: 'modals/archive-item',
          controllerProperties: {
            itemName: 'Inspection',
            postArchiveRoute: 'actions.index',
            archiveItem: this.controllerModel
          }
        });
      }
    }
  });

  var _default = InspectionIndexRoute;
  _exports.default = _default;
});