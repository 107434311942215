define("safesite/components/footer-menu", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // This forces actions to bubble up to application route.
    // This also may break in the future pleae watch.
    target: Ember.computed.alias("targetObject"),
    metrics: Ember.inject.service(),
    // Enable event  service to trigger the activity menu
    eventBusService: Ember.inject.service('event-bus'),
    listen: Ember.on('init', function () {
      this.eventBusService.on('footer', this, 'triggerFooterMenu');
      this.eventBusService.on('closefooter', this, 'triggerCloseOpened');
    }),
    triggerFooterMenu: function triggerFooterMenu() {
      this.send('toggleActivities');
    },
    triggerCloseOpened: function triggerCloseOpened() {
      if (this.showActivities) {
        this.send('toggleActivities');
      }

      if (this.showModules) {
        this.send('toggleModules');
      }
    },
    // remember to remove what you bind upon component destruction
    cleanup: Ember.on('willDestroyElement', function () {
      this.eventBusService.off('footer', this, 'triggerFooterMenu');
      this.eventBusService.off('closefooter', this, 'triggerCloseOpened');
    }),
    myRouteName: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName');
    }),
    showActivities: false,
    showModules: false,
    notifCount: Ember.computed('selectedProject.id', 'session.model.received_notifications.@each.read', 'session.model.received_notifications.[]', function () {
      if (Ember.isPresent(this.selectedProject) && this.session.model && Ember.isPresent(this.session.model.received_notifications)) {
        return this.session.get('model.received_notifications').filterBy('read', false).filterBy('site.id', this.selectedProject.id).length;
      } else {
        if (Ember.isPresent(this.session.get('model'))) {
          return this.session.get('model.received_notifications').filterBy('read', false).length;
        }
      }
    }),
    isActionActive: Ember.computed('myRouteName', function () {
      var routeName = this.myRouteName;
      var activeRouteNames = ['sites/actions.index', 'actions.index'];
      return activeRouteNames.includes(routeName);
    }),
    isProjectActive: Ember.computed('myRouteName', function () {
      var routeName = this.myRouteName;
      var activeRouteNames = ['sites.index', 'sites.create'];
      return activeRouteNames.includes(routeName);
    }),
    isInboxActive: Ember.computed('myRouteName', function () {
      var routeName = this.myRouteName;
      var activeRouteNames = ['sites/notifications.index', 'notifications.index'];
      return activeRouteNames.includes(routeName);
    }),
    isHqActive: Ember.computed('myRouteName', function () {
      var routeName = this.myRouteName;
      var activeRouteNames = ['hazards.index', 'inspections.index', 'observations.index', 'equipments.index', 'incidentreports.index', 'laborstats.index', 'hazard.index', 'hazard.edit', 'inspection.index', 'observation.index', 'incidentreports.index', 'laborstat.index', 'meeting.index', 'hazards.create', 'inspections.create', 'inspections.index', 'observations.create', 'observations.index', 'equipments.index', 'equipments.create', 'equipments.index', 'equipment.edit', 'incidentreports.create', 'incidentreports.index', 'laborstats.create', 'laborstats.index', 'meetings.index', 'meetings.create', 'meetings.edit', 'meeting.add-attendees', 'equipment.index', 'hazard.index', 'incidentreport.edit', 'observation.edit', 'meeting.edit', 'inspection.edit', 'sites/hazards.index', 'sites/inspections.index', 'sites/observations.index', 'sites/meetings.index', 'sites/incidentreports.index', 'sites/equipments.index'];
      return activeRouteNames.includes(routeName);
    }),
    navMap: {
      'site.index': 'sites',
      'userprofile': 'index',
      'sites.index': 'index',
      'hazards.index': 'actions.index',
      'inspections.index': 'actions.index',
      'observations.index': 'actions.index',
      'equipments.index': 'actions.index',
      'incidentreports.index': 'actions.index',
      'laborstats.index': 'actions.index',
      'meetings.index': 'actions.index',
      'notifications.index': 'actions.index',
      'hazard.index': 'hazards.index',
      'inspection.index': 'inspections.index',
      'observation.index': 'observations.index',
      'equipment.index': 'equipments.index',
      'incidentreport.index': 'incidentreports.index',
      'laborstat.index': 'laborstats.index',
      'meeting.index': 'meetings.index',
      'hazards.create': 'hazards.index',
      'inspections.create': 'inspections.index',
      'observations.create': 'observations.index',
      'equipments.create': 'equipments.index',
      'incidentreports.create': 'incidentreports.index',
      'laborstats.create': 'laborstats.index',
      'meetings.create': 'meetings.index',
      'equipment.edit': 'equipment.index',
      'hazard.edit': 'hazard.index',
      'incidentreport.edit': 'incidentreport.index',
      'observation.edit': 'observation.index',
      'meeting.edit': 'meetings.index',
      'inspection.edit': 'inspections.index',
      'meeting.add-attendees': 'meeting.edit',
      'sites/hazards.index': 'sites/actions.index',
      'sites/inspections.index': 'sites/actions.index',
      'sites/observations.index': 'sites/actions.index',
      'sites/meetings.index': 'sites/actions.index',
      'sites/incidentreports.index': 'sites/actions.index',
      'sites/equipments.index': 'sites/actions.index',
      'sites/notifications.index': 'sites/actions.index'
    },
    showBackground: Ember.computed('showActivities', 'showModules', function () {
      if (this.showActivities || this.showModules) {
        return true;
      }

      return false;
    }),
    actions: {
      navBack: function navBack() {
        this.send('closeContainers');
        var navMap = this.navMap;
        var route = navMap[this.application.get('currentRouteName')];

        if (route === 'back') {
          window.history.back();
        } else {
          this.application.send('redirectTo', route);
        }

        this.sendAction('close');
      },
      goToProjects: function goToProjects() {
        this.metrics.trackEvent({
          event: 'm_Project_YourProjects'
        });
        this.application.send('transition', 'sites.index');
        this.send('closeContainers');
      },
      navigate: function navigate(route, siteRoute) {
        this.send('closeContainers');

        if (Ember.isEmpty(this.get('siteController.model'))) {
          this.sendAction('transition', route);
        } else {
          this.sendAction('transition', siteRoute, this.get('siteController.model'));
        }

        this.sendAction('close');
      },
      navigateInbox: function navigateInbox(route, siteRoute) {
        this.metrics.trackEvent({
          event: 'm_Inbox_screen_open'
        });

        if (Ember.isEmpty(this.get('siteController.model'))) {
          this.sendAction('transition', route, {
            queryParams: {
              read: 'False',
              page: 0
            }
          });
        } else {
          this.sendAction('transition', siteRoute, this.get('siteController.model.id'));
        }

        this.sendAction('close');
        this.send('closeContainers');
      },
      toggleActivities: function toggleActivities() {
        var event = this.showActivities ? 'm_SafetyItem_screen_close' : 'm_SafetyItem_screen_open';
        this.metrics.trackEvent({
          event: event
        });
        (0, _jquery.default)('.modal').modal('hide');

        if (this.showModules) {
          this.set('showModules', false);
        }

        this.toggleProperty('showActivities');
      },
      toggleModules: function toggleModules() {
        var event = this.showModules ? 'm_HQ_screen_close' : 'm_HQ_screen_open';
        this.metrics.trackEvent({
          event: event
        });
        (0, _jquery.default)('.modal').modal('hide');

        if (this.showActivities) {
          this.set('showActivities', false);
        }

        this.toggleProperty('showModules');
      },
      goToActivity: function goToActivity(linkTo, title, module) {
        var eventMap = {
          'hazard': 'm_SafetyItem_Hazard',
          'observation': 'm_SafetyItem_Observation',
          'equipment': 'm_SafetyItem_Equipment',
          'meeting': 'm_SafetyItem_Meeting',
          'inspection': 'm_SafetyItem_Inspection',
          'incident': 'm_SafetyItem_Incident'
        };
        this.metrics.trackEvent({
          event: eventMap[module]
        });
        this.metrics.trackEvent({
          event: 'm_SafetyItem_general_started'
        });

        if (linkTo.match(/\.create$/)) {
          this.sendAction('enterNewItemMode', title);
        }

        var route = Ember.getOwner(this).lookup('route:application');
        route.send('goToActivity', linkTo, title, module);
        this.send('closeContainers');
      },
      closeContainers: function closeContainers() {
        this.set('showActivities', false);
        this.set('showModules', false);
      }
    }
  });

  _exports.default = _default;
});