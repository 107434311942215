define("safesite/controllers/modals/join-site", ["exports", "jquery", "safesite/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JoinSiteModalController = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    sitesController: Ember.inject.controller('sites'),
    authController: Ember.inject.controller('auth'),
    pin: null,
    isLoading: false,
    title: Ember.computed('model.name', function () {
      var name = this.get('model.name');
      return 'Join Site - ' + name;
    }),
    actions: {
      joinSite: function joinSite() {
        var self = this;
        self.set('isLoading', true);

        if (Ember.isEmpty(self.get('pin'))) {
          self.woof.danger('Please enter all mandatory fields');
          self.set('isLoading', false);
        } else {
          var data = self.getProperties('content.id', 'pin');

          _jquery.default.ajax({
            data: data,
            async: true,
            type: 'POST',
            url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/joinsite',
            headers: {
              'Authorization': 'Token ' + self.get('authController.api_token')
            }
          }).then(function () {
            self.get('store').findRecord('site', self.get('content.id')).then(function (site) {
              site.get('personnel').then(function (personnel) {
                self.get('authController.content').then(function (user) {
                  personnel.pushObject(user);
                });
              });
              self.get('sitesController.content').pushObject(site);
              self.set('isLoading', false);
              (0, _jquery.default)('.modal').modal('hide');
            });
          }, function (e) {
            if (e.responseText === 'Invalid Site PIN') {
              self.set('error', 'Incorrect Site PIN - Please check with the site supervisor');
            }

            self.set('isLoading', false);
          });
        }
      }
    }
  });
  var _default = JoinSiteModalController;
  _exports.default = _default;
});