define("safesite/templates/modals/archive-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bVYyW1fi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"hideCancel\",\"title\"],[\"closeModal\",true,[24,[\"title\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"confirmArchive\",\"btn btn-default btn-lg btn-danger btn-block\",[28,\"t\",[\"modals.archive-item.button.yes\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n    \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"cancelArchive\",\"btn btn-default btn-lg btn-default btn-block\",[28,\"t\",[\"modals.archive-item.button.no\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/archive-item.hbs"
    }
  });

  _exports.default = _default;
});