define("safesite/routes/site/edit", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var SiteEditRoute = _restricted.default.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('validationFields', Ember.A());
    },
    renderTemplate: function renderTemplate() {
      this.render('modals/edit-site', {
        into: 'application',
        outlet: 'modal',
        controller: 'site/edit'
      });
    },
    deactivate: function deactivate() {
      this.transitionTo('sites');
    },
    actions: {
      closeModal: function closeModal() {
        this.transitionTo('sites');
      }
    }
  });

  var _default = SiteEditRoute;
  _exports.default = _default;
});