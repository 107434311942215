define("safesite/components/custom-dropdown-multiple-btn-default-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CustomDropdownMultipleBtnDefaultItem = Ember.Component.extend({
    layoutName: 'components/custom-dropdown-multiple-btn-default-item',
    labelPath: undefined,
    displayValue: Ember.computed('value', 'labelPath', function () {
      return this.value.get ? this.value.get(this.labelPath) : this.value[this.labelPath];
    }),
    actions: {
      remove: function remove(item) {
        this.set('isLoading', true);
        this.sendAction("removeHandler", item);
        this.set('isLoading', false);
      }
    }
  });
  var _default = CustomDropdownMultipleBtnDefaultItem;
  _exports.default = _default;
});