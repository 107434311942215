define("safesite/controllers/certificate/edit", ["exports", "safesite/controllers/certificates/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CertificateEditController = _create.default.extend({
    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),
    init: function init() {
      this._super();

      this.metrics.trackEvent({
        event: 'm_Certificates_View'
      });
    },
    actions: {}
  });

  var _default = CertificateEditController;
  _exports.default = _default;
});