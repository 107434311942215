define("safesite/components/fa-stack", ["exports", "ember-font-awesome/components/fa-stack"], function (_exports, _faStack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _faStack.default;
    }
  });
});