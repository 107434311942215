define("safesite/components/generic-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GenericItemComponent = Ember.Component.extend({
    layoutName: 'components/generic-item',
    addMargin: true,
    module: null,
    showIcon: true,
    underlineIcon: false,
    icon: Ember.computed('module', function () {
      var module = this.module;

      if (module === 'hazard') {
        return '<i class="icon-Hazard"></i>';
      } else if (module === 'inspection') {
        return '<i class="icon-Inspection"></i>';
      } else if (module === 'meeting') {
        return '<i class="icon-Meeting"></i>';
      } else if (module === 'equipment') {
        return '<i class="icon-Equipment"></i>';
      } else if (module === 'observation') {
        return '<i class="icon-Observation"></i>';
      } else if (module === 'incident-report') {
        return '<i class="icon-Incident"></i>';
      } else if (module === 'verify') {
        return '<i class="icon-verify_email"></i>';
      } else if (module === 'announcement') {
        return '<i class="icon-bullhorn"></i>';
      } else if (module === 'complete-profile') {
        return '<i class="icon-Profile"></i>';
      } else if (module === 'safety-item') {
        return '<i class="icon-mobileapp"></i>';
      } else if (module === 'certificate') {
        return '<img class="icon-Certificate" src="assets/images/certs-black.svg">';
      }

      return '';
    }),
    actions: {}
  });
  var _default = GenericItemComponent;
  _exports.default = _default;
});