define("safesite/services/nav-menu-tab-bar", ["exports", "safesite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    controller: null,
    selectTab: function selectTab(tab) {
      this.controller.send('selectTab', tab);
    }
  });

  _exports.default = _default;
});