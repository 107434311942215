define("safesite/initializers/nav-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:nav-menu', 'siteController', 'controller:site');
  }

  var _default = {
    name: 'nav-menu',
    initialize: initialize
  };
  _exports.default = _default;
});