define("safesite/controllers/observations/index", ["exports", "moment", "safesite/mixins/pagination"], function (_exports, _moment, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObservationsIndexController = Ember.Controller.extend(_pagination.default, {
    siteController: Ember.inject.controller('site'),
    filter: null,
    filterOpen: true,
    queryParams: ['filterStatus', 'filter', 'attrSelected', 'start_date', 'end_date', 'page'],
    sortBy: ['created_date_time:desc'],
    attrSelected: 'all',
    filterStatus: 'safe',
    start_date: (0, _moment.default)().subtract(14, 'days').format('YYYY-MM-DD'),
    end_date: (0, _moment.default)().add(1, 'days').format('YYYY-MM-DD'),
    allActive: Ember.computed('attrSelected', function () {
      return this.attrSelected === 'all';
    }),
    conActive: Ember.computed('attrSelected', function () {
      return this.attrSelected === 'team';
    }),
    locActive: Ember.computed('attrSelected', function () {
      return this.attrSelected === 'location';
    }),
    safe: Ember.computed('filterStatus', function () {
      return this.filterStatus === 'safe';
    }),
    risk: Ember.computed('filterStatus', function () {
      return this.filterStatus === 'risk';
    }),
    safeCount: Ember.computed('model.@each.nature', 'paginatedModel', function () {
      return this.paginatedModel.filterBy('nature', 'safe').length;
    }),
    riskCount: Ember.computed('model.@each.nature', 'paginatedModel', function () {
      return this.paginatedModel.filterBy('nature', 'risk').length;
    }),
    filterList: Ember.computed('filterStatus', 'model.@each.nature', 'paginatedModel', function () {
      var self = this;
      var contentMap = self.paginatedModel;
      var filteredObservationz = null;

      if (this.filterStatus === 'safe') {
        filteredObservationz = contentMap.filterBy('nature', 'safe');
        return filteredObservationz.sortBy('created_date_time').reverse();
      } else {
        filteredObservationz = contentMap.filterBy('nature', 'risk');
        return filteredObservationz.sortBy('created_date_time').reverse();
      }
    }),
    detailContent: Ember.computed('filterStatus', 'attrSelected', 'model.@each.filterStatus', 'filterList', function () {
      if (this.attrSelected !== 'all') {
        var self = this;

        var promised = function promised(initialValue, func) {
          var flightKey;
          flightKey = '_promisedInFlight' + Ember.guidFor({});
          return function (k, v) {
            var myNumber;

            if (arguments.length > 1) {
              return v;
            }

            if (this[flightKey] == null) {
              this[flightKey] = 0;
            }

            this[flightKey] += 1;
            myNumber = this[flightKey];
            func.apply(this, [k, v]).then(function (_this) {
              return function (result) {
                if (_this[flightKey] <= myNumber) {
                  _this.set(k, result);
                }

                return _this[flightKey] -= 1;
              };
            }(this));
            return initialValue;
          };
        };

        var Line = Ember.Object.extend({
          promise: null,
          'name': promised('loading...', function () {
            var self = this;
            return new Ember.RSVP.Promise(function (resolve) {
              self.get('promise').then(function (result) {
                resolve(result);
              });
            });
          }).property()
        });
        var hashArray = Ember.A();
        var contentMap = self.get('filterList');
        var newMap = contentMap.map(function (inspection) {
          return {
            'id': inspection.get(self.get('attrSelected')).get('id'),
            'name': new Ember.RSVP.Promise(function (resolve) {
              inspection.get(self.get('attrSelected')).then(function (result) {
                if (result == null) {
                  resolve('N/A');
                } else {
                  resolve(result.get('name'));
                }
              });
            }),
            'count': 1
          };
        });
        var reduced = newMap.reduce(function (prev, next) {
          if (prev[next.id]) {
            prev[next.id].set('count', prev[next.id].get('count') + next.count);
            return prev;
          } else {
            prev[next.id] = Line.create({
              'count': 1,
              'promise': next.name
            });
            return prev;
          }
        }, {});

        for (var value in reduced) {
          hashArray.push(Line.create({
            'promise': reduced[value].promise,
            'count': reduced[value].count
          }));
        }

        return hashArray.sortBy('count').reverse();
      } else {
        return;
      }
    }),
    filteredArticles: Ember.computed('filter', 'model', 'filterList', function () {
      var filter = this.filter;
      var attrSelected = this.attrSelected + '.name';
      var modelz = this.filterList;
      var filteredModelz = [];

      if (filter) {
        if (filter === "N/A") {
          filteredModelz = modelz.filterBy(attrSelected, null);
        } else {
          filteredModelz = modelz.filterBy(attrSelected, filter);
        }

        return filteredModelz.sortBy('created_date_time').reverse();
      } else {
        return;
      }
    }),
    actions: {
      selectCat: function selectCat(string) {
        this.set('filter', null);
        this.transitionToRoute({
          queryParams: {
            attrSelected: string
          }
        });
      },
      selectStatus: function selectStatus(string) {
        this.set('filter', null);
        this.transitionToRoute({
          queryParams: {
            filterStatus: string
          }
        });
      },
      filterListed: function filterListed(string) {
        this.transitionToRoute({
          queryParams: {
            filter: string
          }
        });
      },
      clearFilter: function clearFilter() {
        this.set('filter', null);
      },
      toggleFilter: function toggleFilter() {
        this.toggleProperty('filterOpen');
      }
    }
  });
  var _default = ObservationsIndexController;
  _exports.default = _default;
});