define("safesite/models/notification", ["exports", "ember-data", "moment", "safesite/models/abstract"], function (_exports, _emberData, _moment, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstract.default.extend({
    content_type: _emberData.default.attr(),
    object_pk: _emberData.default.attr(),
    created_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    modified_by: _emberData.default.belongsTo('user', {
      async: true
    }),
    recipient: _emberData.default.belongsTo('user', {
      async: true
    }),
    read: _emberData.default.attr(),
    time_from_now: Ember.computed('create_date_time', function () {
      if ((0, _moment.default)().diff(this.created_date_time, 'days') <= 2) {
        return (0, _moment.default)(this.created_date_time).fromNow(true);
      } else {
        return (0, _moment.default)(this.created_date_time, 'YYYY-MM-DD').format('MM-DD-YYYY');
      }
    }),
    title: _emberData.default.attr(),
    message: _emberData.default.attr(),
    n_type: _emberData.default.attr(),
    site: _emberData.default.belongsTo('site', {
      async: true
    })
  });

  _exports.default = _default;
});