define("safesite/models/user-team-profile", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var UserTeamProfile = _abstract.default.extend({
    member_type: attr(),
    is_active: attr('boolean'),
    user: _emberData.default.belongsTo('user', {
      inverse: null,
      async: true
    }),
    team: _emberData.default.belongsTo('team', {
      inverse: 'team_user_profiles',
      async: true
    })
  });

  var _default = UserTeamProfile;
  _exports.default = _default;
});