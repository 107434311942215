define("safesite/components/dropdown-btn-location", ["exports", "safesite/components/dropdown-btn"], function (_exports, _dropdownBtn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dropdownBtn.default.extend({
    action: "openGenericDropdown",
    controllerFor: "dropdown-location",
    creatable: true,
    content: Ember.computed.alias('siteController.model.locations')
  });

  _exports.default = _default;
});