define("safesite/components/date-filter", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    startDate: null,
    endDate: null,
    i18n: Ember.inject.service(),
    connectThings: Ember.on('didInsertElement', function () {
      var self = this;
      Ember.run.later(this, function () {
        var _this = this;

        var getCalendarDetails = function getCalendarDetails(hiddenPrefix) {
          return {
            clear: _this.i18n.t('datePicker.clear'),
            close: _this.i18n.t('datePicker.close'),
            today: _this.i18n.t('datePicker.today'),
            monthsShort: [_this.i18n.t('datePicker.month.jan'), _this.i18n.t('datePicker.month.feb'), _this.i18n.t('datePicker.month.mar'), _this.i18n.t('datePicker.month.apr'), _this.i18n.t('datePicker.month.may'), _this.i18n.t('datePicker.month.jun'), _this.i18n.t('datePicker.month.jul'), _this.i18n.t('datePicker.month.aug'), _this.i18n.t('datePicker.month.sep'), _this.i18n.t('datePicker.month.oct'), _this.i18n.t('datePicker.month.nov'), _this.i18n.t('datePicker.month.dec')],
            weekdaysShort: [_this.i18n.t('datePicker.week.sun'), _this.i18n.t('datePicker.week.mon'), _this.i18n.t('datePicker.week.tue'), _this.i18n.t('datePicker.week.wed'), _this.i18n.t('datePicker.week.thu'), _this.i18n.t('datePicker.week.fri'), _this.i18n.t('datePicker.week.sat')],
            container: ".page-header",
            showMonthsShort: true,
            format: 'mmm dd, yyyy',
            formatSubmit: 'yyyy-mm-dd',
            hiddenPrefix: hiddenPrefix,
            onSet: function onSet(_ref) {
              var select = _ref.select;

              if (select) {
                self.set(hiddenPrefix, (0, _moment.default)(select).format('YYYY-MM-DD'));
              }
            }
          };
        };

        var calendarStart = getCalendarDetails('startDate');
        (0, _jquery.default)('.startDate').pickadate(calendarStart);
        var calendarEnd = getCalendarDetails('endDate');
        (0, _jquery.default)('.endDate').pickadate(calendarEnd);
      }, 100);
    })
  });

  _exports.default = _default;
});