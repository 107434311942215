define("safesite/mixins/observation-validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObservationValidationMixin = Ember.Mixin.create({
    validations: {
      'model.location': function modelLocation() {
        if (Ember.isBlank(this.model.location.content)) {
          return this.i18n.t('observations.create.validation.locationNotBlank');
        }
      },
      'model.description': function modelDescription() {
        if (Ember.isBlank(this.model.description)) {
          return this.i18n.t('observations.create.validation.descriptionBlank');
        }

        if (this.model.description.trim().split(/\s/).length <= 1) {
          return this.i18n.t('observations.create.validation.descriptionShort');
        }
      }
    }
  });
  var _default = ObservationValidationMixin;
  _exports.default = _default;
});