define("safesite/templates/modals/permissions-404-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y3xPjFb/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"hideCancel\",\"title\"],[\"closeModal\",false,[24,[\"title\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[10,\"style\",\"text-align: center;\"],[8],[0,\"Missing Permissions\"],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"style\",\"text-align: center;\"],[8],[0,\"You do not have permissions to access this Site.\"],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"style\",\"text-align: center;\"],[8],[7,\"i\",true],[8],[0,\"If this continues please contact \"],[7,\"a\",true],[10,\"href\",\"mailto:success@safesitehq.com\"],[8],[0,\"support\"],[9],[9],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/permissions-404-site.hbs"
    }
  });

  _exports.default = _default;
});