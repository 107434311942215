define("safesite/models/user-site-profile", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var UserSiteProfile = _abstract.default.extend({
    safesite_user: attr('boolean'),
    hazard_quota: attr(),
    member_type: attr(),
    observation_quota: attr(),
    inspection_quota: attr(),
    meeting_quota: attr(),
    daily_summary: attr('boolean'),
    daily_summary_time: attr(),
    weekly_summary: attr('boolean'),
    weekly_summary_day: attr(),
    weekly_summary_time: attr(),
    user: _emberData.default.belongsTo('user', {
      inverse: null,
      async: true
    }),
    site: _emberData.default.belongsTo('site', {
      inverse: null,
      async: true
    })
  });

  var _default = UserSiteProfile;
  _exports.default = _default;
});