define("safesite/controllers/modals/sign-up", ["exports", "jquery", "safesite/config/environment", "email-validator"], function (_exports, _jquery, _environment, _emailValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SignUpModalController = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    requiredFields: ['email', 'firstName', 'lastName', 'company', 'password', 'mobile'],
    email: null,
    password: null,
    firstName: null,
    lastName: null,
    company: null,
    passwordType: 'password',
    passwordMsg: 'show',
    mobile: null,
    isLoading: false,
    actions: {
      togglePasswordType: function togglePasswordType() {
        if (this.passwordType == 'password') {
          this.set('passwordType', 'text');
          this.set('passwordMsg', 'Hide');
        } else {
          this.set('passwordType', 'password');
          this.set('passwordMsg', 'Show');
        }
      },
      signup: function signup() {
        var self = this;
        self.set('isLoading', true); // Collect missing fields

        var missingFields = self.get('requiredFields').reduce(function (acc, val) {
          if (Ember.isEmpty(self.get(val))) {
            acc.push(val);
          }

          return acc;
        }, []);

        if (!Ember.isEmpty(missingFields)) {
          self.woof.danger('This following fields are mandatory: ' + missingFields.reduce(function (acc, val) {
            return acc + ', ' + val;
          }), "");
          self.set('isLoading', false);
        } else if (self.get('password.length') < 6) {
          self.woof.danger('Please enter a password of at least 6 characters in length');
          self.set('isLoading', false);
        } else if ((0, _jquery.default)('.input-mobile').hasClass('error')) {
          self.woof.danger(self.i18n.t('default-form-messages.enter.valid.mobile.number'));
          self.set('isLoading', false);
        } else {
          if (self.get('email')) {
            if (!(0, _emailValidator.validate)(self.email)) {
              self.woof.danger(self.i18n.t('default-form-messages.enter.valid.email'));
              self.set('isLoading', false);
            } else {
              self.set('email', self.get('email').toLowerCase());
              var data = self.getProperties('email', 'password', 'firstName', 'lastName', 'company', 'mobile');

              _jquery.default.ajax({
                data: data,
                async: true,
                type: 'POST',
                url: _environment.default.APP.API_HOST + '/api/signup'
              }).then(function () {
                self.get('metrics').trackEvent({
                  event: 'm_SignUp_Success',
                  category: 'account',
                  action: 'create'
                });
                self.set('authController.username', self.get('email'));
                self.set('authController.password', self.get('password'));
                self.get('authController').send('login');
                self.set('isLoading', false);
                (0, _jquery.default)('.modal').modal('hide');
                self.sendAction();
              }, function (e) {
                self.set('isLoading', false);
                self.woof.danger(e.responseText);
              });
            }
          }
        }
      }
    }
  });
  var _default = SignUpModalController;
  _exports.default = _default;
});