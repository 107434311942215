define("safesite/components/template-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: false,
    shortenDescription: true,
    item: null,
    onSelect: null,
    textLength: 200,
    hideOverflow: true,
    shortened: Ember.computed('item.description', 'textLength', function () {
      var text = this.get('item.description');

      if (text) {
        return text.length > this.textLength;
      } else {
        return false;
      }
    }),
    description: Ember.computed('item.description', 'textLength', 'shortened', 'shortenDescription', function () {
      var text = this.get('item.description');

      if (this.get('shortened') === true) {
        if (this.get('shortenDescription') === true) {
          var theString = text.substring(0, this.get('textLength')) + " ... ";
          return theString;
        } else {
          return text;
        }
      }

      return text;
    }),
    industries: Ember.computed('item.industries', function () {
      return this.get('item.industries').replace('-', ' ');
    }),
    actions: {
      submitSelect: function submitSelect() {
        // call onSelect with a item argument
        this.get('onSelect')(this.get('item'));
      },
      toggleShortenDescription: function toggleShortenDescription() {
        this.toggleProperty('shortenDescription');
      }
    }
  });

  _exports.default = _default;
});