define("safesite/controllers/equipment/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EquipmentIndexController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    siteController: Ember.inject.controller('site'),
    deleteMode: false,
    status: true,
    testDateFormatted: Ember.computed(function () {
      if (this.get('model.last_test_date')) {
        return (0, _moment.default)(this.get('model.last_test_date'), 'YYYY-MM-DD').format('MMM DD, YYYY');
      }
    }),
    hazards: Ember.computed('model.hazards', function () {
      return this.get('model.hazards').filterBy('status', true);
    }),
    actions: {
      edit: function edit() {
        this.transitionToRoute('equipment.edit');
      },
      delete: function _delete() {
        this.toggleProperty('deleteMode');
      },
      cancelDelete: function cancelDelete() {
        // set deleteMode back to false
        this.set('deleteMode', false);
      },
      confirmDelete: function confirmDelete() {
        // tell Ember to delete the current user - use self as a proxy as 'this' becomes unknown in callback due to object scope
        var self = this;
        this.set('deleteMode', false);
        this.model.deleteRecord();
        this.model.save().then(function () {
          self.transitionToRoute('equipments');
        }).catch(function () {
          console.error("failed delete equipment");
        });
      },
      goBack: function goBack() {
        window.history.back();
      },
      archive: function archive() {
        this.toggleProperty('archiveMode');
      },
      cancelArchive: function cancelArchive() {
        // set deleteMode back to false
        this.set('archiveMode', false);
      },
      confirmArchive: function confirmArchive() {
        var self = this;
        this.model.set('is_deleted', true);
        this.model.save().then(function () {
          self.set('archiveMode', false);
          self.transitionToRoute('equipments');
        }).catch(function () {
          console.error("failed archive equipment");
        });
      }
    }
  });
  var _default = EquipmentIndexController;
  _exports.default = _default;
});