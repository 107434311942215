define("safesite/components/x-woof-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var XWoofMessageComponent = Ember.Component.extend({
    classNames: ['x-woof-message-container'],
    classNameBindings: ['insertState'],
    insertState: 'pre-insert',
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.later(function () {
        _this.set('insertState', 'inserted');
      }, 250);

      if (this.woof.timeout) {
        Ember.run.later(function () {
          _this.woof.removeObject(_this.get('message'));
        }, this.woof.timeout);
      }
    },
    click: function click() {
      this.set('insertState', 'destroyed');
    }
  });
  var _default = XWoofMessageComponent;
  _exports.default = _default;
});