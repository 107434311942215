define("safesite/controllers/incidentreport/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IncidentReportIndexController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    siteController: Ember.inject.controller('site'),
    deleteMode: false,
    positive: Ember.computed('model.nature', function () {
      return this.get('model.nature') === "safe";
    }),
    actions: {
      addComment: function addComment(id, comment) {
        if (!Ember.isEmpty(comment)) {
          var newComment = {
            comment: comment,
            created_by: this.get('authController.model.id'),
            modified_by: this.get('authController.model.id'),
            created_date_time: _moment.default.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z',
            modified_date_time: _moment.default.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z'
          };

          if (Ember.isBlank(this.get('model.comments'))) {
            this.set('model.comments', []);
          }

          this.get('model.comments').pushObject(newComment);
          this.model.save();
        }
      },
      edit: function edit() {
        this.transitionToRoute('incidentreport.edit', this.get('model.id'));
      },
      delete: function _delete() {
        this.toggleProperty('deleteMode');
      },
      cancelDelete: function cancelDelete() {
        // set deleteMode back to false
        this.set('deleteMode', false);
      },
      confirmDelete: function confirmDelete() {
        // tell Ember to delete the current user - use self as a proxy as 'this' becomes unknown in callback due to object scope
        var self = this;
        this.set('deleteMode', false);
        this.model.deleteRecord();
        this.model.save().then(function () {
          self.transitionToRoute('incidentreports');
        }).catch(function () {});
      },
      goBack: function goBack() {
        window.history.back();
      }
    }
  });
  var _default = IncidentReportIndexController;
  _exports.default = _default;
});