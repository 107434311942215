define("safesite/models/inspection-online", ["exports", "ember-data", "safesite/models/inspection"], function (_exports, _emberData, _inspection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _inspection.default.extend({
    couchdb_id: _emberData.default.attr(),
    hazards: _emberData.default.hasMany('hazard-online', {
      async: true
    }),
    observations: _emberData.default.hasMany('observation-online', {
      async: true
    })
  });

  _exports.default = _default;
});