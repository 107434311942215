define("safesite/controllers/incidentreports/create", ["exports", "safesite/controllers/incidentreport/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var IncidentReportsCreateController = _edit.default.extend({
    cocreatorReadOnly: false,
    // In create mode - the created_by is the current device user
    createdBy: Ember.computed('authController.model', function () {
      return this.authController.model;
    })
  });

  var _default = IncidentReportsCreateController;
  _exports.default = _default;
});