define("safesite/components/nav-menu", ["exports", "jquery", "safesite/config/environment", "@safesitehq/intercom"], function (_exports, _jquery, _environment, _intercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mask: null,
    device: Ember.inject.service(),
    metrics: Ember.inject.service(),
    i18n: Ember.inject.service(),
    eventBusService: Ember.inject.service('event-bus'),
    navMenuTabBar: Ember.inject.service(),
    synchronizationBar: Ember.inject.service(),
    language: Ember.computed('i18n', function () {
      return this.i18n.locale;
    }),
    appVersion: _environment.default.appVersion,
    appEnv: _environment.default.environment,
    appHost: _environment.default.APP.API_HOST,
    appApi: _environment.default.APP.API_NAMESPACE,
    isNotProduction: Ember.computed('appEnv', function () {
      return this.appEnv != "production";
    }),
    // Enable event  service to trigger the activity menu (added for bakc button, close menu)
    triggerMenuClose: function triggerMenuClose() {
      if (this.menuIsOpen) {
        this.send('toggleMenu');
      }
    },
    listen: Ember.on('init', function () {
      this.eventBusService.on('closesidemenu', this, 'triggerMenuClose');
    }),
    // remember to remove what you bind upon component destruction
    cleanup: Ember.on('willDestroyElement', function () {
      this.eventBusService.off('closesidemenu', this, 'triggerMenuClose');
    }),
    profile_name: Ember.computed('session.model.{first_name,username}', function () {
      if (this.get('session.model.first_name')) {
        return this.get('session.model.first_name');
      } else {
        return this.get('session.model.username');
      }
    }),
    menuIsOpen: false,
    // When menuIsOpen is changed trigger menu open or closed
    menuIsOpenChanged: Ember.observer('menuIsOpen', function () {
      // New value is True so we want to open the menu, send open action
      if (this.menuIsOpen) {
        this.send('openMenu'); // New value is False, so we want to close the menu
      } else {
        this.send('closeMenu');
      }
    }),
    myRouteName: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName');
    }),
    showNavbar: Ember.computed('myRouteName', function () {
      var routeName = this.myRouteName;
      var activeRouteNames = ['splash', 'welcome', 'auth', 'reset', 'signup', 'signup-organization-set-up', 'userprofile', 'certificates.index', 'certificates.create', 'certificate.index', 'certificate.edit', 'certificates.loading'];

      if (activeRouteNames.includes(routeName)) {
        return false;
      } else {
        return true;
      }
    }),
    isLoggedIn: Ember.computed('session.isUserLoggedIn', function () {
      return this.session.isUserLoggedIn;
    }),
    isIndex: Ember.computed('currentPath', function () {
      return this.currentPath === 'index' || this.currentPath === 'sites';
    }),
    checkIntercomMessagesTimer: Ember.observer('device.isCordova', 'isLoggedIn', function () {
      if (this.get('device.isCordova') && this.isLoggedIn && _environment.default.INTERCOM.ACTIVE) {
        var interval = 30000;

        _intercom.Intercom.unreadConversationCount(function (result) {
          this.set('intercomUnreadConversationCount', result);
        }.bind(this));

        Ember.run.later(this, function () {
          _intercom.Intercom.unreadConversationCount(function (result) {
            this.set('intercomUnreadConversationCount', result);
          }.bind(this));

          this.checkIntercomMessagesTimer();
        }, interval);
      }
    }),
    intercomUnreadConversationCount: 0,
    hasIntercomMessages: Ember.computed('intercomUnreadConversationCount', function () {
      return this.intercomUnreadConversationCount > 0;
    }),
    navMap: {
      'site.index': 'sites',
      'userprofile': 'index',
      'sites.index': 'index',
      'notifications.index': 'back',
      'hazards.index': 'site.index',
      'inspections.index': 'site.index',
      'observations.index': 'site.index',
      'equipments.index': 'site.index',
      'incidentreports.index': 'site.index',
      'laborstats.index': 'site.index',
      'hazard.index': 'hazards.index',
      'inspection.index': 'inspections.index',
      'observation.index': 'observations.index',
      'equipment.index': 'equipments.index',
      'incidentreport.index': 'incidentreports.index',
      'laborstat.index': 'laborstats.index',
      'hazards.create': 'hazards.index',
      'inspections.create': 'inspections.index',
      'observations.create': 'observations.index',
      'equipments.create': 'equipments.index',
      'incidentreports.create': 'incidentreports.index',
      'laborstats.create': 'laborstats.index',
      'equipment.edit': 'equipment.index',
      'hazard.edit': 'hazard.index',
      'incidentreport.edit': 'incidentreport.index',
      'observation.edit': 'observation.index'
    },
    doesPageHaveTabs: Ember.computed('navMenuTabBar.tabs', function () {
      return this.navMenuTabBar.tabs && this.navMenuTabBar.tabs.length > 0;
    }),
    actions: {
      intercom: function intercom() {
        if (this.get('device.isCordova') && _environment.default.INTERCOM.ACTIVE === true) {
          _intercom.Intercom.displayMessenger();

          this.set('intercomUnreadConversationCount', 0);
        } else {
          this.sendAction('renderModal', {
            controllerPath: 'modals/contact-us',
            controllerProperties: {
              model: this.model
            }
          });
        }

        this.metrics.trackEvent({
          event: 'm_Nav_Settings_ContactUs'
        });
      },
      navBack: function navBack() {
        var navMap = this.navMap;
        var route = navMap[this.application.get('currentRouteName')];

        if (route === 'back') {
          window.history.back();
        } else {
          this.sendAction('transition', route);
        }

        if (this.menuIsOpen) {
          this.send('toggleMenu');
        }
      },
      toggleMenu: function toggleMenu() {
        var event = this.menuIsOpen ? 'm_Nav_Settings_close' : 'm_Nav_Settings_open';
        this.metrics.trackEvent({
          event: event
        });
        this.toggleProperty('menuIsOpen');
      },
      openMenu: function openMenu() {
        var self = this;
        var mask = self.get('mask');

        if (Ember.isEmpty(mask)) {
          mask = document.createElement("div");
          mask.className = "mask";
          mask.addEventListener("click", function () {
            self.set('menuIsOpen', false);
          });
        }

        document.body.appendChild(mask);
        self.set('mask', mask);
        (0, _jquery.default)('body').addClass('pmr-open');
      },
      closeMenu: function closeMenu() {
        if (!Ember.isEmpty(this.mask)) {
          document.body.removeChild(this.mask);
        }

        (0, _jquery.default)('body').removeClass('pmr-open');
      },
      navigate: function navigate(siteRoute, route) {
        var toggleMenu = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

        if (toggleMenu) {
          this.send('toggleMenu');
        }

        if (route === 'notifications' && Ember.isPresent(this.get('siteController.model'))) {
          this.sendAction('transition', route, this.get('siteController.model'));
        } else {
          this.sendAction('transition', siteRoute);
        }
      },
      exitNewItemMode: function exitNewItemMode() {
        this.send('navigate', 'sites/actions', 'actions', false);
        this.sendAction('exitNewItemMode');
      },
      openTour: function openTour() {
        this.sendAction('openTourModal');
      },
      refresh: function refresh() {
        this.metrics.trackEvent({
          event: 'm_Nav_Settings_Refresh'
        });
        localStorage.setItem('manual_reload', new Date().getTime());
        document.location.reload(true);
      },
      tour: function tour() {
        this.send('openTour');
      },
      logout: function logout() {
        this.metrics.trackEvent({
          event: 'm_Nav_Settings_Logout'
        });
        this.sendAction('renderModal', {
          controllerPath: 'modals/logout-confirmation',
          controllerProperties: {
            unableToLogout: false
          }
        });
      },
      exitNewItemModeConfirmation: function exitNewItemModeConfirmation() {
        this.sendAction('renderModal', {
          controllerPath: 'modals/exit-new-item-mode-confirmation'
        });
      },
      openGenericDropdown: function openGenericDropdown(_ref) {
        var content = _ref.content,
            name = _ref.name,
            view = _ref.view,
            creatable = _ref.creatable,
            labelPath = _ref.labelPath,
            hasMany = _ref.hasMany,
            controller = _ref.controller;
        this.sendAction('openGenericDropdown', {
          content: content,
          name: name,
          view: view,
          creatable: creatable,
          labelPath: labelPath,
          hasMany: hasMany,
          controller: controller,
          templateName: 'modals/dropdown-site'
        });
      },
      resetSite: function resetSite(site) {
        this.sendAction('resetSite', site);
      },
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      navMenuTabBarSelectTab: function navMenuTabBarSelectTab(tab) {
        this.navMenuTabBar.selectTab(tab);
      }
    }
  });

  _exports.default = _default;
});