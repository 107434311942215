define("safesite/components/add-signature-modal", ["exports", "signature_pad", "jquery"], function (_exports, _signature_pad, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Assume only one modal will ever be open at a time.
  var canvasSelector = '.add-signature-modal-canvas';
  var modalContentSelector = '.add-signature-modal-body-content';

  function getModal() {
    return (0, _jquery.default)(modalContentSelector).closest('.modal');
  } // This code comes directly from the creator of signature_pad.
  // https://jsfiddle.net/szimek/4ea7wgb3


  function resizeCanvas(canvas) {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
  }

  var _default = Ember.Component.extend({
    signaturePad: null,
    isSaveDisabled: true,
    // Do not let user click save with empty signature
    signatory: null,
    i18n: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      // Cannot use this.element.querySelector due to Ember Wormhole.
      // Assume only one modal can be open at a time.
      var canvas = (0, _jquery.default)(canvasSelector)[0];

      if (!canvas) {
        // eslint-disable-next-line no-console
        console.error('Cannot find signature canvas element');
        return;
      }

      this.signaturePad = new _signature_pad.default(canvas, {
        onEnd: function onEnd() {
          _this.set('isSaveDisabled', false); // Canvas is no longer empty

        }
      }); // If the signature pad initializes before the modal has opened, it
      // will have a size of 0. This can cause UI issues.

      getModal().on('shown.bs.modal', function () {
        var canvas = (0, _jquery.default)(canvasSelector)[0]; // Re-lookup prevent memleak.

        resizeCanvas(canvas);
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (this.signaturePad) {
        this.signaturePad.off();
        this.signaturePad = null; // Release memory.
      }

      getModal().off();
    },
    actions: {
      clear: function clear() {
        if (this.signaturePad) {
          this.signaturePad.clear();
          this.set('isSaveDisabled', true);
        }
      },
      save: function save() {
        if (this.signaturePad) {
          var data = this.signaturePad.toDataURL('image/svg+xml'); // eslint-disable-next-line ember/closure-actions

          this.sendAction('addSignatureHandler', this.get('signatory'), data);
        }

        (0, _jquery.default)('#safesite-dropdown').modal('hide');
      },
      cancel: function cancel() {
        // eslint-disable-next-line ember/closure-actions
        this.sendAction('closeSigntureModalHandler');
      }
    }
  });

  _exports.default = _default;
});