define("safesite/transforms/attachments", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  keys = Object.keys || Ember.keys;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isNone(serialized)) {
        return [];
      }

      return Ember.keys(serialized).map(function (attachmentName) {
        var attachment = serialized[attachmentName];
        return Ember.Object.create({
          name: attachmentName,
          content_type: attachment.content_type,
          data: attachment.data,
          stub: attachment.stub,
          length: attachment.length,
          digest: attachment.digest,
          // extra fields 
          multiple: attachment.multiple,
          field_name: attachment.field_name //

        });
      });
    },
    serialize: function serialize(deserialized) {
      if (!Ember.isArray(deserialized)) {
        return null;
      }

      return deserialized.reduce(function (acc, attachment) {
        var serialized = {
          content_type: Ember.get(attachment, 'content_type'),
          // extra fields
          multiple: Ember.get(attachment, 'multiple'),
          field_name: Ember.get(attachment, 'field_name') //

        };

        if (Ember.get(attachment, 'stub')) {
          serialized.stub = true;
          serialized.length = Ember.get(attachment, 'length');
          serialized.digest = Ember.get(attachment, 'digest');
        } else {
          serialized.data = Ember.get(attachment, 'data');
          serialized.length = Ember.get(attachment, 'length');
        }

        acc[Ember.get(attachment, 'name')] = serialized;
        return acc;
      }, {});
    }
  });

  _exports.default = _default;
});