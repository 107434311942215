define("safesite/components/comment-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    comment: null,
    created_by_full_name: Ember.computed('comment.created_by', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.findRecord('user', this.get('comment.created_by')).then(function (user) {
          return user.get('full_name');
        })
      });
    }),
    actions: {}
  });

  _exports.default = _default;
});