define("safesite/components/x-woof", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var XWoofComponent = Ember.Component.extend({
    classNames: 'woof-messages',
    messages: Ember.computed.alias('woof'),
    hiddenNavMenu: false
  });
  var _default = XWoofComponent;
  _exports.default = _default;
});