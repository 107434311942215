define("safesite/components/loading-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LoadingButtonComponent = Ember.Component.extend({
    connectionStatus: Ember.inject.service(),
    tagName: 'button',
    classNames: ['btn'],
    attributeBindings: ['disabled'],
    type: 'submit',
    placeholder: null,
    isLoading: false,
    click: function click() {
      this.sendAction();
    },
    showOfflineMessage: Ember.computed('connectionStatus.offline', 'disableIfOffline', function () {
      return this.connectionStatus.offline && this.disableIfOffline;
    })
  });
  var _default = LoadingButtonComponent;
  _exports.default = _default;
});