define("safesite/controllers/modals/validate-email", ["exports", "jquery", "safesite/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VealidateEmailController = Ember.Controller.extend({
    connectionStatus: Ember.inject.service(),
    metrics: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    applicationController: Ember.inject.controller('application'),
    isLoading: false,
    isSubmitButtonDisabled: Ember.computed('connectionStatus.offline', 'isLoading', function () {
      return this.connectionStatus.offline || this.isLoading;
    }),
    email: Ember.computed('authController.model.email', function () {
      var _email = this.get('authController.model.email');

      return _email;
    }),
    init: function init() {
      this._super();

      this.applicationController.send('enterNewItemMode', '');
    },
    actions: {
      cancel: function cancel() {
        (0, _jquery.default)('.modal').modal('hide');
        this.applicationController.send('exitNewItemMode');
      },
      resetActivation: function resetActivation() {
        var self = this;
        self.set('isLoading', true);
        var data = {
          'company_name': self.get('org_name'),
          'email': self.get('email')
        };

        _jquery.default.ajax({
          data: data,
          async: true,
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/resendActivationSignup',
          headers: {
            'Authorization': 'Token ' + self.get('authController.api_token')
          }
        }).then(function () {
          self.set('isLoading', false);
          self.woof.info("Sent Activation Email");
          self.send('cancel');
        }).catch(function (error) {
          self.set('isLoading', false);
          self.woof.danger(error.responseText);
        });
      }
    }
  });
  var _default = VealidateEmailController;
  _exports.default = _default;
});