define("safesite/models/user-organization-profile", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var UserOrganizationProfile = _abstract.default.extend({
    type: attr(),
    member_type: attr(),
    is_active: attr('boolean'),
    user: _emberData.default.belongsTo('user', {
      inverse: null,
      async: true
    }),
    organization: _emberData.default.belongsTo('organization', {
      inverse: 'organization_user_profiles',
      async: true
    })
  });

  var _default = UserOrganizationProfile;
  _exports.default = _default;
});