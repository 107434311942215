define("safesite/templates/modals/exit_new_item_mode_confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "STcm3zKg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"safesite-modal\",null,[[\"action\",\"title\"],[\"closeModal\",[28,\"t\",[\"modals.exit-new-item-mode.title\"],null]]],{\"statements\":[[0,\"    \"],[7,\"form\",false],[12,\"role\",\"form\"],[3,\"action\",[[23,0,[]],\"exitNewItemMode\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"classNames\",\"value\"],[\"send\",\"submit\",\"btn btn-primary btn-lg btn-block\",[28,\"t\",[\"modals.exit-new-item-mode.button.yes\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/exit_new_item_mode_confirmation.hbs"
    }
  });

  _exports.default = _default;
});