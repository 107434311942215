define("safesite/routes/meeting/edit", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MeetingEditRoute = _restricted.default.extend({
    i18n: Ember.inject.service(),
    controllerModel: null,
    model: function model() {
      return this.modelFor('meeting');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      var loadme = model.get('attendees');
      controller.set('responses', {});
      this.set('controllerModel', model);
      this.controller.send('resetInternalState');
      this.controllerFor('application').send('enterNewItemMode', this.i18n.t('meeting.conduct'));
    },
    renderTemplate: function renderTemplate() {
      this.render('meetings.create', {
        controller: 'meeting/edit'
      });
    },
    actions: {
      addObservation: function addObservation(activeTopicView) {
        this.set('activeTopicView', activeTopicView);
        var modelName = this.controller.model.isOnlineModel() ? 'observation-online' : 'observation';
        var observation = this.store.createRecord(modelName);
        observation.set('location', this.get('controller.location'));
        this.send('renderModal', {
          controllerPath: 'modals/observation-create',
          controllerProperties: {
            model: observation,
            showErrors: false
          }
        });
      },
      addHazard: function addHazard(activeTopicView) {
        this.set('activeTopicView', activeTopicView);
        var modelName = this.controller.model.isOnlineModel() ? 'hazard-online' : 'hazard';
        var hazard = this.store.createRecord(modelName);
        hazard.set('eq_location', this.get('controller.model.location'));
        this.send('renderModal', {
          controllerPath: 'modals/hazard-create',
          controllerProperties: {
            model: hazard,
            showErrors: false
          }
        });
      },
      openObservation: function openObservation(activeTopicView, observation) {
        this.set('activeTopicView', activeTopicView);
        this.send('renderModal', {
          controllerPath: 'modals/observation-create',
          controllerProperties: {
            model: observation
          }
        });
      },
      openHazard: function openHazard(activeTopicView, hazard) {
        this.set('activeTopicView', activeTopicView);
        this.send('renderModal', {
          controllerPath: 'modals/hazard-create',
          controllerProperties: {
            model: hazard
          }
        });
      },
      closeModalHandler: function closeModalHandler() {
        var activeQ = this.activeTopicView;

        if (activeQ && activeQ.get('hazard') === null) {
          activeQ.set('response', null);
        }

        this.send('closeModal');
      },
      saveObservation: function saveObservation() {
        var self = this;
        this.controllerFor('modals/observation-create').send('save', function (observation) {
          Ember.run.schedule('afterRender', function () {
            self.get('activeTopicView.observations').addObject(observation.getCouchDBId());
            self.woof.success('Observation Successfully Saved.');
          });
        });
      },
      saveAndCloseHazard: function saveAndCloseHazard() {
        var self = this;
        this.controllerFor('modals/hazard-create').send('saveAndClose', function (hazard) {
          self.get('activeTopicView.hazards').addObject(hazard.getCouchDBId());
          self.controllerFor('meeting/edit').send('scrollToNext', -2);
          self.woof.success('Hazard Successfully Saved.');
        });
      },
      saveHazard: function saveHazard() {
        var self = this;
        this.controllerFor('modals/hazard-create').send('save', function (hazard) {
          self.get('activeTopicView.hazards').addObject(hazard.getCouchDBId());
          self.controllerFor('meeting/edit').send('scrollToNext', -2);
          self.woof.success('Hazard Successfully Saved.');
        });
      },
      archiveModal: function archiveModal() {
        this.send('renderModal', {
          controllerPath: 'modals/archive-item',
          controllerProperties: {
            itemName: 'Meeting',
            postArchiveRoute: 'actions.index',
            archiveItem: this.controllerModel
          }
        });
      }
    }
  });

  var _default = MeetingEditRoute;
  _exports.default = _default;
});