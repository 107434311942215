define("safesite/controllers/modals/add-witness", ["exports", "jquery", "safesite/mixins/data-validator"], function (_exports, _jquery, _dataValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dataValidator.default, {
    siteController: Ember.inject.controller('site'),
    authController: Ember.inject.controller('auth'),
    showErrors: false,
    validations: {
      'model.person': function modelPerson() {
        if (!(Object.keys(this.model.person).length > 0 && Ember.isPresent(this.model.person.username))) {
          return "Must not be blank.";
        }
      },
      'model.statement': function modelStatement() {
        if (Ember.isBlank(this.model.statement)) {
          return "Must not be blank.";
        }
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        if (this.isValid()) {
          this.set('model.site', this.get('siteController.model'));
          this.set('model.is_deleted', false);
          this.set('model.incident', this.get('createIncidentController.model'));
          this.model.save().then(function (witness) {
            _this.createIncidentController.send('addWitness', witness);

            _this.set('model', null);

            (0, _jquery.default)('.modal').modal('hide');
          });
        } else {
          this.set('showErrors', true);
          this.woof.danger('Please fill in mandatory fields');
        }
      }
    }
  });

  _exports.default = _default;
});