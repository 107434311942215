define("safesite/templates/modals/exit-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kBldUZg+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"hideCancel\",\"title\"],[\"closeModal\",true,[28,\"t\",[\"modals.exit-item.title\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"editMode\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"confirmSave\",\"btn btn-default btn-lg btn-danger btn-block\",[28,\"t\",[\"modals.exit-item.button.yes_save\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n        \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"cancelExit\",\"btn btn-default btn-lg btn-default btn-block\",[28,\"t\",[\"modals.exit-item.button.cancel\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"confirmSave\",\"btn btn-default btn-lg btn-danger btn-block\",[28,\"t\",[\"modals.exit-item.button.save_later\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n        \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"confirmArchive\",\"btn btn-default btn-lg btn-default btn-block\",[28,\"t\",[\"modals.exit-item.button.no\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n        \"],[1,[28,\"loading-btn\",null,[[\"action\",\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"cancelExit\",\"btn btn-default btn-lg btn-default btn-block\",[28,\"t\",[\"modals.exit-item.button.cancel\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/exit-item.hbs"
    }
  });

  _exports.default = _default;
});