define("safesite/models/organization", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var Organization = _abstract.default.extend({
    name: attr(),
    domain: attr(),
    administrators: _emberData.default.hasMany('user', {
      async: true
    }),
    personnel: _emberData.default.hasMany('user', {
      async: true
    }),
    all_people: _emberData.default.hasMany('user', {
      async: true
    }),
    default_personnel: _emberData.default.hasMany('user', {
      async: true
    }),
    current_inspection_templates: _emberData.default.hasMany('inspection-template', {
      async: true
    }),
    current_meeting_templates: _emberData.default.hasMany('meeting-template', {
      async: true
    }),
    brand_image_url: attr(),
    observation_brand_image_url: attr(),
    naics: attr(),
    organization_user_profiles: _emberData.default.hasMany('user-organization-profile', {
      inverse: 'organization',
      async: true
    }),
    equipment_module: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    hazards_module: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    inspections_module: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    observations_module: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    incidents_module: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_admin_resolve_hazards: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_creator_resolve_hazards: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_team_resolve_hazards: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_admin_archive_hazards: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_creator_archive_hazards: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_team_archive_hazards: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_mobile_create_locations: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    can_mobile_leave_project: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    is_cocreator_enabled: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    // shared models
    injury_type: _emberData.default.hasMany('injury-type', {
      async: true
    }),
    incident_task: _emberData.default.hasMany('incident-task', {
      async: true
    }),
    risk_consequence: _emberData.default.hasMany('risk-consequence', {
      async: true
    }),
    risk_priority: _emberData.default.hasMany('risk-priority', {
      async: true
    }),
    risk_probability: _emberData.default.hasMany('risk-probability', {
      async: true
    }),
    risk_type: _emberData.default.hasMany('risk-type', {
      async: true
    }),
    root_cause: _emberData.default.hasMany('root-cause', {
      async: true
    }),
    body_part: _emberData.default.hasMany('body-part', {
      async: true
    }),
    test_frequency: _emberData.default.hasMany('test-frequency', {
      async: true
    }),
    make: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    model: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    equipment_type: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });

  var _default = Organization;
  _exports.default = _default;
});