define("safesite/routes/certificates/create", ["exports", "safesite/routes/restricted", "safesite/mixins/unload-unused-model"], function (_exports, _restricted, _unloadUnusedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CertificatesCreateRoute = _restricted.default.extend(_unloadUnusedModel.default, {
    i18n: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('certificate');
    },
    // in this case (the create route), we can reuse the certificate/edit template
    // associated with the CertificateCreateController
    renderTemplate: function renderTemplate() {
      this.render('certificate.edit', {
        controller: 'certificates/create'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('validationFields', Ember.A());
      controller.set('showErrors', false);
    }
  });

  var _default = CertificatesCreateRoute;
  _exports.default = _default;
});