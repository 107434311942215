define("safesite/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RadioButton = Ember.Component.extend({
    tagName: "input",
    type: "radio",
    attributeBindings: ["name", "type", "value", "checked:checked"],
    click: function click() {
      this.set("selection", this.$().val());
    },
    checked: Ember.computed('selection', 'value', function () {
      return this.value === this.selection;
    })
  });
  var _default = RadioButton;
  _exports.default = _default;
});