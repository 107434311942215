define("safesite/components/add-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    basicUserCreator: Ember.inject.service(),
    addPersonModalOpened: false,
    readOnly: false,
    showErrors: false,
    multiple: true,
    required: Ember.computed('errors.[]', 'showErrors', function () {
      return !Ember.isEmpty(this.errors) && this.showErrors;
    }),
    isPersonSelected: Ember.computed('person', function () {
      return this.person && Object.keys(this.person).length > 0;
    }),
    displayAddButton: Ember.computed('multiple', 'readOnly', 'person', 'people', function () {
      if (this.multiple) {
        return true && !this.readOnly;
      } else {
        return this.get('people.length') === 0 && !this.get('isPersonSelected') && !this.readOnly;
      }
    }),
    selectedPeople: Ember.computed('people', function () {
      return this.people;
    }),
    people: Ember.A(),
    person: null,
    actions: {
      removePerson: function removePerson(person) {
        if (this.multiple) {
          this.people.removeObject(person);
        } else {
          this.set('person', null);
        }

        this.sendAction('removePersonHandler', person);
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('addPersonModalOpened');
      },
      addPerson: function addPerson(person) {
        var basicUserJSON;

        if (person.rev) {
          basicUserJSON = this.basicUserCreator.generateObjectFromUserRecord({
            user: person
          });
        } else {
          basicUserJSON = person;
        }

        if (!this.multiple) {
          this.set('person', basicUserJSON);
        } else {
          this.people.pushObject(basicUserJSON);
        }

        this.sendAction('addHandler', basicUserJSON);
      }
    }
  });

  _exports.default = _default;
});