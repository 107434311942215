define("safesite/templates/modals/contact-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QbzslW3d",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\"],[\"closeModal\",[28,\"t\",[\"contact-us.title\"],null]]],{\"statements\":[[0,\"    \"],[7,\"form\",false],[12,\"role\",\"form\"],[3,\"action\",[[23,0,[]],\"send\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group alert alert-warning\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"contact-us.message.feedback\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n            \"],[1,[28,\"safesite-textarea\",null,[[\"placeholder\",\"value\",\"rows\",\"classNames\",\"maxlength\",\"showCounter\"],[[28,\"t\",[\"contact-us.placeholder.message\"],null],[24,[\"message\"]],\"3\",\"form-control\",500,true]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"classNames\",\"value\"],[\"send\",\"submit\",\"btn btn-primary btn-lg btn-block\",[28,\"t\",[\"contact-us.button.send\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/contact-us.hbs"
    }
  });

  _exports.default = _default;
});