define("safesite/templates/components/input-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/lXzUuhy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"control-label\"],[11,\"for\",[29,[[24,[\"inputField\",\"elementId\"]]]]],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\",\"viewName\"],[[24,[\"type\"]],\"form-control\",[24,[\"placeholder\"]],[24,[\"value\"]],\"inputField\"]]],false],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"help-block\"],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/input-email.hbs"
    }
  });

  _exports.default = _default;
});