define("safesite/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1IMJVli5",
    "block": "{\"symbols\":[],\"statements\":[[15,\"services/synchronization\",[]],[0,\"\\n\\n\"],[1,[28,\"nav-menu\",null,[[\"menuIsOpen\",\"transition\",\"renderModal\",\"openGenericDropdown\",\"openTourModal\",\"resetSite\",\"closeModal\",\"currentProject\",\"newItemMode\",\"newItemTitle\",\"exitNewItemMode\",\"toggleMenu\"],[[24,[\"menuIsOpen\"]],\"transition\",\"renderModal\",\"openGenericDropdown\",\"openTourModal\",\"resetSite\",\"closeModal\",[24,[\"selectedProject\"]],[24,[\"newItemMode\"]],[24,[\"newItemTitle\"]],\"exitNewItemMode\",\"toggleMenu\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"app\"],[11,\"class\",[29,[[22,\"applicationClass\"],\" \",[28,\"if\",[[28,\"await\",[[24,[\"synchronizationBar\",\"areThereItemsToSyncOrIsOffline\"]]],null],\"offline-mode-on\"],null]]]],[8],[0,\"\\n    \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isFooterEnabled\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"footer-menu\",null,[[\"transition\",\"close\",\"selectedProject\",\"newItemMode\",\"newItemTitle\",\"showFooter\",\"enterNewItemMode\"],[\"transition\",\"parentCloseMenu\",[24,[\"selectedProject\"]],[24,[\"newItemMode\"]],[24,[\"newItemTitle\"]],[24,[\"isFooterEnabled\"]],\"enterNewItemMode\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"injury-modal\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"safesite-modals\"],[8],[9],[0,\"\\n\\n\"],[1,[28,\"outlet\",[\"modal\"],null],false],[0,\"\\n\"],[1,[28,\"outlet\",[\"dropdown\"],null],false],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "safesite/templates/application.hbs"
    }
  });

  _exports.default = _default;
});