define("safesite/routes/observation/index", ["exports", "safesite/routes/restricted", "safesite/mixins/export"], function (_exports, _restricted, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ObservationIndexRoute = _restricted.default.extend(_export.default, {
    report_url: null,
    controllerModel: null,
    setupController: function setupController(controller, model) {
      this.set('report_url', 'observation/' + model.get('id'));
      controller.set('model', model);
      controller.set('archiveMode', false);
      this.set('controllerModel', model);
    },
    actions: {
      archiveModal: function archiveModal() {
        this.send('renderModal', {
          controllerPath: 'modals/archive-item',
          controllerProperties: {
            itemName: 'Observation',
            postArchiveRoute: 'actions.index',
            archiveItem: this.controllerModel
          }
        });
      }
    }
  });

  var _default = ObservationIndexRoute;
  _exports.default = _default;
});