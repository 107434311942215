define("safesite/components/custom-dropdown-multiple-user-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CustomDropdownMultipleUserItem = Ember.Component.extend({
    labelPath: undefined,
    displayValue: Ember.computed('result', 'labelPath', function () {
      return this.result.get ? this.result.get(this.labelPath) : this.result[this.labelPath];
    })
  });
  var _default = CustomDropdownMultipleUserItem;
  _exports.default = _default;
});