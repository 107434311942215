define("safesite/templates/components/loading-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yIPV6e/3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"spinner spinner-white\"],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"buttonLabel\"],false],[0,\" \"],[4,\"if\",[[24,[\"showOfflineMessage\"]]],null,{\"statements\":[[0,\"(\"],[1,[28,\"t\",[\"components.loading-btn.disabled.offline.message\"],null],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/loading-btn.hbs"
    }
  });

  _exports.default = _default;
});