define("safesite/models/risk-type", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstract.default.extend({
    name: _emberData.default.attr(),
    organization: _emberData.default.attr()
  });

  _exports.default = _default;
});