define("safesite/mixins/data-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ModelValidator = Ember.Mixin.create({
    errors: {},
    isValid: function isValid() {
      if (Ember.isPresent(this.validations)) {
        var validations = Object.keys(this.validations).reduce(function (accum, field) {
          accum.push({
            field: field,
            error: this.validations[field].bind(this)()
          });
          return accum;
        }.bind(this), []).reject(function (item) {
          return Ember.isBlank(item.error);
        });

        if (validations.length > 0) {
          this.set('errors', {
            model: validations.reduce(function (accum, value) {
              accum[value.field.replace('model.', '')] = value.error;
              return accum;
            }, {})
          });
          return false;
        }
      }

      return true;
    },
    displayErrors: function displayErrors() {
      var _this = this;

      this.set('showErrors', true);
      Object.keys(this.get("errors").model).map(function (key) {
        return _this.woof.danger(_this.get("errors").model[key].string || _this.get("errors").model[key]);
      });
    }
  });
  var _default = ModelValidator;
  _exports.default = _default;
});