define("safesite/templates/modals/announcement-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ca2xQkUL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\",\"keyboard\",\"backdrop\",\"additionalClass\",\"hideCancel\"],[\"closeModal\",\"\",\"false\",\"static\",\"announcement-modal verify-email-modal\",true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"announcement-font\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon-bullhorn\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"form\",false],[12,\"role\",\"form\"],[12,\"class\",\"form-group ss-modal-form-group safesite-announcement\"],[3,\"action\",[[23,0,[]],\"acknowledge\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"announcement-info\"],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"announcement-bottom-buttons\"],[8],[0,\"\\n            \"],[1,[28,\"loading-btn\",null,[[\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"btn btn-default btn-lg btn-primary btn-block\",[28,\"t\",[\"modals.announcement-modal.button.ack\"],null],[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/announcement-modal.hbs"
    }
  });

  _exports.default = _default;
});