define("safesite/components/image-gallery", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ImageGalleryComponent = Ember.Component.extend({
    classNames: ['img-gallery-component'],
    isOpen: false,
    activeImage: null,
    scrollPosition: 0,
    loaded: false,
    errorThrown: false,
    appMarginTop: null,
    greaterThanTwoImages: Ember.computed('images', 'images.length', function () {
      return this.images.filterBy('is_deleted', false).length > 2;
    }),
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('.img-gallery').scroll(function () {
        this.set('scrollPosition', (0, _jquery.default)('.img-gallery').scrollLeft());
      }.bind(this));
    },
    didDestroyElement: function didDestroyElement() {
      (0, _jquery.default)('.img-gallery').unbind('scroll');
    },
    _resolveImage: Ember.on('didRender', function () {
      var component = this;
      var image = component.$('#lightbox-img');
      image.one('load', function () {
        image.off('error');
        Ember.run.schedule('afterRender', component, function () {
          return Ember.set(component, 'loaded', true);
        });
      });
      image.one('error', function () {
        image.off('load');
        Ember.run.schedule('afterRender', component, function () {
          return Ember.set(component, 'errorThrown', true);
        });
      });
    }),
    updateScrollIndicators: function updateScrollIndicators() {
      var scrollPosition = this.scrollPosition;

      if (scrollPosition > 0) {
        this.set('turnLeftOn', true);
      } else {
        this.set('turnLeftOn', false);
      }

      if ((0, _jquery.default)('.img-gallery')[0].scrollWidth - (0, _jquery.default)('.img-gallery').scrollLeft() - (0, _jquery.default)('.img-gallery').width() == 0) {
        this.set('turnRightOn', false);
      } else {
        this.set('turnRightOn', true);
      }
    },
    observerScroll: Ember.observer('scrollPosition', function () {
      Ember.run.debounce(this, this.updateScrollIndicators, 200);
    }),
    turnLeftOn: false,
    turnRightOn: true,
    actions: {
      openLightbox: function openLightbox(image) {
        // Display image and overlay
        this.set('isOpen', true);
        this.set('activeImage', image); // Set height for setting up light box

        this.set('viewHeight', (0, _jquery.default)(window).height()); // Set scroll top to remember scroll position

        this.set('scrollTop', (0, _jquery.default)(window).scrollTop()); //Disable scrolling on body while light box is open

        var $body = (0, _jquery.default)(document.body);
        var oldWidth = $body.innerWidth();
        $body.css("overflow", "hidden");
        $body.css("position", "fixed");
        $body.width(oldWidth);
        (0, _jquery.default)('#modal-dialog').css("margin-top", "0"); // Set the lightbox height to be the view port height for overflow scrolling
        // when the image is too big for viewport.

        Ember.run.schedule("afterRender", this, function () {
          (0, _jquery.default)('.lightbox').height(this.viewHeight + 'px');
        }); // Hide nav menu's for light box image viewing

        var navbar = (0, _jquery.default)('.footer-menu, .navbar');
        navbar.css({
          visibility: 'hidden',
          opacity: '0',
          transition: 'visibility 0.3s, opacity 0.3s linear',
          position: 'absolute'
        }); // Hide syncing states bar

        var syncingBar = (0, _jquery.default)('.offline-mode-wrapper');
        syncingBar.css({
          display: 'none'
        }); //Move entire view to top above menu

        this.set('appMarginTop', (0, _jquery.default)('#app').css('margin-top'));
        (0, _jquery.default)('#app').css("cssText", "margin-top: 0 !important;");
      },
      closeLightbox: function closeLightbox() {
        // set flags to hide html
        this.set('isOpen', false);
        this.set('confirming', false);
        this.set('loaded', false);
        this.set('activeImage', null); //Allow scrolling on body once again

        var $body = (0, _jquery.default)(document.body);
        $body.css("position", "initial");
        $body.css("overflow", "scroll"); //Reset top margin to account for header menu

        (0, _jquery.default)('#app').css("cssText", "margin-top: ".concat(this.appMarginTop, " !important;")); //Make header and footer menues visible again

        var navbar = (0, _jquery.default)('.footer-menu, .navbar');
        navbar.css({
          visibility: 'visible',
          opacity: '1',
          transition: 'visibility 0.3s, opacity 0.3s linear',
          position: 'fixed'
        }); // Make syncing states bar visible again

        var syncingBar = (0, _jquery.default)('.offline-mode-wrapper');
        syncingBar.css({
          display: 'block'
        });
        (0, _jquery.default)('#app').css("margin-top", ""); // Do not scroll to remembered scroll position if modal is open

        if (!(0, _jquery.default)(".modal-dialog").length > 0) {
          (0, _jquery.default)('html, body').scrollTop(this.scrollTop);
        }
      },
      deleteRequest: function deleteRequest() {
        this.set('confirming', true);
      },
      deleteConfirm: function deleteConfirm(image) {
        if (this.parentModel) {
          this.parentModel[this.parentModelDeleteMethod](this.model, image);
        } else {
          this.model.deleteImage(this.fieldName, image);
        }

        this.set('confirming', false);
        this.send('closeLightbox', false);
      },
      deleteCancel: function deleteCancel() {
        this.set('confirming', false);
      }
    }
  });
  var _default = ImageGalleryComponent;
  _exports.default = _default;
});