define("safesite/controllers/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    siteController: Ember.inject.controller('site'),
    model: Ember.computed(function () {
      return this.get('siteController.model.locations');
    })
  });

  _exports.default = _default;
});