define("safesite/controllers/certificate/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CertificateIndexController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    siteController: Ember.inject.controller('site'),
    actions: {
      edit: function edit() {
        this.transitionToRoute('certificate.edit');
      },
      goBack: function goBack() {
        window.history.back();
      },
      archive: function archive() {
        this.toggleProperty('archiveMode');
      },
      cancelArchive: function cancelArchive() {
        this.set('archiveMode', false);
      },
      confirmArchive: function confirmArchive() {
        var self = this;
        this.model.set('is_deleted', true);
        this.model.save().then(function () {
          self.set('archiveMode', false);
          self.transitionToRoute('sites/actions');
        }).catch(function () {
          console.error("failed archive certificate");
        });
      }
    }
  });
  var _default = CertificateIndexController;
  _exports.default = _default;
});