define("safesite/models/inspection-template", ["exports", "ember-data", "safesite/models/abstract"], function (_exports, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Inspectiontemplate = _abstract.default.extend({
    name: _emberData.default.attr(),
    uuid: _emberData.default.attr(),
    version: _emberData.default.attr(),
    description: _emberData.default.attr(),
    created_date_time: _emberData.default.attr(),
    author: _emberData.default.attr(),
    has_hazards: _emberData.default.attr(),
    jurisdiction: _emberData.default.attr(),
    is_safesite_template: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization', {
      inverse: 'current_inspection_templates',
      async: true
    }),
    sections: _emberData.default.hasMany('section', {
      async: true
    }),
    sorted_sections: Ember.computed('sections', 'sections.@each.order', function () {
      return this.get('sections').sortBy('order');
    }),
    questions: _emberData.default.hasMany('question', {
      async: true
    }),
    sorted_questions: Ember.computed('questions', 'questions.@each.order', function () {
      return this.questions.sortBy('order');
    }),
    tags: _emberData.default.attr(),
    language_slug: _emberData.default.attr(),
    industries: Ember.computed('tags', function () {
      return this.tags.map(function (industry) {
        return industry.capitalize();
      }).join(', ');
    }),
    archived: _emberData.default.attr()
  });

  var _default = Inspectiontemplate;
  _exports.default = _default;
});