define("safesite/serializers/observation-online", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeQueryResponse: function normalizeQueryResponse(store, ModelClass, payload, id, requestName) {
      payload['observation-online'] = payload.observation;
      return this._super(store, ModelClass, payload, id, requestName);
    },
    normalizeResponse: function normalizeResponse(store, ModelClass, payload, id, requestName) {
      payload['observation-online'] = payload.observation;
      return this._super(store, ModelClass, payload, id, requestName);
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      data["observation"] = this.serialize(record, options);
    }
  });

  _exports.default = _default;
});