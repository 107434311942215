define("safesite/routes/sites/dashboard", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DashboardIndexRoute = _restricted.default.extend({
    renderTemplate: function renderTemplate() {
      this.render('dashboard/index', {
        controller: 'sites/dashboard'
      });
    }
  });

  var _default = DashboardIndexRoute;
  _exports.default = _default;
});