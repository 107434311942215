define("safesite/routes/hazard/index", ["exports", "safesite/mixins/export", "safesite/routes/restricted", "moment"], function (_exports, _export, _restricted, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var HazardIndexRoute = _restricted.default.extend(_export.default, {
    controllerModel: null,
    report_url: null,
    setupController: function setupController(controller, model) {
      this.set('report_url', 'hazard/' + model.get('id'));
      controller.set('model', model);
      controller.set('archiveMode', false);
      this.set('controllerModel', model);
    },
    actions: {
      addComment: function addComment(id, comment) {
        if (!Ember.isEmpty(comment)) {
          var newComment = {
            comment: comment,
            created_by: this.controllerFor('auth').get('model.id'),
            modified_by: this.controllerFor('auth').get('model.id'),
            created_date_time: _moment.default.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z',
            modified_date_time: _moment.default.utc().format('Y-MM-DDTHH:mm:ss.SSSSSS') + 'Z'
          };

          if (Ember.isBlank(this.get('controller.model.comments'))) {
            this.set('controller.model.comments', []);
          }

          this.get('controller.model.comments').pushObject(newComment);
          this.get('controller.model').save();
        }
      },
      archiveModal: function archiveModal() {
        this.send('renderModal', {
          controllerPath: 'modals/archive-item',
          controllerProperties: {
            itemName: 'Hazard',
            postArchiveRoute: 'actions.index',
            archiveItem: this.controllerModel
          }
        });
      }
    }
  });

  var _default = HazardIndexRoute;
  _exports.default = _default;
});