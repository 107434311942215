define("safesite/components/date-field", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DateField = Ember.Component.extend({
    classNames: ['datepicker'],
    i18n: Ember.inject.service(),
    hasFocus: false,
    dateValue: null,
    label: 'Date',
    showErrors: false,
    required: Ember.computed('errors.[]', 'showErrors', function () {
      return !Ember.isEmpty(this.errors) && this.showErrors;
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      var dateValue = self.get('dateValue');

      if (dateValue) {
        (0, _jquery.default)('#' + this.elementId).val(dateValue);
      }

      var datePickerInput = (0, _jquery.default)('#' + this.elementId).pickadate({
        clear: this.i18n.t('datePicker.clear'),
        close: this.i18n.t('datePicker.close'),
        today: this.i18n.t('datePicker.today'),
        monthsShort: [this.i18n.t('datePicker.month.jan'), this.i18n.t('datePicker.month.feb'), this.i18n.t('datePicker.month.mar'), this.i18n.t('datePicker.month.apr'), this.i18n.t('datePicker.month.may'), this.i18n.t('datePicker.month.jun'), this.i18n.t('datePicker.month.jul'), this.i18n.t('datePicker.month.aug'), this.i18n.t('datePicker.month.sep'), this.i18n.t('datePicker.month.oct'), this.i18n.t('datePicker.month.nov'), this.i18n.t('datePicker.month.dec')],
        weekdaysShort: [this.i18n.t('datePicker.week.sun'), this.i18n.t('datePicker.week.mon'), this.i18n.t('datePicker.week.tue'), this.i18n.t('datePicker.week.wed'), this.i18n.t('datePicker.week.thu'), this.i18n.t('datePicker.week.fri'), this.i18n.t('datePicker.week.sat')],
        showMonthsShort: true,
        format: 'yyyy-mm-dd',
        formatSubmit: 'yyyy-mm-dd',
        hiddenPrefix: 'last_test_value'
      }).pickadate('picker');
      datePickerInput.on('set', function (event) {
        var selectedDate = (0, _moment.default)(event.select).format('YYYY-MM-DD');

        if (event.select) {
          self.set('dateValue', selectedDate);
        }
      });
    }
  });
  var _default = DateField;
  _exports.default = _default;
});