define("safesite/components/safesite-textarea", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['safesite-textarea-component'],
    type: 'textarea',
    showCounter: false,
    disabled: false,
    showHr: false,
    counterDisplay: Ember.computed('value', 'maxlength', function () {
      if (this.maxlength) {
        if (this.value) {
          return "" + this.value.length + " / " + this.maxlength;
        } else {
          return "0 / " + this.maxlength;
        }
      } else {
        if (this.value) {
          return "" + this.value.length;
        } else {
          return "0";
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.footer = (0, _jquery.default)('.footer-menu');

      this.autoScroll = function () {
        (0, _jquery.default)('.navbar, .footer-menu').css('top', document.body.scrollTop + 'px');
      };
    },
    fixFixedPosition: function fixFixedPosition() {
      this.footer.css({
        visibility: 'hidden',
        opacity: '0',
        transition: 'visibility 0.3s, opacity 0.3s linear',
        position: 'absolute'
      });
    },
    resetFixedPosition: function resetFixedPosition() {
      this.footer.css({
        visibility: 'visible',
        opacity: '1',
        transition: 'visibility 0.3s, opacity 0.3s linear',
        position: 'fixed'
      }); // $(document).off('scroll', this.autoScroll);
      // $(document).off('touchmove', this.autoScroll);
    },
    focusIn: function focusIn(event) {
      // NOTE: The delay is required.
      if (this.device.get('isCordova')) {
        setTimeout(this.fixFixedPosition.bind(this), 100);
      } // Keep the fixed element absolutely positioned at the top
      // when the keyboard is visible
      // $(document).scroll(this.autoScroll);
      // $(document).on('touchmove', this.autoScroll);


      this.sendAction('focus-in', this, event);
    },
    focusOut: function focusOut(event) {
      // this._elementValueDidChange(event);
      if (this.device.get('isCordova')) {
        this.resetFixedPosition.bind(this)();
      }

      this.sendAction('focus-out', this, event);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.device.get('isCordova')) {
        this.resetFixedPosition.bind(this)();
      }
    },
    keyUp: function keyUp(event) {
      this.keyUpAction && this.keyUpAction.bind(this.cntrllr)();
    }
  });

  _exports.default = _default;
});