define("safesite/routes/hazard/edit", ["exports", "safesite/routes/restricted"], function (_exports, _restricted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var HazardEditRoute = _restricted.default.extend({
    i18n: Ember.inject.service(),
    model: function model() {
      return this.modelFor('hazard');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('showErrors', false);
      this.controllerFor('application').send('enterNewItemMode', this.i18n.t('hazard.add'));
    }
  });

  var _default = HazardEditRoute;
  _exports.default = _default;
});