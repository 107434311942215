define("safesite/controllers/modals/invite-site", ["exports", "jquery", "safesite/config/environment", "email-validator"], function (_exports, _jquery, _environment, _emailValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InviteSiteModalController = Ember.Controller.extend({
    authController: Ember.inject.controller('auth'),
    applicationController: Ember.inject.controller('application'),
    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),
    basicUserCreator: Ember.inject.service(),
    email: null,
    mobile: null,
    site: null,
    fromuser: null,
    isLoading: false,
    isValid: true,
    isMobileApp: Ember.computed(function () {
      return _environment.default.cordova;
    }),
    actions: {
      sendInvite: function sendInvite() {
        var self = this;
        self.setProperties({
          'isLoading': true,
          'isValid': true
        });

        if (Ember.isEmpty(self.get('email')) && Ember.isEmpty(self.get('mobile'))) {
          self.woof.danger(self.i18n.t('default-form-messages.enter.valid.email.or.mobile.number'));
          self.setProperties({
            'isLoading': false,
            'isValid': false
          });
        } else {
          if (!Ember.isEmpty(self.get('email'))) {
            if (!(0, _emailValidator.validate)(self.email)) {
              self.woof.danger(self.i18n.t('default-form-messages.enter.valid.email'));
              self.setProperties({
                'isLoading': false,
                'isValid': false
              });
            }
          }

          if (!Ember.isEmpty(self.get('mobile'))) {
            if ((0, _jquery.default)('.input-mobile').hasClass('error')) {
              self.woof.danger(self.i18n.t('default-form-messages.enter.valid.mobile.number'));
              self.setProperties({
                'isLoading': false,
                'isValid': false
              });
            }
          }
        }

        if (self.get('isValid')) {
          self.get('metrics').trackEvent({
            event: 'm_Project_Invite_success'
          });

          if (self.get('authController.model.name_or_email')) {
            self.set('fromuser', self.get('authController.model.name_or_email'));
          } else {
            self.set('fromuser', self.get('authController.model.username'));
          }

          self.send('sendEmail');
        }
      },
      sendEmail: function sendEmail() {
        this.set('email', this.get('email').toLowerCase());
        var data = this.getProperties('email', 'fromuser', 'site.id');
        var personJSON = this.basicUserCreator.generateObject({
          referral: 'project-invite',
          company_name: '',
          email: this.email,
          firstName: '',
          lastName: '',
          createdBy: this.session.model.id,
          modifiedBy: this.session.model.id,
          projects: [this.site.id],
          memberType: 'member'
        });
        this.site.invitees.pushObject(personJSON);
        this.site.save();
        this.set('isLoading', false);

        if (this.get('applicationController.isOnline')) {
          this.woof.success(this.i18n.t('sites.invite-modal.invitation.sent.online.alert'));
        } else {
          this.woof.success(this.i18n.t('sites.invite-modal.invitation.sent.offline.alert'));
        }

        this.set('email', null);
        (0, _jquery.default)('.modal').modal('hide');
      }
    }
  });
  var _default = InviteSiteModalController;
  _exports.default = _default;
});