define("safesite/components/hazard-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HazardItemComponent = Ember.Component.extend({
    layoutName: 'components/hazard-item',
    record: null,
    showProjectName: false
  });
  var _default = HazardItemComponent;
  _exports.default = _default;
});