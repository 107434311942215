define("safesite/app", ["exports", "safesite/resolver", "ember-load-initializers", "safesite/config/environment", "@datadog/browser-logs"], function (_exports, _resolver, _emberLoadInitializers, _environment, _browserLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _browserLogs.datadogLogs.init({
    env: _environment.default.environment,
    version: _environment.default.appVersion,
    clientToken: _environment.default.DATADOG.clientToken,
    service: 'safesite-mobile',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    useSecureSessionCookie: true,
    sampleRate: 100,
    forwardConsoleLogs: ['error', 'info'],
    beforeSend: function beforeSend(log) {
      if (!['production', 'staging1', 'staging'].includes(_environment.default.environment)) {
        return false; // discard event
      } // if user is offline, store errors in LocalStorage and discard event


      if (!window.navigator.onLine) {
        var key = _environment.default.DATADOG.localStorageKey;

        if (!localStorage.getItem(key)) {
          localStorage.setItem(key, JSON.stringify([log]));
        } else {
          var errors = JSON.parse(localStorage.getItem(key));
          errors.push(log);
          localStorage.setItem(key, JSON.stringify(errors));
        }

        return false; // discard event
      }
    }
  });

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});