define("safesite/templates/components/custom-dropdown-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cyA1zEZM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"dropdown\"],[10,\"class\",\"modal\"],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[10,\"aria-labelledby\",\"myModalLabel\"],[10,\"aria-hidden\",\"false\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n                    \"],[7,\"h4\",true],[8],[1,[22,\"icon\"],true],[0,\" \"],[1,[22,\"title\"],false],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"aria-hidden\",\"true\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[7,\"i\",true],[10,\"class\",\"icon-ss-close icon\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n                \"],[14,1],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n                \"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-lg btn-block\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"custom-dropdown.button.cancel\"],null],false],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[2,\" /.modal-content \"],[0,\"\\n    \"],[9],[2,\" /.modal-dialog \"],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/custom-dropdown-modal.hbs"
    }
  });

  _exports.default = _default;
});