define("safesite/utilities/poller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Poller = Ember.Object.extend({
    _interval: 1000,
    _currentlyExecutedFunction: null,
    start: function start(context, pollingFunction) {
      this.set('_currentlyExecutedFunction', this._schedule(context, pollingFunction, [].slice.call(arguments, 2)));
    },
    stop: function stop() {
      Ember.run.cancel(this._currentlyExecutedFunction);
    },
    _schedule: function _schedule(context, func, args) {
      return Ember.run.later(this, function () {
        this.set('_currentlyExecutedFunction', this._schedule(context, func, args));
        func.apply(context, args);
      }, this._interval);
    },
    setInterval: function setInterval(interval) {
      this.set('_interval', interval);
    }
  });
  var _default = Poller;
  _exports.default = _default;
});