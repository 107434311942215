define("safesite/initializers/dropdown-link-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:dropdown-link-site', 'session', 'controller:auth');
  }

  var _default = {
    name: 'dropdown-link-site',
    initialize: initialize
  };
  _exports.default = _default;
});