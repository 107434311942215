define("safesite/initializers/component-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:custom-dropdown-btn', 'store', 'service:store');
    application.inject('component:custom-dropdown-nested-btn', 'store', 'service:store');
    application.inject('component:question-item', 'store', 'service:store');
    application.inject('component:topic-item', 'store', 'service:store');
    application.inject('component:add-attendees', 'store', 'service:store');
    application.inject('component:comment-item', 'store', 'service:store');
    application.inject('component:download-template-list-item', 'store', 'service:store');
    application.inject('service:version-checker', 'store', 'service:store');
    application.inject('service:site', 'store', 'service:store');
    application.inject('service:synchronization-bar', 'store', 'service:store');
  }

  var _default = {
    name: 'component-store',
    initialize: initialize
  };
  _exports.default = _default;
});