define("safesite/routes/certificate/index", ["exports", "safesite/routes/restricted", "safesite/mixins/reset-scroll-position"], function (_exports, _restricted, _resetScrollPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CertificateIndexRoute = _restricted.default.extend(_resetScrollPosition.default, {
    controllerModel: null,
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('archiveMode', false);
      this.set('controllerModel', model);
    },
    actions: {
      archiveModal: function archiveModal() {
        this.send('renderModal', {
          controllerPath: 'modals/archive-item',
          controllerProperties: {
            itemName: 'Certificate',
            postArchiveRoute: 'sites/actions',
            archiveItem: this.controllerModel
          }
        });
      }
    }
  });

  var _default = CertificateIndexRoute;
  _exports.default = _default;
});