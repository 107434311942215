define("safesite/helpers/date-now", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function () {
    return (0, _moment.default)().format("DD-MMM-YYYY");
  });

  _exports.default = _default;
});