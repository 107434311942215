define("safesite/templates/components/dropdown-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ISvNdlfl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ss-flex ss-flex-align-center text-center dropdown-link header-font-primary\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"label\"],false],[0,\"\\n        \"],[7,\"i\",false],[12,\"class\",\"icon icon-larger icon-delete\"],[3,\"action\",[[23,0,[]],\"clearValue\"],[[\"bubbles\"],[false]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"spinner spinner-tiny center\"],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[22,\"emptyLabel\"],false],[0,\" \"],[7,\"i\",true],[10,\"class\",\"icon icon-larger icon-chevron-right\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/dropdown-link.hbs"
    }
  });

  _exports.default = _default;
});