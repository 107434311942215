define("safesite/templates/services/nav-menu-tab-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b+icXsGr",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[4,\"if\",[[24,[\"navMenuTabBar\",\"tabs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ss-flex ss-flex-space-around\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"navMenuTabBar\",\"tabs\"]]],null,{\"statements\":[[0,\"            \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[29,[\"ss-action-tab \",[28,\"if\",[[23,1,[\"isActive\"]],\"ss-action-tab-active\"],null]]]],[3,\"action\",[[23,0,[]],\"navMenuTabBarSelectTab\",[23,1,[\"tabName\"]]]],[8],[0,\"\\n                \"],[1,[23,1,[\"label\"]],true],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/services/nav-menu-tab-bar.hbs"
    }
  });

  _exports.default = _default;
});