define("safesite/controllers/modals/project-invite", ["exports", "jquery", "safesite/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectInviteModalConfirmController = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    synchronization: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    applicationController: Ember.inject.controller('application'),
    i18n: Ember.inject.service(),
    requiredFields: ['new_password1', 'new_password2', 'firstName', 'lastName'],
    deeplink: null,
    new_password1: null,
    new_password2: null,
    mobile: null,
    lastName: null,
    firstName: null,
    invitedMode: true,
    signUpMode: false,
    passwordVisible: false,
    passwordType: Ember.computed('passwordVisible', function () {
      if (this.passwordVisible) {
        return 'text';
      } else {
        return 'password';
      }
    }),
    passwordMsg: Ember.computed('i18n.locale', 'passwordVisible', function () {
      if (this.passwordVisible) {
        return this.i18n.t('auth.password.hide');
      } else {
        return this.i18n.t('auth.password.show');
      }
    }),
    deeplink_stringify: Ember.computed('deeplink', function () {
      return JSON.stringify(this.deeplink);
    }),
    deeplink_user_id: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.user_id');
    }),
    deeplink_user_email: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.email');
    }),
    deeplink_uid: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.uid');
    }),
    deeplink_user_token: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.user_token');
    }),
    deeplink_activation_key: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.activation_key');
    }),
    deeplink_invite_type: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.invite_type');
    }),
    modalTitle: Ember.computed('deeplink', function () {
      return "You've confirmed your email " + this.get('deeplink.deep_data.email') + "!";
    }),
    user_is_onboarded: Ember.computed('deeplink', function () {
      return this.get('authController.model.profile.is_onboarded');
    }),
    isLoading: false,
    actions: {
      togglePasswordType: function togglePasswordType() {
        this.toggleProperty('passwordVisible');
      },
      nextSignUp: function nextSignUp() {
        if (!this.user_is_onboarded) {
          this.set('invitedMode', false);
          this.set('signUpMode', true);
        } else {
          (0, _jquery.default)('.modal').modal('hide');
          this.woof.success('Invite Accepted');
        }
      },
      cancel: function cancel() {
        (0, _jquery.default)('.modal').modal('hide');
      },
      signUp: function signUp() {
        var self = this;
        self.set('isLoading', true); // Collect missing fields

        var missingFields = self.get('requiredFields').reduce(function (acc, val) {
          if (Ember.isEmpty(self.get(val))) {
            acc.push(val);
          }

          return acc;
        }, []);
        var default_language = 0;

        switch (self.get('i18n.locale')) {
          case "en":
            default_language = 0;
            break;

          case "es":
            default_language = 1;
            break;

          case "pt":
            default_language = 2;
            break;

          case "fr":
            default_language = 3;
            break;
        }

        if (!Ember.isEmpty(missingFields)) {
          self.woof.danger('This following fields are mandatory: ' + missingFields.reduce(function (acc, val) {
            return acc + ', ' + val;
          }), "");
          self.set('isLoading', false);
        } else {
          if (self.get('new_password1')) {
            if (!(self.get('new_password1') == self.get('new_password2'))) {
              self.woof.danger('Passwords does not match');
              self.set('isLoading', false);
            } else {
              var data = {
                'uid': self.get('deeplink_uid'),
                'activation_key': self.get('deeplink_activation_key'),
                'user_token': self.get('deeplink_user_token'),
                'email': self.get('deeplink_user_email'),
                'new_password1': self.get('new_password1'),
                'new_password2': self.get('new_password2'),
                'firstName': self.get('firstName'),
                'lastName': self.get('lastName'),
                'mobile': self.get('mobile'),
                'default_language': default_language
              };

              _jquery.default.ajax({
                data: data,
                async: true,
                type: 'POST',
                url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/inviteAccept'
              }).then(function (data) {
                self.set('isLoading', false);
                (0, _jquery.default)('#project-invite-modal-id').modal('hide');
                self.woof.success('Invite Accepted');
                self.get('authController').send('setLocalStorageVars', {
                  response: data
                });
                localStorage.setItem('synchronization_start_time', new Date().getTime());
                self.synchronization.startSignUpCreateCouchDb();
              }).catch(function (e) {
                self.set('isLoading', false);
                self.woof.danger(e.responseText);
              });
            }
          }
        }
      }
    }
  });
  var _default = ProjectInviteModalConfirmController;
  _exports.default = _default;
});