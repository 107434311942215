define("safesite/templates/modals/release-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IKKC4bqF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"keyboard\",\"backdrop\",\"CancelButtonText\",\"hideCancel\"],[\"closeModal\",\"false\",\"static\",\"OK\",false]],{\"statements\":[[0,\"    \"],[7,\"form\",true],[10,\"role\",\"form\"],[10,\"class\",\"form-group ss-modal-form-group\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ss-flex ss-flex-column ss-flex-align-center release-notes-modal\"],[8],[0,\"\\n            \"],[1,[28,\"image\",null,[[\"image\"],[\"assets/images/wifi-animation.gif\"]]],false],[0,\"\\n\\n            \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[1,[28,\"t\",[\"release-notes.title\"],null],false],[9],[0,\"\\n            \"],[7,\"br\",true],[8],[9],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"text-center\"],[8],[1,[28,\"t\",[\"release-notes.message\"],null],false],[9],[0,\"\\n            \"],[7,\"br\",true],[8],[9],[0,\"\\n            \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/release-notes.hbs"
    }
  });

  _exports.default = _default;
});