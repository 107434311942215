define("safesite/controllers/reset", ["exports", "jquery", "safesite/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ResetController = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    i18n: Ember.inject.service(),
    authController: Ember.inject.controller('auth'),
    requiredFields: ['new_password1'],
    deeplink: null,
    username: null,
    new_password1: null,
    passwordVisible: false,
    passwordType: Ember.computed('passwordVisible', function () {
      if (this.passwordVisible) {
        return 'text';
      } else {
        return 'password';
      }
    }),
    passwordMsg: Ember.computed('i18n.locale', 'passwordVisible', function () {
      if (this.passwordVisible) {
        return this.i18n.t('auth.password.hide');
      } else {
        return this.i18n.t('auth.password.show');
      }
    }),
    deeplink_stringify: Ember.computed('deeplink', function () {
      return JSON.stringify(this.deeplink);
    }),
    deeplink_user_id: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.user_id');
    }),
    deeplink_user_email: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.email');
    }),
    deeplink_uid: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.uid');
    }),
    deeplink_token: Ember.computed('deeplink', function () {
      return this.get('deeplink.deep_data.token');
    }),
    isLoading: false,
    actions: {
      togglePasswordType: function togglePasswordType() {
        this.toggleProperty('passwordVisible');
      },
      reset: function reset() {
        var self = this;
        self.set('isLoading', true); // Collect missing fields

        var missingFields = self.get('requiredFields').reduce(function (acc, val) {
          if (Ember.isEmpty(self.get(val))) {
            acc.push(val);
          }

          return acc;
        }, []);

        if (!Ember.isEmpty(missingFields)) {
          self.woof.danger('This following fields are mandatory: ' + missingFields.reduce(function (acc, val) {
            return acc + ', ' + val;
          }), "");
          self.set('isLoading', false);
        } else {
          if (self.get('new_password1')) {
            var data = {
              'uid': self.get('deeplink_uid'),
              'token': self.get('deeplink_token'),
              'email': self.get('deeplink_user_email'),
              'new_password1': self.get('new_password1'),
              'new_password2': self.get('new_password1')
            };

            _jquery.default.ajax({
              data: data,
              async: true,
              type: 'POST',
              url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/passwordChange'
            }).then(function () {
              self.get('metrics').trackEvent({
                event: 'm_Account_Password_change',
                category: 'account',
                action: 'password_changes'
              });
              self.authController.set('username', self.deeplink_user_email);
              self.authController.set('password', self.new_password1);
              self.set('isLoading', false);
              self.transitionToRoute('auth');
              self.woof.success('Updated password, please login');
            }, function (e) {
              self.set('isLoading', false);
              self.woof.danger(e.responseText);
            });
          }
        }
      },
      goBack: function goBack() {
        var self = this;
        self.transitionToRoute('splash');
      }
    }
  });
  var _default = ResetController;
  _exports.default = _default;
});