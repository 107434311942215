define("safesite/templates/modals/join-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tWPJkFeG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"action\",\"title\"],[\"closeModal\",[24,[\"title\"]]]],{\"statements\":[[0,\"    \"],[7,\"form\",false],[12,\"role\",\"form\"],[12,\"class\",\"form-group \"],[3,\"action\",[[23,0,[]],\"joinSite\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group ss-modal-form-group\"],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"type\",\"classNames\",\"autocomplete\",\"autocorrect\",\"autocapitalize\",\"spellcheck\"],[[24,[\"pin\"]],\"Enter Project PIN (eg. 1242)\",\"number\",\"form-control \\n                input-lg\",\"off\",\"off\",\"off\",\"false\"]]],false],[0,\"\\n            \"],[1,[28,\"loading-btn\",null,[[\"classNames\",\"buttonLabel\",\"isLoading\",\"disabled\"],[\"btn btn-default btn-lg btn-primary btn-block\",\"Join Project\",[24,[\"isLoading\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[1,[22,\"error\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/modals/join-site.hbs"
    }
  });

  _exports.default = _default;
});