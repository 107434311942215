define("safesite/router", ["exports", "safesite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    metrics: Ember.inject.service(),
    rootURL: _environment.default.rootURL,
    init: function init() {
      var _arguments = arguments,
          _this = this;

      this.on('routeDidChange', function () {
        _this._super.apply(_this, _toConsumableArray(_arguments));

        _this._trackPage();
      });
    },
    _trackPage: function _trackPage() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = document.location.pathname;

        var title = _this2.getWithDefault('currentRouteName', 'unknown');

        _this2.metrics.trackPage({
          page: page,
          title: title
        });
      });
    }
  });

  var _default = Router.map(function () {
    this.route('splash');
    this.route('welcome');
    this.route('auth', {
      path: '/login'
    });
    this.route('terms');
    this.route('reset');
    this.route('signup');
    this.route('signup-organization-set-up');
    this.route('sites', {
      resetNamespace: true
    }, function () {
      this.route('create');
    });
    this.route('sites/notifications', {
      resetNamespace: true
    }, function () {});
    this.route('userprofile', {
      resetNamespace: true
    });
    this.route('certificates', {
      resetNamespace: true
    }, function () {
      this.route('index');
      this.route('create');
    });
    this.route('certificate', {
      path: 'certificate/:certificate_id',
      resetNamespace: true
    }, function () {
      this.route('edit');
    });
    this.route('sites/actions', {
      resetNamespace: true
    }, function () {});
    this.route('sites/dashboard', {
      resetNamespace: true
    }, function () {});
    this.route('sites/inspections', {
      resetNamespace: true
    }, function () {});
    this.route('sites/hazards', {
      resetNamespace: true
    }, function () {});
    this.route('sites/observations', {
      resetNamespace: true
    }, function () {});
    this.route('sites/meetings', {
      resetNamespace: true
    }, function () {});
    this.route('sites/incidentreports', {
      resetNamespace: true
    }, function () {});
    this.route('sites/equipments', {
      resetNamespace: true
    }, function () {});
    this.route('site', {
      path: 'sites/:site_id',
      resetNamespace: true
    }, function () {
      this.route('notifications', {
        resetNamespace: true
      }, function () {});
      this.route('actions', {
        resetNamespace: true
      }, function () {});
      this.route('dashboard', {
        resetNamespace: true
      }, function () {});
      this.route('edit');
      this.route('inspections', {
        resetNamespace: true
      }, function () {
        this.route('completed');
        this.route('create');
      });
      this.route('inspection', {
        path: 'inspection/:inspection_id',
        resetNamespace: true
      }, function () {
        this.route('edit');
      });
      this.route('equipments', {
        resetNamespace: true
      }, function () {
        this.route('create');
      });
      this.route('equipment', {
        path: 'equipment/:equipment_id',
        resetNamespace: true
      }, function () {
        this.route('edit');
      });
      this.route('incidentreports', {
        resetNamespace: true
      }, function () {
        this.route('create');
        this.route('completed');
      });
      this.route('incidentreport', {
        path: 'incidentreport/:incidentReport_id',
        resetNamespace: true
      }, function () {
        this.route('edit');
      });
      this.route('observations', {
        resetNamespace: true
      }, function () {
        this.route('create');
      });
      this.route('observation', {
        path: 'observation/:observation_id',
        resetNamespace: true
      }, function () {
        this.route('edit');
      });
      this.route('hazards', {
        resetNamespace: true
      }, function () {
        this.route('create');
        this.route('completed');
      });
      this.route('hazard', {
        path: 'hazard/:hazard_id',
        resetNamespace: true
      }, function () {
        this.route('edit');
      });
      this.route('meetings', {
        resetNamespace: true
      }, function () {
        this.route('create');
        this.route('completed');
      });
      this.route('meeting', {
        path: 'meeting/:meeting_id',
        resetNamespace: true
      }, function () {
        this.route('edit');
        this.route('add-attendees');
      });
    });
  });

  _exports.default = _default;
});