define("safesite/templates/components/item-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k4Z0tvet",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"comment-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"comments\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"alert\"],[8],[0,\"\\n        \"],[7,\"strong\",true],[8],[1,[23,1,[\"comment\"]],false],[9],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"small\",true],[8],[0,\"\\n            \"],[1,[28,\"format-yyyymmddthhmm-date-time\",[[23,1,[\"created_date_time\"]]],null],false],[0,\" \"],[1,[23,1,[\"created_by\",\"name_or_email\"]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"components.item-comments.message.noComment\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"form\",true],[8],[0,\"\\n        \"],[1,[28,\"safesite-textarea\",null,[[\"value\",\"classNames\",\"placeholder\",\"maxlength\",\"showCounter\"],[[24,[\"comment\"]],\"form-control\",[28,\"t\",[\"components.item-comments.placeholder.comment\"],null],2000,true]]],false],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-warning btn-lg btn-block\"],[3,\"action\",[[23,0,[]],\"addComment\"]],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"components.item-comments.button.add\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "safesite/templates/components/item-comments.hbs"
    }
  });

  _exports.default = _default;
});